import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import DocumentWorkflow from "../../../../images/blog/document-workflow.png";


const DocumentWorkflowComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                Transforming Document Workflows: OVCODE eSign Explained
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Monday, 15 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={DocumentWorkflow} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1 }}>
                                In the era of digital transformation, businesses are seeking ways to streamline their document
                                processes while ensuring security and efficiency. OVCODE eSign emerges as a solution,
                                revolutionizing the way businesses handle documents in the digital landscape.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>The Challenges of Traditional Document Signatures</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>1.  Tedious Processes:</Typography>
                                    <Typography>
                                        Traditional paper-based signatures involve cumbersome processes, from physical
                                        document preparation to in-person signing. This can lead to delays and inefficiencies.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>2. Security Concerns:</Typography>
                                    <Typography>
                                        The security of paper documents is prone to risks such as loss, forgery, or unauthorized
                                        access. Traditional signatures lack the robust security features required in the digital age.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>3. Geographical Barriers:</Typography>
                                    <Typography>
                                        When signatories are spread across different locations, traditional signatures can
                                        introduce delays and logistical challenges, hindering the pace of business operations.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>OVCODE eSign: Streamlining Document Workflows</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>1.  Effortless Digital Signatures:</Typography>
                                    <Typography>
                                        OVCODE eSign facilitates the transition to digital signatures, enabling users to sign documents
                                        effortlessly from any location. This reduces the time and effort involved in the signing process.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>2. Blockchain Security:</Typography>
                                    <Typography>
                                        Leveraging blockchain technology, OVCODE eSign ensures the security and immutability of
                                        digital signatures. Each signature is cryptographically secured, providing a tamper-proof
                                        record of the signing event.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>3. Global Accessibility:</Typography>
                                    <Typography>
                                        OVCODE eSign breaks down geographical barriers by allowing signatories to digitally sign
                                        documents from anywhere in the world. This global accessibility enhances collaboration and
                                        accelerates business processes.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>4. Audit Trails for Transparency:</Typography>
                                    <Typography>
                                        Every action within OVCODE eSign is recorded on the blockchain, creating an indelible audit
                                        trail. This not only enhances transparency but also serves as a valuable tool for compliance
                                        purposes.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Real-World Applications</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>1.   Contract Management:</Typography>
                                    <Typography>
                                        In the realm of contract management, OVCODE eSign expedites the entire process. Contracts
                                        can be drafted, reviewed, and signed digitally, reducing turnaround times and minimizing the risk
                                        of errors.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>2. Approvals and Authorizations:</Typography>
                                    <Typography>
                                        OVCODE eSign facilitates quick approvals and authorizations for various business documents.
                                        From purchase orders to project proposals, the digital signature process ensures swift decision-making
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }}>3. Legal Documents:</Typography>
                                    <Typography>
                                        In the legal domain, where the authenticity of signatures is paramount, OVCODE eSign provides
                                        a secure and legally recognized method for signing and validating legal documents.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'center' }}>OVCODE eSign: Redefining Document Efficiency</Typography>
                            <Typography sx={{ mt: 1, mb: 1 }}>
                                OVCODE eSign is not just a tool; it's a paradigm shift in how businesses handle documents. By
                                combining the ease of digital signatures with the security of blockchain, it paves the way for a
                                future where document workflows are efficient, secure, and seamlessly integrated into the
                                digital fabric of organizations.
                            </Typography>
                            <Typography>
                                In conclusion, OVCODE eSign stands as a testament to the transformative power of
                                technology in simplifying complex document workflows. As businesses embrace the digital
                                era, OVCODE eSign emerges as a key ally in their journey toward greater efficiency and
                                security.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-esign-unlocking-efficiency"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Unlocking Efficiency: A Guide to Implementing eSign in Your Workflow
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Offer practical tips and advice on integrating eSign into various business workflows.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550 }}>
                                January 15, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-emark-visual-content"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Visual Content Security: How eMark Protects Your Digital Assets
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Provide insights into how OVCODE eSign streamlines document processes for businesses.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                January 15, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DocumentWorkflowComponent