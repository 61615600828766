import {Box, Card, CardMedia, Grid, Link, Typography} from '@mui/material';
import OVCODEGOVERNMENT from '../../images/solutions/government.png';
import OVCODEOVSIGN from '../../images/solutions/ovsign.png';
import OVCODEEID from '../../images/solutions/eid.png';
import government from '../../images/solutions/government-casev2.jpg';
import government2 from '../../images/solutions/government-mdr.jpg';
import OVSignLogo from '../../icons/eSignSuperFinalLogoSize.png';
import eIDLogo from '../../icons/eIDSuperFinalLogoSize.png';

const products_images = [
    {   
        title: "eID",
        name : "Electronic Identification System",
        secondName : "Electronic Identification System",
        icon: OVCODEEID,
        description : 
            <Card sx={{ boxShadow: 'none', cursor:'default'}} >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} sx={{alignItems: 'center', justifyContent: 'center', alignSelf: 'center'}}>
                        <CardMedia
                            component="img"
                            image={eIDLogo}
                            style={{objectFit: 'contain', margin:'auto', width: '90%'}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography sx={{margin: 0, fontWeight: 400, fontSize: '1rem', lineHeight: 1.5,  textAlign: 'start',  color: 'rgb(100, 110, 115)', textAlign: 'justify', textJustify: 'inter-word'}}>
                            Experience the seamless synthesis of conveniece and data security with OVI, empowering the ID stakeholder with a hassle-free, yet highly secure, personal identity verification process.
                        </Typography>
                        <br/>
                        <Grid item xs={12}>
                            <Grid container spacing={2} sx={{margin: 'auto'}}>
                                <Grid item xs={6} sx={{textAlign: 'center', margin: 'auto'}}>
                                    <Typography sx={{margin: 0, fontWeight: 400, fontSize: '1rem', textAlign: 'start',  color: 'rgb(100, 110, 115)'}}>
                                        Click <Link target="_blank" href="https://eid.ovcode.com/" underline="none"><b>here</b></Link> to sign
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign: 'center', margin: 'auto'}}>
                                    <Link target="_blank" href="https://www.youtube.com/embed/WnzgtuJXfzo" underline="none">
                                        <Typography sx={{margin: 0, fontWeight: 600, fontSize: '1rem', textAlign: 'start'}}>
                                            View Demo
                                        </Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>,
        text : "Experience the seamless synthesis of conveniece and data security with OVI.",
    },
    
    {
        title: "eSign",
        name : "Sign Document Workflow",
        secondName : "Sign Document Workflow",
        icon: OVCODEOVSIGN,
        description : 
            <Card sx={{ boxShadow: 'none', cursor:'default'}} >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} sx={{alignItems: 'center', justifyContent: 'center', alignSelf: 'center'}}>
                        <CardMedia
                            component="img"
                            image={OVSignLogo}
                            style={{objectFit: 'contain', margin:'auto', width: '90%'}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography sx={{margin: 0, fontWeight: 400, fontSize: '1rem', lineHeight: 1.5,  textAlign: 'start',  color: 'rgb(100, 110, 115)', textAlign: 'justify', textJustify: 'inter-word'}}>
                            Streamline your document approval process with eSign --- the ultimate solution for effortless and secure digital signing in your business document workflow.
                        </Typography>
                        <br/>
                        <Grid item xs={12}>
                            <Grid container spacing={2} sx={{margin: 'auto'}}>
                                <Grid item xs={6} sx={{textAlign: 'center', margin: 'auto'}}>
                                    <Typography sx={{margin: 0, fontWeight: 400, fontSize: '1rem', textAlign: 'start',  color: 'rgb(100, 110, 115)'}}>
                                    Click <Link target="_blank" href="https://esign.ovcode.com/" underline="none"><b>here</b></Link> to sign
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign: 'center', margin: 'auto'}}>
                                    <Link target="_blank" href="https://www.youtube.com/embed/ThUyqAC5Frc" underline="none">
                                        <Typography sx={{margin: 0, fontWeight: 600, fontSize: '1rem', textAlign: 'start'}}>
                                            View Demo
                                        </Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>           
                    </Grid>
                </Grid>
            </Card>,
        text : "Streamline your document approval process with eSign --- the ultimate solution for effortless and secure digital signing",
    },
    
    {
        
        title: "eMark",
        name :  <p>Transforming Document Authentication <br/>  with Trust</p> ,
        secondName : "OVCODE is transforming Government Documentation with Trust",
        icon: OVCODEGOVERNMENT,
        description : 
            <Card sx={{ boxShadow: 'none', cursor:'default'}} >
                <Typography sx={{fontSize: 15, margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                    OVCODE will aid the government in authenticating, verifying, and securely storing crucial documentation.
                    <br/><br/>
                    OVCODE aims to obsolete counterfeit and fake documentation using four core technologies: blockchain identity, digital twinning, permanent storage, and artificial intelligence. OVCODE makes the government OVCODE-enabled to upgrade their systems.
                </Typography>

                <br/>

                <p style={{fontSize: '1.2rem', margin: 0, lineHeight: 1.6, color: 'rgb(45, 55, 72)', fontWeight: 700}}>
                    Use Case for Government Documentation
                </p>

                <br/>
                
                <CardMedia
                    component="img"
                    image={government}
                    style={{objectFit: 'contain', margin:'auto', width: '90%'}}
                />
                
                <br/>
                <Typography sx={{fontSize: 15, margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                    OVCODE will provide an OVCODE-enabled QR. When scanned, the QR will return the following: document origin, history, publisher credentials, and details. The users will have the option to view the document preview or save a digital copy, depending on access rights set by the government administrator.
                </Typography>

                <br />

                <p style={{fontSize: '1.2rem', margin: 0, lineHeight: 1.6, color: 'rgb(45, 55, 72)', fontWeight: 700}}>
                    Multiple Documentation Requirement
                </p>

                <br />

                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <CardMedia
                        component="img"
                        image={government2}
                        style={{objectFit: 'contain', margin:'auto'}}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography sx={{fontSize: 15, margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                        OVCODE also designed an automated verification for applications with multiple documentary requirements.
                        <br/><br/>
                        Government agencies will no longer need to verify documents one-by-one. Instead, applicants will only have to enter previously OVCODE-verified documents. This will streamline applications and speed up government transactions.
                    </Typography>
                    </Grid>
                </Grid>

                <br/>

                <p style={{fontSize: '1.2rem', margin: 0, lineHeight: 1.6, color: 'rgb(45, 55, 72)', fontWeight: 700}}>
                   Insight
                </p>

                <br/>
                <Typography sx={{fontSize: 15, margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                    OVCODE Insight provides the government with information on how data is being used within their platform. Insight also uses artificial intelligence (AI) technology to learn documentary formats, and eventually detects which documents are real and fake.
                    <br/><br/>
                    The vision is for applicants to no longer queue up in government offices. They will have an online portal where they can process transactions and submit OVCODE-verified documents.
                    <br/><br/>
                    In return, they will also receive authenticated digital copies of their documentary requests which are verifiable by any recipient using online government platforms.
                </Typography>
            </Card>,
        text : "OVCODE will aid the government in authenticating, verifying, and securely storing crucial documentation.",
    }
]

export default products_images