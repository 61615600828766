import React, {useState, useEffect} from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, Box} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OVCodeLogo from "../../icons/new-ovcode-logo.png";
import CancelIcon from '@mui/icons-material/Cancel';

const UnverifiedDataDialog = (props) => {

    const {openUnverifiedData, setOpenUnverifiedData, breakpoints} = props;

    const [isVerifyingReference, setVerifyingReference] = useState(true);
    const [isVerifyingHash, setVerifyingHash] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setVerifyingReference(false);
            setVerifyingHash(false);
        }, 4000)
    })

    const handleCloseVerifyDataDialog = (e) => {
        setOpenUnverifiedData(localStorage.setItem("isUnverifiedDialog", false));

        localStorage.removeItem("isVerifiedDialog");
        localStorage.removeItem("isUnverifiedDialog");
        
        window.location.href = "/verification"
    }

    const CustomDialogTitle = (props) => {
        const {children, onClose, ...other} = props;
      
        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}

                {onClose ? 
                    (
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{position: "absolute", right: 8, top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) 
                : 
                    null
                }
          </DialogTitle>
        )
    }
      

    return (
        <Dialog
            maxWidth={"sm"}
            fullWidth
            open={openUnverifiedData}
            onClose={(e) => handleCloseVerifyDataDialog(e)}
            sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1}}
        >
            
            {(isVerifyingReference || isVerifyingHash) ? 
                <DialogContent>
                    <Box sx={{borderRadius: "4px", display: "flex", background: "#0178f240", height: "50px", padding: "12px"}}>
                        <Typography variant="subtitle1" sx={{color: "#0178f2c7", fontSize: 16}}>
                            Verifying document...
                        </Typography>
                    </Box>
                </DialogContent>

            :
                <>
                    <CustomDialogTitle id="customized-dialog-title" onClose={(e) => handleCloseVerifyDataDialog(e)}>
                        <img alt="" src={OVCodeLogo} style={{width: 130}} /> 
                    </CustomDialogTitle>

                    <DialogContent dividers sx={{padding: 0}}>
                        <Box sx={{justifyContent: "center", display: "flex", background: "red", height: "50px", padding: "12px"}}>
                            <IconButton>
                                <CancelIcon style={{color: "#fff", marginTop: "4px", marginRight: "-3px"}} />
                            </IconButton>
                            <Typography variant="subtitle1" sx={{color: "#fff", fontSize: 17}}>
                                DATA IS NOT VERIFIED 
                            </Typography>
                        </Box>

                        <Box sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "26px 25px" : "26px 55px"}}>
                            {/* <iframe width="100%" height="230" src="https://www.youtube.com/embed/lAldtHMtw8A" 
                                title="Why the world needs a verification engine" frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen muted playsinline
                            ></iframe> */}
                            <Typography sx={{fontWeight: 'bold'}}>Warning: Potential Counterfeit or Fake Information Detected!</Typography>
                            <Typography sx={{mt: 2, mb: 2}}>Do not share any personal information.</Typography>
                            <Typography><b>Report this incident:</b> Contact the document owner or the manufacturer.</Typography>
                        </Box>
                    </DialogContent>
                </>
            }

            <DialogActions sx={{display: "flex", justifyContent: "center", padding: "16px 0px"}}>
                <Typography variant="subtitle1">
                    Powered by: 
                </Typography> 
                
                &nbsp;

                <img alt="" src={OVCodeLogo} style={{width: 100}} /> 
            </DialogActions>
        </Dialog>
    )
}

export default UnverifiedDataDialog