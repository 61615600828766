import React, { useState, useEffect } from "react";
import { Backdrop, ListItem, Link, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, Box, Grid, ListItemText, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OVCodeLogo from "../../icons/new-ovcode-logo.png";
import unilabLogo from '../../icons/Unilab-logo.png'
import unilabOvcodeLogo from '../../icons/Unilab.png'
import UnilabCustomTab from "../common/unilab-custom-tab.component";
import ViewRetailUnilabComponent from "../unilab/view-retail-unilab.component";
import CancelIcon from '@mui/icons-material/Cancel';
import UnilabV2CustomTab from "../common/unilabv2-custom-tab.component";


const UnilabVerifiedDataDialog = (props) => {
    const { setOpenUnilabVerifiedDialog, breakpoints, openUnilabVerifiedDialog, setPdfViewer, setViewingDocument,
    openVerifiedData,setOpenVerifiedData,  setOpenVerificationRes } = props;
    const [verificationName, setVerificationName] = useState("");

    useEffect(() => {
        const url = window.location.href;
        console.log("urlxx", url)
        if (url.includes("MPL")) {
            setVerificationName("MPL");
        } else if (url.includes("RL")) {
            setVerificationName("RL");
        } else if (url.includes("BL")) {
            setVerificationName("BL");
        } else if (url.includes("verification")) {
            setVerificationName("Required");
        } else {
            setVerificationName("Unknown Verification");
        }
    }, []);

    const CustomDialogTitle = (props) => {
        const { children, onClose, ...other } = props;
        return (
            <DialogTitle sx={{ m: 0, p: "10px 16px 10px 16px" }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute", right: 8, top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        )
    }



    const handleCloseVerifyDataDialog = (e) => {
        setOpenUnilabVerifiedDialog(localStorage.setItem("isVerifiedDialog", false));
        window.location.href = "/verification";

    }

    const handleViewButtonClick = () => {
        window.open('/view/pdf/verification', '_blank');
    };

    console.log("verificationName here :", verificationName)

    return (
        <Dialog maxWidth={"sm"} fullWidth
            open={openUnilabVerifiedDialog}
            onClose={(e) => handleCloseVerifyDataDialog(e)}
            sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1}}
        >

            {verificationName === 'Required' ?
                <>
                    <CustomDialogTitle id="customized-dialog-title" onClose={(e) => handleCloseVerifyDataDialog(e)}>
                        <img alt="" src={OVCodeLogo} style={{ width: 130 }} />
                    </CustomDialogTitle>

                    <DialogContent sx={{ padding: 0 }}>
                        <Box sx={{ justifyContent: "center", display: "flex", background: "orange", height: "50px", padding: "12px" }}>
                            <IconButton>
                                <CancelIcon style={{ color: "#fff", marginTop: "4px", marginRight: "-3px" }} />
                            </IconButton>
                            <Typography variant="subtitle1" sx={{ color: "#fff", fontSize: 18, fontWeight: 'bold' }}>
                                OVI IS REQUIRED
                            </Typography>
                        </Box>

                        <Box sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? "26px 25px" : "26px 55px" }}>
                            <iframe width="100%" height="230" src="https://www.youtube.com/embed/lAldtHMtw8A"
                                title="Why the world needs a verification engine" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen muted playsinline
                            ></iframe>
                        </Box>
                    </DialogContent>
                </>
                :
                <>
                    <CustomDialogTitle id="customized-dialog-title" onClose={(e) => handleCloseVerifyDataDialog(e)} >
                        <></>
                    </CustomDialogTitle>

                    <DialogContent sx={{ padding: 0 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '12px',
                                textAlign: 'center',
                            }}
                        >

                            <Grid container spacing={1} alignItems="center" sx={{ textAlign: 'center', justifyContent: 'center' }}>
                                <Grid item xs={12}>
                                    <img alt="" src={unilabLogo} style={{ height: 60, objectFit: "contain", marginTop: (breakpoints == "xs" || breakpoints == "sm") ? "-8px" : {} }} />
                                </Grid>
                                <Grid item sx={{ textAlign: 'center', justifyContent: 'center' }}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontWeight: 600,
                                            color: '#09BD62',
                                            fontSize: 18,
                                            textAlign: 'center !important',
                                        }}
                                    >
                                        DATA FOUND
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ padding: '2px', textAlign: 'center', mt: 0 }}>
                            <Typography sx={{ color: '#0C306F', fontSize: 18, fontWeight: 'bold' }}>
                                {verificationName === "MPL" ? 'Mother Pallet Level OVCODE Hash' : verificationName === "BL"  ? 'Box Level OVCODE Hash' : 'Authentic'}
                            </Typography>
                        </Box>

                        {verificationName === "RL" &&
                            <Box sx={{ padding: '2px', textAlign: 'center', mt: 2, justifyContent: 'center' }}>
                                <Button variant="contained" sx={{ backgroundColor: "#0C306F", color: '#fff', borderRadius: 10, fontSize: 14 }} onClick={handleViewButtonClick}>View</Button>
                            </Box>
                        }

                        <Box sx={{ padding: '2px', textAlign: 'center', mt: 0.5, justifyContent: 'center' }}>
                            {verificationName === "RL"  ?
                                <ViewRetailUnilabComponent />
                            :
                                <UnilabV2CustomTab verificationName={verificationName} setPdfViewer={setPdfViewer} setViewingDocument={setViewingDocument}
                                openVerifiedData={openVerifiedData} setOpenVerifiedData={setOpenVerifiedData}  setOpenVerificationRes={setOpenVerificationRes} />
                            }
                        </Box>

                    </DialogContent>
                </>
            }
            <DialogActions sx={{ display: "flex", justifyContent: "center", padding: "16px 0px" }}>
                <img alt="" src={unilabOvcodeLogo} style={{ width: 80 }} />
            </DialogActions>
        </Dialog>
    )
}

export default UnilabVerifiedDataDialog;
