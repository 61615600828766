import React from "react";
import {Dialog, DialogTitle, DialogContent, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OVCodeLogo from "../../icons/new-ovcode-logo.png";

const SuccessConfirmationDialog = (props) => {

    const {openSuccessConfirmation, setOpenSuccessConfirmation, breakpoints} = props;

    const handleCloseVerifyDataDialog = (e) => {
        setOpenSuccessConfirmation(false);
        window.location.href = "/";
    }

    const CustomDialogTitle = (props) => {
        const {children, onClose, ...other} = props;
      
        return (
            <DialogTitle sx={{m: 0, p: 2}} {...other}>
                {children}

                {onClose ? 
                    (
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{position: "absolute", right: 8, top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) 
                : 
                    null
                }
          </DialogTitle>
        )
    }

    return (
        <Dialog
            maxWidth={"sm"}
            fullWidth
            open={openSuccessConfirmation}
            onClose={(e) => handleCloseVerifyDataDialog(e)}
            sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1}}
        >
            <CustomDialogTitle id="customized-dialog-title" onClose={(e) => handleCloseVerifyDataDialog(e)}>
                <img alt="" src={OVCodeLogo} style={{width: 130}} /> 
            </CustomDialogTitle>

            <DialogContent dividers sx={{pt: 0, borderTop: "none", mt: "-10px", padding: "0px 24px 20px"}}>
                Thank you for expressing your interest in joining OVCODE. 
                We appreciate your time and consideration. 
                Our team will reach out to you shortly to confirm your details and 
                provide you with further information on the next steps. 
                We look forward to having you as a valued member of our community!
            </DialogContent>
        </Dialog> 
    )
}

export default SuccessConfirmationDialog