import React, { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import useVerification from "../../../hooks/useVerification";
import { ColorRing } from 'react-loader-spinner';
import { useGeolocated } from "react-geolocated";
import { useSnackbar } from 'notistack';
import { ThreeDots } from 'react-loader-spinner';




const ScanQRComponent = (props) => {
  const { breakpoints, setOpenScan, setOpenVerifiedData, setOpenUnverifiedData, setOpenVerificationRes, setIsScanning, setOpenUnilabDialog, setPdfViewer, setOpenUnilabVerifiedDialog, isAuthenticated, setLoading } = props;
  const { verifyReference, verifyOvi, verifyUnilabv2Reference, verifyUnilabReference, verifyOviUnilab, scannedResult} = useVerification();
  const { enqueueSnackbar } = useSnackbar();
  const [hasResult, setHasResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const videoRef = useRef(null);

  const [userLocation, setUserLocation] = useState(null);

  console.log("aaa scannedResult here xxx", scannedResult)

  
     // define the function that finds the users geolocation
     useEffect(() => {
      // if geolocation is supported by the users browser
      if (navigator.geolocation) {
          // get the current users location
          navigator.geolocation.getCurrentPosition(
          (position) => {
              // save the geolocation coordinates in two variables
              const { latitude, longitude } = position.coords;
              // update the value of userlocation variable
              setUserLocation({ latitude, longitude });
          },
          // if there was an error getting the users location
          (error) => {
              console.error('Error getting user location:', error);
          }
          );
      }
      // if geolocation is not supported by the users browser
      else {
          console.error('Geolocation is not supported by this browser.');
      }
 },[])

 console.log("userLocation scan-qr" , userLocation)


  // Function to detect device type
  function getDeviceType() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
      return 'mobile';
    }
    return 'web';
  }

  // Function to get constraints based on device type
  function getConstraints(selectedDeviceId) {
    const deviceType = getDeviceType();
    let constraints;

    if (deviceType === 'mobile') {
      constraints = {
        video: {
          deviceId: { exact: selectedDeviceId },
          facingMode: 'environment',
          width: { ideal: 440 },
          height: { ideal: 280 }
        }
      };
    } else {
      constraints = {
        video: {
          deviceId: { exact: selectedDeviceId },
          facingMode: 'environment',
          width: { ideal: 640 },
          height: { ideal: 480 }
        }
      };
    }

    return constraints;
  }


  useEffect(() => {
    if (userLocation) {
      startScan();
    }
  
    return () => {
      setHasResult(false);
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
      }
    };
  }, [userLocation]); // Consider adding hasResult if needed
  
  const startScan = async () => {
    const codeReader = new BrowserMultiFormatReader();
    try {
      const videoInputDevices = await codeReader.listVideoInputDevices();
      let selectedDeviceId = videoInputDevices[0].deviceId;
  
      for (const device of videoInputDevices) {
        if (device.label.toLowerCase().includes('back') || device.label.toLowerCase().includes('rear')) {
          selectedDeviceId = device.deviceId;
          break;
        }
      }
  
      const constraints = getConstraints(selectedDeviceId);
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
  
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
  
      await codeReader.decodeFromVideoDevice(selectedDeviceId, videoRef.current, (result, error) => {
        if (result && !hasResult) {
          setHasResult(true);
          setIsLoading(true); // Set loading state
          setIsScanning(true);
          setLoading(true);
          codeReader.reset();
          stream.getTracks().forEach(track => track.stop()); // Stop camera stream
          handleVerifyReference(result.text);
        }
        if (error && !(error instanceof NotFoundException)) {
          console.error("Error: ", error);
        }
      });
    } catch (error) {
      if (error instanceof DOMException) {
        console.log('Permission denied: Unable to access the camera.');
      } else {
        console.log('An unexpected error occurred:', error);
      }
    }
  };
  

  const handleVerifyReference = (refData) => {

    let reference;
    let oviValue;

    // Check if refData is a URL
    if (refData.startsWith('http://') || refData.startsWith('https://')) {
      try {
        // Parse URL and extract search params
        const url = new URL(refData);
        const searchParams = new URLSearchParams(url.search);
        oviValue = searchParams.get('ovi');

        // Validate oviValue
        if (oviValue && /^OVI\d+$/.test(oviValue)) {
          reference = oviValue;
        } else {
          reference = refData.split("/").pop(); // Fallback reference
        }
      } catch {
        reference = refData; // Fallback if URL parsing fails
      }
    } else {
      reference = refData; // Non-URL data is taken as reference directly
    }

    // Determine if we need to verify using OVI
    const shouldUseOvi = shouldVerifyOvi(reference);

    const payload = {
      ovi: reference,
      latitude: userLocation && userLocation.latitude, 
      longitude: userLocation && userLocation.longitude, 
      device_info: "samsung SM-A042F",
      device_id: "fdd36ed7fd14cc39",
      device_identity: "samsung SM-A042F, ID: fdd36ed7fd14cc39",
      v_method: "QR",
    };

    if (shouldUseOvi) {
      verifyOvi(reference, payload, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData);
    } else {
      // Determine if device is mobile or tablet
      const isMobile = /Mobi|Android/i.test(navigator.userAgent);
      const isTablet = /Tablet|iPad/i.test(navigator.userAgent);

      if (isMobile || isTablet) {

        if (refData.includes("unilab")) {
          verifyOviUnilab(reference, payload, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData);
        } else if (refData.includes("verification")) {
          verifyUnilabReference(reference, oviValue, setOpenScan, setOpenVerifiedData, setOpenUnverifiedData, setOpenUnilabVerifiedDialog);
        } else {
          verifyOvi(reference, payload, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData);
        }
      } else {
        // Different verification actions based on refData content
        if (refData.includes("unilab")) {
          verifyUnilabv2Reference(reference, setOpenScan, setOpenUnverifiedData, setOpenUnilabDialog);
        } else if (refData.includes("verification")) {
          verifyUnilabReference(reference, oviValue, setOpenScan, setOpenVerifiedData, setOpenUnverifiedData, setOpenUnilabVerifiedDialog);
        } else {
          verifyReference(reference, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData, setPdfViewer);
        }
      }
    }
  };

  const shouldVerifyOvi = (reference) => {
    const oviPattern = /^OVI\d+$/;
    return oviPattern.test(reference);
  };

  function PageLoader() {
    return (
        <Backdrop open={isLoading} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.tooltip + 1 }}>
            <Button 
                variant="text"
                sx={{color: '#ffff', textTransform: 'none', fontSize: 24}}
                startIcon={<ThreeDots height="80" width="80" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
            >
                Verifying document
            </Button>
        </Backdrop>
    )
}
{console.log("breakpoints xxx", breakpoints)}
{console.log("isLoading xxx", isLoading)}
  return (
    <Grid container spacing={2} sx={{ padding: breakpoints === 'xs' || breakpoints === 'sm' ? 0 : 18, pt: 0, pb: 0, justifyContent: 'center', alignItems: 'center', width: breakpoints === 'xs' || breakpoints === 'sm' ? '100%' : '0%' }}>
      {isLoading ?
        <PageLoader/>
      :
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10} sx={{justifyContent: 'center', alignItems: 'center'}}>
          <video ref={videoRef} />
        </Grid>
      }
    </Grid >
  );
};

export default ScanQRComponent;