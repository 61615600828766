import { Box,  Grid, Typography } from "@mui/material";
import useWidth from "../hooks/useWidth";
// import background from "../images/ovcoin-background.jpg";
// import background from "../images/ovcoing-backgroundv2.png";
import background from "../images/ovcoin-background-v3.jpg";
import iconProfile from "../images/ico-profile.jpg";
import iconType from "../images/ico-type.jpg";
import iconCompliance from "../images/ico-compliance.jpg";
import OVCImage from "../icons/ovcode-reverse-white.png";


const OVCDisclaimerScreen = () => {

    const breakpoints = useWidth();

    return(
        <Box>



            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                minHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 550,
                maxHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500,
                backgroundPosition: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'hidden',
                margin: 'auto 0',
                width: ' 100%',
            }}>
                <Box sx={{ width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '90%' : '95%', margin: 'auto' }} >
                    <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: breakpoints == "xs" ? 3 : 6 }}>
                        <Grid item xs={12} sm={5} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: breakpoints == "xs" ? '1.5rem!important' : '2rem!important' }}>
                            <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                <img src={OVCImage} style={{ width: '20%', marginLeft: breakpoints == "xs" ? '0px !important' : '50px' }} />
                                <Typography
                                    sx={{
                                        fontSize: 29,
                                        margin: 0,
                                        lineHeight: 1.2,
                                        fontWeight: 700,
                                        textAlign: 'start',
                                        color: "#fff",
                                        width: '95%',
                                        mt: 1
                                    }}
                                >
                                    Utility Token
                                </Typography>
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        color: 'rgb(100, 110, 115)',
                                        fontWeight: 400,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#fff",
                                        // width: breakpoints == "xs" ? '70%' : '80%'
                                    }}
                                >
                                    OVCODE is Listed on both <br/> Coinbene and LAToken
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box 
                sx={{
                    // backgroundColor: '#E2E2E6', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center'
                }}>
            <Box 
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 550,
                    maxHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500, 
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflow: 'hidden',
                    margin: 'auto 0',
                    width: ' 100%',
                }}
            >
            <Box sx={{ width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '100%' : '95%', margin: 'auto' }} >
                <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? 1 : (breakpoints == "md") ? 2 : 4 }}>
                    <Grid item xs={12} sm={4} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? '1rem!important' : '2rem!important' }}>
                        <Box sx={{ mb: 1 }}>
                            <img src={iconProfile} />
                            <Typography 
                                sx={{
                                    color: "#000",
                                    fontSize: 24,
                                    margin: 0,
                                    lineHeight: 1.6,
                                    fontWeight: 550,
                                    textAlign: 'center',
                                }}
                            >
                                PROFILE
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 5 }}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    margin: 0,
                                    lineHeight: 1.2,
                                    textAlign: 'center',
                                    color: "#000",
                                }}
                            >
                                Abbreviation: OVC <br/> Max Supply: 83, 875, 000 <br/> Circulating Supply: 73, 875, 000
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? '1rem!important' : '2rem!important' }}>
                        <Box sx={{ mb: 1 }}>
                            <img src={iconType} />
                            <Typography 
                                sx={{
                                    color: "#000",
                                    fontSize: 24,
                                    margin: 0,
                                    lineHeight: 1.6,
                                    fontWeight: 550,
                                    textAlign: 'center',
                                }}
                            >
                                TYPE
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 5 }}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    margin: 0,
                                    lineHeight: 1.2,
                                    textAlign: 'center',
                                    color: "#000",
                                }}
                            >
                                OVC <br/> is an ERC20 token <br/> and has a finite supply.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? '1rem!important' : '2rem!important' }}>
                        <Box sx={{ mb: 1 }}>
                            <img src={iconCompliance} />
                            <Typography 
                                sx={{
                                    color: "#000",
                                    fontSize: 24,
                                    margin: 0,
                                    lineHeight: 1.6,
                                    fontWeight: 550,
                                    textAlign: 'center',
                                }}
                            >
                                COMPLIANCE
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 5 }}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    margin: 0,
                                    lineHeight: 1.2,
                                    textAlign: 'center',
                                    color: "#000",
                                }}
                            >
                                OVC is focused on <br/> full regulation compliance, <br/> where trust and reliability are its core.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: (breakpoints == "xs" || breakpoints == "sm" ) ? '1rem!important' : '2rem!important', paddingTop: '0rem !important' }}>
                        <Box sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                            <Box sx={{ mb: 4 }}>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        color: 'rgb(100, 110, 115)',
                                        fontWeight: 400,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000",
                                    }}
                                >
                                    <b>Disclaimer:</b> OVCode has been informed that the name of Microsoft Corporation has been connected to the offering of OVCoin in Korea.  
                                    OVCode wishes to correct any misimpression that Microsoft is related in any way to the offering of OVCoin. 
                                    Microsoft Corporation and all of its affiliate companies are not investors of OVCoin or any of OVCode’s subsidiaries in relation to the offering of OVCoin, 
                                    nor is Microsoft or any of its affiliates related in any way with OVCoin.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    
                </Grid>
            </Box>
        </Box>
            </Box>
        </Box>
    )
}

export default OVCDisclaimerScreen