import './App.css';
import MainScreen from "./components/layout/main-layout";
import { BrowserRouter, useNavigate } from 'react-router-dom';
import Routes from './router';
import { history } from "./router/history";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, createMuiTheme } from '@mui/material';
import ClippedDrawer from './screen/VersionHistoriesScreen';
import useWidth from './hooks/useWidth';
import { useEffect, useRef, useState } from 'react';
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import Chatbot from './components/layout/chatbot.component';
import BackToTopButton from './components/layout/scroll-up.component';


const theme = createMuiTheme({                                                                           
  typography: {
    fontFamily: [
      "Segoe UI",
      "SegoeUI",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif"
    ].join(','),
  }
});

const App = () => {

  const url = window.location.pathname;
  const breakpoints = useWidth();
  const { instance } = useMsal();
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const idleTimer = useRef(null);

  // const logoutAndRedirect = () => {
  //   instance.logoutRedirect().then(() => {
  //     window.location.href="/"; // Redirect to the main root path
  //   });
  // };

  // const resetIdleTimer = () => {
  //   clearTimeout(idleTimer.current);

  //   // Set idle timer to 5 minutes (300000 ms)
  //   idleTimer.current = setTimeout(() => {
  //     if (isAuthenticated) {
  //       logoutAndRedirect();
  //     }
  //   }, 300000);
  // };

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     // Add event listeners for user activity
  //     window.addEventListener("mousemove", resetIdleTimer);
  //     window.addEventListener("keydown", resetIdleTimer);
  //     window.addEventListener("scroll", resetIdleTimer);
  //     window.addEventListener("click", resetIdleTimer);

  //     // Start the initial idle timer
  //     resetIdleTimer();

  //     return () => {
  //       // Cleanup event listeners and timer
  //       clearTimeout(idleTimer.current);
  //       window.removeEventListener("mousemove", resetIdleTimer);
  //       window.removeEventListener("keydown", resetIdleTimer);
  //       window.removeEventListener("scroll", resetIdleTimer);
  //       window.removeEventListener("click", resetIdleTimer);
  //     };
  //   }
  // }, [isAuthenticated]);

  // console.log("userLoggedIn xxxx", isAuthenticated);
  // console.log("get account xxxx", accounts);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter history={history}>
          {(url !== "/version-histories-all" &&
            url !== "/version-histories-ovcode-enterprise" &&
            url !== "/version-histories-electronic-identification-admin" &&
            url !== "/version-histories-electronic-identification-android" &&
            url !== "/version-histories-electronic-identification-app-binding" &&
            url !== "/version-histories-electronic-identification-web-app" &&
            url !== "/version-histories-electronic-identification-regClient" &&
            url !== "/version-histories-national-identification-admin") ?
            <MainScreen>
              <Routes />
            </MainScreen>
            :
            <MainScreen>
              {breakpoints === 'xs' || breakpoints === 'sm' ?
                null
                :
                <ClippedDrawer />
              }
              <Routes />
            </MainScreen>
          }

        </BrowserRouter>
      </ThemeProvider>
      <div class="container">
        {isChatbotOpen ?
          null
          :
          <BackToTopButton isChatbotOpen={isChatbotOpen} />
        }
        {url.includes("/v/") || url.includes("/unilab/") ?
          null
          :
          <Chatbot setIsChatbotOpen={setIsChatbotOpen} />
        }
      </div>

    </div>
  )
}

export default App