import { Avatar, Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { getComponentById } from "../clients/ClientVerification";
import DefaultVerificationResComponent from "../verified/default-verification-res.component";
import useWidth from "../../hooks/useWidth";
import UnilabDesktopViewComponent from "../clients/verification-result/unilab-desktop-view.component";
import PDFViewerComponent from "../landing/verify/pdf-viewer.component";
import DataRevokeDialog from "./data-revoke-dialog.component";

const VerificationResultDialogComponent = (props) => {
    const { openVerificationRes, setPdfViewer, setViewingDocument, setOpenVerifiedData, setOpenVerificationRes,
        setOpenUnverifiedData, setOpenMismatchDialog, setOpenUpload, openUpload
     } = props;

    const breakpoints = useWidth();

    const [isVerifyingReference, setVerifyingReference] = useState(true);
    const [isVerifyingHash, setVerifyingHash] = useState(true);
    const [business_id, setBusinessId] = useState("");
    const [ovi_code, setOviCode] = useState("");
    const [mimetype, setMimetype] = useState("");

    console.log("openUpload dddd",openUpload)

    const [foundComponent, setFoundComponent] = useState(null); // State to store the found component

    const unparse_info = localStorage.getItem("scannedInfo");
    const status = localStorage.getItem("status");

    let scannedInfo = null;

    const hasProxy = localStorage.getItem("has_proxy") // Ensure proper boolean conversion

    if (unparse_info) {
        scannedInfo = JSON.parse(unparse_info);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setVerifyingReference(false);
            setVerifyingHash(false);
        }, 4000);

        return () => clearTimeout(timer);
    }, []);

    const handleCloseVerifyDataDialog = () => {
        setOpenVerificationRes(false);
        localStorage.setItem("isVerifiedDialog", false);
        localStorage.removeItem("has_proxy");
        localStorage.removeItem("scannedInfo");
        window.location.href = "/verification";
    }

    useEffect(() => {
        if (hasProxy) {
            const { business } = scannedInfo?.owner || {};
            if (business) {
                setBusinessId(business.id || "");
                setOviCode(scannedInfo.details?.reference || "");
                setMimetype(scannedInfo.details?.mimetype || "");

                try {
                    const component = getComponentById(business.id);
                    setFoundComponent(component);
                } catch (error) {
                    console.error(error.message);
                    setFoundComponent(null); // Handle if component not found
                }
            }
        }
    }, [hasProxy]);

    const CustomDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: "10px 16px 10px 16px" }} {...other}>
                {children}

                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute", right: 8, top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        )
    }

    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const isTablet = /Tablet|iPad/i.test(navigator.userAgent);
    const isDesktop = !isMobile && !isTablet;

    console.log("hasProxyxxx", hasProxy)
    console.log("status here aaaa", status)
    console.log("setOpenVerifiedData here aaaa", setOpenVerifiedData)

    return (
        <>
            <Dialog
                maxWidth={"sm"}
                fullWidth
                open={openVerificationRes}
                // onClose={handleCloseVerifyDataDialog}
                sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1}}
                disableEscapeKeyDown
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseVerifyDataDialog();
                    }
                }}
            >
                <DialogContent>
                    {isVerifyingReference || isVerifyingHash ? (
                        <Box sx={{ borderRadius: "4px", display: "flex", background: "#0178f240", height: "50px", padding: "12px" }}>
                            <Typography variant="subtitle1" sx={{ color: "#0178f2c7", fontSize: 16 }}>
                                Verifying document...
                            </Typography>
                        </Box>
                    ) : hasProxy === 'true' ? (
                        <>
                            {isMobile || isTablet ? (
                                <>
                                    {status === "REVOKED" ?
                                        <>
                                            <CustomDialogTitle id="customized-dialog-title" onClose={handleCloseVerifyDataDialog}></CustomDialogTitle>
                                            <DataRevokeDialog />
                                        </>
                                    :
                                        <>
                                            <CustomDialogTitle id="customized-dialog-title" onClose={handleCloseVerifyDataDialog}></CustomDialogTitle>
                                            {foundComponent && foundComponent.component && (
                                                <foundComponent.component scannedInfo={scannedInfo} setOpenVerificationRes={setOpenVerificationRes} 
                                                setOpenVerifiedData={setOpenVerifiedData} setPdfViewer={setPdfViewer} setViewingDocument={setViewingDocument} />
                                            )}
                                        </>
                                    
                                    }
                                    
                                </>
                            ) : (
                                <>
                                    <CustomDialogTitle id="customized-dialog-title" onClose={handleCloseVerifyDataDialog}></CustomDialogTitle>
                                    <UnilabDesktopViewComponent />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <CustomDialogTitle id="customized-dialog-title" onClose={handleCloseVerifyDataDialog}></CustomDialogTitle>
                            <DefaultVerificationResComponent scannedInfo={scannedInfo} setOpenVerificationRes={setOpenVerificationRes} 
                            setOpenVerifiedData={setOpenVerifiedData} setPdfViewer={setPdfViewer} setViewingDocument={setViewingDocument} 
                            setOpenUnverifiedData={setOpenUnverifiedData}  setOpenMismatchDialog={setOpenMismatchDialog} setOpenUpload={setOpenUpload}
                            openUpload={openUpload}
                            />
                        </>
                    )}
                </DialogContent>
            </Dialog>

        </>

    )
}

export default VerificationResultDialogComponent;
