import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import SuccessStories from "../../../../images/blog/success-stories.png";


const SuccessStoriesComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                eID Success Stories: Real-world Applications of Secure Identity Verification
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Monday, 15 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={SuccessStories} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In the dynamic landscape of digital transactions, OVCODE eID emerges not just as a solution but as
                                a catalyst for transformative change. Let's explore how businesses across various sectors have
                                reaped the benefits of secure identity verification through OVCODE eID.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>1. Financial Institution X: Streamlining Customer Onboarding</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontStyle: 'italic' }}>Background:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        A prominent financial institution faced challenges with the time-consuming and complex
                                        process of customer onboarding. Traditional identity verification methods resulted in delays
                                        and frustrated customers.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontStyle: 'italic' }}>Implementation of OVCODE eID:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        Centralized storage of sensitive personal information is a prime target for hackers. Data
                                        breaches in institutions lead to compromised identities and expose individuals to
                                        various risks.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontStyle: 'italic' }}>Results:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        <b>Reduced Onboarding Time:</b>Customer onboarding time was slashed significantly, improving
                                        customer satisfaction.
                                    </Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        <b>Enhanced Security:</b>The institution reported a notable reduction in fraud cases, thanks to the
                                        robust security features of OVCODE eID.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>2. HealthTech Startup Y: Ensuring Patient Data Integrity</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontStyle: 'italic' }}>Background:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        A HealthTech startup dealing with sensitive patient data recognized the need for an identity
                                        verification solution that not only safeguarded patient information but also ensured its integrity.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontStyle: 'italic' }}>Implementation of OVCODE eID:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE eID was seamlessly integrated into the HealthTech platform to authenticate patient
                                        identities. Biometric authentication added an extra layer of security, ensuring the accuracy of
                                        healthcare records.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontStyle: 'italic' }}>Results:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}} >
                                        <b>Data Accuracy:</b>Instances of errors in patient data were significantly reduced, contributing to
                                        better patient outcomes.
                                    </Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        <b>Compliance:</b>The startup reported improved compliance with healthcare data protection
                                        regulations.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>3. Government Agency Z: Facilitating Efficient Public Services</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontStyle: 'italic' }}>Background:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        A government agency faced challenges in providing efficient and secure public services due to
                                        cumbersome identity verification processes. This hindered the overall efficiency of service
                                        delivery.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontStyle: 'italic' }}>Implementation of OVCODE eID:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE eID was integrated into the government agency's digital services to streamline identity
                                        verification for citizens accessing public services.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontStyle: 'italic' }}>Results:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}} >
                                        <b>Efficiency Gains:</b>The agency reported a notable increase in the efficiency of service delivery as
                                        identity verification became quicker and more reliable.
                                    </Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        <b>Reduced Fraud:</b>Instances of fraudulent attempts to access public services decreased
                                        significantly.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>4. E-commerce Platform W: Building Trust in Online Transactions</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontStyle: 'italic' }}>Background:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        An e-commerce platform aimed to build trust among its users by ensuring secure and
                                        trustworthy transactions. Traditional identity verification methods were deemed insufficient.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography sx={{ fontStyle: 'italic' }}>Implementation of OVCODE eID:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE eID was implemented to bolster trust in online transactions. The platform leveraged
                                        blockchain's transparency and security features to authenticate both buyers and sellers.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontStyle: 'italic' }}>Results:</Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        <b>Increased User Trust:</b> The e-commerce platform reported a surge in user trust, leading to
                                        increased engagement and repeat business.
                                    </Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        <b>Fraud Prevention:</b> Instances of fraudulent transactions were minimized, contributing to a safer
                                        online shopping environment.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'center'  }}>OVCODE eID: Shaping a Secure Digital Future</Typography>
                            <Typography sx={{ mt: 1, mb: 1 , textAlign: 'justify', textJustify: 'inter-word'}}>
                                These success stories highlight the diverse applications of OVCODE eID across industries.
                                Beyond being a secure identity verification tool, OVCODE eID becomes a catalyst for
                                efficiency, compliance, and, most importantly, trust in digital interactions.
                            </Typography>
                            <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                IAs we continue to explore the possibilities of secure identity verification, OVCODE eID stands
                                as a testament to the transformative power of innovation in reshaping the future of digital
                                transactions.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-eid-secure-payments"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Revolutionizing Secure Payments: Enhancing User Experience with OVCODE's eID Verification
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1, }}>
                                        In the digital age, secure and efficient payment systems are not just a convenience but a necessity.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                April 02, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-eid-identity-verification"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        The Future of Identity Verification: An In-Depth Look at OVCODE e
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Discuss the challenges in traditional identity verification and how OVCODE eID revolutionizes the process.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550 }}>
                                January 15, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </Box>
    )
}

export default SuccessStoriesComponent