import React, { useState } from "react";
import { Box, Card, CardContent, Grid, ListItemText, Typography, Pagination } from "@mui/material";

const BlogTopicsComponent = (props) => {

    const { blogTopicData , currentPage, handleClick, handlePageChange, itemsPerPage, totalItems } = props;

    console.log('totalItems here',totalItems)

    return (
        <Box>
            {blogTopicData?.map((item, index) => (
                <div key={index} className="container-blog" style={{ overflow: 'hidden',  marginBottom: '20px' }}>
                    <Card
                        sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', width: '90%', margin: 'auto' }}
                        onClick={() => handleClick(item.url)}
                    >
                        <CardContent> 
                            <Grid container>
                                <Grid item xs={12} sm={6} sx={{ textAlign: 'start' }}>
                                    <img src={item.image} style={{ width: '80%' }} alt={`Ovcode Insights ${index + 1}`} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ textAlign: 'start' }}>
                                            <ListItemText primary={
                                                <React.Fragment>
                                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem' }}>
                                                        {item.title}
                                                    </Typography>
                                                </React.Fragment>
                                            } secondary={
                                                <React.Fragment>
                                                    <Typography sx={{ fontSize: '1rem' }}>{item.date}</Typography>
                                                </React.Fragment>
                                            } />
                                        </Grid>
                                        <Grid item xs={12} sx={{mt: 3, width: '90%', textAlign: 'justify'}}>
                                            <Typography>
                                                {item.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination
                    count={Math.ceil(totalItems / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
                />
            </Box>
        </Box>
    );
};

export default BlogTopicsComponent;
