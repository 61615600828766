import { Box, Grid, Typography, Skeleton } from "@mui/material";
import useUnilabBodyItems from "../hooks/useUnilabBodyItems";
import useWidth from "../../../hooks/useWidth";
import { useEffect, useState } from "react";

const UnilabViewDetailsComponent = (props) => {
    const { unilabResData, scannedInfo, isViewing } = props;
    const { getBodyItems } = useUnilabBodyItems();
    const breakpoints = useWidth();

    // Get bodyItems directly from the hook
    const bodyItems = getBodyItems(unilabResData?.type);

    const [updatedResData, setUpdatedResData] = useState(null);

    useEffect(() => {
        if (unilabResData) {
            const updatedData = { ...unilabResData };

            // Check and set hash_ref if present in bodyItems
            const hashRefItem = bodyItems.find(item => item.code === 'hash_ref');
            if (hashRefItem && scannedInfo?.details?.hash) {
                updatedData[hashRefItem.code] = scannedInfo.details.hash;
            }

            // Check and set serial_number if present in bodyItems
            const serialNumberItem = bodyItems.find(item => item.code === 'serial_number');
            if (serialNumberItem && scannedInfo?.details?.reference) {
                updatedData[serialNumberItem.code] = scannedInfo.details.reference;
            }

            // Update the state here
            setUpdatedResData(updatedData);
        }
    }, [unilabResData]);

    // Render skeleton loader
    const renderSkeleton = () => (
        <Box sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                {Array.from(new Array(5)).map((_, index) => (
                    <Grid item xs={12} key={index}>
                        <Skeleton variant="text" width="100%" height={30} animation="wave" />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );

    // Render data
    const renderData = () => (
        <Box sx={{ padding: 2, textAlign: 'start' }}>
            <Grid container spacing={2}>
                {bodyItems && bodyItems.length > 0 && (
                    <Grid item xs={12}>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {bodyItems.map((item, index) => (
                                <li key={index}>
                                    {item.has_dashed_line && <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '8px', marginBottom: '8px' }} />}
                                    <Typography variant="body1" sx={{ fontSize: 17 }}>
                                        <strong>{item.label}:</strong>&nbsp;&nbsp;
                                        <span style={{ wordBreak: 'break-word' }}>
                                            {updatedResData?.[item.code] || 'N/A'}
                                        </span>
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    </Grid>
                )}
            </Grid>
        </Box>
    );

    // Conditional rendering based on isViewing and data availability
    if (isViewing || !updatedResData) {
        return renderSkeleton();
    }

    return renderData();
};

export default UnilabViewDetailsComponent;
