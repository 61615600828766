import axios from 'axios';
import {QueryParamsFormat} from "./http-utils";
import environment from "../../../environment/environment";
import AuthService from '../../auth';
import {setAuthToken} from "../../sessions/auth_session";


const SUBSCRIPTION_KEY = environment.ovcode_website_service.subscriptionKey;

const CustomHttpPost = (path, payload) => {
    var promise = new Promise((resolve, reject) => {
        const result = CustomHttpActualPost(path, payload);

        resolve(result);
    })

    return promise
}

const CustomHttpActualPost = (path, payload) => {
    let headerParameters = {
        headers: {
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY
        }
    }

    let apiEndpoint = path;
    let result = axios.post(apiEndpoint, payload, headerParameters);

    console.log("CustomHttpActualPost final params xxxx", apiEndpoint , payload, headerParameters);
    console.log("CustomHttpActualPost result xxxx", result);

    return result;
}

const CustomHttpPostParams = (path, parameters, subscriptionKey) => {
    var promise = new Promise((resolve, reject) => {
        const result = CustomHttpActualPostParams(path, parameters, subscriptionKey);

        resolve(result);
    })

    return promise
}

const CustomHttpActualPostParams = (path, parameters, subscriptionKey) => {
    let headerParameters = {
        headers: {
            "Ocp-Apim-Subscription-Key": subscriptionKey
        }
    }

    let apiEndpoint = path;
    let result = axios.post(apiEndpoint, parameters, headerParameters);

    console.log("CustomHttpActualPostParams final params xxxx", apiEndpoint, headerParameters, subscriptionKey);
    console.log("CustomHttpActualPostParams result xxxx", result);

    return result;
}

const HttpPost = (path, payload) => {

    let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
 

            if (isAuthenticated) {
              AuthService.getAuthToken().then(({accessToken})=>{
                    setAuthToken(accessToken)
                    const result = HttpActualPost(path, payload, accessToken)

                    resolve(result)
                }, (err)=>{
                    console.log("errrrrrr: ", err)
                  
                })
                .catch((err) => {})
            } else {
                const result = HttpActualPost(path, payload)
                resolve(result)
            }
    })

    return promise

}

const HttpActualPost = (path, payload, accessToken = null) => {

    let token = accessToken;
    if(token)
        token = "Bearer " + token;

    let headerParameters = {
        headers: {
            Authorization : token, 
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY
        }
    }
    console.log("headerParameters", headerParameters)
    let apiEndpoint = path;

    console.log("HttpPost final params xxxx", apiEndpoint , payload, headerParameters);

    let result = axios.post(apiEndpoint, payload, headerParameters)
    console.log("HttpActualPost", result);
    return result
}

const HttpPostMultipart = (path, payload) => {

    let isAuthenticated = AuthService.isUserLoggedIn();


    var promise = new Promise((resolve, reject) => {

            if (isAuthenticated) {
               AuthService.getAuthToken().then(({accessToken})=>{
                    setAuthToken(accessToken)
                    const result = HttpActualPostMultipart(path, payload, accessToken)

                    resolve(result)
                }, (err)=>{
                    console.log("HttpActualPostMultipart error xxxx", err)

                })
                .catch((err) => {})
                
            } else {
                const result = HttpActualPostMultipart(path, payload)
                resolve(result)
            }
    })

    return promise

}

const CustomHttpPostMultipart = (path, payload, token) => {

    var promise = new Promise((resolve, reject) => {
        const result = HttpActualPostMultipart(path, payload, token);

        resolve(result);
    })
     
    return promise
}

const HttpActualPostMultipart = (path, payload, accessToken = null) => {

    console.log("HttpActualPostMultipart accessToken xxxx", accessToken);

    let token = accessToken;

    if (token)
        token = "Bearer " + token;

    let headerParameters = {
        headers: {
            Authorization : token, 
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY,
            'Content-Type': 'multipart/form-data'
        }
    }
    console.log("headerParameters", headerParameters)
    let apiEndpoint = path;

    console.log("HttpActualPostMultipart apiEndpoint payload headerParameters xxxx", apiEndpoint, payload, headerParameters)

    let result = axios.post(apiEndpoint, payload, headerParameters)
    console.log("HttpActualPostMultipart", result);
    return result
}


const HttpGet = (path, parameters = '', subscriptionKey, loaderType) => {

    console.log("parametersxxx", parameters);
    console.log("subscriptionKeysxxx", subscriptionKey);

    let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   
            if (isAuthenticated) {
                 AuthService.getAuthToken().then(({accessToken})=>{
                    setAuthToken(accessToken)
                    const result = HttpActualGet(path, parameters, accessToken, subscriptionKey, loaderType)
                    
                    resolve(result)
                }, (err)=>{
                    console.log("errrrrrr uuu: ", err)
                })
            } else {
                const result = HttpActualGet(path, parameters, "", subscriptionKey, loaderType)
                resolve(result)
            }

        } catch (error) {
            console.log("HTTP_GET", error)
            reject(error)
        }
    })

    return promise

}

const HttpActualGet = (path, parameters = '', accessToken = null, subscriptionKey, loaderType) => {
    
    let token;

    if (accessToken) {
        token = "Bearer " + accessToken;
    }

    let headerParameters = {
        headers: {
            Authorization: token,
            "Ocp-Apim-Subscription-Key": subscriptionKey
        }
    }

    console.log("HttpActualGetheaderParameters", headerParameters);

    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    console.log("GetURL:", apiEndpoint + queryParams);
    let result = axios.get(apiEndpoint + queryParams, headerParameters);

    console.log("HttpActualGet result xxxx", result);
    
    return result
}

const HttpRawGet = (path, parameters = '', subscriptionKey, loaderType) => {

    console.log("HttpRawGet():", parameters);
    console.log("path():", path);
    console.log("subscriptionKey():", subscriptionKey);
    console.log("loaderType():", loaderType);


    let isAuthenticated = AuthService.isUserLoggedIn();


    var promise = new Promise((resolve, reject) => {
        try {   
            if (isAuthenticated) {
                console.log("HttpRawGet() isAuthenticated:", isAuthenticated);
               AuthService.getAuthToken().then(({accessToken})=>{
                    setAuthToken(accessToken)
                    const result = HttpActualRawGet(path, parameters, accessToken, subscriptionKey, loaderType)

                    resolve(result)
                }, (err)=>{
                    console.log("errrrrrr: ", err)

                })
            } else {
                const result = HttpActualRawGet(path, parameters, "", subscriptionKey, loaderType)
                resolve(result)
            }

        } catch (error) {
            console.log("HTTP_RAW_GET", error)
            reject(error)
        }
    })

    return promise

}

const HttpActualRawGet = (path, parameters = '', accessToken = null, subscriptionKey, loaderType) => {
    
    let token;

    if (accessToken) {
        token = "Bearer " + accessToken;
    }

    let headerParameters = {
        headers: {
            Authorization: token,
            "Ocp-Apim-Subscription-Key": subscriptionKey
        }
    }

    console.log("headersHere", headerParameters)

    let apiEndpoint = path + "?" + parameters;

    console.log("GetURL:", apiEndpoint);

    return axios.get(apiEndpoint, headerParameters)
}


const HttpQueryPut = (path, params, subscriptionKey) => {

    let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   

            if (isAuthenticated) {
               AuthService.getAuthToken().then(({accessToken})=>{
                    setAuthToken(accessToken)
                    const result = HttpActualQueryPut(path, params, subscriptionKey, accessToken)

                    resolve(result)
                }, (err)=>{
                    console.log("errrrrrr: ", err)

                })
                .catch((err) => {})

            } else {
                const result = HttpActualQueryPut(path, params)
                resolve(result)
            }

        } catch (error) {
            console.log("HTTP_PUT", error)
            reject(error)
        }
    })

    return promise
}

const HttpActualQueryPut = (path, parameters, subscriptionKey, accessToken = null) => {
    
    let token = accessToken;

    if(token)
        token = "Bearer " + token;

    let headerParameters = {
        headers: {
            Authorization : token,
            "Ocp-Apim-Subscription-Key": subscriptionKey,
        }
    }

    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    console.log("GetURL:", apiEndpoint + queryParams);
    let result = axios.put(apiEndpoint + queryParams, headerParameters);

    console.log("HttpActualQueryPut result xxxx", result);

    return result;   
}



const HttpPut = (path, payload) => {


    let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   

            if (isAuthenticated) {
               AuthService.getAuthToken().then(({accessToken})=>{
                    setAuthToken(accessToken)
                    const result = HttpActualPut(path, payload, accessToken)

                    resolve(result)
                }, (err)=>{
                    console.log("errrrrrr: ", err)

                })
                .catch((err) => {})

            } else {
                const result = HttpActualPut(path, payload)
                resolve(result)
            }

        } catch (error) {
            console.log("HTTP_PUT", error)
            reject(error)
        }
    })

    return promise

}

const HttpActualPut = (path, payload, accessToken = null) => {
    
    let token = accessToken;

    if(token)
        token = "Bearer " + token;

    let headerParameters = {
        headers: {
            Authorization : token,
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY,
        }
    }

    let apiEndpoint = path;

    let result = axios.put(apiEndpoint , payload, headerParameters)
    console.log("HttpActualPut", result);
    return result;
    
}


const HttpPut1 = (path, parameters="", payload) => {

    let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   

            if (isAuthenticated) {

                console.log("HttpPut1 path parameters payload xxxx", path, parameters, payload)
               AuthService.getAuthToken().then(({accessToken})=>{
                    setAuthToken(accessToken)
                    const result = HttpActualPut1(path, parameters, payload, accessToken)

                    resolve(result)
                }, (err)=>{
                    console.log("errrrrrr: ", err)
                })
            } else {
                const result = HttpActualPut1(path, parameters = '', payload, )
                resolve(result)
            }

        } catch (error) {
            console.log("HTTP_PUT", error)
            reject(error)
        }
    })

    return promise

}

const HttpActualPut1 = (path, parameters = "", payload, accessToken = null) => {
    
    let token;

    if (accessToken) {
        token = "Bearer " + accessToken;
    }

    let headerParameters = {
        headers: {
            Authorization: token,
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY
        }
    }

    console.log("HttpPutheaderParameters xxxx", headerParameters, path, parameters, payload, accessToken);

    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    console.log("GetURL:", apiEndpoint + queryParams);
    let result = axios.put(apiEndpoint + queryParams, payload, headerParameters);

    console.log("HttpPut result xxxx", result);
    return result;
}



const HttpDelete = (path, parameters) => {


    const MsalAuthenticate = window.MsalAuthenticate
    let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   

            if (isAuthenticated) {
               AuthService.getAuthToken().then(({accessToken})=>{
                    setAuthToken(accessToken)
                    const result = HttpActualDelete(path, parameters, accessToken)

                    resolve(result)
                }, (err)=>{
                    console.log("errrrrrr: ", err)
                    MsalAuthenticate.getTokenPopup().then(({accessToken})=>{
                        setAuthToken(accessToken)
                        const result = HttpActualDelete(path, parameters, accessToken)

                        resolve(result)
                    }, (err)=>{
                        console.log("errrrrrr xxx: ", err)
                        // logout
                        localStorage.clear()
                        window.location.reload()
                    })
                })
                .catch((err) => {})

            } else {
                const result = HttpActualDelete(path, parameters)
                resolve(result)
            }

        } catch (error) {
            console.log("HttpDelete", error)
            reject(error)
        }
    })

    return promise

}


const HttpDelete1 = (path, parameters) => {


    const MsalAuthenticate = window.MsalAuthenticate
    let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   

            if (isAuthenticated) {
               AuthService.getAuthToken().then(({accessToken})=>{
                    setAuthToken(accessToken)
                    const result = HttpActualDelete1(path, parameters, accessToken)

                    resolve(result)
                }, (err)=>{
                    console.console.log("errrrrrr: ", err)
                    MsalAuthenticate.getTokenPopup().then(({accessToken})=>{
                        setAuthToken(accessToken)
                        const result = HttpActualDelete1(path, parameters, accessToken)

                        resolve(result)
                    }, (err)=>{
                        console.console.log("errrrrrr xxx: ", err)
                        // logout
                        localStorage.clear()
                        window.location.reload()
                    })
                })
                .catch((err) => {})

            } else {
                const result = HttpActualDelete1(path, parameters)
                resolve(result)
            }

        } catch (error) {
            console.console.log("HTTP_PUT", error)
            reject(error)
        }
    })

    return promise

}

const HttpActualDelete = (path, parameters = '', accessToken = null) => {
    
    let token = accessToken;

    if(token)
        token = "Bearer " + token;

    let headerParameters = {
        headers: {
            Authorization : token,
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY,
        }
    }

    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters)
    if(queryParams)
        queryParams =  "?" + queryParams;
    else
        queryParams = '';

    let result = axios.delete(apiEndpoint + queryParams, headerParameters)

    return result;
}


const HttpActualDelete1 = (path, parameters = '', accessToken = null) => {
    
    let token = accessToken;

    if(token)
        token = "Bearer " + token;

    let headerParameters = {
        headers: {
            Authorization : token,
            "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY,
        }
    }

    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters)
    if(queryParams)
        queryParams =  "?" + queryParams;
    else
        queryParams = '';

    let result = axios.delete(apiEndpoint + queryParams, headerParameters)

    return result;
}





const HttpDownloadFile = (path, parameters = '', subscriptionKey) => {

    console.log("parametersxxx", parameters);
    console.log("subscriptionKeysxxx", subscriptionKey);


    let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   

            console.log("isAuthenticated oo", isAuthenticated);
            if (isAuthenticated) {
               AuthService.getAuthToken().then(({accessToken})=>{
                    console.log("accessTokenxxxx", accessToken);
                    setAuthToken(accessToken)
                    const result = HttpActualDownloadFile(path, parameters, accessToken, subscriptionKey)
                    
                    resolve(result)
                }, (err)=>{
                    console.log("errrrrrr uuu: ", err)

                })
            } else {
                const result = HttpActualDownloadFile(path, parameters, "", subscriptionKey)
                resolve(result)
            }

        } catch (error) {
            console.log("HTTP_GET", error)
            reject(error)
        }
    })

    return promise

}

const HttpActualDownloadFile = (path, parameters = '', accessToken = null, subscriptionKey) => {
    let token;

    if (accessToken) {
        token = "Bearer " + accessToken;
    }

    let options = {
        headers: {
            Authorization: token,
            "Ocp-Apim-Subscription-Key": subscriptionKey
        },
        responseType: 'blob',
    }

    console.log("Http Download File xxx", options);

    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    console.log("GetURL:", apiEndpoint + queryParams);
    let result = axios.get(apiEndpoint + queryParams, options)
    return result
}

const HttpPublicGet = (path, accessToken, subscriptionKey) => {

    var promise = new Promise((resolve, reject) => {
        try {   
            const result = HttpActualPublicGet(path, accessToken, subscriptionKey);
            resolve(result);

        } catch (error) {
            console.log("HttpActualGet2 error xxxx", error);
            reject(error);
        }
    })

    return promise

}

const HttpActualPublicGet = (path, accessToken, subscriptionKey) => {
    
    let token;

    if (accessToken) {
        token = "Bearer " + accessToken;
    }

    let headerParameters = {
        headers: {
            Authorization: token,
            "Ocp-Apim-Subscription-Key": subscriptionKey
        }
    }

    console.log("HttpActualGet2 headerParameters xxxxx", headerParameters);

    let apiEndpoint = path;

    console.log("apiEndpoint xxxx", apiEndpoint);

    let result = axios.get(apiEndpoint, headerParameters);

    console.log("HttpActualGet2 result xxxx", result);
    
    return result
}

const HttpPost1 = (path, parameters = '', payload, subscriptionKey, loaderType) => {

    console.log("parametersxxx HttpPost1", parameters);
    console.log("subscriptionKeysxxx", subscriptionKey);

    let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   
            if (isAuthenticated) {
                 AuthService.getAuthToken().then(({accessToken})=>{
                    setAuthToken(accessToken)
                    const result = HttpActualPost1(path, parameters, payload, accessToken, subscriptionKey, loaderType)
                    
                    resolve(result)
                }, (err)=>{
                    console.log("errrrrrr uuu: ", err)
                })
            } else {
                const result = HttpActualPost1(path, parameters, payload, subscriptionKey, loaderType)
                resolve(result)
            }

        } catch (error) {
            console.log("HTTP_GET", error)
            reject(error)
        }
    })

    return promise

}

const HttpActualPost1 = (path, parameters = '', payload, accessToken = null, subscriptionKey, loaderType) => {
    
    let token;

    if (accessToken) {
        token = "Bearer " + accessToken;
    }

    let headerParameters = {
        headers: {
            Authorization: token,
            "Ocp-Apim-Subscription-Key": subscriptionKey,
            "Content-Type": "text/plain" // Set content type to JSON
        }
    }

    console.log("HttpActualGetheaderParameters", headerParameters);

    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    console.log("GetURL:", apiEndpoint + queryParams);

    let result = axios.post(apiEndpoint + queryParams, payload, headerParameters);

    console.log("HttpActualGet result xxxx", result);
    
    return result;
}


const HttpGet1 = (path, parameters = '', sharedKey, subscriptionKey) => {

    console.log("parametersxxx", parameters);
    console.log("subscriptionKeysxxx", subscriptionKey);

    let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   
            if (isAuthenticated) {
                 AuthService.getAuthToken().then(({accessToken})=>{
                    setAuthToken(accessToken)
                    const result = HttpActualGet1(path, parameters, accessToken, sharedKey, subscriptionKey)
                    
                    resolve(result)
                }, (err)=>{
                    console.log("errrrrrr uuu: ", err)
                })
            } else {
                const result = HttpActualGet1(path, parameters, "", sharedKey, subscriptionKey)
                resolve(result)
            }

        } catch (error) {
            console.log("HTTP_GET", error)
            reject(error)
        }
    })

    return promise

}

const HttpActualGet1 = (path, parameters = '', accessToken = null, sharedKey, subscriptionKey) => {
    
    let token;

    if (accessToken) {
        token = "Bearer " + accessToken;
    }

    let headerParameters = {
        headers: {
            Authorization: token,
            "Shared-Key" : sharedKey,
            "Ocp-Apim-Subscription-Key": subscriptionKey
        }

    }

    console.log("HttpActualGetheaderParameters", headerParameters);

    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    console.log("GetURL:", apiEndpoint + queryParams);
    let result = axios.get(apiEndpoint + queryParams, headerParameters);

    console.log("HttpActualGet result xxxx", result);
    
    return result
}


const HttpGetBlob = (path, parameters = '', sharedKey, subscriptionKey) => {

    console.log("parametersxxx", parameters);
    console.log("subscriptionKeysxxx", subscriptionKey);

    let isAuthenticated = AuthService.isUserLoggedIn();

    var promise = new Promise((resolve, reject) => {
        try {   
            if (isAuthenticated) {
                 AuthService.getAuthToken().then(({accessToken})=>{
                    setAuthToken(accessToken)
                    const result = HttpActualGetBlob(path, parameters, accessToken, sharedKey, subscriptionKey)
                    
                    resolve(result)
                }, (err)=>{
                    console.log("errrrrrr uuu: ", err)
                })
            } else {
                const result = HttpActualGetBlob(path, parameters, "", sharedKey, subscriptionKey)
                resolve(result)
            }

        } catch (error) {
            console.log("HTTP_GET", error)
            reject(error)
        }
    })

    return promise

}

const HttpActualGetBlob = (path, parameters = '', accessToken = null, sharedKey, subscriptionKey) => {
    
    let token;

    if (accessToken) {
        token = "Bearer " + accessToken;
    }

    let headerParameters = {
        headers: {
            Authorization: token,
            "Shared-Key" : sharedKey,
            "Ocp-Apim-Subscription-Key": subscriptionKey
        }, 
        
        responseType: "blob"

    }

    console.log("HttpActualGetheaderParameters", headerParameters);

    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    console.log("GetURL:", apiEndpoint + queryParams);
    let result = axios.get(apiEndpoint + queryParams, headerParameters);

    console.log("HttpActualGet result xxxx", result);
    
    return result
}


export { HttpRawGet,HttpPost, HttpActualPost, HttpGet, HttpActualGet, HttpPut, HttpActualPut, HttpDelete, HttpActualDelete, 
    HttpDownloadFile,HttpPostMultipart,HttpActualPostMultipart, HttpPut1, HttpActualPut1, HttpPublicGet, HttpActualPublicGet, 
    CustomHttpPostMultipart, CustomHttpPost, CustomHttpActualPost, CustomHttpPostParams, CustomHttpActualPostParams,
    HttpGet1, HttpActualGet1, HttpGetBlob,
}