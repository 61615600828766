import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import WhatToKnow from "../../../../images/blog/what-to-know.png";


const WhatToKnowComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                Blockchain and Digital Trust Trends: What You Need to Know
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Tuesday, 16 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={WhatToKnow} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Blockchain technology, once synonymous with cryptocurrencies, has evolved into a
                                transformative force with far-reaching implications for digital trust across industries. Here's
                                a closer look at the latest trends in blockchain and how they are shaping the landscape of
                                digital trust.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>1. Decentralized Finance (DeFi):</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>Trend:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        Decentralized Finance, or DeFi, is a rapidly growing trend within the blockchain space.
                                        DeFi platforms leverage blockchain to recreate traditional financial services such as
                                        lending, borrowing, and trading in a decentralized and trustless manner.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Implications for Digital Trust:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        DeFi eliminates the need for intermediaries like banks, providing users with direct control
                                        over their assets. Smart contracts on blockchain ensure transparent and automated
                                        execution of financial transactions, fostering a new level of trust in financial interactions.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>2. Non-Fungible Tokens (NFTs):</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>Trend:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        The rise of Non-Fungible Tokens (NFTs) has become a cultural phenomenon. NFTs,
                                        representing unique digital assets like art, music, and collectibles, are bought and sold on
                                        blockchain platforms, providing proof of ownership and authenticity.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Implications for Digital Trust:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        NFTs leverage blockchain's immutability and transparency to certify the authenticity and
                                        ownership of digital assets. This trend is revolutionizing the art and entertainment industries
                                        by providing creators and buyers with a secure and transparent platform.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>3. Interoperability and Cross-Chain Solutions:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>Trend:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        Interoperability solutions and cross-chain technologies aim to bridge different blockchain
                                        networks, enabling seamless communication and asset transfers between them. Projects are
                                        working on protocols to connect blockchains that operate independently.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Implications for Digital Trust:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        Interoperability addresses the fragmentation of blockchain networks, allowing users and
                                        businesses to navigate different platforms with trust and security. It enhances the versatility
                                        and utility of blockchain technologies on a global scale.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>4. Privacy-Focused Blockchains:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>Trend:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        Privacy-focused blockchains, incorporating advanced cryptographic techniques, are gaining
                                        prominence. These blockchains prioritize user privacy by providing enhanced confidentiality
                                        in transactions and smart contracts.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Implications for Digital Trust:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        As concerns about data privacy grow, privacy-focused blockchains empower users with
                                        greater control over their personal information. This trend aligns with the rising demand for
                                        trust and transparency in digital interactions.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>5. Central Bank Digital Currencies (CBDCs):</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>Trend:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        Central banks worldwide are exploring the concept of Central Bank Digital Currencies
                                        (CBDCs), which are digital versions of national currencies. CBDCs aim to leverage
                                        blockchain for more efficient and secure monetary systems.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Implications for Digital Trust:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        CBDCs have the potential to enhance the security and transparency of traditional monetary
                                        systems. By leveraging blockchain, central banks can provide a trusted and verifiable digital
                                        alternative to physical currencies.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>6. Sustainability and Green Blockchain:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>Trend:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        Addressing concerns about the environmental impact of blockchain, the industry is
                                        witnessing a trend toward sustainable and green blockchain solutions. Projects are
                                        exploring ways to minimize energy consumption and carbon footprints.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography><b>Implications for Digital Trust:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        Sustainability initiatives enhance the social responsibility of blockchain projects. This trend
                                        aligns with growing awareness of environmental issues and contributes to building trust by
                                        addressing concerns related to the ecological footprint of blockchain networks.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Conclusion:</Typography>
                            <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                As blockchain continues to evolve, these trends underscore its expanding role in reshaping
                                digital trust. Whether it's redefining financial services, certifying the authenticity of digital
                                assets, or enhancing privacy, blockchain remains at the forefront of innovations that foster a
                                more secure and transparent digital future.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-cybergems-art-of-authenticity"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        The Art of Authenticity - OVCODE's Blockchain Verifying Cybergems
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Dive into the details of how OVCODE’s blockchain technology ensures the authenticity and uniqueness of each Cybergem, setting new standards in the NFT marketplace.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                May 06, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-industry-ovcode-role"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        The Role of OVCODE in Securing the Supply Chain
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Discuss how OVCODE contributes to supply chain security and authenticity.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                October 09, 2023
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default WhatToKnowComponent