import environment from "../../../environment/environment";

const OVCODE_WEBSITE_BASE_URL = environment.ovcode_website_service.url ;
const OVCODE_VERIFICATION_BASE_URL = environment.ovcode_verification_service.url;


export default {

   // join
   JOIN_OVCODE:                           OVCODE_WEBSITE_BASE_URL + '/api/v1/join-now',

   //verification
   HASH_VERIFICATION :                    OVCODE_VERIFICATION_BASE_URL + '/api/v1/hash/verification',
   VERIFY_REFERENCE:                      OVCODE_VERIFICATION_BASE_URL + '/api/v1/reference/verification',
   VERIFY_OVI:                            OVCODE_VERIFICATION_BASE_URL + '/api/v1/ovi/verification',
   GENERATE_SHARED_KEY:                   OVCODE_VERIFICATION_BASE_URL + '/api/v1/shared-key/generate',
   DOWNLOAD_USING_SHARED_KEY:             OVCODE_VERIFICATION_BASE_URL + '/api/v1/download',
}