import React, { useState, useEffect } from 'react';
import ServicesOneComponent from './servcies-one-component';
import ServicesTwoComponent from './services-two.component';

import DigitalSignatureImage from '../../../images/products/ovcode-digital-signature.jpg';
import VerifyAuthenticityImage from '../../../images/products/ovcode-verify-authenticity.jpg';
import VaultStorageImage from '../../../images/products/ovcode-vault-storage.jpg';
import DecentralizedStorageImage from '../../../images/products/ovcode-decentralized-storage.jpg';
import useWidth from '../../../hooks/useWidth';


const images = [
  <ServicesOneComponent />,
  <ServicesTwoComponent />

];

const ProductsServicesSlider = (props) => {

  const breakpoints = useWidth();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 30000); // Change slide 
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isPlaying]);

  const handlePlayPause = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );

  };




  return (
      <div class="content-card carouselbase carousel aem-GridColumn aem-GridColumn--default--12">
        <section id="content-card-uid55d6">
          <div class="row row-cols-1   row-cols-sm-2 row-cols-lg-4">

            <div class="col mb-5 mb-lg-0">
              <div class="card">
                <img src={VerifyAuthenticityImage}
                  loading="lazy" class="card-img" alt="Identity Verification" style={{ marginBottom: 10}} />

                <div class="card-body  pt-3">
                    <h2 class="h4" style={{textAlign: 'start', marginBottom: 10}} >
                      Identity Verification
                    </h2>
                    <p style={{textAlign: 'start'}}>Streamlined onboarding processes simplify identity verification for businesses or government agencies.</p>
                </div>
              </div>
            </div>

            <div class="col  mb-5 mb-lg-0">
              <div class="card">
                <img src={DecentralizedStorageImage}
                  loading="lazy" class="card-img" alt="Digital Signatures" style={{ marginBottom: 10}} />
                <div class="card-body  pt-3">
                    <h2 class="h4" style={{textAlign: 'start', marginBottom: 10}} >
                      Digital Signatures
                    </h2>
                    <p style={{textAlign: 'start'}}>Facilitate secure and legally binding signatures, reduce paper waste, and expedite approval processes.</p>
                </div>
              </div>
            </div>

            <div class="col  mb-5 mb-lg-0">
              <div class="card">
                <img src={DigitalSignatureImage}
                  loading="lazy" class="card-img" alt="Document Authentication" style={{ marginBottom: 10}} />
                <div class="card-body  pt-3">
                    <h2 class="h4" style={{textAlign: 'start', marginBottom: 10}} >
                      Document Authentication
                    </h2>
                    <p style={{textAlign: 'start'}}>Security-enhanced technology prevents document tampering and fraud, providing verifiable proof of authenticity.</p>
                </div>
              </div>
            </div>

            {/* <div class="col mb-5 mb-sm-0" > */}
            <div class="col">
              <div class="card">
                <img src={VaultStorageImage}
                  loading="lazy" class="card-img"
                  alt="Vault Storage" style={{ marginBottom: 10}} />
                <div class="card-body  pt-3">
                    <h2 class="h4" style={{textAlign: 'start', marginBottom: 10}} >
                      Blockchain Services
                    </h2>
                    <p style={{textAlign: 'start'}}>Harness the power of blockchain services, including smart contracts and decentralized applications, to meet your specific needs.</p>
                </div>
              </div>
            </div>


            
          </div>
        </section>
      </div>
  );
};

export default ProductsServicesSlider;
