import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Grid, Typography } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';
import useWidth from "../../../hooks/useWidth";
import useVerification from '../../../hooks/useVerification';
import { ThreeDots } from "react-loader-spinner";

const UnilabHistoryComponent = (props) => {

    const { scannedInfo, setOpenVerificationRes, setOpenVerifiedData, setPdfViewer, isViewing } = props;

    const { generateSharedKey } = useVerification();

    const [historyList, setHistoryList] = useState([]);
    const [isViewingDocument, setViewingDocument] = useState(false);

    const breakpoints = useWidth();

    const openPdfViewer = (fileReference) => {
        // Implement the logic to open PDF viewer here
        console.log("Opening PDF viewer for file reference:", fileReference);
        let isViewBtn = true;
        generateSharedKey(fileReference, isViewBtn, setViewingDocument,  setOpenVerificationRes, setOpenVerifiedData, setPdfViewer);
    };

    useEffect(() => {
        // Assuming history_logs will be included in the scannedInfo in the correct shape.
        if (scannedInfo && scannedInfo.misc && scannedInfo.misc.history_logs) {
            const historyLogs = scannedInfo.misc.history_logs.history;
            setHistoryList(historyLogs || []);
        }
    }, [scannedInfo]);

    return (
        <>
        <Box sx={{ padding: 6, pt: breakpoints === 'xs' ? 3 : 1, pb: 0, textAlign: 'start' }}>
            <Typography sx={{ fontWeight: 'bold', mb: 3, fontSize: 17 }}>History</Typography>
            <Grid container spacing={2}>
                {historyList.length > 0 ? (
                    historyList.map((item, index) => (
                        <Grid key={index} item xs={12} sx={{ paddingTop: '3px' }}>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Typography sx={{ fontSize: 17, wordBreak: 'break-word' }}>{item.ovi}</Typography>
                                    <Typography sx={{ fontSize: 17 }}>{new Date(item.timestamp).toLocaleString()}</Typography>
                                    <Typography sx={{ fontSize: 17 }}>Location: {item.location}</Typography>
                                    <Typography sx={{ fontSize: 17 }}>File Reference: {item.file_reference}</Typography>
                                    <hr style={{ borderTop: '2px dashed #000', width: '100%' }} />
                                </Grid>
                                <Grid item xs={3} sx={{ textAlign: 'center' }}>
                                    <DescriptionIcon
                                        fontSize="large"
                                        sx={{ color: '#95B0C9', cursor: 'pointer' }}
                                        onClick={() => openPdfViewer(item.file_reference)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                ) : (
                    <Typography sx={{ fontSize: 17 }}>No history logs available.</Typography>
                )}
            </Grid>
        </Box>

        <Backdrop open={isViewingDocument}
                sx={{
                    color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1,
                    opacity: "1 !important", backgroundColor: "#fff"
                }}
            >
                <Grid sx={{ display: "block", margin: "auto", textAlign: "center" }}>
                    <Typography sx={{
                        color: "#000",
                        fontWeight: "bold",
                        mb: "-5px",
                        fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 10 : "1rem"
                    }}>
                        Redirecting to viewer, please wait for a moment
                    </Typography>

                    <ThreeDots
                        height="50"
                        width={(breakpoints == "xs" || breakpoints == "sm") ? "50" : "80"}
                        radius="9"
                        color="#000"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ margin: "auto", textAlign: "center", justifyContent: "center" }}
                        wrapperClassName=""
                        visible={true}
                    />
                </Grid>
            </Backdrop>
        </>
    );
};

export default UnilabHistoryComponent;
