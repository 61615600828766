import { Box, Button, Grid, Link, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth";
import verificationBanner from "../../../images/verify/bannerv2.png";
import EnterpriseBackground from "../../../images/enterprise-background.gif";


const VerificationBannerComponent = () => {
    const breakpoints = useWidth();

    return (
        <Box sx={{
            backgroundImage: `url(${EnterpriseBackground})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 550,
            maxHeight: (breakpoints == "xs" || breakpoints == "sm") ? 'auto' : 500, 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundPosition: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
            margin: 'auto 0',
            width: ' 100%',
        }}>
            <Box sx={{ width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '90%' : '95%', margin: 'auto' }} >
                <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: breakpoints === 'xs' ? 1 : 6 }}>
                    <Grid item xs={12} sm={5} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: breakpoints === 'xs' ? '1rem!important' : '3rem!important' }}>
                        <Box sx={{ mb: 5 }}>
                            <Typography
                                sx={{
                                    fontSize: 29,
                                    margin: 0,
                                    lineHeight: 1.2,
                                    fontWeight: 700,
                                    textAlign: 'start',
                                    color: "#000"
                                }}
                            >
                                OVCODE Enterprise
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 4 }}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    margin: 0,
                                    lineHeight: 1.6,
                                    color: 'rgb(100, 110, 115)',
                                    fontWeight: 400,
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    color: "#000",
                                    width: '95%'
                                }}
                            >
                                Revolutionize your business operations with our cutting-edge enterprise web application, driving efficiency and growth in a seamless digital experience.

                            </Typography>
                        </Box>
                        <Box sx={{ mb: 4 }}>
                            <Grid container>
                                <Grid item xs={5} sx={{ textAlign: 'start' }}>
                                    <Button 
                                        variant="outlined" 
                                        onClick={() => window.open('https://dev-portal.ovcode.com', '_blank') }
                                        sx={{ 
                                                width: '60%', 
                                                fontSize: 16, 
                                                height: 40, 
                                                borderColor: '#fff', 
                                                color: '#fff', 
                                                textTransform: 'none', 
                                                borderRadius: 0, 
                                                '&:hover': { backgroundColor: 'transparent', borderColor: '#fff', color: '#fff' } 
                                            }}
                                        >
                                        Sign In
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={1}></Grid>
                    <Grid item xs={12} sm={6} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                        <img src={verificationBanner} style={{ width: '65%' }} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default VerificationBannerComponent;
