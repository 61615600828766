
let auth_token;

export const setAuthToken = (token) => {
    auth_token = token
}

export const getAuthToken = () => {
    return auth_token
}
