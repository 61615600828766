import cybergemsOne from '../../../images/cybergems/one.jpg';
import cybergemsTwo from '../../../images/cybergems/two.jpg';
import eidOne from '../../../images/eID-in-depth/in-depth-2.png';
import eidTwo from '../../../images/eID-in-depth/in-depth-1.png';
import eidThree from '../../../images/eID-in-depth/in-depth-3.png';
import industryInsightOne from "../../../images/industry-insights/industry-1.png";
import industryInsightTwo from "../../../images/industry-insights/industry-2.png";
import industryInsightThree from "../../../images/industry-insights/industry-3.png";
import industryInsightFive from "../../../images/industry-insights/industry-5.png";
import industryInsightSix from "../../../images/industry-insights/industry-6.jpg";
import industryInsightSeven from "../../../images/industry-insights/industry-7.png";
import industryInsightEight from "../../../images/industry-insights/industry-8.png";
import industryInsightNine from "../../../images/industry-insights/industry-9.png";
import industryInsightTen from "../../../images/industry-insights/industry-10.png";
import industryInsightEleven from "../../../images/industry-insights/industry-11.png";
import industryInsightTwelve from "../../../images/industry-insights/industry-12.png";
import industryInsightThirteen from "../../../images/industry-insights/industry-13.png";
import industryInsightFourteen from "../../../images/industry-insights/industry-16.png";
import industryInsightFiftteen from "../../../images/industry-insights/industry-19.png";
import industryInsightSixteen from "../../../images/industry-insights/industry-20.png";
import industryInsightSeventeen from "../../../images/industry-insights/industry-22.png";
import industryInsightEighteen from "../../../images/industry-insights/industry-24.png";
import industryInsightNineteen from "../../../images/industry-insights/industry-26.png";
import industryInsightTwenty from "../../../images/industry-insights/industry-28.png";
import ovcodeCGImageOne from '../../../images/cybergems/one.jpg';
import ovcodeCGImageTwo from '../../../images/cybergems/two.jpg';
import ovcodeCGImageThree from '../../../images/cybergems/three.jpg';
import ovcodeCGImageFour from '../../../images/cybergems/four.jpg';
import ovcodeCGImageFive from '../../../images/cybergems/five.png';
import ovcodeCGImageSix from '../../../images/cybergems/six.jpg';
import ovcodeCGImageSeven from '../../../images/cybergems/seven.jpg';
import ovcodeCGImageEight from '../../../images/cybergems/eight.jpg';
import ovcodeCGImageNine from '../../../images/cybergems/nine.jpg';
import ovcodeCGImageTen from '../../../images/cybergems/ten.jpg';
import ovcodeCGImageEleven from '../../../images/cybergems/eleven.jpg';
import ovcodeCGImageTwelve from '../../../images/cybergems/twelve.png';
import ovcodeImageFifteen from '../../../images/blog/transparent-ecosystem.png';
import ovcodeImageOne from '../../../images/ovcode-insights/insights-1.png';
import ovcodeImageTwo from '../../../images/ovcode-insights/insights-2.png';
import ovcodeImageThree from '../../../images/ovcode-insights/insights-3.png';
import ovcodeImageFour from '../../../images/ovcode-insights/insights-4.png';
import ovcodeImageFive from '../../../images/ovcode-insights/insights-5.png';
import ovcodeImageSix from '../../../images/ovcode-insights/insights-6.png';
import ovcodeImageSeven from '../../../images/ovcode-insights/insights-7.png';
import ovcodeImageEight from '../../../images/ovcode-insights/insights-8.png';
import ovcodeImageNine from '../../../images/ovcode-insights/insights-9.png';
import ovcodeImageTen from '../../../images/ovcode-insights/insights-10.png';
import ovcodeImageEleven from '../../../images/ovcode-insights/insights-11.png';
import eSignOne from '../../../images/eSign-in-depth/in-depth-1.png';
import eSignTwo from '../../../images/eSign-in-depth/in-depth-2.png';
import emarkOne from '../../../images/eMark-as-asset/asset-1.png';
import emarkTwo from '../../../images/eMark-as-asset/asset-2.png';
import supportOne from '../../../images/support-and-community/support-1.png';
import supportTwo from '../../../images/support-and-community/support-2.png';
import supportThree from '../../../images/support-and-community/support-3.png';
import supportFour from '../../../images/support-and-community/support-4.png';
import partnerOne from '../../../images/partners/partner-1.png';
import partnerTwo from '../../../images/partners/partner-2.png';

const blogTopicsData = [
    {
        title: "OVCODE and Cybergems: Authenticating the Digital Treasures of Tomorrow",
        date: "May 02, 2024",
        description: "Discover how OVCODE is revolutionizing the world of digital collectibles with Cybergems, a pioneering implementation of its blockchain technology. This blog post delves into how OVCODE's robust blockchain framework ensures the authenticity and permanence of Cybergems, the world's first digital gems. By embedding personal memories into these blockchain-secured gems, OVCODE not only enhances their value and uniqueness but also sets a new benchmark for integrity and trust in digital assets.",
        image: cybergemsOne,
        url: "/blog-cybergems-authenticating-digital-treasure",
        blog: 'blog-cybergems',
        headerText: "Cybergems",
        headerDescription: "The official source of product insight from the OVCODE Team."
    },
    {
        title: "Cybergems Unveiled - The Dawn of Digital Heirlooms",
        date: "May 02, 2024",
        description: "Explore how OVCODE's blockchain authentication is integral to the launch of Cybergems, marking the beginning of a new chapter in digital legacy creation.",
        image: cybergemsTwo,
        url: "/blog-cybergems-unveiled",
        blog: 'blog-cybergems',
        headerText: "Cybergems",
        headerDescription: "The official source of product insight from the OVCODE Team."
    },
    {
        title: "The Art of Authenticity - OVCODE's Blockchain Verifying Cybergems",
        date: "May 06, 2024",
        description: "Dive into the details of how OVCODE’s blockchain technology ensures the authenticity and uniqueness of each Cybergem, setting new standards in the NFT marketplace.",
        image: eidOne,
        url: "/blog-cybergems-art-of-authenticity",
        blog: 'blog-cybergems',
        headerText: "Cybergems",
        headerDescription: "The official source of product insight from the OVCODE Team."
    },
    {
        title: "The Future of Identity Verification: An In-Depth Look at OVCODE eID",
        date: "October 09, 2023",
        description: "Discuss the challenges in traditional identity verification and how OVCODE eID revolutionizes the process.",
        image: eidTwo,
        url: "/blog-eid-identity-verification",
        blog: 'blog-eID-in-depth',
        headerText: "Electronic Identification (eID) in Depth",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        title: "eID Success Stories: Real-world Applications of Secure Identity Verification",
        date: "October 09, 2023",
        description: "Discuss the challenges in traditional identity verification and how OVCODE eID revolutionizes the process.",
        image: eidThree,
        url: "/blog-eid-success-stories",
        blog: 'blog-eID-in-depth',
        headerText: "Electronic Identification (eID) in Depth",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        title: "Revolutionizing Secure Payments: Enhancing User Experience with OVCODE's eID Verification",
        date: "April 02, 2024",
        description: "Leverage the seamless integration of OVCODE’s eID verification to transform the payment industry. This platform provides a secure and user-friendly way to authenticate transactions, reducing fraud and simplifying the checkout process. The convenience and security of OVCODE’s eID verification enrich the overall user experience, fostering trust in digital transactions.",
        image: industryInsightThree,
        url: "/blog-eid-secure-payments",
        blog: 'blog-eID-in-depth',
        headerText: "Electronic Identification (eID) in Depth",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: industryInsightOne,
        title: "Blockchain and Digital Trust Trends: What You Need to Know",
        date: "October 09, 2023",
        description: "Explore the latest trends in blockchain technology and their implications for digital trust.",
        url: "/blog-industry-what-to-know",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        title: "The Art of Authenticity - OVCODE's Blockchain Verifying Cybergems",
        date: "May 06, 2024",
        description: "Dive into the details of how OVCODE’s blockchain technology ensures the authenticity and uniqueness of each Cybergem, setting new standards in the NFT marketplace.",
        image: ovcodeCGImageThree,
        url: "/blog-cybergems-art-of-authenticity",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: industryInsightTwo,
        title: "The Role of OVCODE in Securing the Supply Chain",
        date: "October 09, 2023",
        description: "Discuss how OVCODE contributes to supply chain security and authenticity.",
        url: "/blog-industry-ovcode-role",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        title: "OVCODE and Cybergems: Authenticating the Digital Treasures of Tomorrow",
        date: "May 02, 2024",
        description: "Discover how OVCODE is revolutionizing the world of digital collectibles with Cybergems, a pioneering implementation of its blockchain technology. This blog post delves into how OVCODE's robust blockchain framework ensures the authenticity and permanence of Cybergems, the world's first digital gems. By embedding personal memories into these blockchain-secured gems, OVCODE not only enhances their value and uniqueness but also sets a new benchmark for integrity and trust in digital assets.",
        image: ovcodeCGImageOne,
        url: "/blog-cybergems-authenticating-digital-treasure",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: industryInsightThree,
        title: "Enhancing User Experience with OVCODE: How Seamless Integration Builds Trust",
        date: "April 02, 2024",
        description: "Discover how OVCODE revolutionizes user experience by offering seamless integration of its robust digital trust solutions. Learn how the platform's user-centric design not only simplifies verification processes but also reinforces user confidence. By merging advanced PKI with the immutability of blockchain technology, OVCODE ensures that each transaction is secure, transparent, and efficient. This integration is key to building and sustaining trust in digital interactions, making OVCODE an indispensable ally in the pursuit of uncompromising online security and convenience.",
        url: "/blog-industry-user-experience",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        title: "Behind Every Gem - The Technology That Secures Memories",
        date: "May 06, 2024",
        description: "Discuss the technical underpinnings of OVCODE's blockchain that make it possible for Cybergems to securely embed and immortalize personal memories.",
        image: ovcodeCGImageFour,
        url: "/blog-cybergems-secures-memories",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: industryInsightFive,
        title: "Maximizing Efficiency with OVCODE's Real-Time Verification",
        date: "April 02, 2024",
        description: "Streamline operations and boost productivity with OVCODE’s real-time verification capabilities. This feature empowers businesses to validate transactions and credentials instantly, minimizing delays, and enhancing workflow efficiency. Real-time verification is key to maintaining a competitive edge in today’s fast-paced digital environment.",
        url: "/blog-industry-real-time-verification",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        title: "The Future of Collecting - Blockchain-Certified Cybergems",
        date: "May 06, 2024",
        description: "Envision the future of collecting through the lens of OVCODE-certified Cybergems and how this transforms the way we think about ownership and value in the digital age.",
        image: ovcodeCGImageFive,
        url: "/blog-cybergems-future-collecting",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: industryInsightSix,
        title: "OVCODE: Bridging Certified Trust with AATL and Blockchain Synergy",
        date: "April 02, 2024",
        description: "OVCODE redefines digital trust by integrating AATL-certified security with the unparalleled verification power of blockchain technology. This synergy enhances document security and integrity, streamlines global digital processes, and sets a new standard for trust in the digital age.",
        url: "/blog-industry-blockchain-synergy",
        blog: 'iblog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        title: "Crafting Trust in the Blockchain - OVCODE's Role in NFT Integrity",
        date: "May 07, 2024",
        description: "Delve into the concept of trust in the blockchain ecosystem and how OVCODE’s robust authentication protocols are critical for maintaining the integrity of NFTs like Cybergems.",
        image: ovcodeCGImageSix,
        url: "/blog-cybergems-ovcode-role-nft",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: industryInsightSeven,
        title: "Building Trust in the Cloud Era with Technology Certified by AATL Members",
        date: "April 02, 2024",
        description: "In the fast-evolving cloud computing landscape, OVCODE's use of technology certified by members of the Adobe Approved Trust List (AATL), like Entrust, marks a significant leap in establishing trust. Delve into how OVCODE's adherence to these distinguished security standards is crucial for fostering unshakeable confidence in cloud-based interactions and data storage.",
        url: "/blog-industry-building-trust",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        title: "Cybergems: An Investment in the Future with OVCODE's Blockchain",
        date: "May 07, 2024",
        description: "Analyze the investment potential of Cybergems within the blockchain landscape, backed by OVCODE's secure technology, and discuss the factors that make them a sound investment.",
        image: ovcodeCGImageSeven,
        url: "/blog-cybergems-investment-era",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: industryInsightEight,
        title: "Decoding OVCODE's Security: PKI, Blockchain, and AATL Certified Excellence",
        date: "April 02, 2024",
        description: "Unpack the layers of security that OVCODE provides, combining the strength of Public Key Infrastructure (PKI) with the unalterable record-keeping of blockchain technology, enhanced by AATL-certified solutions from Entrust. This multifaceted security approach ensures that each user’s data remains untampered and protected against evolving digital threats.",
        url: "/blog-industry-decoding-security",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        title: "Cybergems Unveiled - The Dawn of Digital Heirlooms",
        date: "May 02, 2024",
        description: "Explore how OVCODE's blockchain authentication is integral to the launch of Cybergems, marking the beginning of a new chapter in digital legacy creation.",
        image: ovcodeCGImageTwo,
        url: "/blog-cybergems-unveiled",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: industryInsightNine,
        title: "Future-Proofing Data Integrity: OVCODE’s Secure Storage Solutions",
        date: "April 02, 2024",
        description: "With OVCODE’s secure storage solutions, users benefit from a forward-looking approach that safeguards data integrity against the test of time. This prepares businesses and individuals alike for a future where data permanence and retrieval are critical, ensuring long-term access and reliability.",
        url: "/blog-industry-future-proofing",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        title: "A Legacy in Pixels - OVCODE's Commitment to Secure Digital Legacies",
        date: "May 07, 2024",
        description: "Reflect on the importance of digital legacy and how OVCODE’s technology enables users to preserve their digital footprints for future generations with Cybergems.",
        image: ovcodeCGImageEight,
        url: "/blog-cybergems-pixel-legacy",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: industryInsightTen,
        title: "From On-Prem to Cloud: OVCODE's Flexible and Secure Storage Explained",
        date: "April 02, 2024",
        description: "Transition with ease from on-premises to cloud storage without sacrificing security with OVCODE’s solutions. Flexibility doesn't come at the cost of protection; instead, OVCODE ensures that wherever your data resides, it's under the lock and key of industry-leading security standards.",
        url: "/blog-industry-flexible-and-secure-storage",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        title: "The Evolution of Digital Collectibles with OVCODE and Cybergems",
        date: "May 07, 2024",
        description: "Take a look at how the collaboration between OVCODE and Cybergems is pushing the boundaries of digital collectibles and leading an industry evolution.",
        image: ovcodeCGImageNine,
        url: "/blog-cybergems-evolution-of-digital-collectibles",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: industryInsightEleven,
        title: "OVCODE's Hybrid Approach: Combining Traditional PKI with Blockchain Innovation",
        date: "April 02, 2024",
        description: "OVCODE's hybrid security model merges the reliability of traditional PKI with the cutting-edge innovation of blockchain. Learn about this synergistic approach that offers users a comprehensive and advanced level of data protection and verification.",
        url: "/blog-industry-hybrid-approach",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: ovcodeCGImageTen,
        title: "Intersecting Paths - When Blockchain Meets Personal Memories",
        date: "May 07, 2024",
        description: "Examine the intersection of blockchain technology and personal memorabilia, and how OVCODE's secure platform makes Cybergems a reality.",
        url: "/blog-cybergems-intersecting-paths",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: industryInsightTwelve,
        title: "Exploring the Layers of OVCODE's Security: Certified Trust in a Digital World",
        date: "May 21, 2024",
        description: "Dive deep into the multilayered security framework of OVCODE, where every layer is fortified with AATL member-certified technologies. This in-depth look showcases how integrated security measures work in concert to protect digital assets and transactions.",
        url: "/blog-industry-trust-digital-world",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: ovcodeCGImageEleven,
        title: "Embracing a New Era of NFTs with OVCODE's Trusted Technology",
        date: "May 07, 2024",
        description: "Discuss the broader implications of OVCODE’s technology in shaping the future of NFTs and digital assets, highlighting the success of Cybergems as a case study.",
        url: "/blog-cybergems-embracing-new-era",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: industryInsightThirteen,
        title: "Implementing AATL-Certified Security: How OVCODE Enhances Cloud Trust",
        date: "May 21, 2024",
        description: "In the era where cloud computing is ubiquitous, OVCODE stands out by providing AATL-certified solutions, ensuring that trust is not just promised but embedded within every layer of digital interaction. With such certification, users can confidently rely on cloud services knowing their data is secure.",
        url: "/blog-industry-cloud-trust",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: ovcodeCGImageTwelve,
        title: "The OVCODE Trust Network - Elevating Cybergems to Global Standards",
        date: "May 21, 2024",
        description: "Outline how OVCODE's global trust network plays a role in elevating Cybergems to meet and exceed global standards of digital authentication and trust.",
        url: "/blog-cybergems-elevating-to-global-standard",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: ovcodeImageFifteen,
        title: "Building a Transparent Ecosystem with OVCODE's Blockchain Solutions",
        date: "August 05, 2024",
        description: "OVCODE's blockchain solutions are creating a transparent ecosystem where every transaction is traceable and tamper-proof. Explore how transparency is key to trust and how OVCODE is making it a cornerstone of digital interactions.",
        url: "/blog-industry-transparent-ecosystem",
        blog: 'blog-industry-insights',
        headerText: "Industry Insights",
        headerDescription: "The official source of product insight from the OVCODE Team"
    },
    {
        image: ovcodeImageOne,
        title: "The Evolution of Digital Trust: A Deep Dive into OVCODE",
        date: "October 09, 2023",
        description: "Explore the historical context and development of digital trust, and discuss OVCODE's role in shaping this evolution.",
        url: "/blog-ovcode-insights-evolution",
        blog: 'blog-ovcode-insights',
        headerText: 'OVCODE Insights',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: ovcodeImageTwo,
        title: "Why Digital Trust Matters in Today's World",
        date: "October 09, 2023",
        description: "Highlight the importance of digital trust in various industries and how OVCODE addresses the growing need for secure digital transactions.",
        url: "/blog-ovcode-insights-digital-trust",
        blog: 'blog-ovcode-insights',
        headerText: 'OVCODE Insights',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: ovcodeImageThree,
        title: "OVCODE: A Trailblazer in Blockchain Technology",
        date: "October 09, 2023",
        description: "Showcase how OVCODE leverages blockchain technology to provide a robust and secure solution for digital trust.",
        url: "/blog-ovcode-insights-trailblazer-blockchain",
        blog: 'blog-ovcode-insights',
        headerText: 'OVCODE Insights',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: ovcodeImageFour,
        title: "Securing the Future: A Look into OVCODE's Vision",
        date: "October 09, 2023",
        description: "Discuss OVCODE's long-term vision for the future of digital trust and its potential impact on industries.",
        url: "/blog-ovcode-insights-securing-future",
        blog: 'blog-ovcode-insights',
        headerText: 'OVCODE Insights',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: ovcodeImageFive,
        title: "OVCODE in the News: Latest Updates and Media Coverage",
        date: "October 09, 2023",
        description: "Compile and summarize recent news articles and media coverage featuring OVCODE to keep your audience informed.",
        url: "/blog-ovcode-insights-in-news",
        blog: 'blog-ovcode-insights',
        headerText: 'OVCODE Insights',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: ovcodeImageSix,
        title: "Beyond PKI: How OVCODE Integrates Blockchain for Next-Level Authentication",
        date: "April 01, 2024",
        description: "Discover how OVCODE transcends traditional Public Key Infrastructure (PKI) by integrating blockchain technology to offer superior authentication services. This innovative approach provides enhanced security, ensuring that digital interactions are more reliable and verifiable than ever before.",
        url: "/blog-ovcode-insights-beyond-kpi",
        blog: 'blog-ovcode-insights',
        headerText: 'OVCODE Insights',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: ovcodeImageSeven,
        title: "The Power of AATL  Certification in OVCODE's Trust Infrastructure",
        date: "April 01, 2024",
        description: "OVCODE harnesses the power of technologies certified by the Adobe Approved Trust List (AATL) through its partnership with Entrust, ensuring the highest level of security and trust in its infrastructure. This alignment with AATL-certified entities allows OVCODE to offer an unparalleled standard of authentication, giving users confidence in the integrity and reliability of their digital transactions. By prioritizing top-tier security certifications, OVCODE demonstrates its commitment to maintaining a robust and secure digital environment for all its users.",
        url: "/blog-ovcode-insights-power-of-AATL-certification",
        blog: 'blog-ovcode-insights',
        headerText: 'OVCODE Insights',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: ovcodeImageEight,
        title: "OVCODE’s Blockchain-Powered PKI: A New Paradigm for Digital Trust",
        date: "April 01, 2024",
        description: "Experience a new standard in digital trust with OVCODE’s blockchain-powered PKI solutions. This novel approach brings the robust security of blockchain to the tried-and-tested PKI systems, creating a more dynamic and secure framework for digital identity management.",
        url: "/blog-ovcode-insights-powered-kpi",
        blog: 'blog-ovcode-insights',
        headerText: 'OVCODE Insights',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: ovcodeImageNine,
        title: "OVCODE’s End-to-End Encryption: Securing Data with AATL-Certified Practices",
        date: "April 01, 2024",
        description: "Secure every piece of data from start to finish with OVCODE's end-to-end encryption, underpinned by practices certified by AATL members. Understand how this encryption fortifies the privacy and security of digital information across any platform.",
        url: "/blog-ovcode-insights-end-to-end-encryption",
        blog: 'blog-ovcode-insights',
        headerText: 'OVCODE Insights',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: ovcodeImageTen,
        title: "The Significance of AATL Certification for OVCODE's Blockchain Solutions",
        date: "May 06, 2024",
        description: "Grasp the importance of AATL certification in reinforcing the trustworthiness of OVCODE's blockchain solutions. This endorsement is key for users seeking to leverage blockchain's potential with the assurance of recognized security standards.",
        url: "/blog-ovcode-insights-significance-of-certification",
        blog: 'blog-ovcode-insights',
        headerText: 'OVCODE Insights',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: ovcodeImageEleven, 
        title: "OVCODE: Setting the Standard for Secure Digital Transactions", 
        date: "May 06, 2024", 
        description: "OVCODE is raising the bar for secure digital transactions. Explore how its commitment to security excellence sets a new standard for trust and reliability in the digital space, providing peace of mind for users engaging in various online activities.", 
        url: "/blog-ovcode-insights-setting-standard-for-digital-transactions",
        blog: 'blog-ovcode-insights',
        headerText: 'OVCODE Insights',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: eSignOne, 
        title: "Transforming Document Workflows: OVCODE eSign Explained", 
        date: "October 09, 2023", 
        description: "Provide insights into how OVCODE eSign streamlines document processes for businesses.", 
        url: "/blog-esign-document-workflow",
        blog: 'blog-eSign-in-depth',
        headerText: 'Electronic Signature (eSign) in Depth',
        headerDescription: 'Electronic Signature (eSign) in Depth'
    },
    {
        image: eSignTwo, 
        title: "Unlocking Efficiency: A Guide to Implementing eSign in Your Workflow", 
        date: "October 09, 2023", 
        description: " Offer practical tips and advice on integrating eSign into various business workflows.", 
        url: "/blog-esign-unlocking-efficiency",
        blog: 'blog-eSign-in-depth',
        headerText: 'Electronic Signature (eSign) in Depth',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: emarkOne, 
        title: "Visual Content Security: How eMark Protects Your Digital Assets", 
        date: "October 09, 2023", 
        description: "Explain the significance of eMark in protecting visual content and preventing tampering.", 
        url: "/blog-emark-visual-content",
        blog: 'blog-eMark-as-asset',
        headerText: 'Electronic Mark (eMark) as Asset',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: emarkTwo, 
        title: "Blockchain-Backed Protection: A Closer Look at eMark's Security Features", 
        date: "October 09, 2023", 
        description: "Explore the technical aspects of how eMark utilizes blockchain for enhanced security.", 
        url: "/blog-emark-blockhain",
        blog: 'blog-eMark-as-asset',
        headerText: 'Electronic Mark (eMark) as Asset',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {   
        image: supportOne, 
        title: "Navigating OVCODE: A Comprehensive Guide to User Support", 
        date: "October 09, 2023", 
        description: "Provide an overview of the support services offered by OVCODE and guide users on how to access assistance.", 
        url: "/blog-support-navigating-ovcode",
        blog: 'blog-support-and-community',
        headerText: 'Support & Community',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: supportTwo, 
        title: "Community Spotlight: Stories from the OVCODE User Community", 
        date: "October 09, 2023", 
        description: "Share success stories, experiences, and contributions from the OVCODE user community.", 
        url: "/blog-support-community-spotlight",
        blog: 'blog-support-and-community',
        headerText: 'Support & Community',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: supportThree, 
        title: "Navigating Secure Storage Options with OVCODE: Cloud vs. On-Premises", 
        date: "May 06, 2024", 
        description: "With OVCODE, navigate the choices between cloud and on-premises storage confidently. This comparative insight provides clarity on how OVCODE's security measures are tailored to fit both storage options, ensuring data sovereignty and compliance.", 
        url: "/blog-support-navigating-secure-storage",
        blog: 'blog-support-and-community',
        headerText: 'Support & Community',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: partnerOne, 
        title: "Unlock Growth: A Guide to Becoming an OVCODE Partner", 
        date: "May 06, 2024", 
        description: "Outline the benefits and steps for individuals or businesses interested in becoming OVCODE partners.", 
        url: "/blog-partner-unlock-growth",
        blog: 'blog-partners',
        headerText: 'Partners',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: partnerTwo, 
        title: "OVCODE Distributors Making an Impact: Success Stories", 
        date: "May 06, 2024", 
        description: "Highlight the success stories of OVCODE distributors and the impact they've made in various regions.", 
        url: "/blog-partner-ovcode-distributor",
        blog: 'blog-partners',
        headerText: 'Partners',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: partnerTwo, 
        title: "How OVCODE's AATL-Certified Blockchain Technology Is Revolutionizing Secure Storage", 
        date: "August 21, 2024", 
        description: "Witness the revolution in secure storage with OVCODE’s blockchain technology, certified by AATL members. This post will detail the innovative ways OVCODE is providing users with secure, blockchain-backed storage solutions that set new industry benchmarks.", 
        url: "/blog-ovcode-insights-revolutionizing-secure-storage",
        blog: 'blog-ovcode-insights',
        headerText: 'OVCODE Insights',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: industryInsightFourteen, 
        title: "Decentralizing Trust: How OVCODE is Pioneering the Shift", 
        date: "August 21, 2024", 
        description: "Join OVCODE in the shift towards decentralizing trust. By leveraging blockchain, OVCODE disrupts traditional trust paradigms, empowering users to engage in peer-to-peer transactions with the assurance of security and authenticity.", 
        url: "/blog-industry-pioneering-shift",
        blog: 'blog-industry-insights',
        headerText: 'Industry Insight',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    },
    {
        image: industryInsightFiftteen, 
        title: "OVCODE and IoT: Securing the Internet of Things", 
        date: "August 21, 2024", 
        description: "As IoT devices proliferate, security becomes more critical. OVCODE’s security solutions extend to IoT, offering a robust framework to protect connected devices and their data, ensuring trust and safety in the increasingly connected world we live in.", 
        url: "/blog-industry-internet-of-things",
        blog: 'blog-industry-insights',
        headerText: 'Industry Insight',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    }, 
    {
        image: industryInsightSixteen, 
        title: "The Role of OVCODE in Facilitating Smart Contracts", 
        date: "August 22, 2024", 
        description: "OVCODE is streamlining the future of contractual agreements with smart contracts secured by blockchain. This innovation ensures that contracts are not only self-executing and efficient but also rooted in transparency and immutability.", 
        url: "/blog-industry-smart-contracts",
        blog: 'blog-industry-insights',
        headerText: 'Industry Insight',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    }, 
    {
        image: industryInsightSeventeen, 
        title: "OVCODE in Healthcare: A New Era for Medical Data Integrity", 
        date: "August 22, 2024", 
        description: "OVCODE is pioneering a new era in healthcare by safeguarding medical data integrity. Its blockchain solutions offer patients and providers a secure, immutable record-keeping system that stands as the new standard for confidentiality and compliance.", 
        url: "/blog-industry-medical-data-integrity",
        blog: 'blog-industry-insights',
        headerText: 'Industry Insight',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    }, 
    {
        image: industryInsightEighteen, 
        title: "Digital Certificates: The OVCODE Way", 
        date: "August 22, 2024", 
        description: "Discover the OVCODE approach to digital certificates, enhancing the security and authenticity of credentials in the digital realm. By leveraging blockchain technology, OVCODE ensures that every certificate issued is verifiable and tamper-proof.", 
        url: "/blog-industry-ovcode-way",
        blog: 'blog-industry-insights',
        headerText: 'Industry Insight',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    }, 
    {
        image: industryInsightNineteen, 
        title: "Revolutionizing Education with OVCODE's Verification Systems", 
        date: "August 22, 2024", 
        description: "OVCODE is transforming the education sector with verification systems that authenticate academic credentials securely and efficiently, ensuring that qualifications are credible and recognized globally.", 
        url: "/blog-industry-verification-system",
        blog: 'blog-industry-insights',
        headerText: 'Industry Insight',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    }, 
    {
        image: industryInsightTwenty, 
        title: "OVCODE's Impact on Reducing Online Fraud", 
        date: "August 22, 2024", 
        description: "OVCODE's innovative blockchain and PKI technologies are making significant strides in reducing online fraud. By creating a more secure digital environment, OVCODE is protecting users from fraudulent activities and enhancing the integrity of online transactions.", 
        url: "/blog-industry-reducing-online-fraud",
        blog: 'blog-industry-insights',
        headerText: 'Industry Insight',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    }, 
    {
        image: supportFour, 
        title: "How OVCODE Prioritizes Data Privacy", 
        date: "August 27, 2024", 
        description: "Data privacy is a critical concern in today’s digital landscape, and OVCODE takes this responsibility seriously. Unlike many platforms that store user data on centralized servers, OVCODE empowers clients by allowing them to manage their own data storage. Through seamless integration, OVCODE interacts with client-managed storage systems without taking custody of the data itself. This approach ensures that sensitive information remains under the client’s control, minimizing the risk of unauthorized access or breaches. By prioritizing privacy by design, OVCODE offers a secure and flexible solution that meets the highest standards of data protection while maintaining full compliance with global regulations. This commitment to privacy makes OVCODE an ideal choice for organizations that value security, transparency, and control over their data.", 
        url: "/blog-support-data-privacy",
        blog: 'blog-support-and-community',
        headerText: 'Support & Community',
        headerDescription: 'The official source of product insight from the OVCODE Team'
    }, 
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // }, 
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // }, 
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // }, 
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // }, 
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // }, 
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // },
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // },
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // },
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // },
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // },
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // },
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // },
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // },
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // },
    // {
    //     image: partnerTwo, 
    //     title: "", 
    //     date: "", 
    //     description: "", 
    //     url: "",
    //     blog: '',
    //     headerText: '',
    //     headerDescription: 'The official source of product insight from the OVCODE Team'
    // },
    
    
];

export { blogTopicsData }