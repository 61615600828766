import { Box, CardMedia, Grid, Typography } from "@mui/material";
import solutionOne from "../../../images/solutions-6.png";

function SolutionsBannerComponent(props) {

    const {breakpoints} = props;

    return(
        <Box sx={{mb: 4, width: '90%', margin: 'auto', overflow : 'hidden'}}>
            <Grid container spacing={2} sx={{justifyContent: 'center', alignItems: 'center'}}>
                <Grid item xs={12} sm={7}>
                    <Box sx={{mb: 4}}>
                        <Typography sx={{color: "#002060", textShadow: "3px 2px 0px rgba(0,0,0,0.15)", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "2rem" : "3.3333rem", margin: 0, lineHeight: 1.2, fontWeight: 700,  textAlign: (breakpoints == "xs" || breakpoints == "sm") ? 'center' : 'start'}}>
                            OVCODE SOLUTIONS
                        </Typography>
                    </Box>

                    <Box sx={{mb: 4}}>
                        <Typography sx={{fontSize: "1.25rem", margin: 0 , lineHeight: 1.6, color:' rgb(100, 110, 115)', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word'}}>
                            <b style={{fontWeight: 700, textTransform: 'uppercase'}}>OVCODE</b> innovative solutions have revolutionized the way documents are verified and authenticated online, ensuring a seamless and foolproof process which eliminates any possible fraudulent activity.
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={5}>
                    <CardMedia
                        component="img"
                        image={solutionOne}
                        style={{objectFit: 'contain', margin:'auto'}}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default SolutionsBannerComponent