import React, { useState, useEffect } from "react";
import { Box, Typography } from '@mui/material';
import { CustomTab, CustomTabs } from "./custom-tabs.component";
import UnilabViewDetailsComponent from "../clients/verification-result/unilab-view-detail.component";
import UnilabHistoryComponent from "../clients/verification-result/unilab-history.component";



function useUnilabTabs({ unilabResData, scannedInfo, setOpenVerificationRes, setOpenVerifiedData, setPdfViewer, isViewing }) {
  const [unilabTabs, setUnilabTabs] = useState(null);

  useEffect(() => {
    function setupTabs() {
      const tabsDefault = [
        { name: 'View', component: <UnilabViewDetailsComponent isViewing={isViewing} unilabResData={unilabResData} scannedInfo={scannedInfo} /> },
        { name: 'History', component: <UnilabHistoryComponent scannedInfo={scannedInfo} setOpenVerificationRes={setOpenVerificationRes} setOpenVerifiedData={setOpenVerifiedData} setPdfViewer={setPdfViewer} /> },
      ];
      setUnilabTabs(tabsDefault);
    }
    setupTabs();
  }, [unilabResData, scannedInfo]); 

  return unilabTabs;
}

function UnilabCustomTab({ unilabResData, scannedInfo, setOpenVerificationRes, setOpenVerifiedData, setPdfViewer, isViewing }) {
  const tabs = useUnilabTabs({ unilabResData, scannedInfo, setOpenVerificationRes, setOpenVerifiedData, setPdfViewer, isViewing });
  const [value, setValue] = useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  if (!tabs) return <>Loading...</>;

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <CustomTabs
          value={value} 
          onChange={handleChange} 
          sx={{ marginTop: 2 }} 
          TabIndicatorProps={{ sx: { display:'none' } }}
          centered
        >
          {tabs.map((tab, index) => (
            <CustomTab 
              key={index} 
              label={tab.name} 
              disableRipple 
              {...a11yProps(index)} 
            />
          ))}
        </CustomTabs>
        {tabs.map((tab, index) => (
          <TabPanel 
            key={index} 
            value={value} 
            index={index} 
            style={{ padding: "0px" }}
          >
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}

export default UnilabCustomTab;