import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material"
import background from "../../images/join-us-now.png";

const JoinUsFutureComponent = () => {

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                minHeight: 400,
                maxHeight: 400,
                backgroundPosition: 'center',
                flexDirection: 'column',
                overflow: 'hidden',
                margin: 'auto 0',
                width: ' 100%',
            }}>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                <Box >
                    <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={12} sm={6} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: '3rem!important' }}>
                            <Box sx={{ mb: 1 }}>
                                <Typography
                                    sx={{
                                        fontSize: 29,
                                        margin: 0,
                                        lineHeight: 1.2,
                                        fontWeight: 700,
                                        textAlign: 'start',
                                        color: "#000"
                                    }}
                                >
                                    Join Us in Shaping the Future
                                </Typography>
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        fontWeight: 400,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000",
                                        width: '77%'
                                    }}
                                >
                                    Your partner in digital trust
                                </Typography>
                            </Box>
                            <Box sx={{ textAlign: "start", mt: 0 }}>
                                <Button variant="outlined" onClick={(e) => window.location.href = "/join"}
                                    sx={{
                                        color: "#000",
                                        border: "1.5px solid #000",
                                        borderRadius: 0,
                                        textTransform: "none",
                                        "&:hover": { color: "#000", border: "1.5px solid #000" }
                                    }}
                                >
                                    Join Now
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default JoinUsFutureComponent