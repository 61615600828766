
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Backdrop, Box, Card, CircularProgress, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import 'leaflet/dist/leaflet.css';
import L, { latLng } from 'leaflet';
import { useGeolocated } from "react-geolocated";
import axios from 'axios';
import publicIp from 'react-public-ip';


const GeolocationComponentv2 = ({coords, isGeolocationEnabled, isGeolocationAvailable}) => {
    const [locationData, setLocationData] = React.useState(null);
    const [address, setAddress] = React.useState('')
    const [addressData, setAddressData] = React.useState(null);
    const [ipAddress, setIpAddress] = useState(null); 
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchIpAddress = async () => {
        try {
            const ip = await publicIp.v4() || await publicIp.v6();
            setIpAddress(ip);
        } catch (err) {
            setError('Failed to fetch IP address');
        }
        };

        fetchIpAddress();
    }, []);

    console.log('get address data here', addressData)

    useEffect(() => {
        if(coords){
            const fetchData = async () => {
            try {
                    const response = await fetch(`https://atlas.microsoft.com/search/address/reverse/json?subscription-key=E2I8QVFeWE7agpB8YTzXxqawU388OspxEm9vTtVWN5NXcclioRB4JQQJ99AGAC8vTIn9SgnjAAAgAZMPV4wx&api-version=1.0&query=${coords?.latitude},${coords?.longitude}`);
                    const data = await response.json();
                    setLocationData(data?.addresses[0]?.address);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchData();
        }
    }, [coords]);

    useEffect(() => {
        if(coords){
            const fetchAddress = async () => {
                try {
                const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${coords?.latitude}&lon=${coords?.longitude}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data) {
                    setAddress(data.display_name);
                    setAddressData(data?.address)
                } else {
                    alert('No results found');
                }
                } catch (error) {
                alert('Geocoder failed due to: ' + error.message);
                }
            };

            fetchAddress();
        }
    }, [coords]);


    return !isGeolocationAvailable ? (
        <Box sx={{height:'100vh'}}>
            <div>Your browser does not support Geolocation</div>
        </Box>

        ) : !isGeolocationEnabled ? (
            <div>Geolocation is not enabled</div>
        ) : coords ? (
        
            <Box sx={{height:'70vh'}}>
                <Typography></Typography>
                <Typography>Lat: {coords?.latitude}</Typography>
            
                <Typography>Long: {coords?.longitude}</Typography>

                <Card sx={{boxShadow: 'none', border: 'none', width: "100%", margin: "auto",padding: "25px",}}>
                    <List sx={{ width: '100%',bgcolor: 'background.paper', margin:'auto' }}>
                        <Grid container>
                            <Grid item xs={6} sm={6}>
                                <ListItem>
                                    <ListItemText primary="Complete Address" secondary={address ? address : 'No address found'}/>
                                </ListItem>
                                {/* <ListItem>
                                    <ListItemText primary="Route Numbers" secondary={locationData?.routeNumbers?.length > 0 ? locationData?.routeNumbers : 'NA'} />
                                </ListItem> */}
                                {addressData?.country &&
                                    <ListItem>
                                        <ListItemText primary="Country" secondary={addressData?.country}/>
                                    </ListItem>
                                }
                                {/* <ListItem>
                                    <ListItemText primary="Street Name" secondary={addressData?.streetName}/>
                                </ListItem> */}
                                {addressData?.country_code &&
                                    <ListItem>
                                        <ListItemText primary="Country Code" secondary={addressData?.country_code}/>
                                    </ListItem>
                                }
                                {addressData?.postcode &&
                                    <ListItem>
                                        <ListItemText primary="Post Code" secondary={addressData?.postcode}/>
                                    </ListItem>
                                }
                                {addressData?.quarter &&
                                    <ListItem>
                                        <ListItemText primary="Quarter" secondary={addressData?.quarter}/>
                                    </ListItem>
                                }
                                
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                {addressData?.region &&
                                    <ListItem>
                                        <ListItemText primary="Region" secondary={addressData?.region} />
                                    </ListItem>
                                }
                                {addressData?.road &&
                                    <ListItem>
                                        <ListItemText primary="Road" secondary={addressData?.road}/>
                                    </ListItem>
                                }
                                {addressData?.state &&
                                    <ListItem>
                                        <ListItemText primary="State" secondary={addressData?.state}/>
                                    </ListItem>
                                }
                                {addressData?.town &&
                                    <ListItem>
                                        <ListItemText primary="Town" secondary={addressData?.town}/>
                                    </ListItem>
                                }
                                <ListItem>
                                    <ListItemText primary="IP Address" secondary={ipAddress}/>
                                </ListItem>
                                {/* <ListItem>
                                    <ListItemText primary="Country Subdivision Code" secondary={locationData?.countrySubdivisionCode}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Local Name" secondary={locationData?.localName}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="IP Address" secondary={ip}/>
                                </ListItem> */}
                            </Grid>
                        </Grid>
                    </List>
                </Card>
            </Box>
    ) : (
        <Box sx={{height:'100%'}}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, zIndex: 9999 }}
                open={true}
            >
                <Box>
                    <Typography sx={{ mb: 1}}>Getting the location data</Typography>
                    <CircularProgress color="inherit" />
                </Box>
            </Backdrop>
        </Box>
    );
};

export default GeolocationComponentv2;
