import React, { useState } from "react";
import {
  Backdrop, ListItem, Link, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton,
  Typography, Box, Grid, ListItemText, Avatar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OVCodeLogo from "../../icons/new-ovcode-logo.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import useVerification from "../../hooks/useVerification";
import { ThreeDots } from "react-loader-spinner";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import unilabLogo from '../../icons/Unilab-logo.png';
import tobaccoLogo from '../../icons/tobacco.png';
import unilabOvcodeLogo from '../../icons/Unilab.png';
import UnilabCustomTab from "../common/unilab-custom-tab.component";
import ViewRetailUnilabComponent from "../unilab/view-retail-unilab.component";
import CancelIcon from '@mui/icons-material/Cancel';
import oviIcon from '../../icons/ovi.png';
import androidIcon from '../../icons/android.png';
import iosIcon from '../../icons/apple.png';

const UnilabDialog = (props) => {
  const { openUnilabDialog, breakpoints, setOpenUnilabDialog, verificationOvi } = props;
  const [verificationName, setVerificationName] = useState("");

  const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: "10px 16px 10px 16px" }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute", right: 8, top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const handleCloseVerifyDataDialog = (e) => {
    setOpenUnilabDialog(localStorage.setItem("isVerifiedDialog", false));
    window.location.href = "/verification";
  };

  const handleClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.ovcode.mobile', '_blank');
  };

  function isMobile() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={openUnilabDialog}
      onClose={(e) => handleCloseVerifyDataDialog(e)}
      sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1}}
    >
      {verificationName === 'Required' ?
        <>
          <CustomDialogTitle id="customized-dialog-title" onClose={(e) => handleCloseVerifyDataDialog(e)}>
            <img alt="" src={OVCodeLogo} style={{ width: 130 }} />
          </CustomDialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <Box sx={{ justifyContent: "center", display: "flex", background: "orange", height: "50px", padding: "12px" }}>
              <IconButton>
                <CancelIcon style={{ color: "#fff", marginTop: "4px", marginRight: "-3px" }} />
              </IconButton>
              <Typography variant="subtitle1" sx={{ color: "#fff", fontSize: 18, fontWeight: 'bold' }}>
                OVI IS REQUIRED
              </Typography>
            </Box>
            <Box sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? "26px 25px" : "26px 55px" }}>
              <iframe
                width="100%"
                height="230"
                src="https://www.youtube.com/embed/lAldtHMtw8A"
                title="Why the world needs a verification engine"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                muted
                playsInline
              ></iframe>
            </Box>
          </DialogContent>
        </>
        :
        <>
          <CustomDialogTitle id="customized-dialog-title" onClose={(e) => handleCloseVerifyDataDialog(e)}>
            <></>
          </CustomDialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '12px',
                textAlign: 'center',
              }}
            >
              <Grid container spacing={1} alignItems="center" sx={{ textAlign: 'center', justifyContent: 'center' }}>
                <Grid item xs={12}>
                  <img alt="" src={unilabLogo} style={{ height: 120, objectFit: "contain", marginTop: (breakpoints == "xs" || breakpoints == "sm") ? "-8px" : {} }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontStyle: 'italic', fontSize: 18 }}>Open in OVCODE Mobile App</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container sx={{ padding: 4, paddingBottom: 0 }}>
                    {isMobile() ?
                      <Grid item xs={6} style={{ cursor: 'pointer' }}>
                        <a href={`intent://dev.ovcode.com/unilab/${verificationOvi}/#Intent;scheme=https;package=com.ovcode.app;end`} style={{ textDecoration: 'none' }}>
                          <img alt="" src={androidIcon} style={{ width: 100 }} />
                          <p style={{ fontWeight: 'bold', fontSize: 18, marginBottom: '0px' }}>Android</p>
                        </a>
                      </Grid>
                      :
                      <Grid item xs={6} onClick={handleClick} style={{ cursor: 'pointer' }}>
                        <img alt="" src={androidIcon} style={{ width: 100 }} />
                        <p style={{ fontWeight: 'bold', fontSize: 18, marginBottom: '0px' }}>Android</p>
                      </Grid>
                    }
                    <Grid item xs={6} style={{ position: 'relative', width: 'fit-content', alignSelf: 'center', padding: 5 }}>
                      <img alt="iOS Icon" src={iosIcon} style={{ width: 100 }} />
                      <p style={{ fontWeight: 'bold', fontSize: 18, marginBottom: '0px' }}>iOS</p>
                      <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: 24,
                        fontWeight: 'bold',
                        zIndex: 1,
                      }}>
                        Coming Soon
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </>
      }
      <DialogActions sx={{ display: "flex", justifyContent: "center", padding: "16px 0px", mt: 3 }}>
        <Grid container spacing={0} alignItems="center" sx={{ textAlign: 'center', justifyContent: 'center' }}>
          <Grid item sx={12}>
            <img alt="" src={unilabOvcodeLogo} style={{ width: 50 }} />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UnilabDialog;
