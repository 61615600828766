import { Box, Button, Grid, Typography } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import ElectronicIdentificationBannerComponent from "./electronic-identification-banner.component";
import AboutElectronicIdentificationComponent from "./about-electronic-identification.component";
import ExploreElectronicIdentificationComponent from "./explore-electronic-identification.component";
import { useState } from "react";


const ElectronicIdentificationComponent = () => {

    const breakpoints = useWidth();
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);


    return (
        <Box>

           <ElectronicIdentificationBannerComponent />

           <Box sx={{backgroundColor: '#E2E2E6', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <AboutElectronicIdentificationComponent />
            </Box>

            <Box sx={{width: (breakpoints == "xs" || breakpoints == "sm" || breakpoints == "md") ? '90%' : '93%', display: 'flex', justifyContent: 'center'}}>
                <ExploreElectronicIdentificationComponent />
            </Box>

            
        </Box>
    )
}

export default ElectronicIdentificationComponent;
