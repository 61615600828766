import React, { useState } from "react";
import { Card, CardContent, Chip, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { ovcodemobiledata, roadmapdata, verificationdata } from "../common/ovcode-mobile-app-notes-data";

const OvcodeMobileAppReleaseNotes = () => {
    const [showFullDetails, setShowFullDetails] = useState(false);

    const toggleDetails = () => {
        setShowFullDetails(!showFullDetails);
    };

    return (
        <Card
            sx={{
                borderRadius: "4px",
                marginBottom: "10px",
                transition: ".5s",
                boxShadow: "0 0 6px 1px rgba(0,0,0,0.08)",
                backgroundColor: "#ffffff",
                "&:hover": {
                    boxShadow: "0 0 12px 2px rgba(0,0,0,0.16)",
                },
            }}
        >
            <div style={{ padding: "40px" }}>
                <header style={{ textAlign: "start" }}>
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ fontSize: 28, fontWeight: 600, margin: "0 0 5px 0", color: '#161616' }}
                    >
                        OVCODE Mobile App v1.36
                    </Typography>
                    <div
                        style={{
                            justifyContent: "flex-start",
                            display: "flex",
                            alignItems: "center",
                            fontSize: 14,
                            color: "#999",
                        }}
                    >
                        <List className="post-tags">
                            <ListItem sx={{ paddingLeft: 0 }}>
                                <Chip label={'NEW'} sx={{ background: '#00800061' }} /> &nbsp;
                                <Chip label={"ENHANCEMENTS"} sx={{ background: '#ffa5004f' }} />
                            </ListItem>
                        </List>
                        <Typography
                            variant="body2"
                            component="span"
                            className="posted-time"
                        >
                            <time dateTime="2020-04-23">July 12, 2024</time>
                        </Typography>
                    </div>
                </header>

                <CardContent sx={{ fontSize: 18, padding: 0 }}>
                    <div style={{ mt: 20, paddingLeft: 0 }}>
                        <Typography variant="h6" component="h3" sx={{ textAlign: "start" }}>
                            New Features and Enhancements
                        </Typography>
                        <List sx={{ paddingLeft: 0 }}>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>Verification</Typography>}
                                    secondary={
                                        <React.Fragment>
                                            <List>
                                                {verificationdata.map((data) => {
                                                    return(
                                                        <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                            <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                <CircleIcon sx={{ width: 5, height: 12 }} />
                                                            </ListItemIcon>
                                                            <ListItemText sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                               {data?.title}
                                                            </ListItemText>
                                                        </ListItem>
                                                    )
                                                })}
                                                
                                            </List>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            {showFullDetails && (
                                <>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>UI/UX Enhancements</Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <List>
                                                        {ovcodemobiledata.map((data) => {
                                                            return(
                                                            <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                </ListItemIcon>
                                                                <ListItemText sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                    {data?.title}
                                                                </ListItemText>
                                                            </ListItem>
                                                            )
                                                        })}
                                                        
                                                    </List>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>

                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>Road Map</Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <List>
                                                        {roadmapdata.map((data) => {
                                                            return(
                                                            <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                    <CircleIcon sx={{ width: 5, height: 12 }} />
                                                                </ListItemIcon>
                                                                <ListItemText sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                    {data?.title}
                                                                </ListItemText>
                                                            </ListItem>
                                                            )
                                                        })}
                                                        
                                                    </List>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                </>
                            )}
                        </List>
                    </div>
                </CardContent>
                <footer style={{ textAlign: "center" }}>
                    <a
                        style={{
                            borderRadius: "4px",
                            border: "none",
                            color: "#017eff",
                            border: "1px solid #017eff",
                            padding: "11px 15px 9px 15px",
                            fontSize: "14px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            display: "inline-block",
                            opacity: 0.80,
                            transition: ".5s",
                            textDecoration: "none",
                            cursor: 'pointer'
                        }}
                        onClick={toggleDetails}
                    >
                        {showFullDetails ? "Hide Details" : "See Full Details"}
                    </a>
                </footer>
            </div>
        </Card>
    );
};

export default OvcodeMobileAppReleaseNotes;
