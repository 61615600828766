import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { ColorRing } from 'react-loader-spinner';
import { useLocation, useParams } from "react-router";
import useVerification from "../../../hooks/useVerification";
import useWidth from "../../../hooks/useWidth";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import DataUploaderComponent from "./data-uploader.component";
import ScanQRComponent from "./scan-qr.component";
import VerificationResultDialogComponent from "../../dialog/verification-result-dialog.component";
import UnverifiedDataDialog from "../../dialog/unverified-data-dialog.component";
import { loginRequest } from "../../../config";
import { enqueueSnackbar } from "notistack";
import PDFViewerComponent from "./pdf-viewer.component";
import UnilabVerifiedDataDialog from "../../dialog/unilab-verification-dialog.component";
import UnilabDialog from "../../dialog/unilab-dialog.component";
import VerifiedDataDialog from "../../dialog/verified-data-dialog.component";
import { useGeolocated } from "react-geolocated";
import DataMismatchDialog from "../../dialog/data-mismatch-dialog.component";
import ovcodeLogo from '../../../icons/new-ovcode-logo.png';



const VerifyLinkComponent = () => {
    const breakpoints = useWidth();
    const { verifyReference, verifyOvi, verifyUnilabv2Reference, verifyUnilabReference, verifyOviUnilab } = useVerification();
    const { referenceCode } = useParams();
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [openUpload, setOpenUpload] = useState(false);
    const [openScan, setOpenScan] = useState(false);
    const [openVerifiedData, setOpenVerifiedData] = useState(false);
    const [openVerificationRes, setOpenVerificationRes] = useState(false);
    const [openUnverifiedData, setOpenUnverifiedData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfViewer, setPdfViewer] = useState(null);
    const [openUnilabVerifiedDialog, setOpenUnilabVerifiedDialog] = useState(false);
    const [openUnilabDialog, setOpenUnilabDialog] = useState(false);
    const [isViewingDocument, setViewingDocument] = useState(false);
    const [openMismatchDialog, setOpenMismatchDialog] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [userLocation, setUserLocation] = useState(null);


    const scannedReference = localStorage.getItem("scannedReference");
    const referenceDataScanned = localStorage.getItem("referenceDataScanned");

    const login = async (onSuccess) => {
        if (isProcessing) return; // Prevent multiple triggers
        setIsProcessing(true);

        try {
            const res = await instance.loginPopup(loginRequest);
            if (res) {
                onSuccess(); // Call the success callback
            }
        } catch (error) {
            enqueueSnackbar("Login failed. Please try again.", {
                variant: "error",
                autoHideDuration: 3000,
            });
        } finally {
            setIsProcessing(false); // Reset the processing state
        }
    };


    // define the function that finds the users geolocation
    useEffect(() => {
        // if geolocation is supported by the users browser
        if (navigator.geolocation) {
            // get the current users location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // save the geolocation coordinates in two variables
                    const { latitude, longitude } = position.coords;
                    // update the value of userlocation variable
                    setUserLocation({ latitude, longitude });
                },
                // if there was an error getting the users location
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        }
        // if geolocation is not supported by the users browser
        else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, [])

    console.log("userLocation verify-link", userLocation)



    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (event.type === "unload") {
                localStorage.removeItem("isVerifiedDialog");
                localStorage.removeItem("isUnverifiedDialog");
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    const isMobileOrTablet = () => {
        return /Mobi|Android/i.test(navigator.userAgent) || /Tablet|iPad/i.test(navigator.userAgent);
    };

    const url = window.location.pathname;
    const query = new URLSearchParams(useLocation().search);
    const { id } = useParams();
    const verificationOvi = id;

    const patterns = [/^RL-/, /^BL-/, /^MPL-/];

    useEffect(() => {
        
        if (referenceCode && referenceCode !== "undefined" && userLocation) {
            const fetchData = async () => {
                setIsLoading(true);

                const payload = {
                    ovi: referenceCode,
                    latitude: userLocation && userLocation.latitude,
                    longitude: userLocation && userLocation.longitude,
                    device_info: "samsung SM-A042F",
                    device_id: "fdd36ed7fd14cc39",
                    device_identity: "samsung SM-A042F, ID: fdd36ed7fd14cc39",
                    v_method: "QR",
                };

                try {
                    if (isMobileOrTablet()) {
                        if (isAuthenticated) {
                            // Mobile/Tablet and user is authenticated
                            if (url.includes("unilab")) {
                                await verifyOviUnilab(referenceCode, payload, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData);
                            } else if (url.includes("verification")) {
                                await verifyUnilabReference(referenceCode, verificationOvi, setOpenScan, setOpenVerifiedData, setOpenUnverifiedData, setOpenUnilabVerifiedDialog);
                            } else {
                                await verifyOvi(referenceCode, payload, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData);
                            }
                        } else {
                            // Mobile/Tablet and user is not authenticated
                            setIsDialogOpen(true);
                        }
                    } else { // Desktop or other devices
                        if (!isAuthenticated) {
                            await login(() => {
                                // Retry the verification after successful login
                                fetchData();
                            });
                        } else {
                            if (url.includes("unilab")) {
                                await verifyUnilabv2Reference(referenceCode, setOpenScan, setOpenUnverifiedData, setOpenUnilabDialog);
                            } else if (url.includes("verification")) {
                                await verifyUnilabReference(referenceCode, verificationOvi, setOpenScan, setOpenVerifiedData, setOpenUnverifiedData, setOpenUnilabVerifiedDialog);
                            } else {
                                await verifyReference(referenceCode, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData, setPdfViewer);
                            }
                        }
                    }
                } catch (error) {
                    const errorMessage = error.response ? error.response.data.error : error.message;
                    enqueueSnackbar(errorMessage, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }

                setIsLoading(false);
            };

            fetchData();
        }
    }, [referenceCode, userLocation, isAuthenticated]);

    // useEffect(() => {

    //     if (referenceCode && referenceCode !== "undefined" && userLocation) {
    //         const fetchData = async () => {
    //             setIsLoading(true);

    //               const payload = {
    //                 ovi: referenceCode,
    //                 latitude: userLocation && userLocation.latitude, 
    //                 longitude: userLocation && userLocation.longitude,  
    //                 device_info: "samsung SM-A042F",
    //                 device_id: "fdd36ed7fd14cc39",
    //                 device_identity: "samsung SM-A042F, ID: fdd36ed7fd14cc39",
    //                 v_method: "QR",
    //               };

    //             try {
    //                 if (/Mobi|Android/i.test(navigator.userAgent) || /Tablet|iPad/i.test(navigator.userAgent)) {
    //                     if (url.includes("unilab")) {
    //                         await verifyOviUnilab(referenceCode, payload, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData);
    //                     } else if (url.includes("verification")) {
    //                         await verifyUnilabReference(referenceCode, verificationOvi, setOpenScan, setOpenVerifiedData, setOpenUnverifiedData, setOpenUnilabVerifiedDialog);
    //                     } else {
    //                         await verifyOvi(referenceCode, payload, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData);
    //                     }
    //                 } else {
    //                     if (url.includes("unilab")) {
    //                         await verifyUnilabv2Reference(referenceCode, setOpenScan, setOpenUnverifiedData, setOpenUnilabDialog);
    //                     } else if (url.includes("verification")) {
    //                         await verifyUnilabReference(referenceCode, verificationOvi, setOpenScan, setOpenVerifiedData, setOpenUnverifiedData, setOpenUnilabVerifiedDialog);
    //                     } else {
    //                         await verifyReference(referenceCode, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData, setPdfViewer);
    //                     }

    //                 }
    //             } catch (error) {
    //                 // Adjust error handling based on the actual structure of the error object
    //                 const errorMessage = error.response ? error.response.data.error : error.message;
    //                 enqueueSnackbar(errorMessage, { 
    //                     variant: "error",
    //                     autoHideDuration: 3000
    //                 });
    //             }


    //             setIsLoading(false);
    //         };

    //         fetchData();
    //     }
    // }, [referenceCode, userLocation]);


    return (
        <Box sx={{ overflowX: "hidden" }}>

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="xs" fullWidth sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }}>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        padding: "20px",
                    }}
                >
                    <img src={ovcodeLogo} style={{ width: "70%", marginBottom: "20px" }} alt="Logo" />
                    <Typography gutterBottom sx={{ fontSize: 18, fontFamily: "Poppins, sans-serif" }}>
                        To use verification, please log in first.
                    </Typography>
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: "center",
                        padding: "16px",
                    }}
                >
                    <Button
                        onClick={login}
                        disabled={isProcessing}
                        sx={{
                            fontSize: 20,
                            backgroundColor: "#0c306f",
                            color: "#fff",
                            textTransform: "none",
                            width: "50%",
                            fontFamily: "Poppins, sans-serif",
                            "&:hover": {
                                backgroundColor: "#0a254b",
                            },
                        }}
                    >
                        {isProcessing ? <CircularProgress size={24} /> : "Login"}
                    </Button>
                </DialogActions>
            </Dialog>

            {pdfViewer &&
                <Box>
                    <Button onClick={() => window.location.href = "/verification"} sx={{ mb: 2 }}>Close</Button>
                    <PDFViewerComponent pdfView={pdfViewer} />
                </Box>
            }

            {isLoading && (
                <>
                    <ColorRing
                        visible={true}
                        height="120"
                        width="120"
                        ariaLabel="blocks-loading"
                        colors={['#01beff', '#017eff', '#3333cf', '#01beff', '#017eff']}
                    />
                    <Typography sx={{ fontWeight: '600', fontSize: 20, textAlign: 'center', color: '#fff' }}>
                        Verifying
                    </Typography>
                </>
            )}

            {openUpload && (
                <DataUploaderComponent
                    breakpoints={breakpoints} setOpenUpload={setOpenUpload} openUpload={openUpload} setPdfViewer={setPdfViewer}
                    openVerifiedData={openVerifiedData} setOpenUnverifiedData={setOpenUnverifiedData} setOpenVerifiedData={setOpenVerifiedData}
                    setOpenVerificationRes={setOpenVerificationRes} setOpenMismatchDialog={setOpenMismatchDialog} referenceDataScanned={referenceDataScanned}
                    scannedReference={scannedReference}
                />
            )}

            {openScan && (
                <ScanQRComponent
                    breakpoints={breakpoints}
                    setOpenScan={setOpenScan}
                    setOpenVerificationRes={setOpenVerificationRes}
                    setOpenUnverifiedData={setOpenUnverifiedData}
                />
            )}

            {openVerificationRes && (
                <VerificationResultDialogComponent
                    breakpoints={breakpoints}
                    openVerificationRes={openVerificationRes}
                    setOpenVerificationRes={setOpenVerificationRes}
                    setPdfViewer={setPdfViewer} setViewingDocument={setViewingDocument}
                    openVerifiedData={openVerifiedData} setOpenVerifiedData={setOpenVerifiedData}
                    setOpenMismatchDialog={setOpenMismatchDialog} setOpenUpload={setOpenUpload}
                    openUpload={openUpload} setOpenUnverifiedData={setOpenUnverifiedData}
                />
            )}

            {openUnverifiedData && (
                <UnverifiedDataDialog
                    breakpoints={breakpoints}
                    openUnverifiedData={openUnverifiedData}
                    setOpenUnverifiedData={setOpenUnverifiedData}
                />
            )}

            {openUnilabVerifiedDialog &&
                <UnilabVerifiedDataDialog openUnilabVerifiedDialog={openUnilabVerifiedDialog} setOpenUnilabVerifiedDialog={setOpenUnilabVerifiedDialog}
                    setPdfViewer={setPdfViewer} setViewingDocument={setViewingDocument}
                    openVerifiedData={openVerifiedData} setOpenVerifiedData={setOpenVerifiedData} setOpenVerificationRes={setOpenVerificationRes}
                />
            }

            {openUnilabDialog &&
                <UnilabDialog openUnilabDialog={openUnilabDialog} setOpenUnilabDialog={setOpenUnilabDialog} />
            }

            {openVerifiedData &&
                <VerifiedDataDialog breakpoints={breakpoints} setPdfViewer={setPdfViewer} setViewingDocument={setViewingDocument}
                    openVerifiedData={openVerifiedData} setOpenVerifiedData={setOpenVerifiedData} setOpenVerificationRes={setOpenVerificationRes}
                />
            }

            {openMismatchDialog &&
                <DataMismatchDialog
                    openMismatchDialog={openMismatchDialog}
                    setOpenMismatchDialog={setOpenMismatchDialog}
                    referenceDataScanned={referenceDataScanned}
                    scannedReference={scannedReference}
                />
            }
        </Box>
    );
};

export default VerifyLinkComponent;