import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { Box } from "@mui/material";

const JSONViewerComponent = () => {
    const [jsonData, setJsonData] = useState(null);

    useEffect(() => {
        console.log("useEffect triggered");
        try {
            const storedData = localStorage.getItem("jsonData");
            console.log("storedData from localStorage:", storedData);
            if (storedData) {
                const parsedData = JSON.parse(storedData);
                console.log("parsedData after JSON.parse:", parsedData);
                setJsonData(parsedData);
            } else {
                console.log("No storedData found in localStorage.");
            }
        } catch (error) {
            console.error("Error parsing JSON from localStorage:", error);
        }
    }, []);

    console.log("Component rendered, jsonData:", jsonData);

    return (
        <Box sx={{ mt: 3, 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center",
            flexDirection: "column",
             }}>
            {jsonData ? (
                <Box sx={{ textAlign: 'left' }}>
                    <ReactJson
                        src={jsonData}
                        theme="summerfruit:inverted"
                        iconStyle="triangle"
                        displayDataTypes={false}
                        enableClipboard={false}
                        displayObjectSize={false}
                        name={false}
                        collapsed={false}
                        indentWidth={8}
                    />
                </Box>
            ) : (
                <p>No JSON data available</p>
            )}
        </Box>
    );
};

export default JSONViewerComponent;
