import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";
import OVCodeLogo from "../../icons/new-ovcode-logo.png";
import useWidth from "../../hooks/useWidth";

const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: "10px 16px 10px 16px" }} {...other}>
            {children}

            {onClose ?
                (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute", right: 8, top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )
                :
                null
            }
        </DialogTitle>
    )
}

const DataMismatchDialog = (props) => {

    const { referenceDataScanned , openMismatchDialog, setOpenMismatchDialog, scannedReference} = props;

    const breakpoints = useWidth();

    console.log("openMismatchDialog here aaaa", openMismatchDialog)


    const handleCloseMismatchDialog = (e) => {
        setOpenMismatchDialog(false);

        localStorage.removeItem("isVerifiedDialog");
        localStorage.removeItem("isUnverifiedDialog");
        localStorage.removeItem("scannedReference");
        localStorage.removeItem("referenceDataScanned");
        localStorage.removeItem("isComingFromUploadData");
        
        window.location.href = "/verification"
    }

    return(
        <Dialog
            maxWidth={"sm"}
            fullWidth
            open={openMismatchDialog}
            onClose={(e) => handleCloseMismatchDialog(e)}
            sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1}}
        >
            <CustomDialogTitle id="customized-dialog-title" onClose={(e) => handleCloseMismatchDialog(e)}>
                <img alt=""  src={OVCodeLogo}
                style={{ width: (breakpoints == "xs" || breakpoints == "sm") ? 100 : {}, height: 55, objectFit: "contain", marginTop: (breakpoints == "xs" || breakpoints == "sm") ? "-8px" : {} }} />
            </CustomDialogTitle>

            <DialogContent dividers sx={{ padding: 0 }}>
                <Box sx={{justifyContent: "center", display: "flex", background: "orange", height: "50px", padding: "12px"}}>
                    <Typography variant="subtitle1" sx={{color: "#fff", fontSize: 18, fontWeight: 600}}>
                        DATA MISMATCH
                    </Typography>
                </Box>

                <Box sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "26px 25px" : "20px 25px"}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography sx={{fontWeight: 'bold'}}>Mismatch Details</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>REFERENCE SCANNED: &nbsp;&nbsp;<b>{scannedReference}</b></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>UPLOADED FILE: &nbsp;&nbsp;<b>{referenceDataScanned }</b></Typography>
                        </Grid>
                    </Grid>
                    <br/>
                    <Typography sx={{mt: 2, mb: 2}}>Do not share any personal information.</Typography>
                    <Typography><b>Report this incident:</b> Contact the document owner or the manufacturer.</Typography>
                </Box>
            </DialogContent>

            <DialogActions sx={{display: "flex", justifyContent: "center", padding: "16px 0px"}}>
                <Typography variant="subtitle1">
                    Powered by: 
                </Typography> 
                
                &nbsp;

                <img alt="" src={OVCodeLogo} style={{width: 100}} /> 
            </DialogActions>

        </Dialog>
    )
}

export default DataMismatchDialog