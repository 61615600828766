import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import LatestNews from "../../../../images/ovcode-insights/banner-2.png";


const SettingForStandardDigitalTransactionsComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                OVCODE: Setting the Standard for <br /> Secure Digital Transactions
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Monday, 06 May 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={LatestNews} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In an era where digital transactions form the backbone of the global economy, security is a top priority. OVCODE sets the standard for secure digital transactions by providing an infrastructure that ensures the utmost safety, reliability, and trustworthiness for every exchange.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Pioneering Transaction Security</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE's advanced security measures are at the heart of its digital transaction platform. With features such as real-time encryption and blockchain verification, OVCODE guarantees that every transaction is not only secure but also seamlessly executed.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 1 }}>
                            <Box sx={{ padding: 3, pt: 0, pb: 0 }}>
                                <ul>
                                    <li style={{ marginBottom: 10 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Encrypted Transactions Every Step of the Way</Typography>
                                        <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                            From the moment a transaction is initiated to the time it's completed, OVCODE ensures that all data is encrypted.
                                            This unbreakable encryption shields sensitive information from potential cyber threats, ensuring privacy and security.
                                        </Typography>
                                    </li>
                                    <li style={{ marginBottom: 10 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Blockchain: The Seal of Verification</Typography>
                                        <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                            Blockchain technology provides an additional layer of security to OVCODE's transaction platform.
                                            By recording every transaction on a decentralized ledger, OVCODE ensures that all operations are tamper-proof and verifiable, cementing the foundation of trust with its users.
                                        </Typography>
                                    </li>
                                </ul>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Seamless and Secure User Experience</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE believes that security should not come at the expense of user experience.
                                Therefore, it combines stringent security protocols with an intuitive interface, ensuring that users can conduct their transactions effortlessly and without concern.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 1 }}>
                            <Box sx={{ padding: 3, pt: 0, pb: 0 }}>
                                <ul>
                                    <li style={{ marginBottom: 10 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>User-Friendly Security Protocols</Typography>
                                        <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                            OVCODE's platform is designed with the user in mind, featuring simple navigation through complex security processes.
                                            This approach demystifies the security aspect of digital transactions, making it accessible to all users, irrespective of their technical expertise.
                                        </Typography>
                                    </li>
                                    <li style={{ marginBottom: 10 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Compliance Without Complication</Typography>
                                        <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                            With OVCODE, compliance with global security standards is a given.
                                            The platform is built to adhere to the latest regulations, providing peace of mind to users that their transactions are not only secure but also fully compliant with international standards.
                                        </Typography>
                                    </li>
                                </ul>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'center' }}>Trust OVCODE with Your Digital Transactions</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE is not just setting standards; it's exceeding them. It provides a platform where secure digital transactions are the norm, not the exception.
                                Users and businesses choosing OVCODE are opting for a partner that is committed to delivering security, compliance, and ease of use—all integral to the future of digital transactions.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-ovcode-insights-powered-kpi"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        OVCODE’s Blockchain-Powered PKI: A New Paradigm for Digital Trust
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Experience a new standard in digital trust with OVCODE’s blockchain-powered PKI solutions.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550 }}>
                                April 01, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-ovcode-insights-evolution"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        The Evolution of Digital Trust: A Deep Dive into OVCODE
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        In the rapidly advancing digital age, trust is the linchpin that holds our online interactions together.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                January 12, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SettingForStandardDigitalTransactionsComponent