import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import OvcodeRole from "../../../../images/blog/cloud-trust.png";


const CloudTrustComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                Implementing AATL-Certified Security: <br/> How OVCODE Enhances Cloud Trust 
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Tuesday, 21 May  2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={OvcodeRole} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In today’s cloud-driven landscape, the assurance of security and trust is not just preferred but expected. 
                                OVCODE rises to meet this expectation by not only incorporating AATL (Adobe Approved Trust List)-certified solutions but by weaving these into the fabric of its cloud infrastructure to ensure secure, verified, and reliable user interactions. 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Activating Enhanced Security with AATL Integration</Typography>
                            <Typography sx={{ mt: 1, mb: 1 , textAlign: 'justify', textJustify: 'inter-word'}}>
                                AATL's rigorous certification process serves as a backbone to OVCODE’s security strategy. 
                                By verifying identities and ensuring document authenticity, OVCODE’s integration of AATL-certified security empowers users to operate in the digital sphere with confidence, executing actions from contract signing to identity verification seamlessly. 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Digitally Signed, Globally Trusted</Typography>
                            <Typography sx={{ mt: 1, mb: 1 , textAlign: 'justify', textJustify: 'inter-word'}}>
                                Each digital signature powered by OVCODE goes through an exhaustive AATL certification process, ensuring global recognition and trust. 
                                This level of certification enables businesses to transcend geographical boundaries without a second thought to the legitimacy of their digital signatures.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Authenticity Preserved in Every Interaction</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE's use of AATL-certified solutions guarantees the preservation of document authenticity throughout its lifecycle on the platform. 
                                This not only secures documents against tampering but also ensures they carry legal weight, being recognized as authentic in any legal context.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>OVCODE’s Pledge to Uphold Digital Trust</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE’s AATL-certified solutions are a clear reflection of its unwavering pledge to uphold trust in the digital ecosystem. 
                                This commitment to integrating AATL-certified security measures stands as a declaration of OVCODE's role as a custodian of digital trust.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Surpassing the Benchmarks of Trust</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE doesn't just aim to meet industry standards for trust and security — it strives to surpass them. 
                                By adopting AATL-certified security measures, OVCODE sets a new benchmark in cloud service reliability, one that others in the industry look to emulate.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'center' }}>A Trustworthy Cloud Journey with OVCODE</Typography>
                            <Typography sx={{ mt: 1, mb: 1 , textAlign: 'justify', textJustify: 'inter-word'}}>
                                Embracing cloud technology with OVCODE means stepping into a future where trust is inherent, and security is a given. 
                                The integration of AATL-certified security is a cornerstone of this promise, offering peace of mind in every digital transaction. 
                                With OVCODE, users and businesses are not just adopting cloud technology; they are choosing a partner dedicated to a secure and trustworthy digital tomorrow. 
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-cybergems-future-collecting"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        The Future of Collecting - Blockchain-Certified Cybergems
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Envision the future of collecting through the lens of OVCODE-certified Cybergems and how this transforms the way we think about ownership and value in the digital age.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                May 06, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-industry-blockchain-synergy"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        OVCODE: Bridging Certified Trust with AATL and Blockchain Synergy
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        OVCODE redefines digital trust by integrating AATL-certified security with the unparalleled verification power of blockchain technology
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                April 02, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CloudTrustComponent