import React, { useState } from "react";
import {Typography, Box, Grid} from "@mui/material";
import unilabLogo from '../../../icons/Unilab-logo.png';
import androidIcon from '../../../icons/android.png';
import iosIcon from '../../../icons/apple.png';
import useWidth from "../../../hooks/useWidth";
import unilabOvcodeLogo from '../../../icons/Unilab.png';


const UnilabDesktopViewComponent = () => {

    const breakpoints = useWidth();

    const handleClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.ovcode.app', '_blank');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '12px',
                textAlign: 'center',
            }}
        >
            <Grid container spacing={1} alignItems="center" sx={{ textAlign: 'center', justifyContent: 'center' }}>
                <Grid item xs={12}>
                    <img alt="" src={unilabLogo} style={{ height: 120, objectFit: "contain", marginTop: (breakpoints == "xs" || breakpoints == "sm") ? "-8px" : {} }} />
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ fontStyle: 'italic', fontSize: 18 }}>Open in OVCODE Mobile App</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{ padding: 4, paddingBottom: 0 }}>

                        <Grid item xs={6} onClick={handleClick} style={{ cursor: 'pointer' }}>
                            <img alt="" src={androidIcon} style={{ width: 100 }} />
                            <p style={{ fontWeight: 'bold', fontSize: 18, marginBottom: '0px' }}>Android</p>
                        </Grid>

                        <Grid item xs={6} style={{ position: 'relative', width: 'fit-content', alignSelf: 'center', padding: 5 }}>
                            <img alt="iOS Icon" src={iosIcon} style={{ width: 100 }} />
                            <p style={{ fontWeight: 'bold', fontSize: 18, marginBottom: '0px' }}>iOS</p>
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                fontSize: 24,
                                fontWeight: 'bold',
                                zIndex: 1,
                            }}>
                                Coming Soon
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", padding: "16px 0px", mt: 5 }}>
                    <img alt="" src={unilabOvcodeLogo} style={{ width: 50 }} />
                </Grid>
            </Grid>
           
        </Box >
    )
}

export default UnilabDesktopViewComponent