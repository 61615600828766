import React, { useEffect, useState } from "react";
import VerifyLinkComponent from "../components/landing/verify/verify-link.component";
import { loginRequest, resetPasswordRequest } from "../config";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import ovcodeLogo from '../icons/new-ovcode-logo.png';
import AuthService from "../services/auth";

const VerifyReferenceLinkScreen = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    var isUserLoggedIn = AuthService.isUserLoggedIn();


    const [isDialogOpen, setIsDialogOpen] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false); // State to track login process

    useEffect(() => {
        // If the user is authenticated, close the dialog
        if (isUserLoggedIn) {
            setIsDialogOpen(false);
        } else {
            // Open the dialog only if the user is not authenticated
            setIsDialogOpen(true);
        }

        // Cleanup function to set flag to false when component unmounts
        return () => {
            setIsDialogOpen(false);
        };
    }, [isUserLoggedIn]);

    const login = async () => {
        if (isProcessing) return; // Prevent multiple clicks

        setIsProcessing(true); // Set processing to true

        try {
            const res = await instance.loginPopup(loginRequest);
            if (res) {
                setIsDialogOpen(false); // Close the dialog upon successful login
            }
        } catch (error) {
            console.log('Error during login:', error);
            if (error.errorMessage && error.errorMessage.indexOf("AADB2C90118") > -1) {
                // Handle specific error if needed
            } else {
                enqueueSnackbar("Login failed. Please try again.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        } finally {
            setIsProcessing(false); // Reset processing after login attempt
        }
    };

    return (
        <>
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="xs" fullWidth>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        padding: '20px',
                    }}
                >
                    <img src={ovcodeLogo} style={{ width: '70%', marginBottom: '20px' }} alt="Logo" />
                    <Typography gutterBottom sx={{ fontSize: 18, fontFamily: 'Poppins, sans-serif' }}>
                        To use verification, please log in first.
                    </Typography>
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: 'center',
                        padding: '16px',
                    }}
                >
                    <Button
                        onClick={login}
                        disabled={isProcessing}
                        sx={{
                            fontSize: 20,
                            backgroundColor: '#0c306f',
                            color: '#fff',
                            textTransform: 'none',
                            width: '50%',
                            fontFamily: 'Poppins, sans-serif',
                            '&:hover': {
                                backgroundColor: '#0a254b',
                            },
                        }}
                    >
                        {isProcessing ? <CircularProgress size={24} /> : "Login"}
                    </Button>
                </DialogActions>
            </Dialog>

            {!isDialogOpen && <VerifyLinkComponent />}
        </>
    );
};

export default VerifyReferenceLinkScreen;
