import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Backdrop, Box, Card, CircularProgress, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import 'leaflet/dist/leaflet.css';
import L, { latLng } from 'leaflet';
import { useGeolocated } from "react-geolocated";
import GeolocationComponentv1 from '../components/geolocation/geolocation-component-v1';
import GeolocationComponentv2 from '../components/geolocation/geolocation-component-v2';
import Masonry from '@mui/lab/Masonry';


const GeolocationScreen = () => {
    const inputRef = useRef(null);
    const [latitude, setLatitude] = React.useState(null);
    const [longitude, setLongitude] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [watchId, setWatchId] = React.useState(null);
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 5000,
        });
    const [averageCoords, setAverageCoords] = React.useState(null);

    useEffect(() => {
        if (coords) {
            // Function to get multiple readings
            const getMultipleReadings = async (count) => {
                const positions = [];
                for (let i = 0; i < count; i++) {
                    positions.push(coords);
                    await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second between readings
                }
                return positions;
            };

            const calculateAverageCoords = async () => {
                try {
                    const positions = await getMultipleReadings(3);
                    const avgLatitude =
                        positions.reduce((sum, pos) => sum + pos.latitude, 0) / positions.length;
                    const avgLongitude =
                        positions.reduce((sum, pos) => sum + pos.longitude, 0) / positions.length;
                    setAverageCoords({ latitude: avgLatitude, longitude: avgLongitude });
                } catch (error) {
                    setError('Error calculating average coordinates');
                }
            };

            calculateAverageCoords();
        }
    }, [longitude, latitude]);

    useEffect(() => {
        if (navigator.geolocation) {
          const id = navigator.geolocation.watchPosition(
            (position) => {
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
            },
            (err) => {
              setError(err.message);
            }
          );
          setWatchId(id);
        } else {
          setError("Geolocation is not supported by this browser.");
        }
    
        // Clean up the watchPosition on component unmount
        return () => {
          if (watchId !== null) {
            navigator.geolocation.clearWatch(watchId);
          }
        };
      }, []);

      console.log('library latlong', averageCoords)
      console.log('no library latlong', latitude, longitude)

    return (
        <Box>
            {/* <Masonry id="cardGrid" columns={{ xs: 1, sm: 2 }} spacing={2} sx={{ margin: 'auto', width: '100%', padding: 0, justifyContent:'center' }}> */}
                {/* <GeolocationComponentv1 latitude={latitude} longitude={longitude}/> */}
                <GeolocationComponentv2 coords={coords} isGeolocationAvailable={isGeolocationAvailable} isGeolocationEnabled={isGeolocationEnabled} />
            {/* </Masonry> */}
            
        </Box>
    );
};

export default GeolocationScreen;
