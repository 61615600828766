import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography, Button, IconButton, ListItemText, ListItem, Link, Backdrop } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import { ThreeDots } from 'react-loader-spinner';
import useVerification from '../../hooks/useVerification';
import OVCodeLogo from "../../icons/new-ovcode-logo.png";
import PDFViewerComponent from '../landing/verify/pdf-viewer.component';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const StatusBox = ({ scannedInfo }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: scannedInfo?.status === 'REVOKED' ? 'red' : scannedInfo?.status === 'SUSPENDED' ? 'orange' : '#1bad1bbf',
      height: scannedInfo?.status === 'ACTIVE' ? '70px' : '50px',
      padding: '12px',
      textAlign: 'center',
    }}
  >
    <Grid container spacing={1} alignItems="center" sx={{ textAlign: 'center', justifyContent: 'center' }}>
      <Grid item sx={{ justifyContent: 'start', textAlign: 'start' }}>
        {scannedInfo?.status === 'REVOKED' ? <HighlightOffIcon style={{ color: '#fff', marginTop: '4px', marginRight: '-3px' }} />
          : scannedInfo?.status === 'SUSPENDED' ? <NotInterestedIcon style={{ color: '#fff', marginTop: '4px', marginRight: '-3px' }} />
            : <CheckCircleIcon style={{ color: '#fff', marginTop: '4px', marginRight: '-3px' }} />}
      </Grid>
      <Grid item sx={{ textAlign: 'center', justifyContent: 'center' }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: scannedInfo?.status === 'ACTIVE' ? 600 : 500,
            color: '#fff',
            fontSize: 17,
            textAlign: 'center !important',
          }}
        >
          {scannedInfo?.status === 'REVOKED' ? 'DATA IS REVOKED'
            : scannedInfo?.status === 'SUSPENDED' ? 'DATA IS SUSPENDED'
              : 'DATA FOUND'}
        </Typography>
      </Grid>
    </Grid>
  </Box>
);

const ActionButtons = ({
  scannedInfo,
  isViewingDocument,
  isDownloading,
  isViewBtnClicked,
  handleViewDocument,
  handleDownloadDocument,
}) => (
  <Grid container direction="row" sx={{ padding: theme => theme.breakpoints.down('sm') ? '8px 25px' : '8px 40px', mb: '-23px' }}>
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: '15px', fontWeight: 'bold', mt: '7px' }}>
            {console.log("scannedInfo here", scannedInfo)}
            {scannedInfo && (scannedInfo?.status !== 'REVOKED' && scannedInfo?.status !== 'SUSPENDED') && scannedInfo?.owner?.project?.name}
          </Typography>
        </Grid>

        {scannedInfo?.is_data_downloadable === true && !['SUSPENDED', 'REVOKED'].includes(scannedInfo?.status) &&
        <Grid item xs={6}>
          <Button
            disabled={isViewingDocument}
            variant="contained"
            sx={{
              mt: '7px',
              width: isViewingDocument ? 'auto' : 80,
              textTransform: 'none',
              borderRadius: '16px',
              background: isViewingDocument ? '#808080c9' : '#008000c4',
              cursor: isViewingDocument ? 'no-drop' : 'pointer',
              '&:hover': { background: isViewingDocument ? '#808080c9' : '#008000c4' },
              '&.Mui-disabled': {
                background: '#008f007a',
                color: '#fff',
              },
            }}
            startIcon={isViewingDocument ? <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> : <VisibilityIcon />}
            onClick={handleViewDocument}
          >
            View
          </Button>

          &nbsp;
          <Button
            variant="contained"
            sx={{
              mt: '7px',
              width: isDownloading && !isViewBtnClicked ? 'auto' : 80,
              borderRadius: '16px',
              textTransform: 'none',
              background: '#008000c4',
              cursor: isDownloading && !isViewBtnClicked ? 'no-drop' : 'pointer',
              '&:hover': { background: '#008000c4' },
            }}
            startIcon={isDownloading && !isViewBtnClicked ? <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> : <DownloadIcon />}
            onClick={handleDownloadDocument}
          >
            {isDownloading && !isViewBtnClicked ? 'Saving' : 'Save'}
          </Button>
        </Grid>
        }
      </Grid>
    </Grid>
  </Grid>
);

const DocumentInfo = ({ scannedReference, scannedInfo, isDocxFile, isViewingDocument, handleFileChange, fileInputRef, handleButtonClick }) => (
  <Grid container direction="row" sx={{ padding: theme => theme.breakpoints.down('sm') ? '8px 25px' : '8px 40px', mb: '-23px' }}>
    <Grid item xs={12} sm={6} sx={{ display: scannedReference ? 'visible' : 'none', width: '100%', wordWrap: 'break-word' }}>
      <Typography sx={{ fontSize: '15px', fontWeight: 'bold', mt: '7px' }}>
        OVCODE:
      </Typography>
      {scannedReference}
    </Grid>

    <Grid item xs={12} sm={6}>
      <Typography sx={{ fontSize: '15px', fontWeight: 'bold', mt: '7px' }}>
        {scannedInfo?.details?.updated_time ? 'Published Date:' : null}
      </Typography>
      {scannedInfo?.details?.updated_time}
    </Grid>

    {scannedInfo?.details?.pipeline !== "[EMAIL]" && !['SUSPENDED', 'REVOKED'].includes(scannedInfo?.status) &&
    <Grid item xs={6}>
      {/* <input type="file" onChange={handleFileChange} /> */}
      <Button
         role={undefined}
         tabIndex={-1}
        disabled={isDocxFile || isViewingDocument}
        variant="contained"
        sx={{
          mt: '7px',
          mb: '7px',
          width: 'auto',
          textTransform: 'none',
          borderRadius: '16px',
          background: isViewingDocument ? '#808080c9' : '#008000c4',
          cursor: isViewingDocument ? 'no-drop' : 'pointer',
          '&:hover': { background: isViewingDocument ? '#808080c9' : '#008000c4' },
          '&.Mui-disabled': {
            background: '#008f007a',
            color: '#fff',
          },
        }}
        onClick={handleButtonClick}
      >
        Upload Data
        <VisuallyHiddenInput
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      </Button>
     
    </Grid>
    }
  </Grid>
);

const DocumentDetails = ({ scannedInfo, breakpoints,  setPdfViewer, setOpenVerificationRes, setOpenUnverifiedData, setOpenUpload, setOpenMismatchDialog, setOpenVerifiedData, openUpload , handleCloseVerifyDataDialog}) => {

  const { isDownloading, isViewBtnClicked, isDocxFile, generateSharedKey, scannedResult,
    uploadData, verifying_uploaded_data, uploadDataMismatch
  } = useVerification();

  console.log("setOpenUpload aaa", setOpenUpload)
  console.log("openUpload xxx", openUpload)

  const [isVerifyingReference, setVerifyingReference] = useState(true);
  const [isVerifyingHash, setVerifyingHash] = useState(true);
  const [isViewingDocument, setViewingDocument] = useState(false);

  let isScannedDocument = localStorage.getItem("isScannedDocument");
  let reference_data = localStorage.getItem("reference_data");
  let scannedReference = localStorage.getItem("scannedReference");
  const isComingFromUploadData = localStorage.getItem("isComingFromUploadData");

  useEffect(() => {
    setTimeout(() => {
      setVerifyingReference(false);
      setVerifyingHash(false);
    }, 4000)

    localStorage.removeItem("pdfBase64Data");
    localStorage.removeItem("jsonData");
    localStorage.removeItem("pdfUrl");
    localStorage.removeItem("jsonUrl");
    localStorage.removeItem("textData");
    localStorage.removeItem("textUrl");


  }, [])


  function getHash(algorithm, data) {
    const main = async (msgUint8) => {
      const hashBuffer = await crypto.subtle.digest(algorithm, msgUint8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    };

    if (data instanceof Blob) {
      return new Promise(async (resolve) => {
        const arrayBuffer = await data.arrayBuffer();
        const msgUint8 = new Uint8Array(arrayBuffer);
        resolve(await main(msgUint8));
      });
    }

    const encoder = new TextEncoder();
    const msgUint8 = encoder.encode(data);

    return main(msgUint8);
  }

  const [file, setFile] = useState(null);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    console.log("event xxx", event);

    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      // Handle the file as needed
      localStorage.removeItem("pdfBase64Data");
      localStorage.removeItem("jsonData");
      localStorage.removeItem("pdfUrl");
      localStorage.removeItem("jsonUrl");
      localStorage.removeItem("textData");
      localStorage.removeItem("textUrl");
      localStorage.removeItem("scannedInfo");

      localStorage.setItem("isVerifiedDialog", "false");
      localStorage.removeItem("isVerifiedDialog");
      localStorage.removeItem("isUnverifiedDialog");

      localStorage.setItem("isComingFromUploadData", "true");
    }
  };


  // const handleFileChange = (event) => {
  
  // };

  // const handleUploadData = (event) => {

  //   console.log("event xxx", event)

  //   const selectedFile = event.target.files[0];
  //   setFile(selectedFile);

  //   // Clear specific localStorage items
  //   localStorage.removeItem("pdfBase64Data");
  //   localStorage.removeItem("jsonData");
  //   localStorage.removeItem("pdfUrl");
  //   localStorage.removeItem("jsonUrl");
  //   localStorage.removeItem("textData");
  //   localStorage.removeItem("textUrl");
  //   localStorage.removeItem("scannedInfo");

  //   // Set and remove items correctly
  //   localStorage.setItem("isVerifiedDialog", "false");
  //   localStorage.removeItem("isVerifiedDialog");
  //   localStorage.removeItem("isUnverifiedDialog");

  //   // Set flags for upload
  //   // localStorage.setItem("isComingFromUpload", "true");
  //   localStorage.setItem("isComingFromUploadData", "true");
   
    
  // };

   // Function to get file hash
   const getFileHash = async (file) => {
    try {
      const hashHex = await getHash("SHA-256", file);
      console.log("hashHex :", hashHex);
      // Call the upload function
      uploadDataMismatch(hashHex, setOpenUnverifiedData, setOpenUpload, setOpenMismatchDialog, setOpenVerifiedData, setOpenVerificationRes);
    } catch (error) {
      console.error("Error computing file hash:", error);
    }
  };


  useEffect(() => {
    if (file) {
      getFileHash(file);
    } else {
      console.error("No file selected.");
    }
  },[file])


  const handleDownloadDocument = (e) => {
    let isViewBtn = false;
    generateSharedKey(scannedReference, isViewBtn, setViewingDocument, setOpenVerificationRes, setOpenVerifiedData, setPdfViewer);
  }

  const handleViewDocument = (e) => {
    const storedJsonData = localStorage.getItem("jsonData");
    const storedTextData = localStorage.getItem("textData");

    let isViewBtn = true;
    generateSharedKey(scannedReference, isViewBtn, setViewingDocument, setOpenVerificationRes, setOpenVerifiedData, setPdfViewer);
  }

  const isScannedDocumentBoolean = isScannedDocument === "true";
  

  return (

    <>

      {isScannedDocumentBoolean ? (
        <>
          <StatusBox scannedInfo={scannedInfo} />

          <Box sx={{ padding: '2px', textAlign: 'center', mt: 0.5 }}>
            <Typography sx={{ color: 'orange', fontSize: 14 }}>
              The data reference was found. <br /> {scannedInfo?.details?.pipeline !== "[EMAIL]" && "Please upload a digital copy of an authenticated document for proper verification."}
            </Typography>
          </Box>

          <ActionButtons
            scannedInfo={scannedInfo}
            isViewingDocument={isViewingDocument}
            isDownloading={isDownloading}
            isViewBtnClicked={isViewBtnClicked}
            handleViewDocument={handleViewDocument}
            handleDownloadDocument={handleDownloadDocument}
            // handleUploadData={handleUploadData}
          />

          <DocumentInfo
            scannedInfo={scannedInfo}
            scannedReference={scannedReference}
            // handleUploadData={handleUploadData}
            isDocxFile={isDocxFile}
            isViewingDocument={isViewingDocument}
            fileInputRef={fileInputRef}
            handleFileChange={handleFileChange}
            handleButtonClick={handleButtonClick}
          />
        </>
      ) : (
        <>
          <Box sx={{ justifyContent: 'center', display: 'flex', background: scannedInfo?.status === 'REVOKED' ? 'red' : scannedInfo?.status === 'SUSPENDED' ? 'orange' : '#1bad1bbf', height: '50px', padding: '12px' }}>
            <IconButton>
              {scannedInfo?.status === 'REVOKED' ? <HighlightOffIcon style={{ color: '#fff', marginTop: '4px', marginRight: '-3px' }} />
                : scannedInfo?.status === 'SUSPENDED' ? <NotInterestedIcon style={{ color: '#fff', marginTop: '4px', marginRight: '-3px' }} />
                  : <CheckCircleIcon style={{ color: '#fff', marginTop: '4px', marginRight: '-3px' }} />}
            </IconButton>
            <Typography variant="subtitle1" sx={{ color: '#fff', fontSize: 17 }}>
              {scannedInfo?.status === 'REVOKED' ? 'DATA IS REVOKED ' : scannedInfo?.status === 'SUSPENDED' ? 'DATA IS SUSPENDED ' : 'DATA IS VERIFIED '}
            </Typography>
          </Box>

          {scannedInfo?.is_data_downloadable === true && (scannedInfo?.status !== 'REVOKED' && scannedInfo?.status !== 'SUSPENDED') ? (
            <ActionButtons
              scannedInfo={scannedInfo}
              isViewingDocument={isViewingDocument}
              isDownloading={isDownloading}
              isViewBtnClicked={isViewBtnClicked}
              handleViewDocument={handleViewDocument}
              handleDownloadDocument={handleDownloadDocument}
              // handleUploadData={handleUploadData}
            />
          ) : (
            <Grid container direction="row" sx={{ padding: (breakpoints === 'xs' || breakpoints === 'sm') ? '8px 25px' : '8px 40px', mb: '-23px' }}>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                  {scannedInfo?.owner?.project?.name}
                </Typography>
              </Grid>

              <Grid item xs={(scannedInfo?.scannedInfo?.owner.business.name === null || scannedInfo?.owner.business.id === null) ? 12 : 6} sx={{ display: (scannedReference === 'null' || scannedReference === 'undefined') ? 'none' : 'visible', width: '100%', wordWrap: 'break-word' }}>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                      OVCODE:
                    </Typography>
                  }
                  secondary={scannedReference && scannedReference}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                      {scannedInfo?.details?.updated_time ? 'Published Date:' : null}
                    </Typography>
                  }
                  secondary={scannedInfo?.details?.updated_time}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}

      {!isScannedDocumentBoolean && scannedInfo?.status && (
        <Grid container direction="row" sx={{ mt: '10px', padding: '8px 35px', mb: '-23px' }}>
          <ListItem sx={{ padding: '0px' }}>
            <ListItemText style={{ display: 'flex' }}
              primary={
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: 14 }}>
                  Status:
                </Typography>
              }
              secondary={
                <Typography variant="subtitle2"
                  sx={{
                    fontSize: '16px', ml: '3px', mt: '-2px',
                    color: (scannedInfo?.status === 'SUSPENDED') ? 'orange' : (scannedInfo?.status === 'REVOKED') ? 'red' : 'green'
                  }}
                >
                  {scannedInfo?.status}
                </Typography>
              }
            />
          </ListItem>
        </Grid>
      )}

      <Grid container direction="row" sx={{ padding: (breakpoints === 'xs' || breakpoints === 'sm') ? '8px 5px' : '8px 25px', mb: '-23px' }}>
        <Typography sx={{ fontSize: '14px', fontWeight: 300, color: '#666666d4', mt: '13px', mb: '14px', textAlign:'justify' }}>
          Stay safe online! Before sharing sensitive info on <Link href="https://ovcode.com/" underline="none" target="_blank">https://ovcode.com,</Link> verify the website certificate and hostname. Check the padlock icon next to ovcode.com to ensure you're on the real site.
        </Typography>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "center", padding: "16px 0px" }}>
        <Typography variant="subtitle1">
          Powered by:
        </Typography>

        &nbsp;

        <img alt="" src={OVCodeLogo} style={{ width: 100 }} />
      </Box>

      <Backdrop open={isViewingDocument}
        sx={{
          color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1,
          opacity: "1 !important", backgroundColor: "#fff"
        }}
      >
        <Grid sx={{ display: "block", margin: "auto", textAlign: "center" }}>
          <Typography sx={{
            color: "#000",
            fontWeight: "bold",
            mb: "-5px",
            fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 10 : "1rem"
          }}>
            Redirecting to viewer, please wait for a moment
          </Typography>

          <ThreeDots
            height="50"
            width={(breakpoints == "xs" || breakpoints == "sm") ? "50" : "80"}
            radius="9"
            color="#000"
            ariaLabel="three-dots-loading"
            wrapperStyle={{ margin: "auto", textAlign: "center", justifyContent: "center" }}
            wrapperClassName=""
            visible={true}
          />
        </Grid>
      </Backdrop>
    </>
  )
}

export default DocumentDetails;
