import { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Document } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Stack } from '@mui/material';
import { getWidthByPercentage } from '../utils/ProportionDimenUtil';
import useWidth from '../../hooks/useWidth';
import PDFPageViewerComponent from '../footer/PDFPageViewerComponent';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ViewUnilabPdfComponent() {
  const iframeRef = useRef(null);
  const width = useWidth();
  const pdfEditorRef = useRef();
  const [pdfPages, setPdfPages] = useState([]);
  const [error, setError] = useState(null);
  const query = useQuery();
  const id = query.get('id');

  const [pdfFile, setPdfFile] = useState(null);

  useEffect(() => {
    if (id) {
      switch (id) {
        case '3bd11b1b18aa801577fb271df3060a89fb248b643c0dd6c87f5cf0b8356ab69e':
          setPdfFile(require('../MDD.pdf'));
          break;
        case '85d669ec792cdecc08bf671a3872f67ab84b55df5b334ca739e2d4fb0e732e93':
          setPdfFile(require('../DWD.pdf'));
          break;
        case '0256497e267cc756b8e017df776631628f83d9cd51fe9602c58573c1dd453bfb':
          setPdfFile(require('../SRD.pdf'));
          break;
        case 'S-5f190117-f2da-4720-9387-b8a8d19d93b7':
          setPdfFile(require('../MDDT.pdf'));
          break;
        case 'S-dda20c4e-1358-4e80-8483-3932985aa109':
          setPdfFile(require('../DWDT.pdf'));
          break;
        case 'S-37b02905-3dcd-49aa-8c67-b675d1f076a5':
          setPdfFile(require('../SRDT.pdf'));
          break;
          case 'Cigarettes':
          setPdfFile(require('../Tobacco.pdf'));
          break;
        default:
          setPdfFile(require('../Unilab.pdf'));
          break;
      }
    } else {
      setPdfFile(require('../Unilab.pdf'));
    }
  }, [id]);

  function handleOnDocumentLoadSuccess(pdf) {
    handleSetPDFPages(pdf);
  }

  const handleSetPDFPages = (pdf) => {
    [...Array(pdf.numPages)].map((item, i) => {
      let pageNum = i + 1;
      pdf.getPage(pageNum).then((page) => {
        let pdfEditorWrapperWidth = pdfEditorRef?.current?.clientWidth;
        let percentage = 60;
        let percentageWidth = getWidthByPercentage(percentage, pdfEditorWrapperWidth);
        let pdfPageOrigWidth = page._pageInfo.view[2];
        let pdfPageOrigHeight = page._pageInfo.view[3];
        let rotate = page.rotate;
        let isLandscape = rotate === 90 || rotate === 270;
        let widthDiff = isLandscape ? pdfPageOrigHeight - percentageWidth : 0;
        let isNegative = widthDiff < 0;
        let newNum = isLandscape ? pdfPageOrigHeight + widthDiff : 0;
        let origNum = isLandscape ? pdfPageOrigHeight : pdfPageOrigWidth;
        let percentageDiff = Math.abs(getPercentageIncreaseDecrease(newNum, origNum));
        percentageDiff = isNegative ? Math.abs(percentageDiff) : -Math.abs(percentageDiff);
        let obj = {
          numPages: pdf.numPages,
          page_number: page.pageNumber,
          isLandscape,
          page,
          ...(isLandscape && { adjustedWidth: percentageWidth, percentageDiff, widthDiff })
        };
        setPdfPages((prev) => {
          let _page = prev.find((item) => item.isLandscape);
          if (_page && !isLandscape) {
            let portrait_percentage_width = getWidthByPercentage(_page.percentageDiff, pdfPageOrigWidth);
            obj.adjustedWidth = portrait_percentage_width + pdfPageOrigWidth;
          }
          return [...prev, obj];
        });
      });
    });
  };

  function getPercentageIncreaseDecrease(newNum, origNum) {
    return ((newNum - origNum) / origNum) * 100;
  }

  function handleOnLoadError(error) {
    setError(error.message);
  }

  console.log("pdfFilexxx", pdfFile)

  return (
    <Document file={pdfFile} onLoadSuccess={handleOnDocumentLoadSuccess} onLoadError={handleOnLoadError}>
      <Stack sx={{ mt: 5, mb: 5 }} direction="column" justifyContent="space-evenly" alignItems="center" spacing={width !== 'xs' ? 10 : 4}>
        {pdfPages.map((page, i) => (
          <PDFPageViewerComponent
            key={i}
            numPages={page.numPages}
            page_number={page.page_number}
            adjustedPDFWidth={page.adjustedWidth}
          />
        ))}
      </Stack>
    </Document>
  );
}

export default ViewUnilabPdfComponent;
