import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Box, Container } from '@mui/material';
import BackToTopButton from '../components/layout/scroll-up.component';
import useWidth from '../hooks/useWidth';

// Import all necessary release notes components
import ElectronicIdentificationAndroidAppReleaseNotes from '../components/enterprise/eid-android-app-release-notes.component';
import OvcodeReleaseNotes from '../components/enterprise/ovcode-release-notes.component';
import ElectronicIdentificationReleaseNotes from '../components/enterprise/electronic-identification-release-notes.component';
import ElectronicIdentificationAppBindingAndRegistrationReleaseNotes from '../components/enterprise/eid-app-binding-and-registration-release-notes.component';
import ElectronicIdentificationWebAppReleaseNotes from '../components/enterprise/eid-web-app-release-notes.component';
import ElectronicIdentificationRegClientReleaseNotes from '../components/enterprise/eid-reg-client-release-notes.component';
import NationalIdentificationAdminWebPortalReleaseNotes from '../components/enterprise/nid-admin-portal-release-notes.component';
import ElectronicSignatureReleaseNotes from '../components/enterprise/electronic-signature-release-notes.component';
import OvcodeReleaseNotesV2 from '../components/enterprise/ovcode-release-notes-v2.component';
import ElectronicIdentificationAndroidAppReleaseNotesv2 from '../components/enterprise/eid-android-app-release-notes-v2.component';
import ElectronicIdentificationWebAppReleaseNotesv2 from '../components/enterprise/eid-web-app-release-notes-v2.component';
import ElectronicSignatureReleaseNotesv2 from '../components/enterprise/esign-release-notes-v2.component';
import OvcodeMobileAppReleaseNotes from '../components/enterprise/ovcode-mobile-app-notes.component';
import ElectronicSignatureReleaseNotesv3 from '../components/enterprise/esign-release-notes-v3.component';

const ReleaseNotesScreen = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const breakpoints = useWidth();

    // Define flags based on the current path
    const isEnterpriseVersion = currentPath === '/version-histories-ovcode-enterprise';
    const isAllVersion = currentPath === '/version-histories-all';
    const isMobileApplicationVersion = currentPath === '/version-histories-ovcode-mobile';
    const isEidAdminVersion = currentPath === '/version-histories-electronic-identification-admin';
    const isEidAppBindingVersion = currentPath === '/version-histories-electronic-identification-app-binding';
    const isEidAndroidAppVersion = currentPath === '/version-histories-electronic-identification-android';
    const isEidWebAppVersion = currentPath === '/version-histories-electronic-identification-web-app';
    const isEidRegClientVersion = currentPath === '/version-histories-electronic-identification-regClient';
    const isNidAdminVersion = currentPath === '/version-histories-national-identification-admin';
    const isEsignVersion = currentPath === '/version-histories-electronic-sign';

    return (
        <Box>
            <div className="container">
                <BackToTopButton />
            </div>

            <Box sx={{
                overflow: 'hidden',
                margin: 'auto 0',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Container maxWidth="md">
                    <Box sx={{
                        width: '100%',
                        margin: 'auto',
                        marginLeft: breakpoints === 'xs' || breakpoints === 'sm' ? '0px' : '230px'
                    }}>
                        <Box sx={{
                            width: breakpoints === 'xs' || breakpoints === 'sm' ? '100%' : '80%'
                        }}>
                            {isAllVersion && (
                                <Box sx={{ mb: 4 }}>
                                    <Typography
                                        sx={{
                                            fontSize: '40px',
                                            margin: 0,
                                            color: '#000000CC',
                                            fontWeight: 600,
                                            textTransform: 'none'
                                        }}
                                    >
                                        Official Release Notes
                                    </Typography>
                                </Box>
                            )}

                            {isAllVersion ? (
                                <>
                                    <OvcodeReleaseNotes />
                                    <br />
                                    <OvcodeReleaseNotesV2 />
                                    <br />
                                    <OvcodeMobileAppReleaseNotes />
                                    <br />
                                    <ElectronicIdentificationReleaseNotes />
                                    <br />
                                    <ElectronicIdentificationAppBindingAndRegistrationReleaseNotes />
                                    <br />
                                    <ElectronicIdentificationAndroidAppReleaseNotes />
                                    <br />
                                    <ElectronicIdentificationAndroidAppReleaseNotesv2 />
                                    <br />
                                    <ElectronicIdentificationWebAppReleaseNotes />
                                    <br />
                                    <ElectronicIdentificationWebAppReleaseNotesv2 />
                                    <br />
                                    <ElectronicIdentificationRegClientReleaseNotes />
                                    <br />
                                    <NationalIdentificationAdminWebPortalReleaseNotes />
                                    <br />
                                    <ElectronicSignatureReleaseNotes />
                                    <br />
                                    <ElectronicSignatureReleaseNotesv2 />
                                    <br />
                                    <ElectronicSignatureReleaseNotesv3 />
                                </>
                            ) : isEnterpriseVersion ? (
                                <>
                                    <OvcodeReleaseNotes />
                                    <br />
                                    <OvcodeReleaseNotesV2 />
                                </>
                            ) : isMobileApplicationVersion ? (
                                <OvcodeMobileAppReleaseNotes />
                            ) : isEidAdminVersion ? (
                                <ElectronicIdentificationReleaseNotes />
                            ) : isEsignVersion ? (
                                <>
                                    <ElectronicSignatureReleaseNotes />
                                    <br />
                                    <ElectronicSignatureReleaseNotesv2 />
                                    <br />
                                    <ElectronicSignatureReleaseNotesv3 />
                                </>
                            ) : isEidAppBindingVersion ? (
                                <ElectronicIdentificationAppBindingAndRegistrationReleaseNotes />
                            ) : isEidAndroidAppVersion ? (
                                <>
                                    <ElectronicIdentificationAndroidAppReleaseNotes />
                                    <br />
                                    <ElectronicIdentificationAndroidAppReleaseNotesv2 />
                                </>
                            ) : isEidWebAppVersion ? (
                                <>
                                    <ElectronicIdentificationWebAppReleaseNotes />
                                    <br />
                                    <ElectronicIdentificationWebAppReleaseNotesv2 />
                                </>
                            ) : isEidRegClientVersion ? (
                                <ElectronicIdentificationRegClientReleaseNotes />
                            ) : isNidAdminVersion ? (
                                <NationalIdentificationAdminWebPortalReleaseNotes />
                            ) : (
                                <Typography>No release notes available for this version.</Typography>
                            )}
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default ReleaseNotesScreen;
