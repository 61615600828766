import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import unilabLogo from '../../../icons/Unilab-logo.png';
import secureLogo from '../../../icons/Unilab.png';
import useVerification from '../../../hooks/useVerification';
import UnilabViewDetailsComponent from './unilab-view-detail.component';
import { ThreeDots } from "react-loader-spinner";
import useWidth from '../../../hooks/useWidth';
import UnilabCustomTab from '../../common/unilab-custom-tab.component';

const UnilabVerificationResComponent = (props) => {
    const { scannedInfo, setOpenVerificationRes, setOpenVerifiedData, setPdfViewer } = props;
    const breakpoints = useWidth();
    const { generateSharedKeyData, generateSharedKey, unilabData, isViewing } = useVerification();

    const [isViewingDocument, setViewingDocument] = useState(false);
    const [unilabResData, setUnilabResData] = useState("");
    // let unparse_data = localStorage.getItem("jsonData");

    console.log("aaa setOpenVerifiedData here", setOpenVerifiedData)


    useEffect(() => {
        if (unilabData) {
            setUnilabResData(JSON.parse(unilabData));
        }
    }, [unilabData]);

    useEffect(() => {
        if (scannedInfo) {
            generateSharedKeyData(scannedInfo?.details?.reference);
        }
    }, [scannedInfo]);

    const renderDataStatus = () => {
        if (!unilabResData) return null;
        switch (unilabResData?.type) {
            case "RL":
                return "Authentic";
            case "BL":
                return "Box Level OVCODE Hash";
            case "MPL":
                return "Mother Pallet Level OVCODE Hash";
            default:
                return null;
        }
    };

    const openPdfViewer = (fileReference) => {
        console.log("Opening PDF viewer for file reference:", fileReference);
        let isViewBtn = true;
        generateSharedKey(fileReference, isViewBtn, setViewingDocument, setOpenVerificationRes, setOpenVerifiedData, setPdfViewer);
    };


    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '12px', textAlign: 'center' }}>
                <Grid container spacing={1} alignItems="center" sx={{ textAlign: 'center', justifyContent: 'center' }}>
                    <Grid item xs={12}>
                        <img alt="" src={unilabLogo} style={{ height: 60, objectFit: "contain" }} />
                    </Grid>
                    <Grid item sx={{ textAlign: 'center', justifyContent: 'center' }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#09BD62', fontSize: 18, textAlign: 'center !important' }}>
                            DATA FOUND
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ padding: '1px', textAlign: 'center', mt: 0, justifyContent: 'center', alignSelf: 'center' }}>
                {isViewing ? (
                    <Skeleton variant="text" width="100%"  height={40} animation="wave" />
                ) : (
                    <Typography sx={{ color: '#0C306F', fontSize: 18, fontWeight: 'bold' }}>
                        {renderDataStatus()}
                    </Typography>
                )}
            </Box>

            {isViewing ?
                <Skeleton variant="text" width="100%"  height={40} animation="wave" />
            :
                <>
                    {unilabResData && (unilabResData?.type === "RL") ? (
                        <Box sx={{ padding: '1px', textAlign: 'center', mt: 1, justifyContent: 'center' }}>
                            <Button onClick={() => openPdfViewer(scannedInfo?.details?.reference)} variant="contained" sx={{ backgroundColor: "#0C306F", color: '#fff', borderRadius: 10, fontSize: 14 }}>
                                View
                            </Button>
                        </Box>
                    ) : (
                        <Box sx={{ padding: '1px', textAlign: 'center', mt: 1, justifyContent: 'center' }}>
                            <UnilabCustomTab unilabResData={unilabResData} scannedInfo={scannedInfo} setOpenVerificationRes={setOpenVerificationRes} setOpenVerifiedData={setOpenVerifiedData} setPdfViewer={setPdfViewer} isViewing={isViewing} />
                        </Box>
                    )}
                </>
            }
           

            <Box marginTop={2}>
                {unilabResData && unilabResData?.type === "RL" && (
                    <UnilabViewDetailsComponent unilabResData={unilabResData} scannedInfo={scannedInfo} isViewing={isViewing} />
                )}
            </Box>

            <Box textAlign="center" marginTop={2} sx={{ display: "flex", justifyContent: "center", padding: "16px 0px" }}>
                <img src={secureLogo} style={{ width: 50 }} alt="Secure Code" />
            </Box>

            <Backdrop open={isViewingDocument}
                sx={{
                    color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1,
                    opacity: "1 !important", backgroundColor: "#fff"
                }}
            >
                <Grid sx={{ display: "block", margin: "auto", textAlign: "center" }}>
                    <Typography sx={{
                        color: "#000",
                        fontWeight: "bold",
                        mb: "-5px",
                        fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 10 : "1rem"
                    }}>
                        Redirecting to viewer, please wait for a moment
                    </Typography>

                    <ThreeDots
                        height="50"
                        width={(breakpoints == "xs" || breakpoints == "sm") ? "50" : "80"}
                        radius="9"
                        color="#000"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ margin: "auto", textAlign: "center", justifyContent: "center" }}
                        wrapperClassName=""
                        visible={true}
                    />
                </Grid>
            </Backdrop>
        </>
    );
};

export default UnilabVerificationResComponent;
