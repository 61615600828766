import {useState} from "react";
import JoinService from "../services/api/join";
import { useSnackbar } from "notistack";

const useJoin = () => {

    const { enqueueSnackbar } = useSnackbar();

    const [isJoining, setJoining] = useState(false);

    const joinOVCode = (firstName, lastName, companyName, inquiryDetails, email, contactNo, website, setOpenSuccessConfirmation) => {
        setJoining(true);

        JoinService.joinOVCode(firstName, lastName,companyName, inquiryDetails, email, contactNo, website).then((res) => {
            console.log("joinOVCode data xxxx", res);

            if (res.data.success) {
                setJoining(false);
                setOpenSuccessConfirmation(true);
            }

        }).catch((error) => {
            console.log("joinOVCode error xxxx", error);

            setJoining(false);
            setOpenSuccessConfirmation(false);

            if (error.response) {
                enqueueSnackbar(error.response.statusText, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else if (error.request) {
                enqueueSnackbar("The request timed out. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar("An error occurred. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    return {isJoining, joinOVCode}
}

export default useJoin