
import React, { useState, useEffect } from 'react';
import { Box, Drawer, CssBaseline, Toolbar, List, Typography, ListItemButton, ListItemIcon, ListItemText,  Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AppsIcon from '@mui/icons-material/Apps';
import eIDIcon from "../icons/eIDSuperFinalLogoSize.png";
import eSignIcon from "../icons/eSignSuperFinalLogoSize.png";
import ovcodeIcon from "../icons/1.png";
import { useNavigate } from 'react-router-dom';

const drawerWidth = 280;

export default function ClippedDrawer({ children }) {

    const url = window.location.pathname;
    const navigate = useNavigate();

    const [electronicIDMenuOpen, setElectronicIDMenuOpen] = useState(false);
    const [ovcodeMenuOpen, setOvcodeMenuOpen] = useState(false);
    const [allOpen, setAllOpen] = useState(true);
    const [eSignOpen, seteSignOpen] = useState(false);

    const handleElectronicIDMenuOpen = () => {
        setElectronicIDMenuOpen(!electronicIDMenuOpen);
        setOvcodeMenuOpen(false);
        seteSignOpen(false);
        setAllOpen(false);

    };

    const handleOvcodeMenuOpen = () => {
        setOvcodeMenuOpen(!ovcodeMenuOpen);
        setElectronicIDMenuOpen(false);
        seteSignOpen(false);
        setAllOpen(false);
    };

    const handleAllOpen = () => {
        setAllOpen(true);
        setElectronicIDMenuOpen(false);
        setOvcodeMenuOpen(false);
        seteSignOpen(false);
        navigate("/version-histories-all")
    }

    const handleeSignOpen = () => {
        seteSignOpen(true);
        setElectronicIDMenuOpen(false);
        setOvcodeMenuOpen(false);
        setAllOpen(false);
        navigate("/version-histories-electronic-sign")
    }


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    border: 0, // Set border to 0
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        border: 0, // Set border to 0
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItemButton onClick={handleAllOpen}>
                            <ListItemIcon>
                                <AppsIcon sx={{ color: '#017eff', width: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={<React.Fragment>
                                <Typography sx={{ color: allOpen || url === "/version-histories-all" ? '#017eff' : 'inherit', fontSize: 13 }}>All</Typography>
                            </React.Fragment>} />
                        </ListItemButton>
                        <ListItemButton onClick={handleOvcodeMenuOpen} >
                            <ListItemIcon>
                                <img src={ovcodeIcon} style={{ width: 25 }} />
                            </ListItemIcon>
                            <ListItemText primary={<React.Fragment>
                                <Typography sx={{ color: ovcodeMenuOpen  ? '#017eff' : 'inherit', fontSize: 13 }}>OVCODE</Typography>
                            </React.Fragment>} />
                            {ovcodeMenuOpen ? <ExpandLess sx={{ color: '#017eff' }} /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={ovcodeMenuOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/version-histories-ovcode-enterprise")}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon sx={{ fontSize: 13, color: url === "/version-histories-ovcode-enterprise" ? '#017eff' : 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={<React.Fragment>
                                        <Typography sx={{ fontSize: 13, color: url === "/version-histories-ovcode-enterprise" ? '#017eff' : 'inherit' }}>OVCODE Enterprise Portal</Typography>
                                    </React.Fragment>} />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/version-histories-ovcode-mobile")}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon sx={{ fontSize: 13, color: url === "/version-histories-ovcode-mobile" ? '#017eff' : 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={<React.Fragment>
                                        <Typography sx={{ fontSize: 13, color: url === "/version-histories-ovcode-mobile" ? '#017eff' : 'inherit' }}>OVCODE Mobile Application</Typography>
                                    </React.Fragment>} />
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleElectronicIDMenuOpen}>
                            <ListItemIcon>
                                <img src={eIDIcon} style={{ width: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={<React.Fragment>
                                <Typography sx={{ color: electronicIDMenuOpen ? '#017eff' : 'inherit', fontSize: 13 }}>Electronic Identification (eID)</Typography>
                            </React.Fragment>} />
                            {electronicIDMenuOpen ? <ExpandLess sx={{ color: '#017eff' }} /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse in={electronicIDMenuOpen}
                            timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/version-histories-electronic-identification-admin")}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon sx={{ width: 12, color: url === "/version-histories-electronic-identification-admin" ? '#017eff' : 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={<React.Fragment>
                                        <Typography sx={{ fontSize: 13, color: url === "/version-histories-electronic-identification-admin" ? '#017eff' : 'inherit'  }}>eID Admin Portal</Typography>
                                    </React.Fragment>} />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/version-histories-electronic-identification-app-binding")}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon sx={{ width: 12, color: url === "/version-histories-electronic-identification-app-binding" ? '#017eff' : 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={<React.Fragment>
                                        <Typography sx={{ fontSize: 13, color: url === "/version-histories-electronic-identification-app-binding" ? '#017eff' : 'inherit' }}>eID App Binding and Registration</Typography>
                                    </React.Fragment>} />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/version-histories-electronic-identification-android")}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon sx={{ width: 12, color: url === "/version-histories-electronic-identification-android" ? '#017eff' : 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={<React.Fragment>
                                        <Typography sx={{ fontSize: 13, color: url === "/version-histories-electronic-identification-android" ? '#017eff' : 'inherit' }}>eID Android App</Typography>
                                    </React.Fragment>} />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/version-histories-electronic-identification-web-app")}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon sx={{ width: 12, color: url === "/version-histories-electronic-identification-web-app" ? '#017eff' : 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={<React.Fragment>
                                        <Typography sx={{ fontSize: 13, color: url === "/version-histories-electronic-identification-web-app" ? '#017eff' : 'inherit' }}>eID Web App</Typography>
                                    </React.Fragment>} />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/version-histories-electronic-identification-regClient")}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon sx={{ width: 12, color: url === "/version-histories-electronic-identification-regClient" ? '#017eff' : 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={<React.Fragment>
                                        <Typography sx={{ fontSize: 13, color: url === "/version-histories-electronic-identification-regClient" ? '#017eff' : 'inherit'  }}>eID RegClient</Typography>
                                    </React.Fragment>} />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/version-histories-national-identification-admin")}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon sx={{ width: 12, color: url === "/version-histories-national-identification-admin" ? '#017eff' : 'inherit' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={<React.Fragment>
                                        <Typography sx={{ fontSize: 13, color: url === "/version-histories-national-identification-admin" ? '#017eff' : 'inherit' }}>NID Admin Web Portal</Typography>
                                    </React.Fragment>} />
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleeSignOpen}>
                            <ListItemIcon>
                                <img src={eSignIcon} style={{ width: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={<React.Fragment>
                                <Typography sx={{ color: eSignOpen || url === "/version-histories-electronic-sign" ? '#017eff' : 'inherit', fontSize: 13 }}>Electronic Signature (eSign)</Typography>
                            </React.Fragment>} />
                        </ListItemButton>
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
}
