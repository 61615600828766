import { Box, Grid, Typography } from "@mui/material";
import useWidth from "../../hooks/useWidth";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DescriptionIcon from '@mui/icons-material/Description';

const HistoryMplUnilabComponent = ({verificationName}) => {

    const breakpoints = useWidth();


    const openPdfViewer = (id) => {
        const pdfUrlWithId = `/view/pdf/verification?id=${id}`;
        window.open(pdfUrlWithId, '_blank');
    };

    const historyItems = verificationName === "MPL" ? [
        { id: '3bd11b1b18aa801577fb271df3060a89fb248b643c0dd6c87f5cf0b8356ab69e', date: '2024-25-01', location: 'UNILAB Manufacturing Plant Laguna' }
    ]: [
        { id: '0256497e267cc756b8e017df776631628f83d9cd51fe9602c58573c1dd453bfb', date: '2024-27-01', location: 'Mercury Drug Makati' },
        { id: '85d669ec792cdecc08bf671a3872f67ab84b55df5b334ca739e2d4fb0e732e93', date: '2024-26-01', location: 'Distributor Warehouse Makati' },
        { id: '3bd11b1b18aa801577fb271df3060a89fb248b643c0dd6c87f5cf0b8356ab69e', date: '2024-25-01', location: 'UNILAB Manufacturing Plant Laguna' },
    ];

    return (
        <Box sx={{ padding: 6, pt: breakpoints === 'xs' ? 3 : 1, pb: 0, textAlign: 'start' }}>
            <Typography sx={{ fontWeight: 'bold', mb: 3, fontSize: 17 }}>History</Typography>
            <Grid container spacing={2}>
            {historyItems.map((item, index) => (
                    <Grid key={index} item xs={12} sx={{ paddingTop: '3px' }}>
                        <Grid container>
                            <Grid item xs={9}>
                                <Typography sx={{ fontSize: 17 , wordBreak: 'break-word'}}>{item.id}</Typography>
                                <Typography sx={{ fontSize: 17 }}>{item.date}</Typography>
                                <Typography sx={{ fontSize: 17 }}>Location: {item.location}</Typography>
                                <hr style={{ borderTop: '2px dashed #000', width: '100%' }} />
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: 'center' }}>
                                <DescriptionIcon
                                    fontSize="large"
                                    sx={{ color: '#95B0C9', cursor: 'pointer' }}
                                    onClick={() => openPdfViewer(item.id)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
           
            </Grid>
        </Box>
    )
}

export default HistoryMplUnilabComponent;
