import { Box, Grid, Typography } from "@mui/material";
import useWidth from "../../hooks/useWidth";

const ViewMplTobaccoComponent = ({ verificationName }) => {

    console.log("verificationName herexxx:", verificationName)

    const breakpoints = useWidth();



    return (
        <Box sx={{ padding: 6, pt: breakpoints === 'xs' ? 3 : 1, pb: 0, textAlign: 'start' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {verificationName === "MPL" ?

                        <>
                            <Typography sx={{ fontSize: 17 }}><strong>Name:</strong>&nbsp;&nbsp;Classic Smoke Cigarettes</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '15px', marginBottom: '15px' }} />

                            <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                                <Typography sx={{ fontSize: 17 }}><strong>Quantity:</strong></Typography>
                                <ul>
                                    <li>20 Cigarettes per pack</li>
                                    <li>10 Packs per box</li>
                                    <li>10 boxes per rim</li>
                                    <li>5 Rims per big box</li>
                                </ul>
                                <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                                <Typography sx={{ fontSize: 17 }}><strong>Description:</strong>&nbsp;&nbsp;Classic Smoke Cigarettes are made from a blend of premium tobaccos for a smooth, satisfying smoke. Each pack contains 20 cigarettes, crafted with care for a consistent flavor experience.</Typography>
                                <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                            </Grid>
                        </>

                        :
                        <>
                            <Typography sx={{ fontSize: 17 }}><strong>Name:</strong>&nbsp;&nbsp;Classic Smoke Cigarettes</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '15px', marginBottom: '15px' }} />

                            <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                                <Typography sx={{ fontSize: 17 }}><strong>Quantity:</strong></Typography>
                                <ul>
                                    <li>20 Cigarettes per pack</li>
                                    <li>10 Packs per box</li>
                                </ul>
                                <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                                <Typography sx={{ fontSize: 17 }}><strong>Description:</strong>&nbsp;&nbsp;Classic Smoke Cigarettes are made from a blend of premium tobaccos for a smooth, satisfying smoke. Each pack contains 20 cigarettes, crafted with care for a consistent flavor experience.</Typography>
                                <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                            </Grid>
                        </>

                    }
                </Grid>


                {verificationName === "MPL" ?
                    <>
                        <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                            <Typography sx={{ fontSize: 17 }}><strong>Age Restriction:</strong>&nbsp;&nbsp;For adult smokers aged 18 years and above.</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                            <Typography sx={{ fontSize: 17 }}><strong>Hash Ref.:</strong>&nbsp;&nbsp;S-5f190117-f2da-4720-9387-b8a8d19d93b7</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                            <Typography sx={{ fontSize: 17 }}><strong>Manufactured by:</strong>&nbsp;&nbsp;Generic Tobacco Co.</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                        </Grid>
                    </>

                    :
                    <>
                        <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                            <Typography sx={{ fontSize: 17 }}><strong>Manufactured Date:</strong>&nbsp;&nbsp;Production date: 01/23/2024, Batch number: 0123456</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                            <Typography sx={{ fontSize: 17 }}><strong>Delivery Date:</strong>&nbsp;&nbsp;01/27/2024</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                            <Typography sx={{ fontSize: 17 }}><strong>Hash Ref.:</strong>&nbsp;&nbsp;S-dda20c4e-1358-4e80-8483-3932985aa109</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                            <Typography sx={{ fontSize: 17 }}><strong>Distributed by:</strong>&nbsp;&nbsp;Generic Distribution Inc.</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                        </Grid>
                    </>



                }


            </Grid>
        </Box>
    )
}

export default ViewMplTobaccoComponent;
