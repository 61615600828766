import { PublicClientApplication, InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalconfig, loginRequest } from '../../../config';

// This should be the same instance you pass to MsalProvider
const msalInstance = new PublicClientApplication(msalconfig);

/**
 * getMsalInstance
 * @returns {PublicClientApplication} returns PublicClientApplication object of msalInstance
 */
const getMsalInstance = () => {
    return msalInstance;
}

/**
 * loginPopup
 * @returns {Object} returns Object object return by loginPopup
 */
 const loginPopup = () => {
    return msalInstance.loginPopup(loginRequest);

}

/**
 * loginPopup
 * @returns {Object} returns Object object return by loginPopup
 */
const loginRedirect = () => {
    return msalInstance.loginRedirect(loginRequest);

}

const loginssoSilent = () => {
    return msalInstance.ssoSilent(loginRequest);

}

/**
 * loginPopup
 * @returns {Object} returns Object object return by logoutPopup
 */
 const logoutPopup = () => {
    return msalInstance.logoutPopup();
}

// Initialize MSAL instance
const initializeMsal = async () => {
    try {
        await msalInstance.initialize(); // Make sure to await this
        console.log('MSAL initialized successfully');
    } catch (error) {
        console.error('Error initializing MSAL', error);
    }
};

// Ensure the initialization function is called before making any MSAL API calls
initializeMsal().then(() => {
    acquireTokenSilentFirstAccount(); // This function should now work without the error
});

/**
 * acquireTokenSilentFirstAccount
 * @returns {Object} returns Object object return by acquireTokenSilentFirstAccount
 */
const acquireTokenSilentFirstAccount = async () => {
    let accounts = msalInstance.getAllAccounts();
    console.log('acquireTokenSilentFirstAccount', accounts);

    if (accounts.length === 0) {
        console.error("No accounts available.");
        return; // Exit early, no accounts to work with
    }

    if (!msalInstance.getActiveAccount()) {
        msalInstance.setActiveAccount(accounts[0]);
        console.log("Active account set to:", accounts[0]);
    }

    try {
        return await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: msalInstance.getActiveAccount()
        });
    } catch (error) {
        console.error("Failed to acquire token silently:", error);
        // Optionally, handle additional logic here if needed
        if (error instanceof InteractionRequiredAuthError) {
            console.log("Token expired or interaction required. Redirecting to root.");
            msalInstance.logoutRedirect(); // Logout and redirect to root path
        } else {
            throw error; // Handle other errors if needed
        }

    }
};

/**
 * isUserLoggedIn
 * @returns {Boolean} returns boolean 
 */
 const isUserLoggedIn = () => {

    var accounts = getAllAccounts();
    var interactionInProgress = msalInstance.interactionInProgress;

    var isAuthenticated = accounts.length > 0; // checks the array of accounts if has objects
    if(!interactionInProgress){
        interactionInProgress = 'none';
    }

    if (!isAuthenticated && interactionInProgress === InteractionStatus.None) {
        return false
    }

    return true;
}

/**
 * getFirstAccount
 * @returns {AccountInfo} returns first AccountInfo info object from the accounts array 
 */
const getFirstAccount = () => {
    return msalInstance.getAllAccounts()[0];
}

/**
 * getAllAccounts
 * @returns {AccountInfo[]} returns AccountInfo array
 */
 const getAllAccounts = () => {
    return msalInstance.getAllAccounts();
}


/**
 * UNDER CONSTRUCTION
 * has problems after setActiveAccount. If you go to other page, it will getActiveAccount return null
 * getActiveAccount
 * @returns {AccountInfo} returns AccountInfo or null
 */
 const getActiveAccount = () => { // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    return msalInstance.getActiveAccount();
}

const monitorTokenExpiration = () => {
    const checkTokenValidity = async () => {
        try {
            await acquireTokenSilentFirstAccount();
        } catch (error) {
            console.error("Error during token validity check:", error);
        }
    };

    // Periodically check token validity (e.g., every minute)
    setInterval(checkTokenValidity, 60000);
};

monitorTokenExpiration();

const MsalUtil = {
    getMsalInstance,
    loginPopup,
    logoutPopup,
    acquireTokenSilentFirstAccount,
    isUserLoggedIn,
    getFirstAccount,
    getAllAccounts,
    getActiveAccount,
    loginRedirect,
    loginssoSilent
}

export default MsalUtil;