import NavigatingOvcode from "../../../../images/blog/navigating-ovcode.png";
import CommunitySpotlight from "../../../../images/blog/community-spotlight.png";
import SecureStorage from "../../../../images/support-and-community/support-banner-1.png";
import dataPrivacy from "../../../../images/support-and-community/support-banner-2.png";



const supportAndCommunityBlogData = [
    {
        title: "Navigating OVCODE: A Comprehensive Guide \nto User Support",
        date: "Tuesday, 16 January 2024",
        description: "At OVCODE, we are committed to providing a seamless and supportive experience for our users. Whether you're a business integrating our solutions or an individual exploring our platforms, our user support services are designed to assist you every step of the way.",
        image: NavigatingOvcode,
        url: "/blog-support-navigating-ovcode",
        sections: [
            {
                subtitle: "1. Overview of OVCODE Support Services:",
                content: `**a. Knowledge Base:**
             \nExplore our extensive Knowledge Base, a repository of articles, guides, and FAQs covering a wide range of topics. This self-service resource is a valuable tool for finding answers to common queries and learning more about OVCODE's features.
            \n **b. Ticket-Based Support:**
             \nFor personalized assistance, our ticket-based support system allows users to submit specific queries or issues. Our support team will promptly review and respond to your tickets, ensuring a tailored and efficient resolution to your concerns.
             \n **c. Community Forums:**
             \nConnect with other OVCODE users, share insights, and seek advice on our community forums. Our forums are a collaborative space where users can engage with each other, share best practices, and learn from the experiences of the OVCODE community.
             \n **d. Email Support:**
              \nFor more direct communication, our email support channel is available for users to reach out with detailed inquiries. Simply send an email to support@ovcode.com, and our dedicated support team will assist you. `
            },
            {
                subtitle: "2. How to Access Assistance:",
                content: `**a. Knowledge Base:**
             \nVisit our website and navigate to the "Support" or "Help Center" section. Here, you'll find access to the Knowledge Base, where you can browse articles and guides based on relevant categories.
            \n **b. Ticket-Based Support:**
             \n If you have a specific question or encounter an issue, log in to your OVCODE account and visit the "Support" or "Help" section. Here, you can submit a support ticket by providing details about your query. Our support team will respond to your ticket promptly.
             \n **c. Community Forums:**
             \n Join our community forums by visiting the "Community" section on our website. Engage with other users, ask questions, and contribute to discussions. The community forums are a valuable resource for collaborative problem-solving
             \n **d. Email Support:**
              \nFor direct email support, send your inquiries to support@ovcode.com. Our support team will receive your email and respond to assist you with any issues or questions you may have.`
            },
            {
                subtitle: "3. Additional Resources:",
                content: `**a. Video Tutorials:**
             \nExplore our library of video tutorials available on our official YouTube channel. These tutorials provide step-by-step guidance on using OVCODE's features and solutions.
            \n **b. Webinars and Events:**
             \nStay informed about upcoming webinars and events hosted by OVCODE. These interactive sessions provide opportunities to learn directly from experts, ask questions, and stay updated on the latest developments.`
            },
            {
                subtitle: "4. Stay Connected:",
                content: `**a. Social Media:**
             \n Follow OVCODE on social media platforms such as Twitter, LinkedIn, and Facebook. Our social channels are not only a source of news and updates but also avenues for direct communication with our community.
            \n **b. Newsletter:**
             \nSubscribe to our newsletter to receive regular updates, feature highlights, and announcements directly in your inbox. Stay informed about the latest advancements in OVCODE's technologies.`
            },
            {
                content: " At OVCODE, we are dedicated to empowering our users with the knowledge and support they need. Whether you're a seasoned user or just getting started, our comprehensive support services are here to ensure a smooth and enriching experience with OVCODE."
            },
        ],
    },
    {
        title: "Community Spotlight: \nStories from the OVCODE User Community",
        date: "Tuesday, 16 January 2024",
        description: "The heart of OVCODE lies in its diverse and engaged user community. Here, we celebrate the successes, experiences, and contributions of the individuals and businesses that make up the dynamic fabric of the OVCODE community.",
        image: CommunitySpotlight,
        url: "/blog-support-community-spotlight",
        sections: [
            {
                subtitle: "1. Empowering Businesses with Trust:",
                content: `**User:** John Doe, CEO of XYZ Enterprises
                \n **Story:** John Doe, the CEO of XYZ Enterprises, shares how OVCODE has empowered his business with a new level of trust in digital interactions. By implementing OVCODE solutions, XYZ Enterprises has streamlined its document workflows, ensuring the security and authenticity of critical business documents. John notes, "OVCODE has not just been a solution; it's a partner in our journey towards a more secure and efficient digital future."`
            },
            {
                subtitle: "2. From Artist to Innovator:",
                content: `**User:** Jane Smith, Digital Artist
                \n**Story:** Jane Smith, a digital artist, discovered a new dimension to her creative journey with OVCODE's eMark. By safeguarding her digital artworks with eMark, Jane ensures that each piece retains its authenticity. "OVCODE eMark has transformed the way I approach my art. It's not just a watermark; it's a statement of authenticity," says Jane.`
            },
            {
                subtitle: "3. Collaborative Solutions in the Forum:",
                content: `**User:** Community Member, OVCODE Forum
                \n **Story:** In the OVCODE community forums, users collaborate and share insights. One user, a blockchain enthusiast, found solutions to a technical challenge through collaborative discussions. "The OVCODE forums are not just a place for questions; it's a space for collective problem-solving. The support and knowledge-sharing here are invaluable," shares the community member.`
            },
            {
                subtitle: "4. Small Business, Big Impact:",
                content: `**User:** Small Business Owner
                \n **Story:** A small business owner shares how OVCODE has made a big impact on their operations. By integrating eSign into their workflow, the business has seen significant time savings and enhanced document security. "OVCODE eSign is a game-changer for small businesses. It's easy to use, secure, and has transformed the way we handle contracts and approvals," expresses the small business owner.`
            },
            {
                subtitle: "5. Navigating the Future Together:",
                content: `**User:** Developer in the OVCODE Ecosystem
                \n **Story:** A developer within the OVCODE ecosystem reflects on the journey of innovation. "Being part of the OVCODE ecosystem is not just about technology; it's about navigating the future together. The collaborative spirit and commitment to excellence make it an exciting space to be in," says the OVCODE developer.`
            },
            {
                subtitle: "6. Beyond Boundaries with Global Access:",
                content: `**User:** Remote Worker
                \n **Story:** A remote worker highlights the global accessibility of OVCODE solutions. "Working across different time zones, OVCODE's global accessibility has been a game-changer. From eSign to secure document sharing, it's a suite of tools that transcends boundaries," shares the remote worker.`
            },
            {
                subtitle: "Join the Spotlight:",
                content: `Your story could be the next one in the OVCODE Community Spotlight! Share your experiences, successes, and contributions on our forums or social media. Whether you're a business leader, an artist, a developer, or part of the global remote workforce, your journey with OVCODE is an integral part of the community narrative.`
            }
        ]
    },
    {
        title: "Navigating Secure Storage Options with OVCODE: \nCloud vs. On-Premises",
        date: "Monday, 06 May 2024",
        description: "In today’s diverse IT landscape, organizations face the critical decision of selecting the right data storage solution. OVCODE offers a path through this complex choice with secure storage options tailored for cloud and on-premises environments, each fortified with robust security measures to protect valuable digital assets.",
        image: SecureStorage,
        url: "/blog-support-navigating-secure-storage",
        sections: [
            {
                subtitle: "Cloud Storage: Flexibility Meets Security",
                content: `**OVCODE’s cloud storage solutions** are designed for businesses seeking scalability and accessibility. With advanced encryption and real-time threat monitoring, OVCODE's cloud storage provides the flexibility of the cloud without compromising security.`
            },
            {
                subtitle: "Anytime, Anywhere Access",
                content: `The cloud model offers the convenience of accessing data from any location at any time, paired with OVCODE’s commitment to stringent security protocols. This combination ensures that while your data is easily accessible to you, it remains out of reach for unauthorized users.`
            },
            {
                subtitle: "Cost-Effective Scalability",
                content: `Cloud storage with OVCODE eliminates the need for significant upfront hardware investments. It scales with your business, so you pay only for the storage you need, ensuring a cost-effective solution that adjusts to your evolving data requirements.`
            },
            {
                subtitle: "On-Premises Storage: The Power of Local Control",
                content: `For organizations that prefer or require on-site data storage, OVCODE provides on-premises solutions that bring control directly into the hands of the business.`
            },
            {
                subtitle: "Enhanced Control and Compliance",
                content: `With OVCODE’s on-premises storage, data resides within the company’s own IT infrastructure, offering enhanced control over security settings and compliance with industry regulations that mandate data residency within physical boundaries.`
            },
            {
                subtitle: "High-Performance and Customization",
                content: `On-premises solutions from OVCODE deliver high-performance storage with the ability to customize security and system configurations to fit specific operational needs, providing a personalized approach to data management.`
            },
            {
                subtitle: "Hybrid Solutions: Best of Both Worlds",
                content: `Recognizing that one size does not fit all, OVCODE also offers hybrid storage solutions combining the strengths of cloud flexibility and on-premises control.`
            },
            {
                subtitle: "Seamless Integration",
                content: `Hybrid solutions from OVCODE integrate smoothly with existing IT environments, allowing businesses to leverage cloud advantages while retaining certain data on-premises for regulatory or strategic reasons.`
            },
            {
                subtitle: "Tailored Security Across Environments",
                content: `Whether choosing cloud, on-premises, or hybrid storage options, OVCODE ensures that security is consistent and robust across all platforms. OVCODE’s unified security approach guarantees that your data is protected, no matter where it resides.`
            },
            {
                subtitle: "Making the Right Choice with OVCODE",
                content: `Choosing between cloud and on-premises storage options is a strategic decision. With OVCODE, you have a partner that offers secure, compliant, and versatile storage solutions. Navigate your storage options with confidence, knowing that OVCODE’s expertise is guiding you towards the right choice for your organization's present and future data needs.`
            }
        ]
    },
    {
        title: "How OVCODE Prioritizes Data Privacy: \nA Secure Solution Without Data Storage",
        date: "Tuesday, 27 August 2024",
        description: "In today’s digital world, data privacy is a top concern for businesses and individuals alike. With increasing regulations and the rising threat of data breaches, it’s essential to understand how your data is managed and protected. OVCODE offers a unique approach to data security that prioritizes privacy by design. Unlike many platforms, OVCODE does not store any client data; instead, it provides secure and seamless integration with your existing storage systems. This blog post explores how OVCODE ensures data safety while giving clients full control over their data.",
        image: dataPrivacy,
        url: "/blog-support-data-privacy",
        sections: [
            {
                subtitle: "The OVCODE Approach to Data Privacy",
                content: `OVCODE is built with a strong focus on privacy and security. One of the key aspects of OVCODE’s approach is that it does not store your data. Here’s how it works:
                \n\n **Client-Controlled Data Storage**
                \nWith OVCODE, you maintain full control over your data. Instead of storing data on OVCODE’s servers, all information is managed within your own storage systems—whether on-premise, in the cloud, or in a hybrid environment. OVCODE simply integrates with your storage infrastructure, securely interacting with your data without ever taking custody of it.
                \n **Data Integration, Not Data Storage**
                \nOVCODE’s role is to facilitate secure transactions and verifications by integrating with your existing storage system. When you use OVCODE, data files are not uploaded to OVCODE’s servers. Instead, the system interacts with your data where it resides, providing verification and authentication services without storing or replicating sensitive information.`
            },
            {
                subtitle: "Why This Matters for Data Privacy",
                content:  `This approach offers several significant advantages when it comes to data privacy:
                \n\n **No Centralized Data Storage**
                \nSince OVCODE doesn’t store your data, there’s no centralized repository that could be a target for cyberattacks. This decentralized approach reduces the risk of large-scale data breaches and ensures that your information remains secure within your own controlled environment.
                \n **Full Compliance with Data Regulations**
                \nBy keeping data storage under your control, OVCODE helps you maintain compliance with data protection regulations such as GDPR, HIPAA, and others. You decide where and how your data is stored, making it easier to meet regulatory requirements and ensure data sovereignty.
                \n **Enhanced Security Through Integration**
                \nOVCODE uses secure APIs and encryption to interact with your data. These interactions are strictly controlled and monitored, ensuring that data integrity and confidentiality are maintained throughout the process. OVCODE’s blockchain technology adds an additional layer of security, providing immutable records of transactions and verifications without exposing your data.`
            },
            {
                subtitle: "How OVCODE Protects Your Data During Integration",
                content:  `Even though OVCODE doesn’t store your data, it takes data security very seriously during the integration and interaction processes. Here’s how:
                \n\n **End-to-End Encryption**
                \nAll communications between OVCODE and your storage systems are encrypted using state-of-the-art encryption protocols. This ensures that your data is protected from unauthorized access during transmission.
                \n **Secure API Access**
                \nOVCODE uses secure API access to connect with your storage systems. Access is controlled using advanced authentication methods, ensuring that only authorized applications and users can interact with your data.
                \n **Blockchain Verification Without Data Exposure**
                \nOVCODE leverages blockchain technology to verify transactions and data integrity. However, it does so without exposing or storing your actual data on the blockchain. Instead, OVCODE creates and stores cryptographic hashes of your data, which can be used to verify authenticity without revealing the underlying information.`
            },
            {
                subtitle: "The Benefits of OVCODE’s Data Privacy Approach",
                content: "OVCODE’s unique approach to data privacy offers several key benefits:\n- **Control:** You retain complete control over your data, including where and how it is stored.\n- **Security:** By not storing data, OVCODE eliminates the risk of centralized data breaches.\n- **Compliance:** OVCODE’s integration model supports compliance with global data protection regulations.\n- **Trust:** Clients can trust that their data remains private and secure, with no third-party access or storage."
            },
            {
                subtitle: "OVCODE’s Commitment to Data Privacy",
                content: "OVCODE’s approach to data privacy is simple yet powerful: by not storing data, OVCODE ensures that clients have full control over their information while still benefiting from advanced security and verification services. By integrating seamlessly with your existing storage systems and maintaining the highest standards of data security, OVCODE provides a safe and reliable solution for businesses and individuals who prioritize privacy. With OVCODE, you can be confident that your data is secure, private, and fully under your control."
            }
        ]
    }

];

export { supportAndCommunityBlogData }