import environment from './environment/environment'

export const msalconfig={
    auth:{
        clientId:environment.msal_authConfig.clientId,
        redirectUri: environment.msal_url.url,
        postLogoutRedirectUri: environment.msal_url.url,
        knownAuthorities: environment.msal_authConfig.knownAuthorities,
        authority: environment.msal_authConfig.authority,
        // changePasswordUri: environment.msal_change_password_url
    },
    cache: {
        cacheLocation: "localStorage",
    }
};
export const loginRequest = {
    scopes:environment.b2c_scope.scopes
};

export const resetPasswordRequest = {
    authority: "https://ovcodeuser.b2clogin.com/ovcodeuser.onmicrosoft.com/B2C_1_OVCODE_CHANGE_PASSWORD", // Your password reset policy
    scopes: ["openid", "profile", "email"]
};