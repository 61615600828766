import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import BackToTopButton from "../layout/scroll-up.component";
import useWidth from "../../hooks/useWidth";

const TermsOfServiceComponent = () => {

    const breakpoints = useWidth();

    
    return (
        <Box  sx={{mt: 2.5, padding: "0px"}}>
            
            <Box sx={{mb: 4, backgroundColor: "#f2f2f2", paddingTop: "25px", paddingBottom: "25px"}}>
                <Typography sx={{color: "#000000CC", fontSize: 48, margin: 0, lineHeight: 1.2, fontWeight: 400,  textAlign: 'center' }}>
                    OVCODE Terms of Service
                </Typography>
            </Box>

            <div class="resources-container">

            <BackToTopButton />

            <Grid container sx={{padding: "0px"}}>
                <Grid item xs={12} sx={{mt: 2.5}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        1. ACCEPTANCE OF TERMS
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        OVCODE Switzerland AG ("OVCODE") welcomes you. OVCODE provides the OVCODE Services
                        (defined below) to you subject to the following Terms of Service ("TOS"), which may be updated by us
                        from time to time without notice to you.
                        By accessing and using the OVCODE Services, you accept and agree to be bound by the terms and
                        provision of the TOS. In addition, when using particular OVCODE owned or operated services, you and
                        OVCODE shall be subject to any posted guidelines or rules applicable to such services, which may be
                        posted and modified from time to time. All such guidelines or rules (including but not limited to our
                        Spam Policy) are hereby incorporated by reference into the TOS. OVCODE may also offer other services
                        that are governed by different Terms of Service. In such cases the other terms of service will be posted on
                        the relevant service to which they apply.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        2. DESCRIPTION OF OVCODE SERVICES
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 16}}>
                        OVCODE provides users with access to a rich collection of resources, including without limitation
                        various blockchain tools and APIs through its network of properties which may be accessed through any
                        various medium or device now known or hereafter developed (the "OVCODE Services"). You also
                        understand and agree that the OVCODE Services may include advertisements and that these
                        advertisements are necessary for OVCODE to provide the OVCODE Services. You also understand and
                        agree that the OVCODE Services may include certain communications from OVCODE, such as service
                        announcements, administrative messages and the OVCODE Newsletter, and that these communications
                        are considered part of OVCODE membership and you will not be able to opt out of receiving them.
                        Unless explicitly stated otherwise, any new features that augment or enhance the current OVCODE
                        Services, including the release of new OVCODE properties, shall be subject to the TOS. You understand
                        and agree that the OVCODE Services is provided "AS-IS" and that OVCODE assumes no responsibility
                        for the timeliness, deletion, mis-delivery or failure to store any user communications or personalization
                        settings. You are responsible for obtaining access to the OVCODE Services, and that access may involve
                        third-party fees (such as Internet service provider or airtime charges). You are responsible for those fees,
                        including those fees associated with the display or delivery of advertisements. In addition, you must
                        provide and are responsible for all equipment necessary to access the OVCODE Services.
                        You understand that the technical processing and transmission of the OVCODE Services, including your
                        Content, may involve (a) transmissions over various networks; and (b) changes to conform and adapt to
                        technical requirements of connecting networks or devices.
                        Please be aware that OVCODE has created certain areas on the OVCODE Services that contain adult or
                        mature content. You must be at least 18 years of age to access and view such areas. 
                    </Typography>
                </Grid>
            
                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        3. YOUR REGISTRATION OBLIGATIONS
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        In consideration of your use of the OVCODE Services, you represent that you are of legal age to form a
                        binding contract and are not a person barred from receiving the OVCODE Services under the laws of the
                        United States or other applicable jurisdiction. You also agree to: (a) provide true, accurate, current and
                        complete information about yourself as prompted by the OVCODE Service's registration form (the
                        "Registration Data") and (b) maintain and promptly update the Registration Data to keep it true, accurate,
                        current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete,
                        or OVCODE has reasonable grounds to suspect that such information is untrue, inaccurate, not current or
                        incomplete, OVCODE has the right to suspend or terminate your account and refuse any and all current or
                        future use of the OVCODE Services (or any portion thereof). OVCODE is concerned about the safety and
                        privacy of all its users, particularly children. For this reason, parents of children under the age of 13 who
                        wish to allow their children access to the OVCODE Services must create a OVCODE Family Account.
                        When you create a OVCODE Family Account and add your child to the account, you certify that you are
                        at least 18 years old and that you are the legal guardian of the child/children listed on the OVCODE
                        Family Account. By adding a child to your OVCODE Family Account, you also give your child
                        permission to access many areas of the OVCODE Services, including, email, message boards and instant
                        messaging (among others). Please remember that the OVCODE Services is designed to appeal to a broad
                        audience. Accordingly, as the legal guardian, it is your responsibility to determine whether any of the
                        OVCODE Services areas and/or Content (as defined in Section 6 below) are appropriate for your child.
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        4. MEMBER ACCOUNT, PASSWORD AND SECURITY
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        You will receive a password and account designation upon completing the OVCODE Service's
                        registration process. You are responsible for maintaining the confidentiality of the password and account
                        and are fully responsible for all activities that occur under your password or account. You agree to (a)
                        immediately notify OVCODE of any unauthorized use of your password or account or any other breach
                        of security, and (b) ensure that you exit from your account at the end of each session. OVCODE cannot
                        and will not be liable for any loss or damage arising from your failure to comply with this Section 5.
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        5. MEMBER CONDUCT
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        You understand that all information, data, text, software, music, sound, photographs, graphics, video,
                        messages, tags, or other materials ("Content"), whether publicly posted or privately transmitted, are the
                        sole responsibility of the person from whom such Content originated. This means that you, and not
                        OVCODE, are entirely responsible for all Content that you upload, post, email, transmit or otherwise
                        make available via the OVCODE Services. OVCODE does not control the Content posted via the
                        OVCODE Services and, as such, does not guarantee the accuracy, integrity or quality of such Content.
                        You understand that by using the OVCODE Services, you may be exposed to Content that is offensive,
                        indecent or objectionable. Under no circumstances will OVCODE be liable in any way for any Content,
                        including, but not limited to, any errors or omissions in any Content, or any loss or damage of any kind
                        incurred as a result of the use of any Content posted, emailed, transmitted or otherwise made available via
                        the OVCODE Services.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 16}}>
                        You agree to not use the OVCODE Services to:
                        <ul style={{fontWeight: "400", marginTop: "3px"}}>
                            <li>
                                upload, post, email, transmit, or otherwise make available any Content that is unlawful, harmful,
                                threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's
                                privacy, hateful, or racially, ethnically, or otherwise objectionable;
                            </li>
                            <li>
                                harm minors in any way;
                            </li>
                            <li>
                                impersonate any person or entity, including, but not limited to, a OVCODE official, forum leader,
                                or host, or falsely state or otherwise misrepresent your affiliation with a person or entity;
                            </li>
                            <li>
                                forge headers or otherwise manipulate identifiers in order to disguise the origin of any Content
                                transmitted through the OVCODE Service;
                            </li>
                            <li>
                                upload, post, email, transmit, or otherwise make available any Content that you do not have a right to
                                make available under any law or under contractual or fiduciary relationships (such as inside
                                information, proprietary and confidential information learned or disclosed as part of employment
                                relationships or under nondisclosure agreements);
                            </li>
                            <li>
                                upload, post, email, transmit or otherwise make available any Content that infringes any patent,
                                rademark, trade secret, copyright or other proprietary rights ("Rights") of any party;
                            </li>
                            <li>
                                upload, post, email, transmit, or otherwise make available any unsolicited or unauthorized advertising,
                                promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of
                                solicitation, except in those areas (such as shopping) that are designated for such purpose (please read
                                our complete Spam Policy);
                            </li>
                            <li>
                                upload, post, email, transmit, or otherwise make available any material that contains software viruses
                                or any other computer code, files or programs designed to interrupt, destroy or limit the functionality
                                of any computer software or hardware or telecommunications equipment;
                            </li>
                            <li>
                                disrupt the normal flow of dialogue, cause a screen to "scroll" faster than other users of the OVCODE
                                Services are able to type, or otherwise act in a manner that negatively affects other users' ability to
                                engage in real time exchanges;
                            </li>
                            <li>
                                interfere with or disrupt the OVCODE Services or servers or networks connected to the OVCODE
                                Services, or disobey any requirements, procedures, policies or regulations of networks connected to
                                the OVCODE Services, including using any device, software or routine to bypass our robot exclusion
                                headers;
                            </li>
                            <li>
                                intentionally or unintentionally violate any applicable local, state, national or international law,
                                including, but not limited to, regulations promulgated by the U.S. Securities and Exchange
                                Commission, any rules of any national or other securities exchange, including, without limitation, the
                                New York Stock Exchange, the American Stock Exchange, or the NASDAQ, and any regulations
                                having the force of law;
                            </li>
                            <li>
                                provide material support or resources (or to conceal or disguise the nature, location, source, or
                                ownership of material support or resources) to any organization(s) designated by the United States
                                government as a foreign terrorist organization pursuant to section 219 of the Immigration and
                                Nationality Act;
                            </li>
                            <li>
                                "stalk" or otherwise harass another; and/or
                            </li>
                            <li>
                                collect or store personal data about other users in connection with the prohibited conduct and
                                activities set forth in paragraphs a through m above.
                            </li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 16}}>
                        You acknowledge that OVCODE may or may not pre-screen Content, but that OVCODE and its
                        designees shall have the right (but not the obligation) in their sole discretion to pre-screen, refuse, or
                        remove any Content that is available via the OVCODE Services. Without limiting the foregoing,
                        OVCODE and its designees shall have the right to remove any Content that violates the TOS or is
                        otherwise objectionable. You agree that you must evaluate, and bear all risks associated with, the use of
                        any Content, including any reliance on the accuracy, completeness, or usefulness of such Content. In this
                        regard, you acknowledge that you may not rely on any Content created by OVCODE or submitted to
                        OVCODE, including without limitation information in OVCODE Message Boards and in all other parts
                        of the OVCODE Services.
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 16}}>
                        You acknowledge, consent and agree that OVCODE may access, preserve and disclose your account
                        information and Content if required to do so by law or in a good faith belief that such access preservation
                        or disclosure is reasonably necessary to: (i) comply with legal process; (ii) enforce the TOS; (iii) respond
                        to claims that any Content violates the rights of third parties; (iv) respond to your requests for cusstomer
                        service; or (v) protect the rights, property or personal safety of OVCODE, its users and the public.
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 16}}>
                        You understand that the OVCODE Services and software embodied within the OVCODE Services may
                        include security components that permit digital materials to be protected, and that use of these materials is
                        subject to usage rules set by OVCODE and/or content providers who provide content to the OVCODE
                        Services. You may not attempt to override or circumvent any of the usage rules embedded into the
                        OVCODE Services. Any unauthorized reproduction, publication, further distribution or public exhibition
                        of the materials provided on the OVCODE Services, in whole or in part, is strictly prohibited.
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        6. INTERSTATE NATURE OF COMMUNICATIONS ON OVCODE NETWORK
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        When you register with OVCODE, you acknowledge that in using the OVCODE Services to send
                        electronic communications (including but not limited to email, search queries, sending messages to
                        OVCODE Chat or OVCODE Groups, uploading photos and files to Flickr, and other Internet activities),
                        you will be causing communications to be sent through OVCODE's computer networks, portions of
                        which are located in California, Texas, Virginia, and other locations in the United States and portions of
                        which are located abroad. As a result, and also as a result of OVCODE's network architecture and
                        business practices and the nature of electronic communications, even communications that seem to be
                        intrastate in nature can result in the transmission of interstate communications regardless of where you are
                        physically located at the time of transmission. Accordingly, by agreeing to this TOS, you acknowledge
                        that use of the service results in interstate data transmissions.
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        7. SPECIAL ADMONITIONS FOR INTERNATIONAL USE AND EXPORT AND IMPORT
                        COMPLIANCE
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Recognizing the global nature of the Internet, you agree to comply with all local rules regarding online
                        conduct and acceptable Content. Use of the OVCODE Services and transfer, posting and uploading of
                        software, technology, and other technical data via the OVCODE Services may be subject to the export
                        and import laws of the United States and other countries. You agree to comply with all applicable export
                        and import laws and regulations, including without limitation the Export Administration Regulations and
                        sanctions control programs of HOng Kong. In particular, you represent and warrant that you: (a) are not a
                        prohibited party identified on any government export exclusion lists or a member of a government of any
                        other export-prohibited countries as identified in applicable export and import laws and regulations; (b)
                        will not transfer software, technology, and other technical data via the OVCODE Services to exportprohibited parties or countries; (c) will not use the OVCODE Services for military, nuclear, missile,
                        chemical or biological weaponry end uses in violation of U.S. export laws; and (d) will not transfer,
                        upload, or post via the OVCODE Services any software, technology or other technical data in violation of
                        U.S. or other applicable export or import laws.
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        8. CONTENT SUBMITTED OR MADE AVAILABLE FOR INCLUSION ON THE OVCODE
                        SERVICES
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        OVCODE does not claim ownership of Content you submit or make available for inclusion on the
                        OVCODE Services. However, with respect to Content you submit or make available for inclusion on
                        publicly accessible areas of the OVCODE Services, you grant OVCODE the following worldwide,
                        royalty-free and non-exclusive license(s), as applicable:
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 16}}>
                        <ul style={{fontWeight: "400", marginTop: "3px"}}>
                            <li>
                                With respect to Content you submit or make available for inclusion on publicly accessible areas of
                                OVCODE Groups, the license to use, distribute, reproduce, modify, adapt, publicly perform and
                                publicly display such Content on the OVCODE Services solely for the purposes of providing and
                                promoting the specific OVCODE Group to which such Content was submitted or made available.
                                This license exists only for as long as you elect to continue to include such Content on the OVCODE
                                Services and will terminate at the time you remove or OVCODE removes such Content from the
                                OVCODE Services.
                            </li>
                            <li>
                                With respect to photos, graphics, audio or video you submit or make available for inclusion on
                                publicly accessible areas of the OVCODE Services other than OVCODE Groups, the license to use,
                                distribute, reproduce, modify, adapt, publicly perform and publicly display such Content on the
                                OVCODE Services solely for the purpose for which such Content was submitted or made available.
                                This license exists only for as long as you elect to continue to include such Content on the OVCODE
                                Services and will terminate at the time you remove or OVCODE removes such Content from the
                                OVCODE Services.
                            </li>
                            <li>
                                With respect to Content other than photos, graphics, audio or video you submit or make available for
                                inclusion on publicly accessible areas of the OVCODE Services other than OVCODE Groups, the
                                perpetual, irrevocable and fully sublicensable license to use, distribute, reproduce, modify, adapt,
                                publish, translate, publicly perform and publicly display such Content (in whole or in part) and to
                                incorporate such Content into other works in any format or medium now known or later developed.
                            </li> 
                        </ul>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 16}}>
                        "Publicly accessible" areas of the OVCODE Services are those areas of the OVCODE network of
                        properties that are intended by OVCODE to be available to the general public. By way of example,
                        publicly accessible areas of the OVCODE Services would include OVCODE Message Boards and
                        portions of OVCODE Groups and Flickr that are open to both members and visitors. However, publicly
                        accessible areas of the OVCODE Services would not include portions of OVCODE Groups that are
                        limited to members, OVCODE services intended for private communication such as OVCODE Mail or
                        OVCODE Messenger, or areas off of the OVCODE network of properties such as portions of World
                        Wide Web sites that are accessible via hypertext or other links but are not hosted or served by OVCODE.
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        9. CONTRIBUTIONS TO OVCODE
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        By submitting ideas, suggestions, documents, and/or proposals ("Contributions") to OVCODE through its
                        suggestion or feedback webpages, you acknowledge and agree that: (a) your Contributions do not contain
                        confidential or proprietary information; (b) OVCODE is not under any obligation of confidentiality,
                        express or implied, with respect to the Contributions; (c) OVCODE shall be entitled to use or disclose (or
                        choose not to use or disclose) such Contributions for any purpose, in any way, in any media worldwide;
                        (d) OVCODE may have something similar to the Contributions already under consideration or in
                        development; (e) your Contributions automatically become the property of OVCODE without any
                        obligation of OVCODE to you; and (f) you are not entitled to any compensation or reimbursement of any
                        kind from OVCODE under any circumstances.
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        10. INDEMNITY
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        You agree to indemnify and hold OVCODE and its subsidiaries, affiliates, officers, agents, employees,
                        partners and licensors harmless from any claim or demand, including reasonable attorneys' fees, made by
                        any third party due to or arising out of Content you submit, post, transmit, modify or otherwise make
                        available through the OVCODE Services, your use of the OVCODE Services, your connection to the
                        OVCODE Services, your violation of the TOS, or your violation of any rights of another.
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        11. NO COMMERCIAL REUSE OF OVCODE SERVICES
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        You agree not to reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial purposes, any
                        portion or use of, or access to, the OVCODE Services (including Content, advertisements, Software and
                        your OVCODE ID).
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        12. GENERAL PRACTICES REGARDING USE AND STORAGE
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        You acknowledge that OVCODE may establish general practices and limits concerning use of the
                        OVCODE Services, including without limitation the maximum number of days that email messages,
                        message board postings or other uploaded Content will be retained by the OVCODE Services, the
                        maximum number of email messages that may be sent from or received by an account on the OVCODE
                        Services, the maximum size of any email message that may be sent from or received by an account on the
                        OVCODE Services, the maximum disk space that will be allotted on OVCODE's servers on your behalf,
                        and the maximum number of times (and the maximum duration for which) you may access the OVCODE
                        Services in a given period of time. You agree that OVCODE has no responsibility or liability for the
                        deletion or failure to store any messages and other communications or other Content maintained or
                        transmitted by the OVCODE Services. You acknowledge that OVCODE reserves the right to log off
                        accounts that are inactive for an extended period of time. You further acknowledge that OVCODE
                        reserves the right to modify these general practices and limits from time to time.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        OVCODE Messenger, including any web-based versions, will allow you and the people with whom you
                        communicate to save your conversations in your OVCODE accounts located on OVCODE servers. This
                        means you can access and search your message history from any computer with access to the internet.
                        Whether or not you use this feature, other users may choose to use it to save conversations with you in
                        their account on OVCODE too. Your agreement to this TOS constitutes your consent to allow OVCODE
                        to store these communications on its servers. 
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        13. MODIFICATIONS TO OVCODE SERVICES
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        OVCODE reserves the right at any time and from time to time to modify or discontinue, temporarily or
                        permanently, the OVCODE Services (or any part thereof) with or without notice. You agree that
                        OVCODE shall not be liable to you or to any third party for any modification, suspension or
                        discontinuance of the OVCODE Services (or any part thereof).                          
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        14. TERMINATION
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        You may terminate your OVCODE account, any associated email address and access to the OVCODE
                        Services by submitting such termination request to OVCODE.                           
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        You agree that OVCODE may, without prior notice, immediately terminate, limit your access to or
                        suspend your OVCODE account, any associated email address, and access to the OVCODE Services.
                        Cause for such termination, limitation of access or suspension shall include, but not be limited to, (a)
                        breaches or violations of the TOS or other incorporated agreements or guidelines, (b)requests by law
                        enforcement or other government agencies, (c) discontinuance or material modification to the OVCODE
                        Services (or any part thereof), (d) unexpected technical or security issues or problems, (e) extended
                        periods of inactivity, (f) engagement by you in fraudulent or illegal activities, and/or (g) nonpayment of
                        any fees owed by you in connection with the OVCODE Services. Further, you agree that all terminations,
                        limitations of access and suspensions for cause shall be made in OVCODE's sole discretion and that
                        OVCODE shall not be liable to you or any third party for any termination of your account, any associated
                        email address, or access to the OVCODE Services.                          
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Termination of your OVCODE account includes any or all of the following: (a) removal of access to all
                        or part of the offerings within the OVCODE Services, (b) deletion of your password and all related
                        information, files and content associated with or inside your account (or any part thereof), and (c) barring
                        of further use of all or part of the OVCODE Services.                          
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        15. DEALINGS WITH ADVERTISERS
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Your correspondence or business dealings with, or participation in promotions of, advertisers found on or
                        through the OVCODE Services, including payment and delivery of related goods or services, and any
                        other terms, conditions, warranties or representations associated with such dealings, are solely between
                        you and such advertiser. You agree that OVCODE shall not be responsible or liable for any loss or
                        damage of any sort incurred as the result of any such dealings or as the result of the presence of such
                        advertisers on the OVCODE Services.                           
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        16. LINKS
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        The OVCODE Services may provide, or third parties may provide, links to other World Wide Web sites
                        or resources. You acknowledge and agree that OVCODE is not responsible for the availability of such
                        external sites or resources, and does not endorse and is not responsible or liable for any Content,
                        advertising, products or other materials on or available from such sites or resources. You further
                        acknowledge and agree that OVCODE shall not be responsible or liable, directly or indirectly, for any
                        damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such
                        Content, goods or services available on or through any such site or resource.      
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        17. OVCODE'S PROPRIETARY RIGHTS
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        You acknowledge and agree that the OVCODE Services and any necessary software used in connection
                        with the OVCODE Services ("Software") contain proprietary and confidential information that is
                        protected by applicable intellectual property and other laws. You further acknowledge and agree that
                        Content contained in advertisements or information presented to you through the OVCODE Services or
                        by advertisers is protected by copyrights, trademarks, service marks, patents or other proprietary rights
                        and laws. Except as expressly permitted by applicable law or as authorized by OVCODE or the applicable
                        licensor (such as an advertiser), you agree not to modify, rent, lease, loan, sell, distribute, transmit,
                        broadcast, publicly perform or create derivative works based on the OVCODE Services, such Content or
                        the Software, in whole or in part.     
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        OVCODE grants you a personal, non-transferable and non-exclusive right and license to use the object
                        code of its Software on a single computer; provided that you do not (and do not allow any third party to)
                        copy, modify, create a derivative work from, reverse engineer, reverse assemble or otherwise attempt to
                        discover any source code, sell, assign, sublicense, grant a security interest in or otherwise transfer any
                        right in the Software. You agree not to modify the Software in any manner or form, nor to use modified
                        versions of the Software, including (without limitation) for the purpose of obtaining unauthorized access
                        to the OVCODE Services. You agree not to access the OVCODE Services by any means other than
                        through the interface that is provided by OVCODE for use in accessing the OVCODE Services.  
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        18. DISCLAIMER OF WARRANTIES
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 16}}>
                        YOU EXPRESSLY UNDERSTAND AND AGREE THAT:   
                        <ul style={{fontWeight: "400", marginTop: "3px"}}>
                            <li>
                                YOUR USE OF THE OVCODE SERVICES AND SOFTWARE ARE AT YOUR SOLE RISK. THE
                                OVCODE SERVICES AND SOFTWARE ARE PROVIDED ON AN "AS IS" AND "AS
                                AVAILABLE" BASIS. OVCODE AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS,
                                EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ALL
                                WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                                LIMITED TO THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR
                                A PARTICULAR PURPOSE AND NON-INFRINGEMENT.   
                            </li>
                            <li>
                                OVCODE AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
                                PARTNERS AND LICENSORS MAKE NO WARRANTY THAT (i) THE OVCODE SERVICES
                                OR SOFTWARE WILL MEET YOUR REQUIREMENTS; (ii) THE OVCODE SERVICES OR
                                SOFTWARE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (iii) THE
                                RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE OVCODE SERVICES OR
                                SOFTWARE WILL BE ACCURATE OR RELIABLE; (iv) THE QUALITY OF ANY PRODUCTS,
                                SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
                                THROUGH THE OVCODE SERVICES OR SOFTWARE WILL MEET YOUR EXPECTATIONS;
                                AND (v) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.                              
                            </li>
                            <li>
                                ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF
                                THE OVCODE SERVICES OR SOFTWARE IS ACCESSED AT YOUR OWN DISCRETION
                                AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY
                                AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO
                                YOUR COMPUTER SYSTEM, INTERNET ACCESS, DOWNLOAD OR DISPLAY DEVICE, OR
                                LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.                              
                            </li>
                            <li>
                                NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
                                FROM OVCODE OR THROUGH OR FROM THE OVCODE SERVICES OR SOFTWARE
                                SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TOS.                              
                            </li> 
                            <li>
                                A SMALL PERCENTAGE OF USERS MAY EXPERIENCE EPILEPTIC SEIZURES WHEN
                                EXPOSED TO CERTAIN LIGHT PATTERNS OR BACKGROUNDS ON A COMPUTER
                                SCREEN OR WHILE USING THE OVCODE SERVICE. CERTAIN CONDITIONS MAY
                                INDUCE PREVIOUSLY UNDETECTED EPILEPTIC SYMPTOMS EVEN IN USERS WHO
                                HAVE NO HISTORY OF PRIOR SEIZURES OR EPILEPSY. IF YOU, OR ANYONE IN YOUR
                                FAMILY, HAVE AN EPILEPTIC CONDITION, CONSULT YOUR PHYSICIAN PRIOR TO
                                USING THE OVCODE SERVICE. IMMEDIATELY DISCONTINUE USE OF THE OVCODE
                                SERVICES AND CONSULT YOUR PHYSICIAN IF YOU EXPERIENCE ANY OF THE
                                FOLLOWING SYMPTOMS WHILE USING THE OVCODE SERVICE: DIZZINESS, ALTERED
                                VISION, EYE OR MUSCLE TWITCHES, LOSS OF AWARENESS, DISORIENTATION, ANY
                                INVOLUNTARY MOVEMENT, OR CONVULSIONS.                             
                            </li> 
                        </ul>
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        19. LIMITATION OF LIABILITY
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        YOU EXPRESSLY UNDERSTAND AND AGREE THAT OVCODE AND ITS SUBSIDIARIES,
                        AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT
                        BE LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL,
                        CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO,
                        DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
                        LOSSES (EVEN IF OVCODE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
                        RESULTING FROM: (a) THE USE OR THE INABILITY TO USE THE OVCODE SERVICE; (b) THE
                        COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES; (c) UNAUTHORIZED
                        ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (d) STATEMENTS OR
                        CONDUCT OF ANY THIRD PARTY ON THE OVCODE SERVICE; OR (e) ANY OTHER MATTER
                        RELATING TO THE OVCODE SERVICE.   
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        20. EXCLUSIONS AND LIMITATIONS
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR
                        THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
                        DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS OF SECTIONS 19 AND 20
                        MAY NOT APPLY TO YOU.  
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        21. SPECIAL ADMONITION FOR OVCODE SERVICES RELATING TO FINANCIAL MATTERS
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        If you intend to create or join any service, receive or request any news, messages, alerts or other
                        information from the OVCODE Services concerning companies, stock quotes, investments or securities,
                        please read the above Sections 19 and 20 again. They go doubly for you. In addition, for this type of
                        information particularly, the phrase "Let the investor beware" is apt. The OVCODE Services is provided
                        for informational purposes only, and no Content included in the OVCODE Services is intended for
                        trading or investing purposes. OVCODE and its licensors shall not be responsible or liable for the
                        accuracy, usefulness or availability of any information transmitted or made available via the OVCODE
                        Services, and shall not be responsible or liable for any trading or investment decisions based on such
                        information.
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        22. NO THIRD-PARTY BENEFICIARIES
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        You agree that, except as otherwise expressly provided in this TOS, there shall be no third-party
                        beneficiaries to this agreement.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        23. NOTICE
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        OVCODE may provide you with notices, including those regarding changes to the TOS, including by but
                        not limited to email, regular mail, SMS, MMS, text message, postings on the OVCODE Services, or other
                        reasonable means now known or hereafter developed. Such notices may not be received if you violate this
                        TOS by accessing the OVCODE Services in an unauthorized manner. Your agreement to this TOS
                        constitutes your agreement that you are deemed to have received any and all notices that would have been
                        delivered had you accessed the OVCODE Services in an authorized manner.  
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        24. TRADEMARK INFORMATION
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        You agree that all of OVCODE’s trademarks, trade names, service marks and other OVCODE logos and
                        brand features, and product and service names are trademarks and the property of OVCODE Inc. (the
                        "OVCODE Marks"). Without OVCODE's prior permission, you agree not to display or use in any manner
                        the OVCODE Marks.
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        25. NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT OR INTELLECTUAL
                        PROPERTY INFRINGEMENT
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 16}}>
                        OVCODE respects the intellectual property of others, and we ask our users to do the same. OVCODE
                        may, in appropriate circumstances and at its discretion, disable and/or terminate the accounts of users who
                        may be repeat infringers. If you believe that your work has been copied in a way that constitutes
                        copyright infringement, or your intellectual property rights have been otherwise violated, please provide
                        OVCODE's Copyright Agent the following information:   
                        <ul style={{fontWeight: "400", marginTop: "3px"}}>
                            <li>
                                an electronic or physical signature of the person authorized to act on behalf of the owner of the
                                copyright or other intellectual property interest; 
                            </li>
                            <li>
                                a description of the copyrighted work or other intellectual property that you claim has been infringed;                  
                            </li>
                            <li>
                                a description of where the material that you claim is infringing is located on the site;                   
                            </li>
                            <li>
                                your address, telephone number, and email address;                
                            </li> 
                            <li>
                                a statement by you that you have a good faith belief that the disputed use is not authorized by the
                                copyright owner, its agent, or the law;                   
                            </li> 
                            <li>
                                a statement by you, made under penalty of perjury, that the above information in your notice is accurate
                                and that you are the copyright or intellectual property owner or authorized to act on the copyright or
                                intellectual property owner's behalf.                   
                            </li> 
                        </ul>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        OVCODE's Agent for Notice of claims of copyright or other intellectual property infringement can be
                        reached as follows:
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        By mail:
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        OVCODE Switzerland AG.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        Customer Care - Privacy Policy Issues
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        Talstrasse, Zürich, Switzerland
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        Kanton Zürich
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        By email: support@ovcode.com
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        26. CLOSED CAPTIONING
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        OVCODE complies with applicable Federal Communications Commission rules and regulations
                        regarding the closed captioning of video content. Please send us an email for more information or to
                        register any concerns or complaints regarding video content accessible on the OVCODE network of
                        properties.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        27. GENERAL INFORMATION
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Entire Agreement. The TOS constitutes the entire agreement between you and OVCODE and governs
                        your use of the OVCODE Services, superseding any prior version of this TOS between you and
                        OVCODE with respect to the OVCODE Services. You also may be subject to additional terms and
                        conditions that may apply when you use or purchase certain other OVCODE services, affiliate services,
                        third-party content or third-party software.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Choice of Law and Forum. You and OVCODE each agree that the TOS and the relationship between the
                        parties shall be governed by the laws of the State of California without regard to its conflict of law
                        provisions and that any and all claims, causes of action or disputes (regardless of theory) arising out of or
                        relating to the TOS, or the relationship between you and OVCODE, shall be brought exclusively in the
                        courts Delaware. You and OVCODE agree to submit to the personal jurisdiction of the courts located
                        within Delaware, and agree to waive any and all objections to the exercise of jurisdiction over the parties
                        by such courts and to venue in such courts.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Waiver and Severability of Terms. The failure of OVCODE to exercise or enforce any right or provision
                        of the TOS shall not constitute a waiver of such right or provision. If any provision of the TOS is found
                        by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should
                        endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of
                        the TOS remain in full force and effect.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        No Right of Survivorship and Non-Transferability. You agree that your OVCODE account is nontransferable and any rights to your OVCODE ID or contents within your account terminate upon your
                        death. Upon receipt of a copy of a death certificate, your account may be terminated and all contents
                        therein permanently deleted.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Statute of Limitations. You agree that regardless of any statute or law to the contrary, any claim or cause
                        of action arising out of or related to use of the OVCODE Services or the TOS must be filed within one (1)
                        year after such claim or cause of action arose or be forever barred.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        The section titles in the TOS are for convenience only and have no legal or contractual effect.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 22}}>
                        28. VIOLATIONS
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Please report any violations of the TOS to our Customer Care group.
                    </Typography>
                </Grid>
            </Grid>
            </div>
        </Box>
    )
}

export default TermsOfServiceComponent