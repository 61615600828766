import React, { useState } from "react";
import { Card, CardContent, Chip, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle';

const featuredata = [
    { title: "Auto Reminder Threshold (default 7 attempt)" },
    { title: "Individual Document Audit Trail" },
    { title: "Decline Document Process" },
    { title: "Enhance Cancel Document Process" },
    { title: "Resend Cancelled and Denied Document" },
    { title: "Retry Error Document" }
]

const ElectronicSignatureReleaseNotesv3 = () => {

    const [showFullDetails, setShowFullDetails] = useState(false);

    const toggleDetails = () => {
        setShowFullDetails(!showFullDetails);
    };

    return (
        <Card sx={{
            borderRadius: '4px',
            marginBottom: '10px',
            transition: '.5s',
            boxShadow: '0 0 6px 1px rgba(0,0,0,0.08)',
            backgroundColor: '#ffffff',
            '&:hover': {
                boxShadow: '0 0 12px 2px rgba(0,0,0,0.16)',
            },
        }}>
            <div style={{ padding: '40px' }}>
                <header style={{ textAlign: 'start' }}>
                    <Typography variant="h5" component="div" sx={{ fontSize: 28, fontWeight: 600, margin: "0 0 5px 0", color: '#161616' }}>
                        eSign Version 1.0.2
                    </Typography>
                    <div style={{
                        justifyContent: 'flex-start',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 14,
                        color: '#999'
                    }}>
                        <List className="post-tags">
                            <ListItem sx={{ paddingLeft: 0 }}>
                                <Chip label={'NEW'} sx={{ background: '#00800061' }} /> &nbsp;
                                <Chip label={"FIXES"} sx={{ background: '#ff000057' }} /> &nbsp;
                                <Chip label={"ENHANCEMENTS"} sx={{ background: '#ffa5004f' }} />
                            </ListItem>
                        </List>
                        <Typography variant="body2" component="span" className="posted-time">
                            <time dateTime="2023-11-24">July 12, 2024</time>
                        </Typography>
                    </div>
                </header>

                <CardContent sx={{ fontSize: 18, padding: 0 }}>

                    <div style={{ mt: 20, paddingLeft: 0 }}>
                        <Typography variant="h6" component="h3" sx={{ textAlign: 'start' }}>
                            New Features
                        </Typography>
                        <List sx={{ paddingLeft: 0 }}>
                            {featuredata.map((data) => {
                                return (
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>{data?.title}</Typography>}
                                        />
                                    </ListItem>
                                )
                            })}


                        </List>
                        <br />
                        <Typography variant="h6" component="h3" sx={{ textAlign: 'start' }}>
                            Enhancements
                        </Typography>
                        <List sx={{ paddingLeft: 0 }}>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>Auto Reminder</Typography>}
                                    secondary={
                                        <React.Fragment>
                                            <List>
                                                <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                        <CircleIcon sx={{ width: 5, height: 12 }} />
                                                    </ListItemIcon>
                                                    <ListItemText sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                        Default set to Every 24hrs.
                                                    </ListItemText>
                                                </ListItem>
                                                <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                        <CircleIcon sx={{ width: 5, height: 12 }} />
                                                    </ListItemIcon>
                                                    <ListItemText sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                        Reminder Threshold limit is 7 times.
                                                    </ListItemText>
                                                </ListItem>
                                                <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                        <CircleIcon sx={{ width: 5, height: 12 }} />
                                                    </ListItemIcon>
                                                    <ListItemText sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                        Auto Cancellation of Document if Auto reminder Threshold is equal to 7 Times.
                                                    </ListItemText>
                                                </ListItem>
                                                <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                        <CircleIcon sx={{ width: 5, height: 12 }} />
                                                    </ListItemIcon>
                                                    <ListItemText sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                        Email Notification for Auto Cancellation of Document if document reminder reaches 7 times.
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>

                            {showFullDetails && (
                                <>


                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>Decline Document upon signing per signee</Typography>}
                                            secondary="Required Decline Reason on Decline Process Flow on Document."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon >
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>Cancel Process for Documents with Pending Status</Typography>}
                                            secondary="Email Notification for Cancellation of Documents. Signed Signatories received email notification for document cancelation."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>Resend Process for Documents with Cancelled and Denied document status</Typography>}
                                            secondary="New Email Notification for the current document signee (last signee) once the document has been resent. Document status changes to Pending from Cancelled and Denied once the document is resent."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon >
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>Denied Document Process flow for documents declined by a signee</Typography>}
                                            secondary="When a document is declined, its status changes from Pending to Denied, the signee status changes from Ongoing to Declined, email notifications are enhanced, links display a 'Document Denied' message, and when resent, a new email notification is sent, changing the status to Pending from Cancelled or Denied."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon >
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>Auto Logs Workflow Process Updates</Typography>}
                                            secondary="Auto Logs Status Change to CREATE, UPDATE, DENIED, CANCELLED, RESEND, COMPLETED for document process flow."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon >
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>Document Audit trail</Typography>}
                                            secondary="In the Action column in Files, display the document audit trail from creation to its latest status."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon >
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>Retry Error Status</Typography>}
                                            secondary="Document owner can retry the document with Error Status (Previous error unsupported)"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon >
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>eSign Main Navigator Menu</Typography>}
                                            secondary="Change 'Document to Create Document' and update the logo for 'Create Document'."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon >
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>File List</Typography>}
                                            secondary="Change 'Created Time' to 'Last Date Modified' column, display decline reason with an icon in the status column and show the decline reason in a dialog box when clicked, add a scrollable list of up to 7 color-coded status histories in the action column."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon >
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>Denied Tab</Typography>}
                                            secondary="Displays List of Documents with Decline status."
                                        />
                                    </ListItem>



                                    <br />

                                    <Typography variant="h6" component="h3" sx={{ textAlign: 'start' }}>
                                        Bug Fixes
                                    </Typography>

                                    <ListItem>
                                        <ListItemIcon>
                                            <CircleIcon sx={{ width: 12, height: 12 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography sx={{ fontSize: 16, fontWeight: 600 }}>Document Workflow</Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <List>
                                                        <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                            <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                <CircleIcon sx={{ width: 5, height: 12 }} />
                                                            </ListItemIcon>
                                                            <ListItemText sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                QR Code display
                                                            </ListItemText>
                                                        </ListItem>
                                                        <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                            <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                <CircleIcon sx={{ width: 5, height: 12 }} />
                                                            </ListItemIcon>
                                                            <ListItemText sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                Document Lists with Pagination
                                                            </ListItemText>
                                                        </ListItem>
                                                        <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                            <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                <CircleIcon sx={{ width: 5, height: 12 }} />
                                                            </ListItemIcon>
                                                            <ListItemText sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                Permanently delete the file from azure blob if OVCODE Webhook response with success `S` (Completed Status)
                                                            </ListItemText>
                                                        </ListItem>
                                                        <ListItem sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                            <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                <CircleIcon sx={{ width: 5, height: 12 }} />
                                                            </ListItemIcon>
                                                            <ListItemText sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                                Display Elements for documents with Draft status if document is processed for editing
                                                            </ListItemText>
                                                        </ListItem>
                                                    </List>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>

                                </>
                            )}
                        </List>
                    </div>
                </CardContent>
                <footer style={{ textAlign: "center" }}>
                    <a
                        style={{
                            borderRadius: "4px",
                            border: "none",
                            color: "#017eff",
                            border: "1px solid #017eff",
                            padding: "11px 15px 9px 15px",
                            fontSize: "14px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            display: "inline-block",
                            opacity: 0.80,
                            transition: ".5s",
                            textDecoration: "none",
                            cursor: 'pointer'
                        }}
                        onClick={toggleDetails}
                    >
                        {showFullDetails ? "Hide Details" : "See Full Details"}
                    </a>
                </footer>
            </div>
        </Card>
    )
}

export default ElectronicSignatureReleaseNotesv3