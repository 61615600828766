import { Box, Grid, Typography } from "@mui/material";
import electronicIdentificationOne from "../../../images/electronic-identification/one.png";
import electronicIdentificationTwo from "../../../images/electronic-identification/two.png";
import electronicIdentificationThree from "../../../images/electronic-identification/three.png";
import useWidth from "../../../hooks/useWidth";

const ExploreElectronicIdentificationComponent = () => {

    const breakpoints = useWidth();

    return (
        
            <Grid container spacing={2} sx={{ alignContent: 'center', alignItems: 'center', padding: 6, pt: 3, pb: 3 }}>
                <Grid item xs={12}>
                    <Typography sx={{
                        fontSize: 29,
                        margin: 0,
                        lineHeight: 1.2,
                        fontWeight: 700,
                        textAlign: 'center',
                        color: "#000"
                    }}>
                        Explore the eID Platform
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{ mt: breakpoints === 'xs' ? 1 : 5 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={5}>
                            <Grid container spacing={2} sx={{ mt: 5 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        fontWeight: 600,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000",
                                        ml: (breakpoints == "xl") ? "71px !important" : (breakpoints == "lg") ? "42px !important" : {}
                                    }}>
                                        Create eID records
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        fontWeight: 400,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000",
                                        width: '80%',
                                        ml: (breakpoints == "xl") ? "71px !important" : (breakpoints == "lg") ? "42px !important" : {},
                                        mt: "-5px !important",
                                        mb: "8px !important",
                                    }}>
                                        Transforming eID creation from tedious to effortless.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <img alt="" src={electronicIdentificationOne} style={{width: breakpoints === 'xs' ? '100%' : '80%', borderRadius: 10}}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={1} sx={{ justifyContent: 'center!important', display: 'flex!important' }}>
                            <div style={{
                                borderBottomWidth: '.0625rem!important',
                                borderBottomStyle: 'solid',
                                borderBottomColor: '#d2d2d2'
                            }}></div>
                            <div
                                style={{
                                    borderRightWidth: '.0625rem!important',
                                    borderRightStyle: 'solid',
                                    borderRightColor: '#d2d2d2'
                                }}></div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        fontWeight: 600,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000",
                                    }}>
                                        Add on mobile
                                    </Typography>
                                    <br />
                                    <Typography sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        fontWeight: 400,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000",
                                    }}>
                                        You can effortlessly bind your eID within the mobile app and seamlessly add new eIDs as needed.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <img src={electronicIdentificationTwo} style={{width: '100%', borderRadius: 10}}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
                                <div style={{
                                    borderBottomWidth: '.0625rem!important',
                                    borderBottomStyle: 'solid',
                                    borderBottomColor: '#d2d2d2'
                                }}></div>
                                <div
                                    style={{
                                        borderRightWidth: '.0625rem!important',
                                        borderRightStyle: 'solid',
                                        borderRightColor: '#d2d2d2'
                                    }}></div>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        fontWeight: 600,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000"
                                    }}>
                                        Manage eID
                                    </Typography>
                                    <br />
                                    <Typography sx={{
                                        fontSize: 16,
                                        margin: 0,
                                        lineHeight: 1.6,
                                        fontWeight: 400,
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        color: "#000"
                                    }}>
                                        The issued eID can be readily revoked or suspended, and an app binding can be sent to update the details in case any incorrect information is identified.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <img src={electronicIdentificationThree} style={{width: '100%', borderRadius: 10}}/>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
    )
}

export default ExploreElectronicIdentificationComponent;