
import { Box, Typography } from "@mui/material";
import ProductsServicesSlider from "./products-services-slider.component";

const ServicesSliderComponent = (props) => {

  return (

    <Box sx={{ overflow: 'hidden' }}>

      <Box sx={{ mb: 2, mt: 5 }}>
        <Typography sx={{
          fontSize: 37,
          margin: 0,
          lineHeight: 1.2,
          fontWeight: 600,
          textAlign: 'start',
          color: "#000",
          mb: '1.25rem'
        }}>
          Solutions
        </Typography>
      </Box>

      <Box sx={{ mb: 5, mt: 5 }}>
        <ProductsServicesSlider />
      </Box>
    </Box>
  );

}

export default ServicesSliderComponent
