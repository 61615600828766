
const ovcodemobiledata = [
    {
        title: " No Internet Connection Handling: Improved user experience when there is no internet connection. ",
    },
    {
        title: "UI/UX Compatibility: Enhanced user interface and experience.",
    },
    {
        title: "View and Download Button Functionality: Configurable based on enterprise settings (enabled or disabled).",
    },
    {
        title: "Save Digital Document in Preferred Folder: Users can choose the folder to save the document.",
    },
    {
        title: "Overall UI Enhancements: General improvements to the user interface.",
    },
    {
        title: "Request Timeout Exception Handling: Better handling of request timeouts.",
    },
    {
        title: "Multiple Tapping Exception Handling: Prevents issues caused by multiple taps.",
    },
    {
        title: "API Response Error Handling: Improved handling of API response errors.",
    },
    {
        title: "Mandatory Permission Requirements: Requires essential permissions such as Camera and GPS.",
    },
    {
        title: "Verification Functionality Optimization: Improved verification process for better performance.",
    },
    {
        title: "App Deep Link for Verification: Enables redirection to the app for verification if the user scans the OVI OVCODE (link format) using a different application.",
    }

];


const verificationdata = [
    {
        title: "Verify OVCODE OVI through QR Code Scanning: Supports both link and alphanumeric formats.",
    },
    {
        title: "Verify Digital Document: Allows users to upload a digital document copy.",
    },
    {
        title: " Manual Input of OVCODE OVI: Users can manually input the alphanumeric format for verification.",
    },
    {
        title: "View Digital Document Copy: Available after successful verification of OVCODE OVI.",
    },
    {
        title: "Save Digital Document: Option to save the digital document after successful verification.",
    },
    {
        title: " Double Verification: Users can upload a digital copy for additional verification.",
    },
    {
        title: "Custom OVI Detection: Detects custom OVI for each enterprise.",
    },
    {
        title: "Custom Verification Results: Displays results based on enterprise preferences.",
    },
    {
        title: "Send Device Identity, Geolocation, and IP Address: Captures and sends this information for every verification.",
    }

];


const roadmapdata = [
    {
        title: "User Profile: Introduce user profile features.",
    },
    {
        title: "Sign-In Using Email or Social Media Accounts: Provide multiple sign-in options.",
    },
    {
        title: "User Verification History: Track and display user verification history.",
    },
    {
        title: "Sign-In UI/UX Enhancement and Customization: Improve and customize the sign-in experience.",
    },
    {
        title: "AD/Open ID Integration Enhancement: Enhance Active Directory/Open ID integration.",
    },
    {
        title: "Session Handling: Auto logout for inactive users..",
    },
    {
        title: "PIN Configuration: Allow users to set up a PIN after successful sign-in.",
    },
    {
        title: "Document Authentication History: Add document authentication history in the verification result."
    }

];

export { ovcodemobiledata, verificationdata, roadmapdata }