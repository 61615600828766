import { UNILAB_RES_RL_ITEMS, UNILAB_RES_BL_ITEMS, UNILA_RES_MPL_ITEMS, UNILA_RES_MPL_SPECIAL_ITEMS, unilab_res_types } from "../common/staticVariables";

const useUnilabBodyItems = () => {

    const getBodyItems = (type) => {
        const bodyItems = [];

        let items = null;
        if (type === unilab_res_types.RL) {
            items = UNILAB_RES_RL_ITEMS;
        } else if (type === unilab_res_types.BL) {
            items = UNILAB_RES_BL_ITEMS;
        } else if (type === unilab_res_types.MPL) {
            items = UNILA_RES_MPL_ITEMS;
        }

        if (items) {
            items.forEach(item => {
                const code = item.replace(/\s+/g, '_').toLowerCase();
                const hasDashedLine = !UNILA_RES_MPL_SPECIAL_ITEMS.includes(item);

                const bodyItemObj = {
                    label: item,
                    code: code,
                    has_dashed_line: hasDashedLine
                };

                bodyItems.push(bodyItemObj);
            });
        }

        return bodyItems;
    };

    return { getBodyItems };
};

export default useUnilabBodyItems;
