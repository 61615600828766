import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import OvcodeRole from "../../../../images/cybergems/CG-three.png";


const CybergemsArtOfAuthenticityComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                The Art of Authenticity - <br /> OVCODE's Blockchain Verifying Cybergems
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Monday, 06 May 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={OvcodeRole} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In the bustling market of digital collectibles, the authenticity of each piece plays a pivotal role in its value and desirability.
                                OVCODE, with its cutting-edge blockchain technology, stands as the guardian of authenticity for Cybergems, ensuring that each digital gem is not only genuine but also holds a verifiable legacy of its origin and ownership.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Ensuring Authenticity in the Digital Age</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                As Cybergems carve out a niche in the digital world, OVCODE's blockchain technology provides the backbone for their verification.
                                This process guarantees that every Cybergem is an original, not a duplicate, maintaining the integrity of the digital collectibles market.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Immutable Verification</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE's blockchain technology utilizes advanced cryptographic techniques to embed a unique digital signature into each Cybergem.
                                This signature, once set, cannot be altered, ensuring the gem remains authentic from the moment it is created.
                                This permanent verification is critical in a landscape where replication and fraud are ever-present threats.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Transparency and Traceability</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Beyond ensuring each gem's authenticity, OVCODE's blockchain provides a transparent transaction ledger.
                                This traceability allows buyers and collectors to verify the entire history of a Cybergem, from its creation to its current ownership, fostering trust and confidence in the marketplace.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>The Process of Authenticating Cybergems</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE's authentication process involves several key steps that ensure each Cybergem's authenticity and security:
                            </Typography>
                            <Grid container sx={{ pl: 2, pr: 2 }}>
                                <Grid item sx={12} x={{ textAlign: 'start', mt: 3 }}>
                                    <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Digital Certification</Typography>
                                    <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                        Each Cybergem is accompanied by a digital certificate of authenticity, which is stored on the blockchain.
                                        This certificate provides detailed information about the gem, including its creation date, the artist's details, and its transaction history.
                                    </Typography>
                                </Grid>
                                <Grid item sx={12} x={{ textAlign: 'start', mt: 3 }}>
                                    <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Secure Smart Contracts</Typography>
                                    <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                        Smart contracts automate the verification process for each transaction involving Cybergems.
                                        These contracts execute the terms directly on the blockchain, ensuring that every transfer of ownership is legitimate and secure.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Enhancing Collector Confidence</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                With OVCODE’s blockchain technology, collectors can invest in Cybergems with the assurance that they are obtaining authentic digital assets.
                                This level of security enhances the overall value of Cybergems, making them not only a joy to own but also a trustworthy investment.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Building a Trusted Community</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                The authenticity guaranteed by OVCODE helps build a community of trust among collectors, artists, and investors.
                                This trust is essential for the growth of the digital collectibles market and fosters a healthy ecosystem where creativity and ownership flourish.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'center'  }}>A New Standard for Digital Collectibles </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE's verification of Cybergems sets a new standard in the realm of digital collectibles.
                                By ensuring the authenticity of each gem through blockchain technology, OVCODE is shaping a market where trust, transparency, and artistic value are paramount.
                                Collectors can celebrate not just the beauty of their acquisitions but also the peace of mind that comes with knowing each piece is genuinely unique and securely verified.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-industry-ovcode-role"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        The Role of OVCODE in Securing the Supply Chain
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Discuss how OVCODE contributes to supply chain security and authenticity.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                October 09, 2023
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-cybergems-authenticating-digital-treasure"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        OVCODE and Cybergems: Authenticating the Digital Treasures of Tomorrow
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Discover how OVCODE is revolutionizing the world of digital collectibles with Cybergems, a pioneering implementation of its blockchain technology.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                May 02, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CybergemsArtOfAuthenticityComponent