import { useState } from "react";
import { blogTopicsData } from "../components/blog/topics/blog-topics-data.component";
import BlogTopicsComponent from "../components/blog/topics/blog-topics.component";
import { Box, Paper, Typography } from "@mui/material";


const bannerStyle = {
    backgroundColor: '#017eff',
    height: 230,
    width: '100%',
    display: 'flex', // Use flex display for centering
    alignItems: 'center', // Center vertically
    paddingLeft: '50px', // Adjust padding
    color: '#fff',
    textAlign: 'start'
};

const bannerContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center content vertically within the banner
    textAlign: 'start',


};

const BlogScreen = () => {
    const url = window.location.pathname;
    const segments = url.split("/");
    const lastSegment = segments[segments.length - 1];

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3;

    const handleClick = (url) => {
        console.log("url aaa",url)
        if (url.trim() === "") {
            alert("Content is on the way!");
        } else {
            window.location.href = url;
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const filteredData = blogTopicsData?.filter(blog => blog.blog === lastSegment);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);


    console.log("lastSegment aaa",lastSegment)

    const headerText = filteredData.length > 0 ? filteredData[0].headerText : "";
    const headerDescription = filteredData.length > 0 ? filteredData[0].headerDescription : "";


    return (
        <div class="aem-Grid aem-Grid--12 aem-Grid--default--12" style={{ overflow: 'hidden' }}>
            <div class="layout-container responsivegrid aem-GridColumn aem-GridColumn--default--12" style={{ overflow: 'hidden' }}>
                <div class="aem-Grid aem-Grid--12 aem-Grid--default--12" style={{ overflow: 'hidden' }}>
                        {filteredData.length > 0 && (
                            <div className="container-banner" style={{ padding: '0 5px' }}>
                                <Paper style={bannerStyle}>
                                    <div style={bannerContentStyle}>
                                        <Typography variant="h4">{headerText}</Typography>
                                        <Typography variant="subtitle1">{headerDescription}</Typography>
                                    </div>
                                </Paper>
                            </div>
                        )}

                    <Box sx={{ mt: 5 }} />

                    {currentData.length > 0 ? (
                        <BlogTopicsComponent
                            blogTopicData={currentData}
                            handleClick={handleClick}
                            handlePageChange={handlePageChange}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            totalItems={blogTopicsData?.filter(blog => blog.blog === lastSegment).length}
                        />
                    ) : (
                        <p>No blog posts available for this category.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BlogScreen;
