// dev instance
// const website_URL="http://localhost:4502"
const website_URL = 'https://dev.ovcode.com'
const ovcodeWebServiceBaseURL = "https://ovcodedevapim.ovcode.com/ovcode-website"
const subscriptionKey = "c15e79fa1d304133b9d820f41fd0d5de"
const apiDocURL = "https://dev-docs.ovcode.com"
const ediURL = "https://eid.ovcode.com"
const esignURL = "https://esign.ovcode.com"
const ovcodeWebVerificationBaseURL = "https://ovcodedevapim.ovcode.com/ovp"
// live instance
// const website_URL = 'https://ovcode.com'
// const ovcodeWebServiceBaseURL = "https://ovcodeprodapim.azure-api.net/ovcode-website"
// const subscriptionKey = "d82a0e69c1424e41a002564062912bef"
// const apiDocURL = "https://docs.ovcode.com"

// 
export default {

    domain: {
        url: website_URL,
    },

    ovcode_website_service: {
        url: ovcodeWebServiceBaseURL,
        subscriptionKey: subscriptionKey
    },

    ovcode_verification_service: {
        url: ovcodeWebVerificationBaseURL,
        subscriptionKey: subscriptionKey
    },

    apiDocURL: apiDocURL,

    ediURL : ediURL,

    esignURL : esignURL,

    msal_url : {
        url: website_URL,
    },

    msal_change_password_url : {
        // url: changePassword_URL,
    },

    msal_authConfig:{
        clientId:'31d883ba-e9e9-4c98-9aaf-799830f0780c', 
        knownAuthorities: ["ovcodeuser.b2clogin.com"], 
        authority:'https://ovcodeuser.b2clogin.com/tfp/ovcodeuser.onmicrosoft.com/B2C_1_ovcode_test', 
        redirectUri: website_URL,
        postLogoutRedirectUri: website_URL,
    },
    
    b2c_scope:{ 
        scopes:[
            "https://ovcodeuser.onmicrosoft.com/31d883ba-e9e9-4c98-9aaf-799830f0780c/User" 
        ]
    } 

}