import { Box } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import VerificationBannerComponent from "./verification-banner.component";
import ExploreVerificationComponent from "./explore-verification.component";
import AboutVerificationComponent from "./about-verification.component";
import { useEffect, useState } from "react";
import useVerification from "../../../hooks/useVerification";
import { useLocation } from "react-router-dom";

const VerificationComponent = () => {
    const breakpoints = useWidth();
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);
    const [openScan, setOpenScan] = useState(false);
    const [openVerifiedData, setOpenVerifiedData] = useState(false);
    const [openUnverifiedData, setOpenUnverifiedData] = useState(false);
    const [openUnilabVerifiedDialog, setOpenUnilabVerifiedDialog] = useState(false);

    const { verifyUnilabReference } = useVerification();
    const query = new URLSearchParams(useLocation().search);
    
    const verificationOvi = query.get('ovi');

    const [scannedRef, setScannedRef] = useState(null);

    console.log("verificationOvi", verificationOvi);

    // useEffect(() => {
    //     if (verificationOvi) {
    //         handleVerifyReference(verificationOvi);
    //     }
    // }, [verificationOvi]);

    // const handleVerifyReference = (refData) => {
    //     let oviValue = refData;
    //     verifyUnilabReference(
    //         refData,
    //         oviValue,
    //         setOpenScan,
    //         setOpenVerifiedData,
    //         setOpenUnverifiedData,
    //         setOpenUnilabVerifiedDialog
    //     );
    // };

    return (
        <Box>
            <VerificationBannerComponent />
            
            <Box
                sx={{
                    width: breakpoints === "xs" || breakpoints === "sm" || breakpoints === "md" ? '90%' : '93%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto'
                }}
            >
                <ExploreVerificationComponent />
            </Box>
            <Box
                sx={{
                    width: breakpoints === "xs" || breakpoints === "sm" || breakpoints === "md" ? '90%' : '93%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto'
                }}
            >
                <AboutVerificationComponent />
            </Box>
            {/* {openUnilabVerifiedDialog && 
                <UnilabVerifiedDataDialog 
                    openUnilabVerifiedDialog={openUnilabVerifiedDialog}
                    setOpenUnilabVerifiedDialog={setOpenUnilabVerifiedDialog}
                />
            } */}
        </Box>
    );
};

export default VerificationComponent;
