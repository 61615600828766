import { Box } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import VerifyComponent from "../../landing/verify/verify.component";
import { useEffect, useState } from "react";
import useVerification from "../../../hooks/useVerification";
import { useLocation } from "react-router-dom";
import TobaccoVerifiedDataDialog from "../../dialog/tobacco-verification-dialog.component";

const TobaccoComponent = () => {
    const breakpoints = useWidth();
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);
    const [openScan, setOpenScan] = useState(false);
    const [openVerifiedData, setOpenVerifiedData] = useState(false);
    const [openUnverifiedData, setOpenUnverifiedData] = useState(false);
    const [openTobaccoVerifiedDialog , setOpenTobaccoVerifiedDialog] = useState(false);

    const { verifyTobaccoReference } = useVerification();
    const query = new URLSearchParams(useLocation().search);
    const verificationOvi = query.get('ovi');

    const [scannedRef, setScannedRef] = useState(null);

    console.log("verificationOvi", verificationOvi);

    useEffect(() => {
        if (verificationOvi) {
            handleVerifyReference(verificationOvi);
        }
    }, [verificationOvi]);

    const handleVerifyReference = (refData) => {
        let oviValue = refData;
        verifyTobaccoReference(
            refData,
            oviValue,
            setOpenScan,
            setOpenVerifiedData,
            setOpenUnverifiedData,
            setOpenTobaccoVerifiedDialog
        );
    };

    return (
        <Box>
            <Box
                sx={{
                    width: breakpoints === "xs" || breakpoints === "sm" || breakpoints === "md" ? '90%' : '93%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto'
                }}
            >
                <VerifyComponent />
            </Box>
            {openTobaccoVerifiedDialog  && 
                <TobaccoVerifiedDataDialog 
                    openTobaccoVerifiedDialog={openTobaccoVerifiedDialog }
                    setOpenTobaccoVerifiedDialog={setOpenTobaccoVerifiedDialog}
                />
            }
        </Box>
    );
};

export default TobaccoComponent;
