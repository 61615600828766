import { Box } from "@mui/material";
import VerifyComponent from "../components/landing/verify/verify.component";
import useWidth from "../hooks/useWidth";
import { useLocation, useParams } from "react-router-dom";
import useVerification from "../hooks/useVerification";
import { useEffect, useState } from "react";
import UnilabVerifiedDataDialog from "../components/dialog/unilab-verification-dialog.component";
import { useSnackbar } from "notistack";
import UnverifiedDataDialog from "../components/dialog/unverified-data-dialog.component";

const VerificationScreen = () => {
    const breakpoints = useWidth();
    const enqueueSnackbar = useSnackbar();
    const { verifyUnilabReference } = useVerification();

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const referenceCode = query.get('ovi'); // Extract query parameter
    const { id } = useParams(); // Get route parameters
    const verificationOvi = referenceCode;

    const [openScan, setOpenScan] = useState(false);
    const [openVerifiedData, setOpenVerifiedData] = useState(false);
    const [openVerificationRes, setOpenVerificationRes] = useState(false);
    const [openUnverifiedData, setOpenUnverifiedData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfViewer, setPdfViewer] = useState(null);
    const [openUnilabVerifiedDialog, setOpenUnilabVerifiedDialog] = useState(false);
    const [isViewingDocument, setViewingDocument] = useState(false);

    console.log("Current URL:", window.location.href);
    console.log("Query Parameters:", location.search);
    console.log("Reference Code:", referenceCode);

    const patterns = [/^RL-/, /^BL-/, /^MPL-/];

    useEffect(() => {

        if (referenceCode) {
            console.log("Component Mounted - Initial URL:", window.location.href);
            console.log("Reference Code:", referenceCode);
            console.log("Verification Ovi:", verificationOvi);

            console.log("Component Mounted - Initial URL:", window.location.href);
            console.log("Reference Code:", referenceCode);
            console.log("Verification Ovi:", verificationOvi);

            const isValidCode = patterns.some(pattern => pattern.test(referenceCode))

            if (!isValidCode) {
                setOpenUnverifiedData(true);
                return; // Exit early if the code is invalid
            }

            if (isValidCode && referenceCode && referenceCode !== "undefined") {
                const fetchData = async () => {
                    setIsLoading(true);
                    try {
                        console.log("Before API Call - URL:", window.location.href);
                        console.log("Before API Call - Reference Code:", referenceCode);
                        console.log("Before API Call - Verification Ovi:", verificationOvi);

                        await verifyUnilabReference(referenceCode, verificationOvi, setOpenScan, setOpenVerifiedData, setOpenUnverifiedData, setOpenUnilabVerifiedDialog);

                        console.log("After API Call - URL:", window.location.href);
                    } catch (error) {
                        const errorMessage = error.response ? error.response.data.error : error.message;
                        enqueueSnackbar(errorMessage, {
                            variant: "error",
                            autoHideDuration: 3000
                        });
                    } finally {
                        setIsLoading(false);
                    }
                };

                fetchData();
            }
        }
    }, [referenceCode]);


    return (
        <Box
            sx={{
                width: breakpoints === "xs" || breakpoints === "sm" || breakpoints === "md" ? '90%' : '93%',
                display: 'flex',
                justifyContent: 'center',
                margin: 'auto'
            }}
        >
            <VerifyComponent />

            {openUnilabVerifiedDialog &&
                <UnilabVerifiedDataDialog
                    openUnilabVerifiedDialog={openUnilabVerifiedDialog}
                    setOpenUnilabVerifiedDialog={setOpenUnilabVerifiedDialog}
                    setPdfViewer={setPdfViewer}
                    setViewingDocument={setViewingDocument}
                    openVerifiedData={openVerifiedData}
                    setOpenVerifiedData={setOpenVerifiedData}
                    setOpenVerificationRes={setOpenVerificationRes}
                />
            }
            {openUnverifiedData &&
                <UnverifiedDataDialog breakpoints={breakpoints} openUnverifiedData={openUnverifiedData} setOpenUnverifiedData={setOpenUnverifiedData} />
            }
        </Box>
    );
};

export default VerificationScreen;
