import { Box, Typography } from "@mui/material";
import FAQBannerComponent from "./faq-banner.component";
import FAQAccordionComponent from "./faq-accordion.componnet";
import FAQAccordionUsingOvcodeComponent from "./faq-accordion-using-ovcode.component";
import FAQAccordionTrustServiceComponent from "./faq-accordion-trust-service.component";
import FAQAccordionTechnologySecurityComponent from "./faq-accordion-technology-security.component";
import FAQAccordionNetworkComponent from "./faq-accordion-network.component";
import FAQAccordionSecurtyPrivacyComponent from "./faq-accordion-security-privacy.component";
import FAQAccordionFunctionalitySupportComponent from "./faq-accordion-functionality-support.component";
import FAQAccordionIntegrationComponent from "./faq-accordion-integration.component";
import FAQAccordionOthersComponent from "./faq-accordion-others.component";
import FAQAccordionSupportAssistanceComponent from "./faq-accordion-support-assistance.component";
import FAQAccordionTechnicalComponent from "./faq-accordion-technical.component";
import FAQAccordionAccountAccessComponent from "./faq-accordion-account-access.component";
import FAQAccordionDisruptionComponent from "./faq-accordion-disruption.component";
import FAQAccordionEscalationComponent from "./faq-accordion-escalation.component";
import { useState } from "react";
import FAQAccordionOVCComponent from "./faq-accordion-ovc.component";

function FAQComponent() {



    return (

        <div>
    
            <div style={{marginTop: "36px"}}>
                <FAQBannerComponent />
            </div>

            <div class="resources-container">
            
            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000', fontWeight: 600, textTransform: 'none'}}>
                    About OVCODE Trust Platform 
                </Typography>
            </Box>

            <FAQAccordionComponent />

            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000', fontWeight: 600, textTransform: 'none'}}>
                    Using OVCODE Trust Platform
                </Typography>
            </Box>

            <FAQAccordionUsingOvcodeComponent />

            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000', fontWeight: 600, textTransform: 'none'}}>
                    Trust Service
                </Typography>
            </Box>

            <FAQAccordionTrustServiceComponent />


            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000',fontWeight: 600, textTransform: 'none'}}>
                    Technology and Security 
                </Typography>
            </Box>

            <FAQAccordionTechnologySecurityComponent />

            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000',fontWeight: 600, textTransform: 'none'}}>
                    OVCODE Network
                </Typography>
            </Box>

            <FAQAccordionNetworkComponent/>

            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000', fontWeight: 600, textTransform: 'none'}}>
                   Security and Data Privacy
                </Typography>
            </Box>

            <FAQAccordionSecurtyPrivacyComponent/>

            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000', fontWeight: 600, textTransform: 'none'}}>
                    System Functionality and Support
                </Typography>
            </Box>

            <FAQAccordionFunctionalitySupportComponent/>

            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000', fontWeight: 600, textTransform: 'none'}}>
                    Integration and Customization
                </Typography>
            </Box>

            <FAQAccordionIntegrationComponent />


            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000', fontWeight: 600, textTransform: 'none'}}>
                    Other Relevant Questions
                </Typography>
            </Box>

            <FAQAccordionOthersComponent />

            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000', fontWeight: 600, textTransform: 'none'}}>
                    Support and Assistance
                </Typography>
            </Box>

            <FAQAccordionSupportAssistanceComponent />

            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000', fontWeight: 600, textTransform: 'none'}}>
                    Technical Issues
                </Typography>
            </Box>

            <FAQAccordionTechnicalComponent />

            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000', fontWeight: 600, textTransform: 'none'}}>
                    Account and Access
                </Typography>
            </Box>

            <FAQAccordionAccountAccessComponent />

            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000', fontWeight: 600, textTransform: 'none'}}>
                    Service Disruptions and Downtime
                </Typography>
            </Box>

            <FAQAccordionDisruptionComponent/>

            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000', fontWeight: 600, textTransform: 'none'}}>
                    Escalation and Feedback
                </Typography>
            </Box>

            <FAQAccordionEscalationComponent />

            <Box sx={{mt: 4, mb: 2}}>
                <Typography sx={{fontSize: '34px', textAlign: 'start', margin: 0, color: '#000', fontWeight: 600, textTransform: 'none'}}>
                   Discover OVC
                </Typography>
            </Box>

            <FAQAccordionOVCComponent />

            </div>
        </div>

    )
    
}

export default FAQComponent