import { Box, Grid, Typography } from "@mui/material";
import useWidth from "../../hooks/useWidth";

const ViewRetailTobaccoComponent = ({ verificationName }) => {

    const breakpoints = useWidth();

    return (
        <Box sx={{ padding: 6, pt: breakpoints === 'xs' ? 3 : 1, pb: 0, textAlign: 'start' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: 17 }}><strong>Name:</strong>&nbsp;&nbsp;Classic Smoke Cigarettes</Typography>
                    <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '15px', marginBottom: '15px' }} />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                    <Typography sx={{ fontSize: 17 }}><strong>Quantity:</strong>&nbsp;&nbsp;Each pack contains 20 cigarettes</Typography>
                    <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                    <Typography sx={{ fontSize: 17 }}><strong>Description:</strong>&nbsp;&nbsp;Classic Smoke Cigarettes are made from a blend of premium tobaccos for a smooth, satisfying smoke. Each pack contains 20 cigarettes, crafted with care for a consistent flavor experience.</Typography>
                    <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                    <Typography sx={{ fontSize: 17 }}><strong>Age Restriction:</strong>&nbsp;&nbsp;For adult smokers aged 18 years and above</Typography>
                    <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                    <Typography sx={{ fontSize: 17 }}><strong>Manufactured by:</strong>&nbsp;&nbsp;Generic Tobacco Co.</Typography>
                    <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ViewRetailTobaccoComponent;
