import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import OvcodeRole from "../../../../images/blog/role-of-ovcode.png";


const OvcodeRoleComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                The Role of OVCODE in Securing the Supply Chain
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Tuesday, 16 January 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={OvcodeRole} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In an era where supply chain security is paramount, OVCODE emerges as a powerful
                                solution to safeguard the integrity, traceability, and authenticity of products throughout
                                the supply chain. Here's a comprehensive look at how OVCODE plays a pivotal role in
                                securing the supply chain.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>1. Immutable Traceability:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>OVCODE's Contribution:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE leverages blockchain technology to establish an immutable and transparent ledger
                                        for supply chain transactions. Each step of the supply chain process, from manufacturing to
                                        distribution and retail, is recorded on the blockchain. This creates an unalterable digital trail,
                                        providing stakeholders with a secure and verifiable history of product movements.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>2. Anti-Counterfeiting with eMark:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>OVCODE's Contribution:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE's eMark solution becomes a digital fingerprint for physical products. By embedding
                                        eMark on products, manufacturers can uniquely identify each item, making it tamper-proof
                                        and resistant to counterfeiting. Consumers, distributors, and retailers can easily verify the
                                        authenticity of a product by scanning its eMark, ensuring that they are dealing with genuine
                                        items.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>3. Smart Contracts for Trustworthy Transactions:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>OVCODE's Contribution:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        Smart contracts, powered by OVCODE, automate and secure transactions within the supply
                                        chain. These self-executing contracts ensure that predefined conditions are met before a
                                        transaction is completed. For example, payment can be automatically triggered upon the
                                        successful delivery of goods, reducing the risk of fraud and ensuring trust between parties.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>4. Enhanced Visibility and Accountability:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>OVCODE's Contribution:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE enhances visibility and accountability by providing real-time access to supply chain
                                        data. All stakeholders, including manufacturers, distributors, retailers, and even end
                                        consumers, can access a comprehensive view of the product journey. This transparency
                                        fosters accountability at every stage, discouraging fraudulent activities and ensuring that all
                                        parties adhere to ethical practices.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>5. Securing Documentation with eSign:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>OVCODE's Contribution:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE's eSign solution adds an extra layer of security to supply chain documentation.
                                        Contracts, invoices, and other critical documents can be securely signed and verified using
                                        blockchain-based eSignatures. This not only reduces the risk of document tampering but
                                        also streamlines the documentation process, making it more efficient and secure.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>6. Cross-Border Compliance:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>OVCODE's Contribution:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        For global supply chains, OVCODE ensures compliance with cross-border regulations. The
                                        transparent and verifiable nature of blockchain transactions simplifies compliance
                                        checks, reducing the risk of legal complications. OVCODE's solutions are designed to
                                        facilitate seamless cross-border transactions while maintaining the highest standards of
                                        security.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>7. Real-Time Alerts for Anomalies:</Typography>
                            <Box sx={{ padding: 3, pt: 0, pb: 0, mt: 1 }}>
                                <Grid item xs={12}>
                                    <Typography><b>OVCODE's Contribution:</b></Typography>
                                    <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                        OVCODE's system is equipped with real-time monitoring capabilities. Any anomalies or
                                        deviations from predefined supply chain processes trigger immediate alerts. This
                                        proactive approach allows stakeholders to address issues promptly, preventing potential
                                        disruptions or security breaches.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Conclusion:</Typography>
                            <Typography sx={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                OVCODE's integration into the supply chain goes beyond digitizing processes—it
                                revolutionizes the way we approach security and authenticity. By harnessing the power
                                of blockchain, eMark, eSign, and smart contracts, OVCODE ensures that the supply
                                chain becomes a fortified ecosystem where trust is inherent, traceability is immutable,
                                In an ever-evolving landscape, OVCODE stands as a guardian of integrity, providing
                                supply chain stakeholders with the tools they need to navigate a world where security
                                and trust are non-negotiable
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-cybergems-authenticating-digital-treasure"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        OVCODE and Cybergems: Authenticating the Digital Treasures of Tomorrow
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Discover how OVCODE is revolutionizing the world of digital collectibles with Cybergems, a pioneering implementation of its blockchain technology. 
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                May 06, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-industry-user-experience"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Enhancing User Experience with OVCODE: How Seamless Integration Builds Trust
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Discover how OVCODE revolutionizes user experience by offering seamless integration of its robust digital trust solutions.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                April 02, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default OvcodeRoleComponent