const getProportionalCoords = (currentWidth, currentHeight, origWidth, origHeight, x, y) => {
    let propX = (x * origWidth) / currentWidth
    let propY = (y * origHeight) / currentHeight

    return {x: propX, y: propY}
}

const getReverseProportionCoords = (currentWidth, currentHeight, origWidth, origHeight, x, y, page_number) => {

    console.log("pdfPageRefCurrentWidth getReverseProportionCoords", currentWidth);
    console.log("pdfPageRefCurrentHeight getReverseProportionCoords", currentHeight);
    console.log("pageOrigWidth getReverseProportionCoords", origWidth);
    console.log("pageOrigHeight getReverseProportionCoords", origHeight);
    console.log("x getReverseProportionCoords", x);
    console.log("y getReverseProportionCoords", y);
    console.log("page_number getReverseProportionCoords", page_number);
    
    let propX = (x * currentWidth) / origWidth
    let propY = (y * currentHeight) / origHeight

    console.log("element pdf signee x", propX);
    console.log("element pdf signee y", propY);

    return {x: propX, y: propY}
}

const getProportionalDimens = (currentWidth, currentHeight, origWidth, origHeight, w, h) => {
    let propElWidth = (w * origWidth) / currentWidth
    let propElHeight = (h * origHeight) / currentHeight

    return {w: propElWidth, h: propElHeight}
}

const getReverseProportionDimens = (currentWidth, currentHeight, origWidth, origHeight, w, h) => {
    let propElWidth = (w * currentWidth) / origWidth
    let propElHeight = (h * currentHeight) / origHeight

    return {w: propElWidth, h: propElHeight}
}

const getWidthByPercentage = (percentage, parentWidth) => {
    let adjustedWidth = (percentage / 100) * parentWidth;
    return adjustedWidth
}

export {getProportionalCoords, getReverseProportionCoords, getProportionalDimens, getReverseProportionDimens, getWidthByPercentage}