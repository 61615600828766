import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import OvcodeRole from "../../../../images/blog/blockchain03.jpg";


const HybridApproachComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                OVCODE's Hybrid Approach: <br />  Combining Traditional PKI with Blockchain Innovation
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Tuesday, 02 April  2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={OvcodeRole} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                As digital security demands become more sophisticated, OVCODE's hybrid approach stands out by combining the reliability of traditional Public Key Infrastructure (PKI) with the cutting-edge capabilities of blockchain technology.
                                This hybrid model is pioneering a new standard in data integrity and security, addressing contemporary challenges with a fusion of the best from both worlds.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>The Best of Both Worlds
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE recognizes the strengths that both traditional PKI and blockchain technology bring to the table.
                                By integrating these two, OVCODE creates a robust framework for digital security that leverages the proven authentication mechanisms of PKI along with the decentralized and immutable nature of blockchain.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Enhanced Authentication
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                PKI has been the cornerstone of digital authentication for decades, providing a tried-and-tested method for securing digital identities.
                                OVCODE enhances this further with blockchain's distributed ledgers, offering an additional layer of verification and reducing the potential for fraud.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Immutable Audit Trails
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Blockchain's immutable ledger complements PKI by providing a transparent and unalterable history of transactions.
                                This integration ensures that every action taken on the OVCODE platform is recorded permanently, offering indisputable proof of transactions and interactions.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>OVCODE's Innovation in Security
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                With its hybrid approach, OVCODE not only anticipates the future needs of digital security but also addresses them today.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Adaptive Security for a Dynamic Digital Landscape
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                The digital landscape is constantly evolving, and OVCODE's hybrid system is designed to be inherently adaptive.
                                This flexibility ensures that as new threats emerge, OVCODE’s security protocols can immediately adapt to meet and neutralize them.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Streamlining Compliance Across Borders
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Combining PKI with blockchain allows OVCODE to streamline compliance with international regulations.
                                This is crucial for global businesses that must navigate a complex web of cybersecurity laws and standards.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'center' }}>Secure, Scalable, and Future-Ready with OVCODE
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE’s hybrid approach is more than just a security solution --- it is a commitment to innovation, reliability, and trust in an increasingly digital world. Users and organizations that partner with OVCODE can trust in a secure, scalable, and future-ready platform that is well-equipped to handle both current and future security challenges.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-cybergems-intersecting-paths"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Intersecting Paths - When Blockchain Meets Personal Memories
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Examine the intersection of blockchain technology and personal memorabilia, and how OVCODE's secure platform makes Cybergems a reality.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                May 07, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-industry-decoding-security"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Decoding OVCODE's Security: PKI, Blockchain, and AATL Certified Excellence
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        Unpack the layers of security that OVCODE provides, combining the strength of Public Key Infrastructure (PKI) with the unalterable record-keeping of blockchain technology.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                April 02, 2024
                            </Typography>
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
        </Box>
    )
}

export default HybridApproachComponent