import {useState} from "react"
import VerificationService from "../services/api/verification"
import {useVerifiedDataStore} from "../zustand/useVerifiedDataStore";
import {useSnackbar} from "notistack";
import {blobToBase64} from "../utils/blobToBase64Utils";
import environment from "../environment/environment";
import {useNavigate} from 'react-router-dom';

const useVerification = () => {

    const {setPdfBaseUrlData} = useVerifiedDataStore();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();


    const [success, setSuccess] = useState(false);
    const [isDownloading, setDownloading] = useState(false);
    const [isViewBtnClicked, setViewBtnClicked] = useState(null);
    const [isViewingJSONFile, setViewingJSONFile] = useState(false);
    const [isDocxFile, setDocxFile] = useState(false);
    const [verifying_uploaded_data, setVerifyingUploadedData] = useState(false);
    const [verifying_scanned_data, setVerifyingScannedData] = useState(false);
    const [scannedResult, setScannedResult] = useState(false);
    const [pdfView, setPdfView] = useState(null);
    const [unilabData, setUnilabData] = useState("");
    const [isViewing, setIsViewing] = useState(false);

    const uploadDataMismatch = (hash, setOpenUnverifiedData, setOpenUpload, setOpenMismatchDialog, setOpenVerifiedData, setOpenVerificationRes) => {
        setVerifyingUploadedData(true);

        VerificationService.uploadData(hash).then((res) => {

            setVerifyingUploadedData(false);

            let referenceDataScanned = res.data.data.details.reference;
            let scannedReference = localStorage.getItem("scannedReference");

            if(res.data.success){

                localStorage.setItem("referenceDataScanned", referenceDataScanned);

                if(referenceDataScanned !== scannedReference ) {
                    setOpenUpload(false);
                    setOpenUnverifiedData(false);
                    setOpenMismatchDialog(true);
                    setOpenVerifiedData(false);
                    setOpenVerificationRes(false);
                }else {
                    setOpenUpload(false);
                    setOpenUnverifiedData(false);
                    setOpenMismatchDialog(false);
                    setOpenVerificationRes(false);
                    uploadData(hash, setOpenUpload, setOpenVerifiedData, setOpenUnverifiedData);
                }
            }

        }).catch((error) => {
            console.log("uploadData error xxxx", error);

            setVerifyingUploadedData(false);

            enqueueSnackbar(error.response.data.error, { 
                variant: "error",
                autoHideDuration: 3000
            });

            let unverified_doc = "The reference not verified";

            if (error.response.data.error == unverified_doc && error.response.data.success == false) {
                setOpenUnverifiedData(true);

                localStorage.setItem("isUnverifiedDialog", true);
                localStorage.setItem("isVerifiedDialog", false);

            }

            setOpenUpload(false);
        })
    }
    

    const uploadData = (hash, setOpenUpload, setOpenVerifiedData, setOpenUnverifiedData) => {

        setVerifyingUploadedData(true);

        VerificationService.uploadData(hash).then((res) => {
            console.log("uploadData data xxxx", res);

            setVerifyingUploadedData(false);

            // let verified_data = res.data.data.owner.business;
            let verified_data = res.data.data.owner.project;
            let doc_privacy = res.data.data.privacy;
            let doc_downloadable = res.data.data.is_data_downloadable;
            let reference = res.data.data.details.reference;
            let referenceDataScanned = res.data.data.details.reference;
            let doc_status = res.data.data.status;
            let doc_updatedTime = res.data.data.details.updated_time;

            if (res.data.success) {
                setOpenUpload(false);
                setOpenVerifiedData(true);
                setOpenUnverifiedData(false);
                setSuccess(true);
                
                localStorage.setItem("isScannedDocument", false);
                localStorage.setItem("isVerifiedDialog", true);
                localStorage.setItem("isUnverifiedDialog", false);
                localStorage.setItem("scannedReference", reference);
                localStorage.setItem("scannedInfo", JSON.stringify(verified_data));
                localStorage.setItem("docPrivacy", doc_privacy);
                localStorage.setItem("isDownloadable", doc_downloadable);
                localStorage.setItem("docStatus", doc_status);
                localStorage.setItem("docPublishedDate", doc_updatedTime);
                localStorage.setItem("referenceDataScanned", referenceDataScanned);

                // navigate("/v/" + reference);

            } else {
                setSuccess(false);
                setVerifyingUploadedData(false);
            }

        }).catch((error) => {
            console.log("uploadData error xxxx", error);

            enqueueSnackbar(error.response.data.error, { 
                variant: "error",
                autoHideDuration: 3000
            });

            setVerifyingUploadedData(false);

            let unverified_doc = "The reference not verified";

            if (error.response.data.error == unverified_doc && error.response.data.success == false) {
                setOpenUnverifiedData(true);
                setOpenVerifiedData(false);

                localStorage.setItem("isUnverifiedDialog", true);
                localStorage.setItem("isVerifiedDialog", false);

                // navigate("/v/" + hash);
            }

            setOpenUpload(false);
        })

    }

    const verifyUnilabReference = (reference, oviValue, setOpenScan, setOpenVerifiedData, setOpenUnverifiedData, setOpenUnilabVerifiedDialog) => {


        console.log("referencexxx", reference)

        if(reference){

            setScannedResult(true);
            setOpenVerifiedData(false);
            setOpenUnilabVerifiedDialog(true);
            setOpenUnverifiedData(false);
            setOpenScan(false);
        }

        
        navigate("/verification?ovi=" + oviValue);
           

    }

    const verifyUnilabv2Reference = (reference, setOpenScan, setOpenUnverifiedData, setOpenUnilabDialog) => {

        if(reference){
            setScannedResult(true);
            setOpenUnverifiedData(false);
            setOpenScan(false);
            setOpenUnilabDialog(true);
        }
        
        navigate("/unilab/" + reference);

    }

    const verifyTobaccoReference = (reference, oviValue, setOpenScan, setOpenUnverifiedData, setOpenTobaccoVerifiedDialog) => {

        if(reference){

            setScannedResult(true);
            setOpenUnverifiedData(false);
            setOpenScan(false);
            setOpenTobaccoVerifiedDialog(true);
        }
        
        navigate("/tobacco?ovi=" + oviValue);
           
    }

    const verifyReference = (reference, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData) => {

        VerificationService.verifyReference(reference, setOpenScan).then((res) => {
     
            let verified_data = res.data.data;
            let verified_data_owner = res.data.data.owner.project;
            let doc_downloadable = res.data.data.is_data_downloadable;
            let reference_data = reference;
            let has_proxy =  res.data.data.misc.has_proxy;
            let verification_details = res.data.data.details;

            console.log("has_proxyxx", has_proxy)

            if (res.data.success) {
                setScannedResult(true);
                setOpenVerificationRes(true);
                setOpenUnverifiedData(false);
                setOpenScan(false);

                localStorage.setItem("isScannedDocument", true);
                localStorage.setItem("isVerifiedDialog", true);
                localStorage.setItem("isUnverifiedDialog", false);
                localStorage.setItem("scannedReference", reference);
                localStorage.setItem("scannedInfo", JSON.stringify(verified_data));
                localStorage.setItem("scannedDetails", JSON.stringify(verified_data_owner));
                localStorage.setItem("isDownloadable", doc_downloadable);
                localStorage.setItem("reference_data", reference_data);
                localStorage.setItem("has_proxy", has_proxy);
                localStorage.setItem("verification_details", verification_details);

                navigate("/v/" + reference);
            }

        }).catch((error) => {

              if (error.response) {
                // Response error (e.g., 4xx, 5xx HTTP errors)
                if (error.response.data.error === "The reference not verified" && error.response.data.success === false) {
                    setOpenUnverifiedData(true);
                    setOpenVerificationRes(false);

                    localStorage.setItem("isUnverifiedDialog", true);
                    localStorage.setItem("isVerifiedDialog", false);

                    navigate("/v/" + reference);
                }
            } else if (error.request) {
                // console.error("The request timed out or no response was received.");
                setOpenScan(false);

                enqueueSnackbar("The request timed out. Please try again later.", { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                // console.error("An error occurred while setting up the request:", error.message);
                setOpenScan(false);
                enqueueSnackbar("An error occurred. Please try again later.", { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }



    const verifyOvi = (reference, payload, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData) => {
        VerificationService.verifyOvi(reference, payload).then((res) => {
            if (res.data.success) {
                console.log("verifyOvi res here", res);
          
                // Set states
                setOpenVerificationRes(true);
                setOpenScan(false);
                setOpenUnverifiedData(false);
    
                // Set localStorage items
                let verified_data = res.data.data;
                let verified_data_owner = res.data.data.owner.project;
                let doc_downloadable = res.data.data.is_data_downloadable;
                let reference_data = reference;
                let has_proxy = res.data.data.misc.has_proxy;
                let verification_details = res.data.data.details;
                let status = res.data.data.status
    
                localStorage.setItem("isScannedDocument", true);
                localStorage.setItem("isVerifiedDialog", true);
                localStorage.setItem("isUnverifiedDialog", false);
                localStorage.setItem("scannedReference", reference);
                localStorage.setItem("scannedInfo", JSON.stringify(verified_data));
                localStorage.setItem("scannedDetails", JSON.stringify(verified_data_owner));
                localStorage.setItem("isDownloadable", doc_downloadable);
                localStorage.setItem("reference_data", reference_data);
                localStorage.setItem("has_proxy", has_proxy);
                localStorage.setItem("verification_details", verification_details);
                localStorage.setItem("status", status);

    
                // Navigate after state updates
                setTimeout(() => navigate("/v/" + reference), 0);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.data.error === "The reference not verified" && error.response.data.success === false) {
                    setOpenUnverifiedData(true);
                    setOpenVerificationRes(false);
    
                    localStorage.setItem("isUnverifiedDialog", true);
                    localStorage.setItem("isVerifiedDialog", false);
    
                    setTimeout(() => navigate("/v/" + reference), 0);
                }
            } else if (error.request) {
                setOpenScan(false);
    
                enqueueSnackbar("The request timed out. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                setOpenScan(false);
                enqueueSnackbar("An error occurred. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        });
    };
    

    const verifyOviUnilab = (reference, payload,  setOpenScan, setOpenVerificationRes, setOpenUnverifiedData) => {
        VerificationService.verifyOvi(reference,payload).then((res) => {

            if (res.data.success) {

                setOpenScan(false);
                setOpenVerificationRes(true);
                setOpenUnverifiedData(false);

                let verified_data = res.data.data;
                let verified_data_owner = res.data.data.owner.project;
                let doc_downloadable = res.data.data.is_data_downloadable;
                let reference_data = reference;
                let has_proxy =  res.data.data.misc.has_proxy;
                let verification_details = res.data.data.details;
                let status = res.data.data.status

                localStorage.setItem("isScannedDocument", true);
                localStorage.setItem("isVerifiedDialog", true);
                localStorage.setItem("isUnverifiedDialog", false);
                localStorage.setItem("scannedReference", reference);
                localStorage.setItem("scannedInfo", JSON.stringify(verified_data));
                localStorage.setItem("scannedDetails", JSON.stringify(verified_data_owner));
                localStorage.setItem("isDownloadable", doc_downloadable);
                localStorage.setItem("reference_data", reference_data);
                localStorage.setItem("has_proxy", has_proxy);
                localStorage.setItem("verification_details", verification_details);
                localStorage.setItem("status", status);

                navigate("/unilab/" + reference);
            }

        }).catch((error) => {

            if (error.response) {
                // Response error (e.g., 4xx, 5xx HTTP errors)
                if (error.response.data.error === "The reference not verified" && error.response.data.success === false) {
                    setOpenUnverifiedData(true);
                    setOpenVerificationRes(false);

                    localStorage.setItem("isUnverifiedDialog", true);
                    localStorage.setItem("isVerifiedDialog", false);

                    navigate("/v/" + reference);
                }
            } else if (error.request) {
                // console.error("The request timed out or no response was received.");
                setOpenScan(false);

                enqueueSnackbar("The request timed out. Please try again later.", { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                // console.error("An error occurred while setting up the request:", error.message);
                setOpenScan(false);
                enqueueSnackbar("An error occurred. Please try again later.", { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    const generateSharedKey = (reference, isViewBtn, setViewingDocument, setOpenVerificationRes, setOpenVerifiedData, setPdfViewer) => {
 
        setViewBtnClicked(isViewBtn);
        setDownloading(true);
       
        if (isViewBtn) {
            setViewingDocument(true);
        }
 
        VerificationService.generateSharedKey(reference).then((res) => {
            console.log("generateSharedKey data xxxx", res);
 
            let sharedKey = res.data.data.shared_key;
 
            if (res.data.success) {
                downloadUsingSharedKey(sharedKey, isViewBtn, setViewingDocument, setOpenVerificationRes, setOpenVerifiedData, setPdfViewer);
            }
 
        }).catch((error) => {
            console.log("generateSharedKey error xxxx", error);
 
            setViewingDocument(false);
            setDownloading(false);
            if (error.response) {
                enqueueSnackbar(error.response.statusText, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else if (error.request) {
                // Handle cases where no response was received (possibly a timeout)
                enqueueSnackbar("The request timed out. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                // Handle errors in setting up the request
                enqueueSnackbar("An error occurred. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    const generateSharedKeyData = (reference) => {
        
        VerificationService.generateSharedKey(reference).then((res) => {

            let sharedKey = res.data.data.shared_key;

            if (res.data.success) {
                downloadSharedKey(sharedKey);
            }

        }).catch((error) => {
            // enqueueSnackbar(error.response.data.error, { 
            //     variant: "error",
            //     autoHideDuration: 3000
            // });
            if (error.response) {
                enqueueSnackbar(error.response.statusText, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else if (error.request) {
                enqueueSnackbar("The request timed out. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar("An error occurred. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    const downloadSharedKey = (sharedKey) => {

        let domain = environment.domain.url;
        let jsonUrl = domain + "/view/json";

        setIsViewing(true);
     
        VerificationService.getDownloadedData(sharedKey).then((res) => {

            console.log('res xx', res)
            setIsViewing(false);


            const jsonString = JSON.stringify(res.data);
            setUnilabData(jsonString);
            localStorage.setItem("jsonData", jsonString);
            localStorage.setItem("jsonUrl", jsonUrl);
            
        }).catch((error) => {
            setIsViewing(false);
            // enqueueSnackbar(error.response.data.error, { 
            //     variant: "error",
            //     autoHideDuration: 3000
            // });
            if (error.response) {
                enqueueSnackbar(error.response.statusText, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else if (error.request) {
                enqueueSnackbar("The request timed out. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar("An error occurred. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    const downloadUsingSharedKey = async (sharedKey, isViewBtn, setViewingDocument, setOpenVerificationRes, setOpenVerifiedData, setPdfViewer) => {
        try {
            setDownloading(true);
            if (isViewBtn) {
                setViewingDocument(true);
            }
    
            const res = await VerificationService.downloadUsingSharedKey(sharedKey);

            console.log("downloadUsingSharedKey res here", res)
    
            let mimetype = res.headers["content-type"];
            let splittedFilename = res.headers["content-disposition"].split(";").pop();
            let filename = splittedFilename.split("=").pop();
            let extension = filename.split(".").pop();
            let blob = res.data;
    
            let domain = environment.domain.url;
            let pdfUrl = `${domain}/view/pdf`;
            let jsonUrl = `${domain}/view/json`;
            let textUrl = `${domain}/view/text`;
    
            localStorage.setItem("extension", extension);
    
            if (isViewBtn) {
                setOpenVerificationRes(false);
                setOpenVerifiedData(false);
                handleViewButton(extension, blob, sharedKey, filename, { pdfUrl, jsonUrl, textUrl }, setViewingDocument, setPdfViewer);
            } else {
                handleSaveButton(res.data, mimetype, filename);
            }
            
            setDownloading(false);
        } catch (error) {
            setDownloading(false);
            setViewingDocument(false);
            console.log("error xx",error)
            // enqueueSnackbar(error.response.statusText, { 
            //     variant: "error",
            //     autoHideDuration: 3000
            // });
            // if (error.response) {
            //     enqueueSnackbar(error.response.statusText, {
            //         variant: "error",
            //         autoHideDuration: 3000
            //     });
            // } else if (error.request) {
            //     enqueueSnackbar("The request timed out. Please try again later.", {
            //         variant: "error",
            //         autoHideDuration: 3000
            //     });
            // } else {
            //     enqueueSnackbar("An error occurred. Please try again later.", {
            //         variant: "error",
            //         autoHideDuration: 3000
            //     });
            // }
        }
    };

    function stringToUint8Array(str) {
        const len = str.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = str.charCodeAt(i);
        }
        return bytes;
    }

    function base64ToUint8Array(base64) {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i) & 0xFF;
        }
        return bytes;
    }
    
    const handleViewButton = async (extension, blob, sharedKey, filename, urls, setViewingDocument, setPdfViewer) => {
        let { pdfUrl, jsonUrl, textUrl } = urls;

        console.log("view urls here", urls)
        console.log("view extension here", extension)
        console.log("view blob here", blob)
    
        if (extension === "pdf") {

            // const uint8Array = new Uint8Array([...blob].map(char => char.charCodeAt(0)));

            // Create a Blob from the Uint8Array
            // const resss = new Blob([uint8Array], { type: 'application/pdf' });

            // const uint8Array = new TextEncoder().encode(blob);

            // const blobTest = new Blob([blob], { type: 'application/pdf' });

            // console.log('get blob data aaaa', blobTest)


            // const uint8Array = stringToUint8Array(blob);

            // const blobTest = new Blob([uint8Array], { type: 'application/pdf' });


            const res = await blobToBase64(blob);

            
            setPdfBaseUrlData(res);
            // const pdfData = res;
            // localStorage.setItem("pdfData", pdfData);
            if (res) {
                setViewingDocument(false);
                setPdfView(res);
                setPdfViewer(res)
                // window.open(`${pdfUrl}?document=${filename}`, '_self');
            }
        } else if (extension === "json") {
            const res = await VerificationService.getDownloadedData(sharedKey);
            const jsonString = JSON.stringify(res.data);
            setUnilabData(jsonString);
            localStorage.setItem("jsonData", jsonString);
            if (res.data) {
                setViewingDocument(false);
                window.open(`${jsonUrl}?document=${filename}`, '_self');
            }
        } else if (extension === "txt") {
            const res = await VerificationService.getDownloadedData(sharedKey);
            localStorage.setItem("textData", res.data);
            if (res.data) {
                setViewingDocument(false);
                window.open(`${textUrl}?document=${filename}`, '_self');
            }
        } else if (extension === "docx" || extension === "doc") {
            setViewingDocument(false);
            setDocxFile(true);
            enqueueSnackbar("Document viewer not supported", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    };
    
    const handleSaveButton = (data, mimetype, filename) => {
        const blobData = new Blob([data], { type: mimetype });
        const url = URL.createObjectURL(blobData);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute("download", filename); 
        document.body.appendChild(link);
        link.click();
    };
    

    const getDownloadedData = (sharedKey) => {
        VerificationService.getDownloadedData(sharedKey).then((res) => {
            console.log("getDownloadedData data xxxx", res)

        }).catch((error) => {
            // enqueueSnackbar(error.response.data.error, { 
            //     variant: "error",
            //     autoHideDuration: 3000
            // });
            if (error.response) {
                enqueueSnackbar(error.response.statusText, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else if (error.request) {
                enqueueSnackbar("The request timed out. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar("An error occurred. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }


    return {uploadData, verifyReference, generateSharedKey, downloadUsingSharedKey, getDownloadedData, isDownloading, 
        isViewBtnClicked, isViewingJSONFile, isDocxFile, verifying_uploaded_data, scannedResult, pdfView, unilabData,
        isViewing, generateSharedKeyData, verifyOvi, verifyUnilabv2Reference, verifyTobaccoReference, verifyUnilabReference,
        verifyOviUnilab, uploadDataMismatch}

}

export default useVerification