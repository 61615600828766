import React from "react";
import logo from "../../icons/new-ovcode-reverse.png";
import CloseIcon from "@mui/icons-material/Close";
import {AppBar, Toolbar, Button, Box, IconButton} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ViewerHeader = () => {

    const navigate = useNavigate();

    const handleCloseWindow = (e) => {
        // window.close();
        // window.location.href = '/verification';
        navigate(-1); 
    }

    return (
        <AppBar sx={{position: "fixed", zIndex: 1200, top: 0, left: "auto", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgb(0 0 0 / 27%)",
            right: 0, color: "rgb(255, 255, 255)",  display: "flex", flexDirection: "column", width: "100%", boxSizing: "border-box"}}
        >
            <Toolbar sx={{width: "100%"}}>
                <Button size="large" edge="start" color="inherit" aria-label="menu" sx={{mr: 2, textAlign: "center", textDecoration: "none", display: "inline-block"}} disableRipple>
                    <Box sx={{display: {xs: "none", md: "flex"}}}>
                        <img alt="" src={logo} onClick={(e) => window.location.href="/"} 
                            style={{height: 45, verticalAlign: "middle", cursor: "pointer", imageRendering: "pixelated", imageRendering: "-webkit-optimize-contrast"}}
                        />
                    </Box>
                
                    {/* mobile view */}
                    <Box sx={{display: {xs: "flex", md: "none"}}}>
                        <img alt="" src={logo} onClick={(e) => window.location.href="/"}
                            style={{height: 30, verticalAlign: "middle", cursor: "pointer"}}
                        />
                    </Box>
                </Button>
          
                <Box sx={{flexGrow: 1}} />

                <IconButton onClick={(e) => handleCloseWindow(e)}>
                    <CloseIcon />
                </IconButton> 
            </Toolbar>
        </AppBar>
    )
}

export default ViewerHeader