import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PublicClientApplication} from "@azure/msal-browser";
import {msalconfig} from './config';
import { SnackbarProvider } from 'notistack';
import { MsalProvider } from '@azure/msal-react';

const msalInstance = new PublicClientApplication(msalconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

if(window.location.hash !== '' ){
  console.log('errr msal')
}else {
  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </MsalProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();