import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const PermissionDeniedDialog = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1}}>
            <DialogTitle>Permission Denied</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Camera or location permissions have been denied. Please allow camera or location access in your browser settings to scan QR codes.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PermissionDeniedDialog;
