import testlogo from '../../../icons/eMarkSuperFinalLogoSize.png';
import ovsign from '../../../icons/eSignSuperFinalLogoSize.png';
import ovi from '../../../icons/eIDSuperFinalLogoSize.png';
import environment from "../../../environment/environment";
import { useState } from "react";


function SolutionsShowCaseComponent(props) {

    const { breakpoints } = props;
    const apiDocURL = environment.apiDocURL

    console.log("breakpoints", breakpoints)

    const [openOVSIGN, setOpenOVSIGN] = useState(false);
    const [openeID, setOpeneID] = useState(false);

    const handleOpenOVSIGN = () => {
        setOpenOVSIGN(true);
    }

    const handleCloseOVSIGNs = () => {
        setOpenOVSIGN(false);
    }

    const handleOpeneID = () => {
        setOpeneID(true);
    }

    const handleCloseeID = () => {
        setOpeneID(false);
    }

    return (
        <div class="link-list parent-list aem-GridColumn aem-GridColumn--default--12">
            <nav data-componentname="link-list-uid905a" aria-label="product categories">
                <ul class="text-center d-flex flex-wrap list-inline justify-content-center">
                    <li class="font-weight-semibold mx-4 my-3 d-flex align-items-center card flex-grow-0  "
                        data-automation-test-id="LinkListItem0-link-list-uid905a" aria-expanded="false"
                        clickgroup-telemetry-enabled="" data-close-label="Close dialog"
                        aria-controls="listitempopoverxa0d472c0cb444d8191e13213146f69d1" data-placement="bottom">
                        <img src={ovi}
                            loading="lazy" width="80"
                            height="80" alt="" class="img-fluid flex-shrink-0 mb-2" data-bi-cn="Experience eID"
                            data-bi-ecn="Experience eID" data-bi-bhvr="0" data-bi-ct="CTA" data-bi-pa="Body"
                            data-bi-compnm="Link List" />
                        <a href="https://eid.ovcode.com" aria-label="Experience eID"
                            target="_self" data-bi-cn="Experience eID" data-bi-ecn="Experience eID"
                            data-bi-bhvr="0" data-bi-ct="Link" data-bi-pa="Body" data-bi-compnm="Link List"
                            data-target-uri="https://eid.ovcode.com">
                            Discover eID
                            <span class="glyph" aria-hidden="true"></span>
                        </a>
                    </li>

                    <li class="font-weight-semibold mx-4 my-3 d-flex align-items-center card flex-grow-0  "
                        aria-expanded="false" clickgroup-telemetry-enabled="" data-close-label="Close dialog"
                        aria-controls="listitempopoverx687df4f49869465f9d105ba1ab7dcc29" data-placement="bottom">
                        <img src={ovsign} loading="lazy" width="80"
                            height="80" alt="" class="img-fluid flex-shrink-0 mb-2" data-bi-cn="Experience eSign"
                            data-bi-ecn="Experience eSign" data-bi-bhvr="0" data-bi-ct="CTA" data-bi-pa="Body"
                            data-bi-compnm="Link List" />
                        <a href="https://esign.ovcode.com" aria-label="Experience eSign"
                            target="_self" data-bi-cn="Experience eSign" data-bi-ecn="Experience eSign" data-bi-bhvr="0"
                            data-bi-ct="Link" data-bi-pa="Body" data-bi-compnm="Link List"
                            data-target-uri="https://esign.ovcode.com">
                            Use eSign
                            <span class="glyph" aria-hidden="true"></span>
                        </a>
                    </li>

                    <li class="font-weight-semibold mx-4 my-3 d-flex align-items-center card flex-grow-0" aria-expanded="false"
                        clickgroup-telemetry-enabled="" data-close-label="Close dialog"
                        aria-controls="listitempopoverxbc0585204b1147bca8b4d4bcdca77282" data-placement="bottom">
                        <img src={testlogo} loading="lazy" width="80"
                            height="80" alt="" class="img-fluid flex-shrink-0 mb-2" data-bi-cn="xplore eMark"
                            data-bi-ecn="xplore eMark" data-bi-bhvr="0" data-bi-ct="CTA" data-bi-pa="Body"
                            data-bi-compnm="Link List" />
                        <a href={apiDocURL}
                            aria-label="xplore eMark"
                            target="_self" data-bi-cn="xplore eMark" data-bi-ecn="xplore eMark" data-bi-bhvr="0"
                            data-bi-ct="Link" data-bi-pa="Body" data-bi-compnm="Link List"
                            data-target-uri={apiDocURL}>
                            Explore eMark
                            <span class="glyph" aria-hidden="true"></span>
                        </a>
                    </li>

                </ul>
            </nav>
        </div>
    )

}

export default SolutionsShowCaseComponent