
import {Tab, Tabs} from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomTabs = styled(Tabs)({
    
    '& .MuiTab-root': {
      padding: '5px 15px 5px 15px',
      minHeight: 5,
      textTransform: 'none',

      '&.Mui-selected': {
        color: "#fff",
        backgroundColor: '#0C306F',
      },
      ':hover': {
        color: "#fff",
        backgroundColor: '#9a9a9a',
      },
    },
        
  });

  const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    minWidth: 'auto', bgcolor: '#0C306F', borderRadius: 16, marginRight: 10
  }));

export { CustomTabs, CustomTab };