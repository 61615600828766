import { Box, Grid, Typography, Link } from "@mui/material";
import { useState } from "react";
import environment from "../../environment/environment";
import BackToTopButton from "../layout/scroll-up.component";
import Chatbot from "../layout/chatbot.component";


function FooterComponent() {


    const apiDocURL = environment.apiDocURL
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);


    return (
        <footer class="c-uhff context-uhf"  >

            <div class="container">
                { isChatbotOpen ?
                    null
                :
                    <BackToTopButton isChatbotOpen={isChatbotOpen} />
                }
                <Chatbot setIsChatbotOpen={setIsChatbotOpen} />
            </div>

            {/* <nav class="c-uhff-nav" aria-label="SocMed" style={{ textAlign: 'start', marginTop: '-10px' }}>
                <div class="soc-widget" style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ color: '#616161', fontSize: 15, marginLeft: '10px', marginRight: '15px',height: 1,fontWeight: 600 }}>Follow OVCODE</p>
                    <Link target="_blank" href="https://www.facebook.com/OVCodeSwitzerlandAG/" underline="none">
                        <i><FontAwesomeIcon icon={faFacebook} /></i>
                    </Link>
                    <Link href="https://www.youtube.com/channel/UC4658E4JwgY3IFdqqohZBWw">
                        <i><FontAwesomeIcon icon={faYoutube} /></i>
                    </Link>
                    <Link target="_blank" href="https://twitter.com/OVCode">
                        <i><FontAwesomeIcon icon={faTwitter} /></i>
                    </Link>
                </div>
            </nav> */}

            <nav class="c-uhff-nav" aria-label="Footer Resource links">
                <div class="c-uhff-nav-row" style={{ textAlign: 'start' }}>
                    <div class="c-uhff-nav-group">
                        <div class="c-heading-4" role="heading" aria-level="2" style={{padding: '6px 0 4px'}}>Offices</div>
                        <ul class="c-list f-bare" >

                            <li>
                                <a class="c-uhff-link"> Talstrasse, Zürich, Switzerland</a>
                            </li>

                            <li class="c-uhff-link">
                                <a class="c-uhff-link">Makati City, Philippines</a>
                            </li>

                            <li class="c-uhff-link">
                                <a class="c-uhff-link">Cebu City, Philippines</a>
                            </li>

                        </ul>

                    </div>
                </div>

                <div class="c-uhff-nav-row" style={{ textAlign: 'start' }}>
                    <div class="c-uhff-nav-group">
                        <div class="c-heading-4" role="heading" aria-level="2"  style={{padding: '6px 0 4px'}} >Our Business</div>
                        <ul class="c-list f-bare" >

                            <li>
                                <a class="c-uhff-link" href="#about-us-section" >About Us</a>
                            </li>

                            <li class="c-uhff-link">
                                <a class="c-uhff-link" href="#industry-section" >Industry</a>
                            </li>

                            <li class="c-uhff-link">
                                <a class="c-uhff-link" href="#awards-section" >Awards and Patents</a>
                            </li>

                            <li class="c-uhff-link">
                                <a class="c-uhff-link" href="#partners-section" >Partners</a>
                            </li>
                        </ul>

                    </div>
                </div>

                <div class="c-uhff-nav-row" style={{ textAlign: 'start' }}>
                    <div class="c-uhff-nav-group">
                        <div class="c-heading-4" role="heading" aria-level="2"  style={{padding: '6px 0 4px'}}>Technology</div>
                        <ul class="c-list f-bare" >

                            <li>
                                <a aria-label="Verify" class="c-uhff-link"
                                    href="/verificationv">
                                    Verify
                                </a>
                            </li>

                            <li class="c-uhff-link">
                                <a class="c-uhff-link" href="#solutions-section" >Solutions</a>
                            </li>

                            <li class="c-uhff-link">
                                <a class="c-uhff-link" href="#features-section" >Features and Benefits</a>
                            </li>

                            <li class="c-uhff-link">
                                <a class="c-uhff-link" href="#implementation-section" >Implementation</a>
                            </li>

                        </ul>

                    </div>
                </div>

                <div class="c-uhff-nav-row" style={{ textAlign: 'start' }}>
                    <div class="c-uhff-nav-group">
                        <div class="c-heading-4" role="heading" aria-level="2"  style={{padding: '6px 0 4px'}}>Products</div>
                        <ul class="c-list f-bare" >

                            <li>
                                <a aria-label="Electronic Identification (eID)" class="c-uhff-link"
                                    href="/electronic-identification">
                                    Electronic Identification (eID)
                                </a>
                            </li>

                            <li>
                                <a aria-label="Electronic Signature (eSign)" class="c-uhff-link"
                                    href="/electronic-signature">
                                    Electronic Signature (eSign)
                                </a>
                            </li>

                            <li>
                                <a aria-label="Electronic Mark (eMark)" class="c-uhff-link"
                                    href="/electronic-mark">
                                    Electronic Mark (eMark)
                                </a>
                            </li>

                        </ul>

                    </div>
                </div>

                <div class="c-uhff-nav-row" style={{ textAlign: 'start' }}>
                    <div class="c-uhff-nav-group">
                        <div class="c-heading-4" role="heading" aria-level="2"  style={{padding: '6px 0 4px'}}>What's New</div>
                        <ul class="c-list f-bare">

                            <li>
                                <a aria-label="Version Histories" class="c-uhff-link"
                                    href="/version-histories">
                                    Versions
                                </a>
                            </li>

                            <li>
                                <a aria-label="Electronic Mark (eMark)" class="c-uhff-link" href={apiDocURL}>
                                    API Documentation
                                </a>
                            </li>



                        </ul>

                    </div>
                </div>

                <div class="c-uhff-nav-row" style={{ textAlign: 'start' }}>
                    <div class="c-uhff-nav-group">
                        <div class="c-heading-4" role="heading" aria-level="2"  style={{padding: '6px 0 4px'}}>Contact Us</div>
                        <ul class="c-list f-bare">

                            <li>
                                <a aria-label="Enterprise Support" class="c-uhff-link"
                                    href="/join">
                                    Join OVCODE
                                </a>
                            </li>

                            <li>
                                <a aria-label="Enterprise Support" class="c-uhff-link"
                                    href="/enterprise-support">
                                    Enterprise Support
                                </a>
                            </li>
                            <li>
                                <a aria-label="Product Support" class="c-uhff-link"
                                    href="/product-support">
                                    Product Support
                                </a>
                            </li>
                            <li>
                                <a aria-label="Product Support" class="c-uhff-link"
                                    href="/product-support">
                                    Sales and Marketing
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>

            <div class="c-uhff-base" style={{ marginTop: '120px', padding: '30px 11.5% 16px' }}>
                <nav aria-label="OVCODE  links">
                    <ul class="c-list f-bare">

                        <li id="c-uhff-footer_contactus">
                            <a class="c-uhff-link" href="/contact-us">
                                Contact Us</a>
                        </li>

                        <li id="c-uhff-footer_privacyandcookies">
                            <a class="c-uhff-link" href="/privacy-policy">
                                Privacy
                            </a>
                        </li>

                        <li id="c-uhff-footer_termsofuse">
                            <a class="c-uhff-link" href="/terms-of-service">
                                Terms of use
                            </a>
                        </li>

                        <li class=" x-hidden" id="c-uhff-footer_managecookies">
                            <a class="c-uhff-link" href="/faq">
                                FAQ
                            </a>
                        </li>

                        <li>©2023 All Rights Reserved</li>

                    </ul>
                </nav>
            </div>

        </footer>




    )

}

export default FooterComponent