import environment from "../../../environment/environment";
import routes from "../../api/common/api-routes";
import {CustomHttpPost, CustomHttpPostParams, CustomHttpGet, HttpDownloadFile, CustomHttpGetParams, HttpPost, HttpGet, HttpGet1, HttpGetBlob} from "../common/http-request-builder";

const SUBSCRIPTION_KEY = environment.ovcode_website_service.subscriptionKey;

const uploadData = (hash) => {

    let query_params = null
    
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPost(routes.HASH_VERIFICATION  + "?hash=" + hash, query_params, subscriptionKey);

    return result;
}

const verifyReference = (reference) => {

    let query_params = null

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.VERIFY_REFERENCE + "?reference=" + reference, query_params, subscriptionKey);

    return result;

}

const verifyOvi = (reference, payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpPost(routes.VERIFY_OVI + "?reference=" + reference, payload, subscriptionKey);

    return result;

}

const generateSharedKey = (reference) => {
    let query_params = null

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet(routes.GENERATE_SHARED_KEY + "?reference=" + reference, query_params, subscriptionKey);

    return result;
}

const downloadUsingSharedKey = (sharedKey) => {

    let query_params = null

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGetBlob(routes.DOWNLOAD_USING_SHARED_KEY, query_params, sharedKey, subscriptionKey);

    return result;
}

const getDownloadedData = (sharedKey) => {

    let query_params = null

    let subscriptionKey = SUBSCRIPTION_KEY;
    let result = HttpGet1(routes.DOWNLOAD_USING_SHARED_KEY , query_params, sharedKey, subscriptionKey);

    return result;
}


const VerificationService = {
    uploadData,
    verifyReference,
    generateSharedKey,
    downloadUsingSharedKey,
    getDownloadedData,
    verifyOvi
}

export default VerificationService