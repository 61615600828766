import React, { useState, useEffect } from "react";
import { Backdrop, ListItem, Link, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, Box, Grid, ListItemText, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OVCodeLogo from "../../icons/new-ovcode-logo.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import useVerification from "../../hooks/useVerification";
import { ThreeDots } from "react-loader-spinner";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import secureDataLogo from '../../icons/Unilab.png'

const VerifiedDataDialog = (props) => {

    const { openVerifiedData, setOpenVerifiedData, breakpoints, setOpenUpload, setPdfViewer, setOpenVerificationRes } = props;

    const { isDownloading, isViewBtnClicked, isDocxFile, generateSharedKey, scannedResult } = useVerification();


    const [isVerifyingReference, setVerifyingReference] = useState(true);
    const [isVerifyingHash, setVerifyingHash] = useState(true);
    const [isViewingDocument, setViewingDocument] = useState(false);

    let isScannedDocument = localStorage.getItem("isScannedDocument");
    let reference_data = localStorage.getItem("reference_data");

    let unparse_info = localStorage.getItem("scannedInfo");
    let scannedInfo;

    const scannedReference = localStorage.getItem("scannedReference");
    const docPrivacy = localStorage.getItem("docPrivacy");
    const isDownloadable = localStorage.getItem("isDownloadable");
    const docStatus = localStorage.getItem("docStatus");
    const docPublishedDate = localStorage.getItem("docPublishedDate");

    console.log("scannedResultxx", isScannedDocument)
    console.log("scannedResultxx verified data here xxx", isScannedDocument)


    if (unparse_info) {
        scannedInfo = JSON.parse(unparse_info);
    }

    useEffect(() => {
        setTimeout(() => {
            setVerifyingReference(false);
            setVerifyingHash(false);
        }, 4000)

        localStorage.removeItem("pdfBase64Data");
        localStorage.removeItem("jsonData");
        localStorage.removeItem("pdfUrl");
        localStorage.removeItem("jsonUrl");
        localStorage.removeItem("textData");
        localStorage.removeItem("textUrl");


    }, [])

    const handleCloseVerifyDataDialog = (e) => {
        localStorage.removeItem("pdfBase64Data");
        localStorage.removeItem("jsonData");
        localStorage.removeItem("pdfUrl");
        localStorage.removeItem("jsonUrl");
        localStorage.removeItem("textData");
        localStorage.removeItem("textUrl");

        localStorage.removeItem("scannedReference");
        localStorage.removeItem("scannedInfo");
        localStorage.removeItem("docPrivacy");
        localStorage.removeItem("isDownloadable");
        localStorage.removeItem("docStatus");
        localStorage.removeItem("docPublishedDate");
        localStorage.removeItem("isScannedDocument");

        localStorage.removeItem("referenceDataScanned");
        localStorage.removeItem("isComingFromUploadData");

        setOpenVerifiedData(localStorage.setItem("isVerifiedDialog", false));

        localStorage.removeItem("isVerifiedDialog");
        localStorage.removeItem("isUnverifiedDialog");

        // window.location.href = "/verify";
        window.location.href = "/verification";

    }


    const handleUploadData = (e) => {
        localStorage.removeItem("pdfBase64Data");
        localStorage.removeItem("jsonData");
        localStorage.removeItem("pdfUrl");
        localStorage.removeItem("jsonUrl");
        localStorage.removeItem("textData");
        localStorage.removeItem("textUrl");

        // localStorage.removeItem("scannedReference");
        localStorage.removeItem("scannedInfo");
        localStorage.removeItem("docPrivacy");
        localStorage.removeItem("isDownloadable");
        localStorage.removeItem("docStatus");
        localStorage.removeItem("docPublishedDate");
        localStorage.removeItem("isScannedDocument");

        (localStorage.setItem("isVerifiedDialog", false));

        localStorage.removeItem("isVerifiedDialog");
        localStorage.removeItem("isUnverifiedDialog");

        localStorage.setItem("isComingFromUpload", true);
        localStorage.setItem("isComingFromUploadData", true);


        window.location.href = "/verification";

    }

    const handleDownloadDocument = (e) => {
        let isViewBtn = false;
        generateSharedKey(scannedReference, isViewBtn, setViewingDocument,  setOpenVerificationRes, setOpenVerifiedData, setPdfViewer);
    }

    const handleViewDocument = (e) => {
        const storedJsonData = localStorage.getItem("jsonData");
        const storedTextData = localStorage.getItem("textData");
        let isViewBtn = true;
        generateSharedKey(scannedReference, isViewBtn, setViewingDocument,  setOpenVerificationRes, setOpenVerifiedData, setPdfViewer);
    }

    const isScannedDocumentBoolean = isScannedDocument === "true";

    const CustomDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: "10px 16px 10px 16px" }} {...other}>
                {children}

                {onClose ?
                    (
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: "absolute", right: 8, top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    )
                    :
                    null
                }
            </DialogTitle>
        )
    }


    return (
        <Dialog
            maxWidth={"sm"}
            fullWidth
            open={openVerifiedData}
            onClose={(e) => handleCloseVerifyDataDialog(e)}
            sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1}}
        >

            {(isVerifyingReference || isVerifyingHash) ?
                <DialogContent>
                    <Box sx={{ borderRadius: "4px", display: "flex", background: "#0178f240", height: "50px", padding: "12px" }}>
                        <Typography variant="subtitle1" sx={{ color: "#0178f2c7", fontSize: 16 }}>
                            Verifying document...
                        </Typography>
                    </Box>
                </DialogContent>

                :
                <>

                    <CustomDialogTitle id="customized-dialog-title" onClose={(e) => handleCloseVerifyDataDialog(e)}>
                        {(scannedInfo) ?
                            <img alt="" 
                            src={ reference_data === 'S-cb906166-b6a2-4c77-a44e-8c9e94bd5506' || reference_data === 'S-19953587-bce9-4181-a816-b5a9ebf2d7ea' || reference_data === 'S-d86751cf-57d5-43e7-b3ff-5d645a454b83'  ? 
                                secureDataLogo : scannedInfo?.logo  
                            }
                             style={{ width: (breakpoints == "xs" || breakpoints == "sm") ? 100 : {}, height: 55, objectFit: "contain", marginTop: (breakpoints == "xs" || breakpoints == "sm") ? "-8px" : {} }} />
                            :
                            <Avatar round={true} name={scannedInfo?.name} style={{ marginRight: 60 }} maxInitials={2} />

                        }
                    </CustomDialogTitle>


                    <DialogContent dividers sx={{ padding: 0 }}>
                        {console.log("isScannedDocument", isScannedDocumentBoolean)}

                        {isScannedDocumentBoolean ? (
                            <>
                                
                                {console.log("docStatus here aa", docStatus)}

                                <Box
                                   sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: docStatus === 'REVOKED' ? 'red' : docStatus === 'SUSPENDED' ? 'orange' : '#1bad1bbf',
                                    height: docStatus === 'ACTIVE' ? '70px' : '50px',
                                    padding: '12px',
                                    textAlign: 'center', 
                                    
                                  }}
                                >
                                    <Grid container spacing={1} alignItems="center" sx={{textAlign: 'center', justifyContent: 'center'}}>
                                        <Grid item sx={{ justifyContent: 'start', textAlign: 'start'}}>
                                            {docStatus == "REVOKED" ? <HighlightOffIcon style={{ color: "#fff", marginTop: "4px", marginRight: "-3px" }} /> : docStatus == "SUSPENDED" ? <NotInterestedIcon style={{ color: "#fff", marginTop: "4px", marginRight: "-3px" }} /> : <CheckCircleIcon style={{ color: "#fff", marginTop: "4px", marginRight: "-3px" }} /> }
                                        </Grid>
                                        <Grid item sx={{textAlign: 'center', justifyContent: 'center'}}> 
                                            <Typography
                                                variant="subtitle1"
                                                sx={{
                                                    fontWeight: docStatus === 'ACTIVE' ? 600 : 500,
                                                    color: '#fff',
                                                    fontSize: 17,
                                                    textAlign: 'center !important',
                                                }}
                                            >
                                                {docStatus === 'REVOKED' ? 'DATA IS REVOKED' : docStatus === 'SUSPENDED' ? 'DATA IS SUSPENDED' : 'DATA FOUND'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box sx={{ padding: '2px', textAlign: 'center', mt: 0.5 }}>
                                    <Typography sx={{ color: 'orange', fontSize: 14 }}>
                                        The data reference was found. <br/> Please upload a digital copy of an authenticated document for proper verification.
                                    </Typography>
                                </Box>


                                {(isDownloadable == "true" && (docStatus !== "REVOKED" && docStatus !== "SUSPENDED")) ?
                                    <Grid container direction="row" sx={{ padding: (theme) => theme.breakpoints.down('sm') ? '8px 25px' : '8px 40px', mb: '-23px' }}>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={6} >
                                                    <Typography sx={{ fontSize: '15px', fontWeight: 'bold', mt: '7px' }}>
                                                        { reference_data === 'S-cb906166-b6a2-4c77-a44e-8c9e94bd5506' || reference_data === 'S-19953587-bce9-4181-a816-b5a9ebf2d7ea' || reference_data === 'S-d86751cf-57d5-43e7-b3ff-5d645a454b83'  ? 'UNILAB'  : scannedInfo?.name}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Button
                                                        disabled={isDocxFile || isViewingDocument}
                                                        variant="contained"
                                                        sx={{
                                                            mt: '7px',
                                                            width: isViewingDocument ? 'auto' : 80,
                                                            textTransform: 'none',
                                                            borderRadius: '16px',
                                                            background: isViewingDocument ? '#808080c9' : '#008000c4',
                                                            cursor: isViewingDocument ? 'no-drop' : 'pointer',
                                                            '&:hover': { background: isViewingDocument ? '#808080c9' : '#008000c4' },
                                                            '&.Mui-disabled': {
                                                                background: '#008f007a',
                                                                color: '#fff',
                                                            },
                                                        }}
                                                        startIcon={isViewingDocument ? <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> : <VisibilityIcon />}
                                                        onClick={handleViewDocument}
                                                    >
                                                        View
                                                    </Button>

                                                    &nbsp;

                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            mt: '7px',
                                                            width: isDownloading && !isViewBtnClicked ? 'auto' : 80,
                                                            borderRadius: '16px',
                                                            textTransform: 'none',
                                                            background: '#008000c4',
                                                            cursor: isDownloading && !isViewBtnClicked ? 'no-drop' : 'pointer',
                                                            '&:hover': { background: '#008000c4' },
                                                        }}
                                                        startIcon={isDownloading && !isViewBtnClicked ? <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> : <DownloadIcon />}
                                                        onClick={handleDownloadDocument}
                                                    >
                                                        {isDownloading && !isViewBtnClicked ? 'Saving' : 'Save'}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={6} sx={{ display: scannedReference ? 'visible' : 'none', width: '100%', wordWrap: 'break-word' }}>
                                                    <Typography sx={{ fontSize: '15px', fontWeight: 'bold', mt: '7px' }}>
                                                        OVCODE:
                                                    </Typography>
                                                    {scannedReference}
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Typography sx={{ fontSize: '15px', fontWeight: 'bold', mt: '7px' }}>
                                                        {docPublishedDate ? 'Published Date:' : null}
                                                    </Typography>
                                                    {docPublishedDate}
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Button
                                                        disabled={isDocxFile || isViewingDocument}
                                                        variant="contained"
                                                        sx={{
                                                            mt: '7px',
                                                            mb: '7px',
                                                            width: isViewingDocument ? 'auto' : 'auto',
                                                            textTransform: 'none',
                                                            borderRadius: '16px',
                                                            background: isViewingDocument ? '#808080c9' : '#008000c4',
                                                            cursor: isViewingDocument ? 'no-drop' : 'pointer',
                                                            '&:hover': { background: isViewingDocument ? '#808080c9' : '#008000c4' },
                                                            '&.Mui-disabled': {
                                                                background: '#008f007a',
                                                                color: '#fff',
                                                            },
                                                        }}
                                                        onClick={handleUploadData}
                                                    >
                                                        Upload Data
                                                    </Button>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    :

                                    <Grid container direction="row" sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? "8px 25px" : "8px 40px", mb: "-23px" }}>
                                        <Grid item xs={6}>
                                            <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                                                {scannedInfo?.name}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sx={{ mt: '10px' }}>
                                            <Grid container>
                                                <Grid item xs={(scannedInfo?.name == null || scannedInfo?.id == null) ? 12 : 6} sx={{ display: (scannedReference == "null" || scannedReference == "undefined") ? "none" : "visible", width: '100%', wordWrap: 'break-word' }}>
                                                    <ListItemText
                                                        primary={
                                                            <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                                                                OVCODE:
                                                            </Typography>
                                                        }
                                                        secondary={scannedReference && scannedReference}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <ListItemText
                                                        primary={
                                                            <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                                                                {(docPublishedDate) ? "Published Date:" : null}
                                                            </Typography>
                                                        }
                                                        secondary={docPublishedDate}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        disabled={isDocxFile || isViewingDocument}
                                                        variant="contained"
                                                        sx={{
                                                            mt: '7px',
                                                            mb: '7px',
                                                            width: isViewingDocument ? 'auto' : 'auto',
                                                            textTransform: 'none',
                                                            borderRadius: '16px',
                                                            background: isViewingDocument ? '#808080c9' : '#008000c4',
                                                            cursor: isViewingDocument ? 'no-drop' : 'pointer',
                                                            '&:hover': { background: isViewingDocument ? '#808080c9' : '#008000c4' },
                                                            '&.Mui-disabled': {
                                                                background: '#008f007a',
                                                                color: '#fff',
                                                            },
                                                        }}
                                                        onClick={handleUploadData}
                                                    >
                                                        Upload Data
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </>
                        ) : (
                            <>
                                <Box sx={{ justifyContent: "center", display: "flex", background: docStatus == 'REVOKED' ? "red" : docStatus == 'SUSPENDED' ? "orange" : "#1bad1bbf", height: "50px", padding: "12px" }}>
                                    <IconButton>
                                        {docStatus == "REVOKED" ? <HighlightOffIcon style={{ color: "#fff", marginTop: "4px", marginRight: "-3px" }} /> : docStatus == "SUSPENDED" ? <NotInterestedIcon style={{ color: "#fff", marginTop: "4px", marginRight: "-3px" }} /> : <CheckCircleIcon style={{ color: "#fff", marginTop: "4px", marginRight: "-3px" }} />}
                                    </IconButton>
                                    <Typography variant="subtitle1" sx={{ color: "#fff", fontSize: 17 }}>
                                        {docStatus == 'REVOKED' ? 'DATA IS REVOKED ' : docStatus == 'SUSPENDED' ? 'DATA IS SUSPENDED ' : 'DATA IS VERIFIED '}
                                    </Typography>
                                </Box>

                                {(isDownloadable == "true" && (docStatus !== "REVOKED" && docStatus !== "SUSPENDED")) ?
                                    <Grid container direction="row" sx={{ padding: (theme) => theme.breakpoints.down('sm') ? '8px 25px' : '8px 40px', mb: '-23px' }}>

                                        <Grid item xs={6} >
                                            <Typography sx={{ fontSize: '15px', fontWeight: 'bold', mt: '7px' }}>
                                                {scannedInfo?.name}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6} sx={{ display: scannedReference ? 'visible' : 'none', width: '100%', wordWrap: 'break-word' }}>
                                            <Typography sx={{ fontSize: '15px', fontWeight: 'bold', mt: '7px' }}>
                                                OVCODE:
                                            </Typography>
                                            {scannedReference}
                                        </Grid>

                                        <Grid item xs={6} sx={{ mt: 1 }}>
                                            <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                                {docPublishedDate ? 'Published Date:' : null}
                                            </Typography>
                                            {docPublishedDate}
                                        </Grid>

                                        <Grid item xs={6} sx={{ mt: 1 }}>
                                            <Button
                                                disabled={isDocxFile || isViewingDocument}
                                                variant="contained"
                                                sx={{
                                                    mt: '7px',
                                                    width: isViewingDocument ? 'auto' : 80,
                                                    textTransform: 'none',
                                                    borderRadius: '16px',
                                                    background: isViewingDocument ? '#808080c9' : '#008000c4',
                                                    cursor: isViewingDocument ? 'no-drop' : 'pointer',
                                                    '&:hover': { background: isViewingDocument ? '#808080c9' : '#008000c4' },
                                                    '&.Mui-disabled': {
                                                        background: '#008f007a',
                                                        color: '#fff',
                                                    },
                                                }}
                                                startIcon={isViewingDocument ? <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> : <VisibilityIcon />}
                                                onClick={handleViewDocument}
                                            >
                                                View
                                            </Button>

                                            &nbsp;

                                            <Button
                                                variant="contained"
                                                sx={{
                                                    mt: '7px',
                                                    width: isDownloading && !isViewBtnClicked ? 'auto' : 80,
                                                    borderRadius: '16px',
                                                    textTransform: 'none',
                                                    background: '#008000c4',
                                                    cursor: isDownloading && !isViewBtnClicked ? 'no-drop' : 'pointer',
                                                    '&:hover': { background: '#008000c4' },
                                                }}
                                                startIcon={isDownloading && !isViewBtnClicked ? <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} /> : <DownloadIcon />}
                                                onClick={handleDownloadDocument}
                                            >
                                                {isDownloading && !isViewBtnClicked ? 'Saving' : 'Save'}
                                            </Button>
                                        </Grid>

                                    </Grid>


                                    :

                                    <Grid container direction="row" sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? "8px 25px" : "8px 40px", mb: "-23px" }}>
                                        <Grid item xs={6}>
                                            <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                                                {scannedInfo?.name}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={(scannedInfo?.name == null || scannedInfo?.id == null) ? 12 : 6} sx={{ display: (scannedReference == "null" || scannedReference == "undefined") ? "none" : "visible", width: '100%', wordWrap: 'break-word' }}>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                                                        OVCODE:
                                                    </Typography>
                                                }
                                                secondary={scannedReference && scannedReference}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                                                        {(docPublishedDate) ? "Published Date:" : null}
                                                    </Typography>
                                                }
                                                secondary={docPublishedDate}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </>
                        )}

                        {!isScannedDocumentBoolean && docStatus &&

                            <Grid container direction="row" sx={{ mt: '10px', padding: "8px 35px", mb: "-23px" }}>
                                <ListItem sx={{ padding: '0px' }}>
                                    <ListItemText style={{ display: "flex" }}
                                        primary={
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold", fontSize: 14 }}>
                                                Status:
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant="subtitle2"
                                                sx={{
                                                    fontSize: "16px", ml: "3px", mt: "-2px",
                                                    color: (docStatus == "SUSPENDED") ? "orange" : (docStatus == "REVOKED") ? "red" : "green"
                                                }}
                                            >
                                                {docStatus}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </Grid>
                        }

                        <Grid container direction="row" sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? "8px 25px" : "8px 35px", mb: "10px", mt: "5px" }}>
                            <Typography sx={{ fontSize: "14px", fontWeight: 300, color: "#666666d4", mt: "13px", mb: "14px" }}>
                                Stay safe online! Before sharing sensitive info
                                on <Link href="https://ovcode.com/" underline="none" target="_blank">https://ovcode.com,</Link> verify the website certificate and hostname.
                                Check the padlock icon next to ovcode.com to ensure you're on the real site.
                            </Typography>
                        </Grid>

                    </DialogContent>

                </>
            }

            <DialogActions sx={{ display: "flex", justifyContent: "center", padding: "16px 0px" }}>
                <Typography variant="subtitle1">
                    Powered by:
                </Typography>

                &nbsp;

                <img alt="" src={OVCodeLogo} style={{ width: 100 }} />
            </DialogActions>

            <Backdrop open={isViewingDocument}
                sx={{
                    color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1,
                    opacity: "1 !important", backgroundColor: "#fff"
                }}
            >
                <Grid sx={{ display: "block", margin: "auto", textAlign: "center" }}>
                    <Typography sx={{
                        color: "#000",
                        fontWeight: "bold",
                        mb: "-5px",
                        fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 10 : "1rem"
                    }}>
                        Redirecting to viewer, please wait for a moment
                    </Typography>

                    <ThreeDots
                        height="50"
                        width={(breakpoints == "xs" || breakpoints == "sm") ? "50" : "80"}
                        radius="9"
                        color="#000"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ margin: "auto", textAlign: "center", justifyContent: "center" }}
                        wrapperClassName=""
                        visible={true}
                    />
                </Grid>
            </Backdrop>
        </Dialog>
    )
}

export default VerifiedDataDialog