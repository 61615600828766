import { Box, Grid, Typography } from "@mui/material";
import useWidth from "../../hooks/useWidth";

const ViewRetailUnilabComponent = ({ verificationName }) => {

    const breakpoints = useWidth();

    return (
        <Box sx={{ padding: 6, pt: breakpoints === 'xs' ? 3 : 1, pb: 0, textAlign: 'start' }}>
            
              <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Typography sx={{fontSize: 17}}><strong>Name:</strong>&nbsp;&nbsp;Biogesic 500mg 20 Tablets</Typography>
                  <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '15px' , marginBottom: '15px' }} />
              </Grid>
              <Grid item xs={12} sx={{paddingTop: '3px !important'}}>
                  <Typography sx={{fontSize: 17}}><strong>Used For:</strong>&nbsp;&nbsp;Headache, Fever and Flu</Typography>
                  <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px'  }} />
              </Grid>
              <Grid item xs={12} sx={{paddingTop: '3px !important'}}>
                  <Typography sx={{fontSize: 17}}><strong>Description:</strong>&nbsp;&nbsp;Biogesic is the most doctor-prescribed branded Paracetamol, that is trusted for headache and fever relief.</Typography>
                  <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px'  }} />
              </Grid>

              <Grid item xs={12} sx={{paddingTop: '3px !important'}}>
                  <Typography sx={{fontSize: 17}}><strong>Serial Number:</strong>&nbsp;&nbsp;BG23142501 </Typography>
                  <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px'  }} />
              </Grid> 

              <Grid item xs={12} sx={{paddingTop: '3px !important'}}>
                  <Typography sx={{fontSize: 17}}><strong>Manufactured Date:</strong>&nbsp;&nbsp;01/23/2024</Typography>
                  <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px'  }} />
              </Grid>
         
              <Grid item xs={12} sx={{paddingTop: '3px !important'}}>
                  <Typography sx={{fontSize: 17}}><strong>Expiration Date:</strong>&nbsp;&nbsp;01/23/2026</Typography>
                  <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px'  }} />
              </Grid>

              <Grid item xs={12} sx={{paddingTop: '3px !important'}}>
                  <Typography sx={{fontSize: 17}}><strong>Manufactured by:</strong>&nbsp;&nbsp;Unilab Inc.</Typography>
                  <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
              </Grid>
          </Grid>
          
        </Box>
    )
}

export default ViewRetailUnilabComponent;
