import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Button, Box, Typography, Menu, MenuItem, IconButton, Tooltip, Avatar, ListItem, ListItemAvatar, ListItemText, List, ListItemButton, } from "@mui/material";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logo from "../../icons/new-ovcode-logo.png";
import { useNavigate } from "react-router-dom";
import AppsIcon from "@mui/icons-material/Apps";
import EnterpriseIcon from "../../icons/enterprise.png";
import environment from "../../environment/environment";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config";
import { stringAvatar } from "../../utils/stringAvatarUtil";

function AppbarComponent() {
  const url = window.location.pathname;

  const apiDocURL = environment.apiDocURL

  const navigate = useNavigate();

  const [productsMenuOpen, setProductsMenuOpen] = useState(false);
  const [productsMenuAnchorEl, setProductsMenuAnchorEl] = useState(null);
  const [enterpriseMenuOpen, setEnterpriseMenuOpen] = useState(false);
  const [enterpriseMenuAnchorEl, setEnterpriseMenuAnchorEl] = useState(null);
  const [resourcesMenuOpen, setResourcesMenuOpen] = useState(false);
  const [resourcesMenuAnchorEl, setResourcesMenuAnchorEl] = useState(null);
  const [supportMenuOpen, setSupportMenuOpen] = useState(false);
  const [supportMenuAnchorEl, setSupportMenuAnchorEl] = useState(null);

  const [blogMenuOpen, setBlogMenuOpen] = useState(false);
  const [blogMenuAnchorEl, setBlogMenuAnchorEl] = useState(null);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVerification, setSelectedVerification] = useState(false);
  const [selectedEnterprise, setSelectedEnterprise] = useState(null);
  const [selectedSupport, setSelectedSupport] = useState(null);
  const [selectedResources, setSelectedResources] = useState(null);
  const [selectedBlogs, setSelectedBlogs] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // MSAL 
  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated();

  console.log('get user if authenticated xxxxx', isAuthenticated)

  console.log('get user account details here', accounts[0])

  const userDetails = accounts[0]?.idTokenClaims
  const userEmail = accounts[0]?.username

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const login = async () => {
    try {
      const res = await instance.loginPopup(loginRequest);
      console.log('login result here aaaaaa', res);
      if(res){
        window.location.reload();
      }

      
    } catch (error) {
      console.log("login error iii", error);
    }
  };

  const handleLogout = async () => {
    try {
      await instance.logoutPopup();
    } catch (error) {
      console.error("logout error:", error);
    }
  };


  const handleProductsMenuOpen = (event) => {
    setProductsMenuAnchorEl(event.currentTarget);
    setProductsMenuOpen(true);
  };

  const handleProductsMenuClose = () => {
    setProductsMenuAnchorEl(null);
    setProductsMenuOpen(false);
  };

  const handleSupportMenuOpen = (event) => {
    setSupportMenuAnchorEl(event.currentTarget);
    setSupportMenuOpen(true);
  };

  const handleSupportMenuClose = () => {
    setSupportMenuAnchorEl(null);
    setSupportMenuOpen(false);
  };

  const handleEnterpriseMenuOpen = (event) => {
    setEnterpriseMenuAnchorEl(event.currentTarget);
    setEnterpriseMenuOpen(true);
  };

  const handleEnterpriseMenuClose = () => {
    setEnterpriseMenuAnchorEl(null);
    setEnterpriseMenuOpen(false);
  };

  const handleResourceMenuOpen = (event) => {
    setResourcesMenuAnchorEl(event.currentTarget);
    setResourcesMenuOpen(true);
  };

  const handleResourceMenuClose = () => {
    setResourcesMenuAnchorEl(null);
    setResourcesMenuOpen(false);
  };

  const handleBlogsMenuOpen = (event) => {
    setBlogMenuAnchorEl(event.currentTarget);
    setBlogMenuOpen(true);
  };

  const handleBlogsMenuClose = () => {
    setBlogMenuAnchorEl(null);
    setBlogMenuOpen(false);
  };


  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const segments = url.split("/");
    const lastSegment = segments[segments.length - 1];

    if (lastSegment === "electronic-identification") {
      setSelectedProduct("eID");
    } else if (lastSegment === "electronic-signature") {
      setSelectedProduct("eSign");
    } else if (lastSegment === "electronic-mark") {
      setSelectedProduct("eMark")
    } else if (lastSegment === "enterprise") {
      setSelectedEnterprise("verify")
    } else if (lastSegment === "version-histories-all") {
      setSelectedEnterprise("version-histories-all")
    } else if (lastSegment === "enterprise-support") {
      setSelectedSupport("enterprise-support")
    } else if (lastSegment === "product-support") {
      setSelectedSupport("product-support")
    } else if (lastSegment === "contact-us") {
      setSelectedSupport("contact-us")
    } else if (lastSegment === "faq") {
      setSelectedSupport("faq")
    } else if (lastSegment === "system-policies-and-procedures") {
      setSelectedResources("system-policies-and-procedures")
    } else if (lastSegment === "privacy-policy") {
      setSelectedResources("privacy-policy")
    } else if (lastSegment === "terms-of-service") {
      setSelectedResources("terms-of-service")
    } else if (
      lastSegment === "blog-ovcode-insights" ||
      lastSegment === "blog-ovcode-insights-evolution" ||
      lastSegment === "blog-ovcode-insights-digital-trust" ||
      lastSegment === "blog-ovcode-insights-trailblazer-blockchain" ||
      lastSegment === "blog-ovcode-insights-securing-future" ||
      lastSegment === "blog-ovcode-insights-in-news"||
      lastSegment === "blog-ovcode-insights-beyond-kpi"||
      lastSegment === "blog-ovcode-insights-powered-kpi"||
      lastSegment === "blog-ovcode-insights-end-to-end-encryption" ||
      lastSegment === "blog-ovcode-insights-significance-of-certification" ||
      lastSegment === "blog-ovcode-insights-setting-standard-for-digital-transactions" ||
      lastSegment === "blog-ovcode-insights-revolutionizing-secure-storage" 
    ) {
      setSelectedBlogs("ovcode-insights");
    } else if (
      lastSegment === "blog-eID-in-depth" ||
      lastSegment === "blog-eid-identity-verification" ||
      lastSegment === "blog-eid-success-stories"
    ) {
      setSelectedBlogs("eID-in-depth");
    } else if (
      lastSegment === "blog-eSign-in-depth" ||
      lastSegment === "blog-esign-document-workflow" ||
      lastSegment === "blog-esign-unlocking-efficiency"
    ) {
      setSelectedBlogs("eSign-in-depth");
    } else if (
      lastSegment === "blog-eMark-as-asset" ||
      lastSegment === "blog-emark-visual-content" ||
      lastSegment === "blog-emark-blockhain"
    ) {
      setSelectedBlogs("eMark-as-asset");
    }  else if (
      lastSegment === "blog-cybergems" ||
      lastSegment === "blog-cybergems-authenticating-digital-treasure" ||
      lastSegment === "blog-cybergems-unveiled"
    ) {
      setSelectedBlogs("cybergems");
    } else if (
      lastSegment === "blog-support-and-community" ||
      lastSegment === "blog-support-navigating-ovcode" ||
      lastSegment === "blog-support-navigating-secure-storage" ||
      lastSegment === "blog-support-data-privacy" ||
      lastSegment === "blog-support-community-spotlight"
    ) {
      setSelectedBlogs("support-and-community");
    } else if (
      lastSegment === "blog-partners" ||
      lastSegment === "blog-partner-unlock-growth" ||
      lastSegment === "blog-partner-ovcode-distributor"
    ) {
      setSelectedBlogs("partners");
    } else if (
      lastSegment === "blog-industry-insights" ||
      lastSegment === "blog-industry-what-to-know" ||
      lastSegment === "blog-industry-ovcode-role" ||
      lastSegment === "blog-industry-user-experience"||
      // lastSegment === "blog-industry-safety-and-authenticity"||
      lastSegment === "blog-industry-real-time-verification"||
      lastSegment === "blog-industry-blockchain-synergy"||
      lastSegment === "blog-industry-building-trust"||
      lastSegment === "blog-industry-decoding-security"||
      lastSegment === "blog-industry-future-proofing"||
      lastSegment === "blog-industry-flexible-and-secure-storage"||
      lastSegment === "blog-industry-hybrid-approach"||
      lastSegment === "blog-cybergems-authenticating-digital-treasure" ||
      lastSegment === "blog-cybergems-unveiled" ||
      lastSegment === "blog-cybergems-art-of-authenticity" ||
      lastSegment === "blog-cybergems-secures-memories" ||
      lastSegment === "blog-cybergems-future-collecting" ||
      lastSegment === "blog-cybergems-ovcode-role-nft" ||
      lastSegment === "blog-cybergems-investment-era" ||
      lastSegment === "blog-cybergems-pixel-legacy" ||
      lastSegment === "blog-cybergems-evolution-of-digital-collectibles" ||
      lastSegment === "blog-cybergems-intersecting-paths" ||
      lastSegment === "blog-industry-cloud-trust" ||
      lastSegment === "blog-industry-trust-digital-world" ||
      lastSegment === "blog-cybergems-embracing-new-era" ||
      lastSegment === "blog-cybergems-elevating-to-global-standard" ||
      lastSegment === "blog-industry-transparent-ecosystem" || 
      lastSegment === "blog-industry-pioneering-shift" || 
      lastSegment === "blog-industry-internet-of-things" || 
      lastSegment === "blog-industry-smart-contracts" ||
      lastSegment === "blog-industry-medical-data-integrity" ||
      lastSegment === "blog-industry-ovcode-way" ||
      lastSegment === "blog-industry-verification-system" ||
      lastSegment === "blog-industry-reducing-online-fraud"
    ) {
      setSelectedBlogs("industry-insights");
    }  else if (lastSegment === "verification") {
      setSelectedVerification(true)
    }else {
      setSelectedProduct(null);
      setSelectedEnterprise(null);
      setSelectedSupport(null);
      setSelectedResources(null);
      setSelectedBlogs(null);
      setSelectedVerification(false);
    }
    

  }, [url]);


  const mobileMenuId = 'primary-search-account-menu-mobile';

  return (
    <AppBar
      position="fixed" 
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1 ,
        top: 0,
        left: "auto",
        right: 0,
        color: "rgb(255, 255, 255)",
        backgroundColor: "#fff",
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
      }}
     
    >
      <div  class="container">
      <Toolbar sx={{ width: "100%", paddingLeft: '0px !important', paddingRight: '0px !important' }} >
        <Button
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{
            mr: 2,
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            padding: '0px'
          }}
          disableRipple
        >
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <img
              alt=""
              src={logo}
              onClick={(e) => (window.location.href = "/")}
              style={{
                height: 40,
                verticalAlign: "middle",
                cursor: "pointer",
                imageRendering: "pixelated",
                imageRendering: "-webkit-optimize-contrast",
              }}
            />
          </Box>

          {/* FOR MOBILE VIEW */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <img
              src={logo}
              onClick={(e) => (window.location.href = "/")}
              style={{ height: 35, verticalAlign: "middle", cursor: "pointer" }}
            />
          </Box>
        </Button>


        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          &nbsp;
          <Button
            id="demo-customized-button"
            variant="text"
            disableElevation
            endIcon={<ExpandMoreIcon />}
            onClick={handleProductsMenuOpen}
            sx={{ color: '#000', ...(selectedProduct === "eID" || selectedProduct === "eMark" || selectedProduct === "eSign" ? { color: '#017eff' } : {}) }}
          >
            <Typography sx={{ fontSize: 13, textTransform: 'none' }}>
              Products
            </Typography>
          </Button>

          &nbsp;
          <Button
            id="demo-customized-button"
            variant="text"
            disableElevation
            endIcon={<ExpandMoreIcon />}
            onClick={handleEnterpriseMenuOpen}
            sx={{ color: '#000', ...(selectedEnterprise === "verify" || selectedEnterprise === "version-histories" ? { color: '#017eff' } : {}) }}

          >
            <Typography sx={{ fontSize: 13, textTransform: 'none' }}>
              Enterprise
            </Typography>
          </Button>

          &nbsp;
          <Button
            id="demo-customized-button"
            variant="text"
            disableElevation
            endIcon={<ExpandMoreIcon />}
            onClick={handleBlogsMenuOpen}
            sx={{ color: '#000', ...(selectedBlogs === "ovcode-insights" || selectedBlogs === "eID-in-depth" || selectedBlogs === "eSign-in-depth" || 
            selectedBlogs === "eMark-as-asset" || selectedBlogs === "cybergems" || selectedBlogs === "support-and-community" || selectedBlogs === "partners" || selectedBlogs === "industry-industry"  ? { color: '#017eff' } : {}) }}
          >
            <Typography sx={{ fontSize: 13, textTransform: 'none' }}>
              Blog
            </Typography>
          </Button>

          &nbsp;
          <Button
            id="demo-customized-button"
            variant="text"
            disableElevation
            endIcon={<ExpandMoreIcon />}
            onClick={handleResourceMenuOpen}
            sx={{ color: '#000', ...(selectedResources === "system-policies-and-procedures" || selectedResources === "terms-of-service" || selectedResources === "privacy-policy" ? { color: '#017eff' } : {}) }}

          >
            <Typography sx={{ fontSize: 13, textTransform: 'none' }}>
              Resources
            </Typography>
          </Button>

          &nbsp;
          <Button
            id="demo-customized-button"
            variant="text"
            disableElevation
            endIcon={<ExpandMoreIcon />}
            onClick={handleSupportMenuOpen}
            sx={{ color: '#000', ...(selectedSupport === "enterprise-support" || selectedSupport === "product-support" || selectedSupport === "contact-us" || selectedSupport === "faq" ? { color: '#017eff' } : {}) }}
          >
            <Typography sx={{ fontSize: 13, textTransform: 'none' }}>
              Support
            </Typography>
          </Button>

          &nbsp;
          <Button
            id="demo-customized-button"
            variant="text"
            disableElevation
            onClick={(e) => (window.location.href = "/verification")}
            sx={{color: '#000', ...(selectedVerification ? { color: '#017eff' } : {}) }}
          >
            <Typography sx={{ fontSize: 13, textTransform: 'none' }}>
              Verification
            </Typography>
          </Button>

        </Box>
        <Box sx={{ flexGrow: 1 }} />

        {url !== "/join" && (
          <Box
            sx={{ display: "flex", cursor: "pointer" }}
            onClick={(e) => (window.location.href = "/join")}
          >
            {/* <Typography
              sx={{
                background: "#017eff",
                padding: "8px 10px",
                borderRadius: "16px",
                mr: "10px",
                fontSize: 13,
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Join Now
            </Typography> */}
            <Button variant="text" sx={{ width: '100%', fontSize: 13, height: 32, borderColor: 'none', color: '#000000CC', textTransform: 'none', borderRadius: 0, '&:hover': { backgroundColor: 'transparent', borderColor: 'none', color: '#000000CC' } }}>
              Join Now
            </Button>
          </Box>

        )}

        <Tooltip title="Enterprise" arrow>
          <IconButton sx={{ display: { xs: 'none', md: 'flex' }, cursor: "pointer", paddingRight: '0px' }} target="_blank" component={Link} to="https://dev-portal.ovcode.com">
            <img alt="" src={EnterpriseIcon} style={{ height: 24 }} />
          </IconButton>
        </Tooltip>
        &nbsp;
        {isAuthenticated ? 
          <Box>
          <Tooltip title="Account">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar alt="" {...stringAvatar(userDetails?.emails[0])} style={{color: "#fff", background: "#b2d6f9", width: 40, height: 40, border:'1px solid #0178f2'}} />
            </IconButton>
          </Tooltip>
          <Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            
            <List >
              <ListItem>
                <ListItemAvatar>
                <Avatar alt="" {...stringAvatar(userDetails?.emails[0])} style={{color: "#fff", background: "#b2d6f9", width: 40, height: 40, border:'1px solid #0178f2'}} />
                </ListItemAvatar>
                <ListItemText
                sx={{ '& .MuiListItemText-primary': { fontSize: 15, fontWeight:"bold"} }}
                  // primary={userDetails.given_name + " " + userDetails.family_name}
                  secondary={
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {userEmail}
                       
                      </Typography>
                      
                  }
                  
                />
              </ListItem>
               <ListItem disablePadding>
                 <ListItemButton disableRipple sx={{ '&:hover': { backgroundColor: 'transparent' }, pt: 0 }} onClick={handleLogout}>
                   <ListItemText inset primary="Logout" sx={{ '& .MuiListItemText-primary': { fontSize: 12, color:'#0178f2' } }} />
                 </ListItemButton>
               </ListItem>
            </List>
          </Menu>
        </Box>

        :

          <Button 
            variant="text" 
            sx={{ fontSize: 13, height: 32, borderColor: 'none', color: '#000000CC', textTransform: 'none', borderRadius: 0, '&:hover': { backgroundColor: 'transparent', borderColor: 'none', color: '#000000CC' } }}
            onClick={login}
          >
            Sign In
          </Button>
        }
        


        {/* <Tooltip title="User Login" arrow>
          <IconButton sx={{ display: { xs: 'none', md: 'flex' }, cursor: "pointer" }} target="_blank" component={Link} to="https://esign.ovcode.com">
            <img alt="" src={OVSignIcon} style={{ height: 20 }} />
          </IconButton>
        </Tooltip> */}

        {/* FOR MOBILE VIEW */}
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color={"black"}
          >
            <AppsIcon />
          </IconButton>
        </Box>
      </Toolbar>
      </div>

      <Menu
        anchorEl={productsMenuAnchorEl}
        open={productsMenuOpen}
        onClose={handleProductsMenuClose}
      >
        <MenuItem
          onClick={() => [window.location.href = '/electronic-identification', handleProductsMenuClose]}
          sx={{ fontSize: '13px', color: '#000', ...(selectedProduct === "eID" ? { color: '#017eff' } : {}) }}
        >
          eID
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/electronic-signature', handleProductsMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedProduct === "eSign" ? { color: '#017eff' } : {}) }}
        >
          eSign
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/electronic-mark', handleProductsMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedProduct === "eMark" ? { color: '#017eff' } : {}) }}
        >
          eMark
        </MenuItem>
      </Menu>


      <Menu
        anchorEl={blogMenuAnchorEl}
        open={blogMenuOpen}
        onClose={handleBlogsMenuClose}
      >
        <MenuItem
          onClick={() => [window.location.href = '/blog-ovcode-insights', handleBlogsMenuClose]}
          sx={{ fontSize: '13px', color: '#000', ...(selectedBlogs === "ovcode-insights" ? { color: '#017eff' } : {}) }}
        >
          OVCODE Insights
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/blog-eID-in-depth', handleBlogsMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedBlogs === "eID-in-depth" ? { color: '#017eff' } : {}) }}
        >
          eID In-Depth
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/blog-eSign-in-depth', handleBlogsMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedBlogs === "eSign-in-depth" ? { color: '#017eff' } : {}) }}
        >
          eSign In-Depth
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/blog-eMark-as-asset', handleBlogsMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedBlogs === "eMark-as-asset" ? { color: '#017eff' } : {}) }}
        >
          eMark as Asset
        </MenuItem>
        {/* <MenuItem
          onClick={() => [window.location.href = '/blog-cybergems', handleBlogsMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedBlogs === "cybergems" ? { color: '#017eff' } : {}) }}
        >
          CyberGems
        </MenuItem> */}
         <MenuItem
          onClick={() => [window.location.href = '/blog-support-and-community', handleBlogsMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedBlogs === "support-and-community" ? { color: '#017eff' } : {}) }}
        >
          Support & Community
        </MenuItem>
       <MenuItem
          onClick={() => [window.location.href = '/blog-partners', handleBlogsMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedBlogs === "partners" ? { color: '#017eff' } : {}) }}
        >
          Partners
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/blog-industry-insights', handleBlogsMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedBlogs === "industry-insights" ? { color: '#017eff' } : {}) }}
        >
          Industry Insights
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={enterpriseMenuAnchorEl}
        open={enterpriseMenuOpen}
        onClose={handleEnterpriseMenuClose}
      >
        <MenuItem
          onClick={() => [window.location.href = '/enterprise', handleEnterpriseMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedEnterprise === "verify" ? { color: '#017eff' } : {}) }}

        >
          Enterprise
        </MenuItem>
        <MenuItem onClick={() => {
          window.open(apiDocURL, '_blank');
          handleResourceMenuClose();
        }}  sx={{ fontSize: '13px'}}>
          API Documentation
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/version-histories-all', handleEnterpriseMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedEnterprise === "version-histories-all" ? { color: '#017eff' } : {}) }}
        >
          Version Histories
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={resourcesMenuAnchorEl}
        open={resourcesMenuOpen}
        onClose={handleResourceMenuClose}
      >
        <MenuItem
          onClick={() => {
            window.open('/terms-of-service', '_blank');
            handleResourceMenuClose();
          }}
          sx={{  fontSize: '13px', color: '#000', ...(selectedResources === "terms-of-service" ? { color: '#017eff' } : {}) }}

        >
          Terms of Use
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open('/privacy-policy', '_blank');
            handleResourceMenuClose();
          }}
          sx={{  fontSize: '13px', color: '#000', ...(selectedResources === "privacy-policy" ? { color: '#017eff' } : {}) }}

        >
          Data Privacy
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/ovc-disclaimer', handleResourceMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedResources === "privacy-policy" ? { color: '#017eff' } : {}) }}

        >
          OVCoin
        </MenuItem>
        {/* <MenuItem
          onClick={() => [window.location.href = '/system-policies-and-procedures', handleResourceMenuClose]}
          sx={{ color: '#000', ...(selectedResources === "system-policies-and-procedures" ? { color: '#017eff' } : {}) }}

        >
          System Policies and Procedures
        </MenuItem> */}
      </Menu>

      <Menu
        anchorEl={supportMenuAnchorEl}
        open={supportMenuOpen}
        onClose={handleSupportMenuClose}
      >
        <MenuItem
          onClick={() => [window.location.href = '/enterprise-support', handleSupportMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedSupport === "enterprise-support" ? { color: '#017eff' } : {}) }}

        >
          Enterprise Support
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/product-support', handleSupportMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedSupport === "product-support" ? { color: '#017eff' } : {}) }}
        >
          Product Support
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open('/faq', '_blank');
            handleSupportMenuClose();
          }}
          sx={{  fontSize: '13px', color: '#000', ...(selectedSupport === "faq" ? { color: '#017eff' } : {}) }}
        >
          FAQ
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/contact-us', handleSupportMenuClose]}
          sx={{  fontSize: '13px', color: '#000', ...(selectedSupport === "contact-us" ? { color: '#017eff' } : {}) }}

        >
          Contact Us
        </MenuItem>
      </Menu>


      {/* Mobile Menu */}
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(mobileMoreAnchorEl)}
        onClose={handleMobileMenuClose}
      >
        <MenuItem >
          <Button
            id="demo-customized-button"
            variant="text"
            disableElevation
            endIcon={<ExpandMoreIcon />}
            onClick={handleProductsMenuOpen}
            sx={{ color: '#000', ...(selectedProduct === "eID" || selectedProduct === "eMark" || selectedProduct === "eSign" ? { color: '#017eff' } : {}) }}
          >
            <Typography sx={{ fontSize: 13 }}>
              Products
            </Typography>
          </Button>
        </MenuItem>
        <MenuItem >
          <Button
            id="demo-customized-button"
            variant="text"
            disableElevation
            endIcon={<ExpandMoreIcon />}
            onClick={handleEnterpriseMenuOpen}
            sx={{ color: '#000', ...(selectedEnterprise === "enterprise" || selectedEnterprise === "version-histories" ? { color: '#017eff' } : {}) }}

          >
            <Typography sx={{ fontSize: 13 }}>
              Enterprise
            </Typography>
          </Button>
        </MenuItem>
        <MenuItem >
          <Button
             id="demo-customized-button"
             variant="text"
             disableElevation
             endIcon={<ExpandMoreIcon />}
            onClick={handleBlogsMenuOpen}
            sx={{ color: '#000', ...(selectedBlogs === "ovcode-insights" || selectedBlogs === "eID-in-depth" || selectedBlogs === "eSign-in-depth" || selectedBlogs === "eMark-as-asset" || selectedBlogs === "support-and-community" || selectedBlogs === "partners" || selectedBlogs === "industry-industry" ? { color: '#017eff' } : {}) }}

          >
            <Typography sx={{ fontSize: 13}}>
              Blog
            </Typography>
          </Button>
          </MenuItem>
        <MenuItem>
          <Button
            id="demo-customized-button"
            variant="text"
            disableElevation
            endIcon={<ExpandMoreIcon />}
            onClick={handleResourceMenuOpen}
            sx={{ color: '#000', ...(selectedResources === "system-policies-and-procedures" || selectedResources === "terms-of-service" || selectedResources === "privacy-policy" ? { color: '#017eff' } : {}) }}

          >
            <Typography sx={{ fontSize: 13 }}>
              Resources
            </Typography>
          </Button>
        </MenuItem>
        <MenuItem >
          <Button
            id="demo-customized-button"
            variant="text"
            disableElevation
            endIcon={<ExpandMoreIcon />}
            onClick={handleSupportMenuOpen}
            sx={{ color: '#000', ...(selectedSupport === "enterprise-support" || selectedSupport === "product-support" || selectedSupport === "contact-us" || selectedSupport === "faq" ? { color: '#017eff' } : {}) }}
          >
            <Typography sx={{ fontSize: 13 }}>
              Support
            </Typography>
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            id="demo-customized-button"
            variant="text"
            disableElevation
            onClick={(e) => (window.location.href = "/verification")}
            sx={{  color: selectedVerification ? '#017eff' : '#000000CC' }}
          >
            <Typography sx={{ fontSize: 13}}>
              Verification
            </Typography>
          </Button>
          </MenuItem>
      </Menu>
    </AppBar>
  );
}

export default AppbarComponent;