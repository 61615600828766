import React, { useState, useEffect } from "react";
import { Box, Typography } from '@mui/material';
import { CustomTab, CustomTabs } from "./custom-tabs.component";
import ViewMplTobaccoComponent from "../unilab/view-mpl-tobacco.compnent";
import HistoryMplTobaccoComponent from "../unilab/history-mpl-tobacco.component";

function useTobaccoTabs({ verificationName }) {
  const [tobaccoTabs, setTobaccoTabs] = useState(null);

  useEffect(() => {
    function setupTabs() {
      const tabsDefault = [
        { name: 'View', component: <ViewMplTobaccoComponent verificationName={verificationName} /> },
        { name: 'History', component: <HistoryMplTobaccoComponent verificationName={verificationName} /> },
      ];
      setTobaccoTabs(tabsDefault);
    }
    setupTabs();
  }, [verificationName]); 

  return tobaccoTabs;
}

function TobaccoCustomTab({ verificationName }) {
  const tabs = useTobaccoTabs({ verificationName });
  const [value, setValue] = useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  if (!tabs) return <>Loading...</>;

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <CustomTabs
          value={value} 
          onChange={handleChange} 
          sx={{ marginTop: 2 }} 
          TabIndicatorProps={{ sx: { display:'none' } }}
          centered
        >
          {tabs.map((tab, index) => (
            <CustomTab 
              key={index} 
              label={tab.name} 
              disableRipple 
              {...a11yProps(index)} 
            />
          ))}
        </CustomTabs>
        {tabs.map((tab, index) => (
          <TabPanel 
            key={index} 
            value={value} 
            index={index} 
            style={{ padding: "0px" }}
          >
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}

export default TobaccoCustomTab;
