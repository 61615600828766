import { Box } from "@mui/material";
import LandingComponent from "../components/landing/landing.component";
import background from "../images/blueBackground.png";

const LandingScreen = () => {
  return (
    <Box>
      <LandingComponent />
    </Box>
  );
};

export default LandingScreen;
