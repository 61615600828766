import { Box, Card, CardActionArea, CardContent, Grid, ListItemText, Typography } from "@mui/material"
import React from "react"

import OvcodeRole from "../../../../images/cybergems/CG-Five.jpg";


const CybergemsFutureOfCollectingComponent = () => {

    return (
        <Box>
            <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                                                The Future of Collecting - Blockchain-Certified Cybergems
                                            </Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: '1rem' }}>Monday, 06 May 2024</Typography>
                                        </React.Fragment>
                                    } />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
                            <img src={OvcodeRole} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                In an age where digital assets are becoming as treasured as physical ones, Cybergems stand out as beacons of innovation.
                                Thanks to OVCODE's blockchain technology, these digital gems are not only a form of artistic expression but also a new frontier in the world of collecting.
                                We now explore how blockchain certification is setting the future course for digital collectibles, making Cybergems a cornerstone of modern collecting.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Redefining Collectibles with Blockchain</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Blockchain technology is revolutionizing the way we think about collecting by adding layers of security, authenticity, and exclusivity to digital assets.
                                OVCODE's blockchain ensures that each Cybergem is certified, secure, and unique, offering collectors peace of mind alongside exquisite digital art.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Immutable Provenance and Certification</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Each Cybergem comes with a blockchain-based certificate of authenticity that records its provenance.
                                This certification process is immutable, meaning once a Cybergem’s details are recorded on the blockchain, they cannot be altered, providing a permanent record of the gem's history and originality.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Enhanced Security with Blockchain</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                The integration of blockchain technology ensures that each transaction and ownership transfer of Cybergems is securely logged.
                                This not only prevents fraud but also allows collectors to trace the lineage of each piece, adding to its story and value.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>The Appeal of Blockchain-Certified Collectibles</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                As digital assets, Cybergems offer a new type of collectible that merges technology with personal significance.
                                This appeal is broadening the market for collectors, attracting both traditional collectors and new enthusiasts drawn by the technological prowess and personalization capabilities of Cybergems.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>A New Era of Digital Art</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                Cybergems are at the forefront of a new era of digital art that is both collectible and interactive.
                                Collectors can experience art in a dynamic way, engaging with pieces that are crafted not just to be seen but to be interacted with, thanks to the blockchain's capabilities.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Investment Potential</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                The unique nature and limited edition of blockchain-certified Cybergems make them highly desirable as investments.
                                As the market for digital collectibles grows, the value of these assets is likely to increase, driven by their rarity and the security of blockchain certification.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Building a Community of Collectors</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE's blockchain technology does more than secure Cybergems; it also fosters a community of collectors.
                                Owners of Cybergems can be confident in their authenticity and enjoy a shared experience with others who value the intersection of art, technology, and personal legacy.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>Exclusive Events and Offers</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                OVCODE and Cybergems can host exclusive events, previews, and offers for collectors, leveraging the blockchain to verify membership and participation.
                                This creates a sense of community and exclusivity, enhancing the overall collecting experience.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
                            <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'center'  }}>The Future Is Here with Cybergems</Typography>
                            <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                                The future of collecting is being reshaped by blockchain technology and digital assets like Cybergems.
                                With OVCODE's blockchain certification, these digital treasures are set to become a staple in the collections of the future, offering a blend of artistry, technology, and personal significance that is unmatched in the traditional collecting world.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
                <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
            </Grid>

            <Grid container spacing={4} sx={{ width: '95%', margin: 'auto' }}>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        onClick={() => window.location.href = "/blog-industry-blockchain-synergy"}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        OVCODE: Bridging Certified Trust with AATL and Blockchain Synergy
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                        OVCODE redefines digital trust by integrating AATL-certified security with the unparalleled verification power of blockchain technology
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                April 02, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingTop: '0px !important' }}>
                    <Card
                        sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '14px', display: 'flex', flexDirection: 'column' }}
                        // onClick={() => window.location.href = ""}
                    >
                        <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            <Grid container sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                                        Crafting Trust in the Blockchain - OVCODE's Role in NFT Integrity
                                    </Typography>
                                    <Typography sx={{ textAlign: 'start', mt: 1, mb: 1 }}>
                                    Delve into the concept of trust in the blockchain ecosystem and how OVCODE’s robust authentication protocols are critical for maintaining the integrity of NFTs like Cybergems.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 'auto', textAlign: 'start', marginBottom: '5px', fontWeight: 550 }}>
                                May 07, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CybergemsFutureOfCollectingComponent