import { Box, Grid, Typography } from "@mui/material";
import useWidth from "../../hooks/useWidth";

const ViewMplUnilabComponent = ({ verificationName }) => {

    console.log("verificationName herexxx:", verificationName)

    const breakpoints = useWidth();



    return (
        <Box sx={{ padding: 6, pt: breakpoints === 'xs' ? 3 : 1, pb: 0, textAlign: 'start' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {verificationName === "MPL" ?
                        <>
                            <Typography sx={{ fontSize: 17 }}><strong>Name:</strong>&nbsp;&nbsp;Biogesic 500mg</Typography>
                            <Typography sx={{ fontSize: 17 }}><strong>Boxes:</strong>&nbsp;&nbsp;100</Typography>
                            <Typography sx={{ fontSize: 17 }}><strong>Pieces:</strong>&nbsp;&nbsp;50</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '15px', marginBottom: '15px' }} />

                        </>
                        :
                        <Typography sx={{ fontSize: 17 }}><strong>Name:</strong>&nbsp;&nbsp;Biogesic 20 Tablets</Typography>
                    }
                </Grid>

                <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                    <Typography sx={{ fontSize: 17 }}><strong>Used For:</strong>&nbsp;&nbsp;Headache, Fever and Flu</Typography>
                    <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                    <Typography sx={{ fontSize: 17 }}><strong>Used For:</strong>&nbsp;&nbsp;Headache, Fever and Flu</Typography>
                    <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                    <Typography sx={{ fontSize: 17 }}><strong>Description:</strong>&nbsp;&nbsp;Biogesic is the most doctor-prescribed branded Paracetamol, that is trusted for headache and fever relief.</Typography>
                    <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                </Grid>
                {verificationName === "BL" &&
                    <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                        <Typography sx={{ fontSize: 17 }}><strong>Serial Number:</strong>&nbsp;&nbsp;4807788570826</Typography>
                        <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                    </Grid>
                }
                <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                    <Typography sx={{ fontSize: 17 }}><strong>Manufactured Date:</strong>&nbsp;&nbsp;01/23/2024</Typography>
                    <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                </Grid>



                {verificationName === "MPL" ?
                    <>
                        <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                            <Typography sx={{ fontSize: 17 }}><strong>Hash Ref.:</strong>&nbsp;&nbsp;3bd11b1b18aa801577fb271df3060a89fb248b643c0dd<br/>6c87f5cf0b8356ab69e</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                            <Typography sx={{ fontSize: 17 }}><strong>Manufactured by:</strong>&nbsp;&nbsp;Unilab Inc.</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                        </Grid>
                    </>

                    :
                    <>
                        <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                            <Typography sx={{ fontSize: 17 }}><strong>Expiration Date:</strong>&nbsp;&nbsp;01/23/2026</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                            <Typography sx={{ fontSize: 17 }}><strong>Delivery Date:</strong>&nbsp;&nbsp;01/27/2024</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                            <Typography sx={{ fontSize: 17 }}><strong>Hash Ref.:</strong>&nbsp;&nbsp;85d669ec792cdecc08bf671a3872f67ab84b55df5b33<br/>4ca739e2d4fb0e732e93</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                        </Grid>
                        <Grid item xs={12} sx={{ paddingTop: '3px !important' }}>
                            <Typography sx={{ fontSize: 17 }}><strong>Distributed by:</strong>&nbsp;&nbsp;Mercury Warehouse Makati</Typography>
                            <hr style={{ borderTop: '2px dashed #000', width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                        </Grid>
                    </>

                }


            </Grid>
        </Box>
    )
}

export default ViewMplUnilabComponent;
