import React, { useEffect, useState } from "react";
import { Alert, Box, Button, CardMedia, Dialog, DialogContent, DialogActions, Grid, IconButton, Snackbar, TextField, Typography, CircularProgress } from "@mui/material";
import VerifyBannerComponent from "./verify-banner.component";
import DataUploaderComponent from "./data-uploader.component";
import ScanQRComponent from "./scan-qr.component";
import VerifiedDataDialog from "../../dialog/verified-data-dialog.component";
import UnverifiedDataDialog from "../../dialog/unverified-data-dialog.component";
import useWidth from "../../../hooks/useWidth";
import uploadGIF from "../../../icons/upload-v1.gif";
import scanGIF from "../../../icons/scanner-v2.gif";
import TobaccoVerifiedDataDialog from "../../dialog/tobacco-verification-dialog.component";
import UnilabDialog from "../../dialog/unilab-dialog.component";
import PermissionDeniedDialog from "../../dialog/permission-dialog.component";
import { useMsal } from "@azure/msal-react";
import VerificationResultDialogComponent from "../../dialog/verification-result-dialog.component";
import PDFViewerComponent from "./pdf-viewer.component";
import UnilabVerifiedDataDialog from "../../dialog/unilab-verification-dialog.component";
import { useSnackbar } from "notistack";
import { loginRequest } from "../../../config";
import AuthService from "../../../services/auth";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useVerification from "../../../hooks/useVerification";
import DataMismatchDialog from "../../dialog/data-mismatch-dialog.component";
import DrawerHeader from "../../layout/drawer-header";
import ovcodeLogo from '../../../icons/new-ovcode-logo.png';



const VerifyComponent = () => {
    const breakpoints = useWidth();
    const { enqueueSnackbar } = useSnackbar();
    const { verifyOvi, verifyReference } = useVerification();
    const { instance, accounts } = useMsal();
    var isUserLoggedIn = AuthService.isUserLoggedIn();

    const scannedReference = localStorage.getItem("scannedReference");
    const referenceDataScanned = localStorage.getItem("referenceDataScanned");

    const [openUpload, setOpenUpload] = useState(false);
    const [openScan, setOpenScan] = useState(false);
    const [openVerificationRes, setOpenVerificationRes] = useState(false);
    const [openVerifiedData, setOpenVerifiedData] = useState(false);
    const [openUnverifiedData, setOpenUnverifiedData] = useState(false);
    const [openTobaccoVerifiedDialog, setOpenTobaccoVerifiedDialog] = useState(false);
    const [openUnilabDialog, setOpenUnilabDialog] = useState(false);
    const [permissionDenied, setPermissionDenied] = useState(false); // State for permission dialog
    const [isProcessing, setIsProcessing] = useState(false); // State to track if an action is in progress
    const [pdfViewer, setPdfViewer] = useState(null)
    const [openPdfViewerDialog, setOpenPDFViewerDialog] = useState(false)
    const [openUnilabVerifiedDialog, setOpenUnilabVerifiedDialog] = useState(false);
    const [isViewingDocument, setViewingDocument] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openMismatchDialog, setOpenMismatchDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isScanning, setIsScanning] = useState(false);



    const [userLocation, setUserLocation] = useState(null);

    // define the function that finds the users geolocation
    useEffect(() => {
        // if geolocation is supported by the users browser
        if (navigator.geolocation) {
            // get the current users location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // save the geolocation coordinates in two variables
                    const { latitude, longitude } = position.coords;
                    // update the value of userlocation variable
                    setUserLocation({ latitude, longitude });
                },
                // if there was an error getting the users location
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        }
        // if geolocation is not supported by the users browser
        else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, [])


    console.log("userLocation verify", userLocation)

    const handleClose = () => {
        setOpenPDFViewerDialog(false)
    }

    useEffect(() => {
        if (pdfViewer) {
            setOpenPDFViewerDialog(true)
        }
    }, [pdfViewer])

    const login = async (onSuccess) => {
        if (isProcessing) return; // Prevent multiple triggers
        setIsProcessing(true);

        try {
            const res = await instance.loginPopup(loginRequest);
            if (res) {
                onSuccess(); // Call the success callback
            }
        } catch (error) {
            enqueueSnackbar("Login failed. Please try again.", {
                variant: "error",
                autoHideDuration: 3000,
            });
        } finally {
            setIsProcessing(false); // Reset the processing state
        }
    };


    const checkLocationPermission = async () => {
        try {
            const result = await navigator.permissions.query({ name: "geolocation" });
            if (result.state === "granted") {
                return true;
            } else if (result.state === "prompt") {
                await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(
                        () => resolve(true),
                        () => reject(false)
                    );
                });
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    };

    const checkCameraPermission = async () => {
        try {
            const result = await navigator.permissions.query({ name: "camera" });
            if (result.state === "granted") {
                return true;
            } else if (result.state === "prompt") {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                stream.getTracks().forEach(track => track.stop());
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    };

    const isMobileOrTablet = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /android|iphone|ipad|ipod|mobile|tablet/i.test(userAgent) || window.innerWidth <= 1024;
    };


    const handleOpenScanComponent = async () => {
        if (isProcessing) return; // Prevent multiple triggers
        setIsProcessing(true);

        const hasLocationPermission = await checkLocationPermission();
        const hasCameraPermission = await checkCameraPermission();

        if (hasLocationPermission && hasCameraPermission) {
            if (isMobileOrTablet()) {
                if (isUserLoggedIn) {
                    setOpenScan(true);
                    setOpenUpload(false);
                    setIsDialogOpen(false);
                } else {
                    setIsDialogOpen(true);
                }
            } else { // Desktop or other devices
                if (!isUserLoggedIn) {
                    await login(() => {
                        setOpenScan(true);
                        setOpenUpload(false);
                    });
                } else {
                    setOpenScan(true);
                    setOpenUpload(false);
                    setIsDialogOpen(false);
                }
            }
        } else {
            setPermissionDenied(true);
        }

        // if (hasLocationPermission && hasCameraPermission) {
        //     if (isUserLoggedIn) {
        //         setOpenScan(true);
        //         setOpenUpload(false);
        //     } else {
        //         setOpenScan(false);
        //         setOpenUpload(false);
        //         await login(() => {
        //             setOpenScan(true);
        //             setOpenUpload(false);
        //         });
        //     }
        // } else {
        //     setPermissionDenied(true);
        // }
        setIsProcessing(false); // Reset the processing state
    };

    const handleOpenUploadDataComponent = async () => {
        if (isProcessing) return; // Prevent multiple triggers
        setIsProcessing(true);

        if (isUserLoggedIn) {
            setOpenScan(false);
            setOpenUpload(true);
        } else {
            setOpenScan(false);
            setOpenUpload(false);
            await login(() => {
                setOpenScan(false);
                setOpenUpload(true);
            });
        }

        setIsProcessing(false); // Reset the processing state
    };


    // useEffect(() => {
    //     if (localStorage.getItem("isComingFromUpload")) {
    //         localStorage.removeItem("isComingFromUpload");

    //         // Use JavaScript to scroll to the upload-data-section
    //         const uploadDataSection = document.getElementById("upload-data-section");
    //         if (uploadDataSection) {
    //             uploadDataSection.scrollIntoView({ behavior: "smooth" });
    //         }

    //         handleOpenUploadDataComponent();
    //     }
    // }, [])


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [reference, setReference] = useState('');

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleVerifyReference = async (reference) => {
        if (!isUserLoggedIn) {
            try {
                await instance.loginPopup(loginRequest);
            } catch (error) {
                console.error('Login failed:', error);
            }
            return;
        }

        // Show loader while processing
        setLoading(true);

        // Check location permission
        const hasPermission = await checkLocationPermission();

        if (!hasPermission) {
            setLoading(false);
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            ovi: reference,
            latitude: userLocation && userLocation.latitude,
            longitude: userLocation && userLocation.longitude,
            device_info: "samsung SM-A042F",
            device_id: "fdd36ed7fd14cc39",
            device_identity: "samsung SM-A042F, ID: fdd36ed7fd14cc39",
            v_method: "QR",
        };

        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        const isTablet = /Tablet|iPad/i.test(navigator.userAgent);

        try {
            if (isMobile || isTablet) {
                await verifyOvi(reference, payload, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData);
            } else {
                await verifyReference(reference, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData, setPdfViewer);
            }
        } catch (error) {
            console.error('Error verifying reference:', error);
        } finally {
            setLoading(false);
        }
    };

    console.log("aaa openVerificationRes here xxx", openVerificationRes)

    return (
        <Box sx={{ overflow: 'hidden' }}>

            {snackbarOpen &&
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert onClose={handleCloseSnackbar} severity="error">
                        Location permission is required. Please allow location access in your browser settings to verify OVI/Reference.
                    </Alert>
                </Snackbar>
            }

            {pdfViewer &&
                <Dialog fullScreen open={openPdfViewerDialog} onClose={handleClose} sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1}}>
                    <DialogContent>
                        <Button onClick={() => window.location.href = "/verification"} sx={{ mb: 2 }}>Close</Button>
                        <PDFViewerComponent pdfView={pdfViewer} />
                    </DialogContent>
                </Dialog>
            }

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="xs" fullWidth sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }}>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        padding: "20px",
                    }}
                >
                    <img src={ovcodeLogo} style={{ width: "70%", marginBottom: "20px" }} alt="Logo" />
                    <Typography gutterBottom sx={{ fontSize: 18, fontFamily: "Poppins, sans-serif" }}>
                        To use verification, please log in first.
                    </Typography>
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: "center",
                        padding: "16px",
                    }}
                >
                    <Button
                        onClick={login}
                        disabled={isProcessing}
                        sx={{
                            fontSize: 20,
                            backgroundColor: "#0c306f",
                            color: "#fff",
                            textTransform: "none",
                            width: "50%",
                            fontFamily: "Poppins, sans-serif",
                            "&:hover": {
                                backgroundColor: "#0a254b",
                            },
                        }}
                    >
                        {isProcessing ? <CircularProgress size={24} /> : "Login"}
                    </Button>
                </DialogActions>
            </Dialog>

            <DrawerHeader />

            <VerifyBannerComponent />

            <div id="upload-data-section"></div>
            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                <Grid item xs={6} sx={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                    <Box>
                        <CardMedia
                            onClick={handleOpenScanComponent}
                            component="img"
                            image={scanGIF}
                            style={{ cursor: isProcessing || isLoading || isScanning ? "not-allowed" : "pointer", objectFit: 'contain', margin: 'auto', width: (breakpoints === "xs" || breakpoints === "sm") ? "100%" : "60%" }}
                        />
                        <Box sx={{ mb: 4, marginLeft: (breakpoints === "xs" || breakpoints === "sm") ? 'auto' : -2 }}>
                            <Typography
                                sx={{ fontSize: '1.2rem', margin: 0, color: 'rgb(100, 110, 115)', fontWeight: 700, textTransform: 'uppercase' }}
                            >
                                SCAN CODE
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} sx={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                    <Box onClick={handleOpenUploadDataComponent}>
                        <CardMedia
                            component="img"
                            image={uploadGIF}
                            style={{ cursor: isProcessing ? "not-allowed" : "pointer", objectFit: 'contain', margin: 'auto', width: (breakpoints === "xs" || breakpoints === "sm") ? "100%" : "60%" }}
                        />
                        <Box sx={{ mb: 4, marginLeft: (breakpoints === "xs" || breakpoints === "sm") ? 'auto' : -2 }}>
                            <Typography sx={{ fontSize: '1.2rem', margin: 0, color: 'rgb(100, 110, 115)', fontWeight: 700, textTransform: 'uppercase' }}>
                                UPLOAD DATA
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                <Grid item xs={12} sx={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}>
                        <TextField
                            variant="standard"
                            placeholder="Enter OVI/Reference here"
                            value={reference}
                            onChange={(e) => setReference(e.target.value)}
                            sx={{
                                textAlign: 'center',
                                fontSize: (breakpoints === "xs" || breakpoints === "sm") ? '24px' : '1.2rem',
                                color: 'rgb(100, 110, 115)',
                                width: (breakpoints === "xs" || breakpoints === "sm") ? '80%' : '40%',
                                '& .MuiInputBase-input::placeholder': {
                                    fontSize: (breakpoints === "xs" || breakpoints === "sm") ? '13px' : '1.2rem',
                                    margin: 0,
                                    color: 'rgb(100, 110, 115)',
                                    fontWeight: 500,
                                    textTransform: 'uppercase',
                                    textAlign: 'center'
                                },
                                '& .MuiInputBase-input': {
                                    textAlign: 'center',
                                    fontSize: (breakpoints === "xs" || breakpoints === "sm") ? '13px' : '1.2rem',
                                    color: 'rgb(100, 110, 115)'
                                }
                            }}
                        />
                        &nbsp;
                        <IconButton edge="end" onClick={() => handleVerifyReference(reference)}>
                            <ArrowForwardIcon sx={{ color: '#007aff', width: 28, height: 28 }} />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <br />

            {openUpload &&
                <DataUploaderComponent breakpoints={breakpoints} setOpenUpload={setOpenUpload} openUpload={openUpload} setPdfViewer={setPdfViewer}
                    openVerifiedData={openVerifiedData} setOpenUnverifiedData={setOpenUnverifiedData} setOpenVerifiedData={setOpenVerifiedData}
                    setOpenVerificationRes={setOpenVerificationRes} setOpenMismatchDialog={setOpenMismatchDialog} referenceDataScanned={referenceDataScanned}
                    scannedReference={scannedReference}
                />
            }
            {openScan &&
                <ScanQRComponent breakpoints={breakpoints} setOpenScan={setOpenScan} setOpenVerifiedData={setOpenVerifiedData}
                    setOpenUnverifiedData={setOpenUnverifiedData} openVerificationRes={openVerificationRes} setLoading={setIsLoading}
                    setOpenTobaccoVerifiedDialog={setOpenTobaccoVerifiedDialog} setOpenVerificationRes={setOpenVerificationRes} setIsScanning={setIsScanning}
                    setOpenUnilabDialog={setOpenUnilabDialog} setPdfViewer={setPdfViewer} setOpenUnilabVerifiedDialog={setOpenUnilabVerifiedDialog}
                />
            }
            {openTobaccoVerifiedDialog &&
                <TobaccoVerifiedDataDialog openTobaccoVerifiedDialog={openTobaccoVerifiedDialog} setOpenTobaccoVerifiedDialog={setOpenTobaccoVerifiedDialog} />
            }
            {openUnilabDialog &&
                <UnilabDialog openUnilabDialog={openUnilabDialog} setOpenUnilabDialog={setOpenUnilabDialog} />
            }
            {openUnverifiedData &&
                <UnverifiedDataDialog breakpoints={breakpoints} openUnverifiedData={openUnverifiedData} setOpenUnverifiedData={setOpenUnverifiedData} />
            }
            {permissionDenied &&
                <PermissionDeniedDialog open={permissionDenied} onClose={() => setPermissionDenied(false)} />
            }

            {openVerificationRes &&
                <VerificationResultDialogComponent openVerificationRes={openVerificationRes} setOpenVerificationRes={setOpenVerificationRes}
                    setPdfViewer={setPdfViewer} setViewingDocument={setViewingDocument} setOpenVerifiedData={setOpenVerifiedData}
                    setOpenUnverifiedData={setOpenUnverifiedData} setOpenMismatchDialog={setOpenMismatchDialog} setOpenUpload={setOpenUpload}
                    openUpload={openUpload}
                />
            }

            {openVerifiedData &&
                <VerifiedDataDialog breakpoints={breakpoints} setPdfViewer={setPdfViewer} setViewingDocument={setViewingDocument}
                    openVerifiedData={openVerifiedData} setOpenVerifiedData={setOpenVerifiedData} setOpenVerificationRes={setOpenVerificationRes}
                />
            }

            {openUnilabVerifiedDialog &&
                <UnilabVerifiedDataDialog openUnilabVerifiedDialog={openUnilabVerifiedDialog} setOpenUnilabVerifiedDialog={setOpenUnilabVerifiedDialog} />
            }

            {openMismatchDialog &&
                <DataMismatchDialog
                    openMismatchDialog={openMismatchDialog}
                    setOpenMismatchDialog={setOpenMismatchDialog}
                    referenceDataScanned={referenceDataScanned}
                    scannedReference={scannedReference}
                />
            }
        </Box>
    );
};

export default VerifyComponent;