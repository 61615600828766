import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import useWidth from "../../hooks/useWidth";
import BackToTopButton from "../layout/scroll-up.component";

const PrivacyPolicyComponent = () => {

    const breakpoints = useWidth();

    
    return (
        <Box  sx={{mt: 2.5, padding: "0px"}}>
            
            <Box sx={{mb: 4, backgroundColor: "#f2f2f2", paddingTop: "25px", paddingBottom: "25px"}}>
                <Typography sx={{color: "#000000CC", fontSize: 48, margin: 0, lineHeight: 1.2, fontWeight: 400,  textAlign: 'center' }}>
                    OVCODE Privacy Policy
                </Typography>
            </Box>

            <div class="resources-container">
            
            <BackToTopButton />

            <Grid container sx={{padding: "0px"}}>
                <Grid item xs={12} sx={{mt: 2}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        Introduction
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        At OVCODE, data privacy is important to us. 
                        This Privacy Policy sets out how OVCODE collect and use personal information from customers and other individuals who access or use our websites.
                        By using our Services, you understand that we will collect and use your personal information as described in this Privacy Policy.
                    </Typography>
                </Grid>
                <Grid item sx={{mt: 1}}>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        OVCODE’s core product and Services help users create, complete, and show the validity of digital or electronic transactions, 
                        such as electronically signing a contract or placing a digital signature on a document. 
                        As part of our Services, OVCODE helps users to collect and record information that helps the parties prove the validity of the transactions. 
                        This information includes the persons who are involved in the transactions and the devices those persons use.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 2.5}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        1. How We Collect Personal Information
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        OVCODE collects personal information when you register with OVCODE, when you use OVCODE products or services, when you visit OVCODE pages and when you enter promotions.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        1.1 Personal Information We Collect from You.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 16}}>
                        You provide us with personal information about yourself when you:
                        <ul style={{fontWeight: "400", marginTop: "3px"}}>
                            <li>
                                Register or log in to your account
                            </li>
                            <li>
                                Start, sign, or review an electronic document
                            </li>
                            <li>
                                Create or edit your user profile
                            </li>
                            <li>
                                Contact customer support
                            </li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        Personal information you provide to us:
                    </Typography>
                </Grid>
                <Grid item sx={{mt: -1}}>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        <ul>
                            <li>
                                Identifiers: name, email address, mailing address, contact number, occupation, industry, or electronic signature
                            </li>
                            <li>
                                Commercial information: billing information, products or services purchased
                            </li>
                            <li>
                                Geolocation: physical location
                            </li>
                        </ul>
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        1.2 Personal Information We Collect Automatically.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We automatically collect personal information from you and your devices when you use our Services, even when you visit our sites or apps without logging in
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        The categories of personal information we automatically collect includes:
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        Device, Usage Information, and Transactional Data
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We collect personal information about how you use our Services and the computers or other devices, such as mobile phones or tablets, you use to access our Services. Examples are:
                    </Typography>
                </Grid>
                <Grid item sx={{mt: -1}}>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        <ul>
                            <li>
                                IP address
                            </li>
                            <li>
                                Precise geolocation information that you allow our apps to access (usually from your mobile device)
                            </li>
                            <li>
                                Unique device identifiers and device attributes, like operating system and browser type
                            </li>
                            <li>
                                Usage data, such as: web log data, referring and exit pages and URLs, platform type, number of clicks, domain names, landing pages, pages and content viewed and the order of those pages, the amount of time spent on particular pages, the date and time you used our Services, the frequency of your use of our Services, error logs, and other similar information
                            </li>
                            <li>
                                Transactional data, such as: names and email addresses of parties to a transaction, subject line, history of actions that individuals take on a transaction (e.g., review, sign, enable features) and personal information about those individuals or their devices, such as name, email address, IP address, and authentication methods
                            </li>
                        </ul>
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        Cookies
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We may use cookies, which are text files containing small amounts of information that are downloaded on your device (“Cookies”). Cookies can store your preferences and your username.
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        Use of Personal Information
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        In general, we collect, use, store, and process your personal information to provide our Services, to fix and improve them and use it to develop new Services. 
                        Here are some examples of how we use the personal information we process:
                    </Typography>
                </Grid>
                <Grid item sx={{mt: -1}}>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        <ul>
                            <li>
                                To identify you when you visit our websites.
                            </li>
                            <li>
                                To Provide you with the Services and products you request and collect payments
                            </li>
                            <li>
                                To record details about what happens with electronic documents, such as who viewed or signed, and when these events occur
                            </li>
                            <li>
                                To improve our Services and offerings.
                            </li>
                            <li>
                                To promote the security of our Website and Services.
                            </li>
                            <li>
                                To conduct analytics.
                            </li>
                            <li>
                                To respond to inquiries related to support, resolving disputes, or other requests.
                            </li>
                            <li>
                                To prevent, investigate and respond to: fraud, unauthorized access to or use of our Services, breaches of terms and policies, or other wrongful behavior
                            </li>
                            <li>
                                To Comply with legal obligations
                            </li>
                            <li>
                                To Meet legal retention periods
                            </li>
                            <li>
                                For internal administrative purposes, as well as to manage our relationships.
                            </li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start",fontWeight: 400, fontSize: 26}}>
                        Other Uses
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We may combine the personal information we collect (“aggregate”) or remove pieces of personal information (“de-identify”) to limit or prevent identification of any user or device to help with goals like research and marketing. 
                        This Privacy Policy does not apply to our use of such aggregated or de-identified information.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        Lawful Basis for Processing Your Personal Information
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We normally collect or use personal information from you or others where we have your consent to do so, where we need the personal information to perform a contract with you, or where the processing is in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. 
                        In some cases, we may have a legal obligation to collect or retain personal information or may need the personal information to protect your vital interests or those of another person. For example, when we:
                    </Typography>
                </Grid>
                <Grid item sx={{mt: -1}}>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        <ul>
                            <li>
                                Use personal information to create and manage an account, we need it in order to provide relevant Services.
                            </li>
                            <li>
                                Gather and record data associated with use of a digital certificate or digital signature, it is to comply with regulations.
                            </li>
                            <li>
                                Use names and email addresses for email marketing purposes, we do so with your consent (which you can withdraw at any time).
                            </li>
                            <li>
                                Gather usage data and analyze it to improve our Services, we do so base on our legitimate interest in safeguarding and improving our Services.
                            </li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        If you have granted us your consent to process your personal data for certain purposes, 
                        we will process your personal data within the context of, and based on, this consent insofar as no other legal basis applies and we require such a legal basis.
                        Consent granted can be withdrawn at any time, although this withdrawal will not affect data processing operations that have already taken place.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        If you have questions about or need further information concerning the lawful basis on which we collect and use your personal information, 
                        please contact us using the contact details provided in Section 10 of this Privacy Policy
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        3. Personal Information Sharing
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We do not rent, sell or share your personal information with third parties except as disclosed in this Privacy Policy.
                        We share personal information as follows:
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        3.1 Service Providers
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We share your personal information with other companies we use to support our Services. 
                        These companies provide services like intelligent search technology, intelligent analytics, advertising, authentication systems, bill collection, fraud detection, and customer support. 
                        We have contracts with our service providers that address the safeguarding and proper use of your personal information.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        3.2 Affiliates
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We may share your personal information with other companies under common ownership or control with OVCODE. 
                        These companies use your personal information as described in this Privacy Policy.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        3.3 Public or Government Authorities
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We may share your personal information to follow applicable law or to respond to legal process (like a subpoena) only where we are legally compelled to do so. 
                        We also may share your personal information when there are threats to the physical safety of any person, violations of this Privacy Policy or other agreements, or to protect the legal rights of third parties, including our employees, users, or the public.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        3.4 Business Transactions
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We may share your personal information during a corporate transaction like a merger, or sale of our assets, or as part of the due diligence for such contemplated transactions. 
                        If a corporate transaction occurs, we will provide notification of any changes to control of your personal information, as well as choices you may have.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We may share your personal information in other ways if you have asked us to do so or have given consent. 
                        For example, with your consent, we may post user testimonials that may identify you.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Your personal information may also be shared as described below:
                    </Typography>
                </Grid> 
             
                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        3.5 Other OVCODE users
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        When you allow others to access, use, or edit content in your account, we share that content with them.
                        For example, if you send an envelope to others for review or signature, we make the contents of the envelope available to them.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        3.6 Third Parties
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        When you allow others to access, use, or edit content in your account, we share that content with them. 
                        For example, if you send an envelope to others for review or signature, we make the contents of the envelope available to them.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        3.7 Public Information
                    </Typography>
                </Grid>
                <Grid item sx={{mt: -1}}>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        <ul style={{marginTop: "1px"}}>
                            <li>
                                User-Generated Content. When you comment on our blogs or in our community forums, this information may also be read, collected, and used by others.
                            </li>
                            <li>
                                Profile Information. When you create a OVCODE profile, other OVCODE users can view your profile information. If you would like to make this information private, please visit your account settings.
                            </li> 
                        </ul>
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        3.8 Your Employer or Organization
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        When you create an account or user role with an email address assigned to you as an employee, contractor or member of an organization, such as yourname@youremployer.com or yourname@nonprofit.org, 
                        that organization can find your account and take certain actions that may affect your account.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        4. Retention of Personal Information
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        This section describes many of the actions you can take to change or limit the collection or use of your personal information.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        5. Your Choices
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We keep your personal information for no longer than necessary for the purposes for which it is processed. 
                        The length of time for which we retain personal information depends on the purposes for which we collected and use it and/or as required to comply with applicable laws as set out in our data retention policy and information handling standards. 
                        Where there are technical limitations that prevent deletion or anonymization, we safeguard personal information and limit active use of it.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        5.1 Profile
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        You are not required to fill out a profile. 
                        If you do, you can access and review this personal information. 
                        If any personal information is inaccurate or incomplete, you can make changes in your account settings.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        5.2 Cookies and Other Related Technology
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        You can decline cookies through your browser settings or in our Cookie Notice. 
                        However, if you decline cookies, you may not be able to use some parts of our Services. 
                        Please note we do not recognize or respond to browser-initiated. Do Not Track signals.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        5.3 Device and Usage Information
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        If you do not want us to see your device location, you can turn off location sharing on your device, 
                        change your device privacy settings, or decline to share location on your browser.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        5.4 Closing Your Account
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        If you wish to close your account, please log in to your account and edit your plan.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        5.5 Request to Delete Your Information
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        If you wish to delete your data from OVCODE servers for any reason, please send a request to support@ovcode.com indicating your desire to delete any data that was affiliated from your account.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        The deletion process will begin after the 7-day grace period from the time you make the request. 
                        During this period, you can still ask to cancel the request.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Once data deletion is in progress, cancellation will no longer be accepted.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        5.6 Complaints
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We are committed to resolving valid complaints about your privacy and our collection or use of your personal information. 
                        For questions or complaints regarding our data use practices or Privacy Policy, please contact us at support@ovcode.com
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        6. Children's Privacy
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Our Services are not designed for and are not marketed to people under the age of 16 (“minors”). 
                        We do not knowingly collect or ask for personal information from minors. 
                        We do not knowingly allow minors to use our Services. 
                        If you are a minor, please do not use our Services or send us your personal information. 
                        We delete personal information that we learn is collected from a minor without verified parental consent. 
                        Please contact us at ovcode.com if you believe we might have personal information from or about a minor that should be removed from our system.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        7. Your Privacy Rights
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Within the context of the data protection legislation that applies to you and insofar as this legislation contains corresponding provisions (such as in cases involving the EU General Data Protection Regulation (GDPR) and,
                        in some cases, also the Swiss Federal Act on Data Protection (FADP), you may have the following rights granted to you by the Data Privacy Act, its Implementing Rules and Regulations and other related issuances:
                    </Typography>
                </Grid>
                <Grid item sx={{mt: -1}}>
                    <Typography variant="subtitle1" sx={{textAlign: "start"}}>
                        <ul style={{marginTop: "1px"}}>
                            <li>
                                Right to be informed on the processing of your personal data
                            </li>
                            <li>
                                Right to object the processing or sharing of your personal data
                            </li>
                            <li>
                                Right to request access to the personal data that we have collected about you for the purposes of reviewing, modifying or requesting deletion of the data
                            </li>
                            <li>
                                Right to request a copy of the personal data we have collected about you
                            </li> 
                            <li>
                                Right to make changes with regard any of your personal data
                            </li>
                            <li>
                                Right to suspend, withdraw or order the blocking, removal or destruction of your personal data from our system
                            </li>
                            <li>
                                Right to be indemnified for any damages sustained due to inaccurate, incomplete, outdated, false, unlawfully obtained or unauthorized use of your personal data, considering any violation of your rights and freedoms as a data subject
                            </li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Please note, however, that we, in turn, reserve the right to apply the restrictions provided for by law, for example if we are obliged to retain or process certain data, have an overriding interest in the data (to the extent that we are entitled to invoke it) or require the data in order to assert claims.
                        If you are required to pay any costs, we will notify you in advance.
                    </Typography>
                </Grid>
                <Grid item sx={{mt: 1}}>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We have already informed you of your right to withdraw your consent in sections 2.
                    </Typography>
                </Grid>
                <Grid item sx={{mt: 1}}>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        Please note that the exercise of these rights may conflict with contractual agreements, resulting, for example, in the premature termination of the agreement or in costs being incurred. We will inform you in advance in such cases, unless such information is already set out in the contractual provisions.
                    </Typography>
                </Grid>
                <Grid item sx={{mt: 1}}>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        In general, you must be able to clearly prove your identity in order to exercise these rights (e.g., by presenting a copy of your ID card if your identity is not otherwise clear/cannot otherwise be verified). 
                        You can use the address set out in section 10 to contact us in order to assert your rights.
                    </Typography>
                </Grid>
                <Grid item sx={{mt: 1}}>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        In addition, all data subjects have the right to enforce their rights in court or to lodge a complaint with the competent data protection authority.
                        The competent data protection authority in Switzerland is the Federal Data Protection and Information Commissioner.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        8. How We Protect Your Personal Information
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        To keep your personal information safe, we use physical, electronic, and managerial tools. 
                        We apply these tools based on the sensitivity of the personal information we collect, use, and store, and the current state of technology. 
                        We protect your personal information through technical and organizational security measures to minimize risks associated with data loss, misuse, unauthorized access, and unauthorized disclosure and alteration.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        9. Changes to This Policy
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        We may amend this Privacy Policy to reflect changes in the law, our companies, our Services, our data collection use and practices, or advances in technology. 
                        Our use of the personal information we collect is subject to the Privacy Policy in effect at the time such personal information is used. 
                        Depending on the type of change, we may notify you of the change by posting on this page, adding a banner to ovcode.com or by email.
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 1}}>
                    <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        10. How to Contact Us
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start"}}>
                        For questions or complaints regarding our use of your personal information or Privacy Policy, 
                        please contact us at support@ovcode.com or OVCODE Switzerland AG., Customer Care – Privacy Policy Issues, Talstrasse, Zürich, Switzerland, Kanton Zürich
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{mt: 3}}>
                    <Typography variant="subtitle1" gutterBottom sx={{textAlign: "start", fontWeight: 400, fontSize: 26}}>
                        Last Updated: April 06, 2022
                    </Typography>
                </Grid>
            </Grid>
            </div>
        </Box>
    )
}

export default PrivacyPolicyComponent