import UnilabVerificationResComponent from "./verification-result/unilab-verification-res.component";


const clients = [
    {
        id: "665593562b7d1b563f12a274",
        name: "UNILAB_TEST",
        component: UnilabVerificationResComponent 
    }
];

const getComponentById = (id) => {
    return clients.find((item) => item.id === id); 
};

export { getComponentById };