import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ListItem, ListItemText, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";
import OVCodeLogo from "../../icons/new-ovcode-logo.png";
import useWidth from "../../hooks/useWidth";
import { Link } from "react-router-dom";

const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: "10px 16px 10px 16px" }} {...other}>
            {children}

            {onClose ?
                (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute", right: 8, top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )
                :
                null
            }
        </DialogTitle>
    )
}

const DataRevokeDialog = (props) => {

    const breakpoints = useWidth();
    const scannedReference = localStorage.getItem("scannedReference");
    const unparse_info = localStorage.getItem("scannedInfo");

    let scannedInfo = null;

    if (unparse_info) {
        scannedInfo = JSON.parse(unparse_info);
    }

    return (
        
          <>
                <Box sx={{ justifyContent: "center", display: "flex", background: "red", height: "50px", padding: "12px" }}>
                    <Typography variant="subtitle1" sx={{ color: "#fff", fontSize: 18, fontWeight: 600 }}>
                        DATA IS REVOKED
                    </Typography>
                </Box>


                <Box sx={{ padding: '2px', textAlign: 'center', mt: 0.5 }}>
                    <Typography sx={{ color: 'orange', fontSize: 14 }}>
                        The data reference was found. <br /> Please upload a digital copy of an authenticated document for proper verification.
                    </Typography>
                </Box>

                <Grid container direction="row" sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? "8px 25px" : "8px 40px", mb: "-23px" }}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                            {scannedInfo?.owner.business.name}
                        </Typography>
                    </Grid>

                    <Grid item xs={(scannedInfo?.owner.business.name == null || scannedInfo?.owner.business.id == null) ? 12 : 6} sx={{ display: (scannedReference == "null" || scannedReference == "undefined") ? "none" : "visible", width: '100%', wordWrap: 'break-word' }}>
                        <ListItemText
                            primary={
                                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                                    OVCODE:
                                </Typography>
                            }
                            secondary={ scannedReference && scannedReference }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ListItemText
                            primary={
                                <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                                    {(scannedInfo?.details.updated_time) ? "Published Date:" : null}
                                </Typography>
                            }
                            secondary={scannedInfo?.details.updated_time}
                        />
                    </Grid>
                </Grid>

                <Grid container direction="row" sx={{ mt: '10px', padding: "8px 25px", mb: "-23px" }}>
                    <ListItem sx={{ padding: '0px' }}>
                        <ListItemText style={{ display: "flex" }}
                            primary={
                                <Typography variant="subtitle2" sx={{ fontWeight: "bold", fontSize: 14 }}>
                                    Status:
                                </Typography>
                            }
                            secondary={
                                <Typography variant="subtitle2"
                                    sx={{
                                        fontSize: "16px", ml: "3px", mt: "-2px",
                                        color: "red"
                                    }}
                                >
                                    {scannedInfo?.status}
                                </Typography>
                            }
                        />
                    </ListItem>
                </Grid>

                <Grid container direction="row" sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? "8px 25px" : "8px 35px", mb: "10px", mt: "5px" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: 300, color: "#666666d4", mt: "13px", mb: "14px" }}>
                        Stay safe online! Before sharing sensitive info
                        on <Link href="https://ovcode.com/" underline="none" target="_blank">https://ovcode.com,</Link> verify the website certificate and hostname.
                        Check the padlock icon next to ovcode.com to ensure you're on the real site.
                    </Typography>
                </Grid>

                <Box sx={{alignSelf: 'center', justifyContent: 'center', alignItems: 'center', textAlign:'center', display: 'flex'}} >
                    <Typography variant="subtitle1">
                        Powered by:
                    </Typography>

                    &nbsp;

                    <img alt="" src={OVCodeLogo} style={{ width: 100 }} />
                </Box>


        </>
    )
}

export default DataRevokeDialog