import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import VerifyComponent from "../../landing/verify/verify.component";
import { useEffect, useState } from "react";
import useVerification from "../../../hooks/useVerification";
import { useLocation, useParams } from "react-router-dom";
import UnilabDialog from "../../dialog/unilab-dialog.component";
import VerificationResultDialogComponent from "../../dialog/verification-result-dialog.component";
import { useMsal } from "@azure/msal-react";
import AuthService from "../../../services/auth";
import { enqueueSnackbar } from "notistack";
import ovcodeLogo from '../../../icons/new-ovcode-logo.png';
import { loginRequest } from "../../../config";
import UnverifiedDataDialog from "../../dialog/unverified-data-dialog.component";

const UnilabComponent = () => {
  const breakpoints = useWidth();
  const { instance } = useMsal();
  const { verifyUnilabv2Reference, verifyOviUnilab } = useVerification();
  const query = new URLSearchParams(useLocation().search);
  const { id } = useParams();
  const verificationOvi = id;

  const [openScan, setOpenScan] = useState(false);
  const [openUnverifiedData, setOpenUnverifiedData] = useState(false);
  const [openUnilabDialog, setOpenUnilabDialog] = useState(false);
  const [pdfViewer, setPdfViewer] = useState(null);
  const [isViewingDocument, setViewingDocument] = useState(false);
  const [openVerificationRes, setOpenVerificationRes] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [scannedRef, setScannedRef] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [openVerifiedData, setOpenVerifiedData] = useState(false);



  useEffect(() => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
        // get the current users location
        navigator.geolocation.getCurrentPosition(
        (position) => {
            // save the geolocation coordinates in two variables
            const { latitude, longitude } = position.coords;
            // update the value of userlocation variable
            setUserLocation({ latitude, longitude });
        },
        // if there was an error getting the users location
        (error) => {
            console.error('Error getting user location:', error);
        }
        );
    }
    // if geolocation is not supported by the users browser
    else {
      enqueueSnackbar("Location permission is required. Please enable it in your browser settings.", {
        variant: "warning",
      });
    }
},[])

console.log("userLocation unilab" , userLocation)


  useEffect(() => {
    if (verificationOvi && userLocation) {
      checkPermissionsAndVerify(verificationOvi);
    }
  }, [verificationOvi, userLocation]);

  const login = async () => {
    if (isProcessing) return;

    setIsProcessing(true);

    try {
      const res = await instance.loginPopup(loginRequest);
      if (res) {
        setIsDialogOpen(false);
        handleVerifyReference(verificationOvi);
      }
    } catch (error) {
      console.log('Error during login:', error);
      enqueueSnackbar("Login failed. Please try again.", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const checkPermissionsAndVerify = (refData) => {
    const isUserLoggedIn = AuthService.isUserLoggedIn();

    if (!isUserLoggedIn) {
      setIsDialogOpen(true);
      return;
    }

    handleVerifyReference(refData);
  };

  const handleVerifyReference = (refData) => {

    let reference;
    let oviValue;

    if (refData.startsWith("http://") || refData.startsWith("https://")) {
      try {
        const url = new URL(refData);
        const searchParams = new URLSearchParams(url.search);
        oviValue = searchParams.get("ovi");

        if (oviValue && /^OVI\d+$/.test(oviValue)) {
          reference = oviValue;
        } else {
          reference = refData.split("/").pop();
        }
      } catch {
        reference = refData;
      }
    } else {
      reference = refData;
    }

    const shouldUseOvi = shouldVerifyOvi(reference);

    const payload = {
      ovi: reference,
      latitude: userLocation && userLocation.latitude, 
      longitude: userLocation && userLocation.longitude, 
      device_info: "samsung SM-A042F",
      device_id: "fdd36ed7fd14cc39",
      device_identity: "samsung SM-A042F, ID: fdd36ed7fd14cc39",
      v_method: "QR",
    };

    if (shouldUseOvi) {
      verifyOviUnilab(reference, payload, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData);
    } else {
      const isMobile = /Mobi|Android/i.test(navigator.userAgent);
      const isTablet = /Tablet|iPad/i.test(navigator.userAgent);

      if (isMobile || isTablet) {
        verifyOviUnilab(reference, payload, setOpenScan, setOpenVerificationRes, setOpenUnverifiedData);
      } else {
        verifyUnilabv2Reference(reference, setOpenScan, setOpenUnverifiedData, setOpenUnilabDialog);
      }
    }
  };

  const shouldVerifyOvi = (reference) => {
    const oviPattern = /^OVI\d+$/;
    return oviPattern.test(reference);
  };

  return (
    <Box>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="xs" fullWidth sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1}}>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            padding: "20px",
          }}
        >
          <img src={ovcodeLogo} style={{ width: "70%", marginBottom: "20px" }} alt="Logo" />
          <Typography gutterBottom sx={{ fontSize: 18, fontFamily: "Poppins, sans-serif" }}>
            To use verification, please log in first.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            padding: "16px",
          }}
        >
          <Button
            onClick={login}
            disabled={isProcessing}
            sx={{
              fontSize: 20,
              backgroundColor: "#0c306f",
              color: "#fff",
              textTransform: "none",
              width: "50%",
              fontFamily: "Poppins, sans-serif",
              "&:hover": {
                backgroundColor: "#0a254b",
              },
            }}
          >
            {isProcessing ? <CircularProgress size={24} /> : "Login"}
          </Button>
        </DialogActions>
      </Dialog>
    
      <Box
        sx={{
          width: breakpoints === "xs" || breakpoints === "sm" || breakpoints === "md" ? "90%" : "93%",
          display: "flex",
          justifyContent: "center",
          marginLeft: breakpoints === "xs" || breakpoints === "sm" || breakpoints === "md" ? "30px" : "60px",
        }}
      >
        <VerifyComponent />
      </Box>
      {isViewingDocument && <UnilabDialog pdfViewer={pdfViewer} />}
      {openVerificationRes &&
        <VerificationResultDialogComponent
          openUnverifiedData={openUnverifiedData}
          setOpenUnverifiedData={setOpenUnverifiedData}
          openUnilabDialog={openUnilabDialog}
          setOpenUnilabDialog={setOpenUnilabDialog}
          openVerificationRes={openVerificationRes}
          setOpenVerificationRes={setOpenVerificationRes}
          setOpenVerifiedData={setOpenVerifiedData}
        />
      }
      {openUnverifiedData && (
        <UnverifiedDataDialog
          breakpoints={breakpoints}
          openUnverifiedData={openUnverifiedData}
          setOpenUnverifiedData={setOpenUnverifiedData}
        />
      )}
       {openUnilabDialog &&
        <UnilabDialog openUnilabDialog={openUnilabDialog} setOpenUnilabDialog={setOpenUnilabDialog} />
      }
    </Box>
  );
};

export default UnilabComponent;