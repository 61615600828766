const SuccessResponse = (data) => {
    return {
        success : true,
        data : data
    }
}

const ErrorResponse = (error) => {
    return {
        success : false,
        error : error.error,
        code : error.code
    }
}

const QueryParamsFormat = (parameters) => {

    if (parameters) {
        let queryParameters = "";

        for (var k in parameters) {
            if (parameters.hasOwnProperty(k)) {
                if (parameters[k]) {
                    queryParameters += k.trim() + "=" + parameters[k] + "&";
                }
            }
        }

        return queryParameters.substring(0, queryParameters.length - 1)

    } else {
        return;
    }
}

export {
    SuccessResponse, ErrorResponse, QueryParamsFormat
}