import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Typography, ListItemText } from '@mui/material';
import { blogContentData } from './blog-content-data.component';
import ReactMarkdown from 'react-markdown';

const getRandomPosts = (posts, num) => {
  const shuffled = [...posts].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
};

const BlogContentComponent = () => {
  const location = useLocation(); 
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const blogPost = blogContentData.find((post) => post.url === currentPath);

  if (!blogPost) {
    return <Typography>No blog content found for this URL.</Typography>;
  }

  // Filter out the currentPath posts
  const filteredPosts = blogContentData.filter(post => post.url !== currentPath);

  // Get a random subset of 2 posts
  const randomPosts = getRandomPosts(filteredPosts, 2);

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
};

  return (
    <Box>
      <Card sx={{ cursor: 'pointer', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '18px', width: '90%', margin: 'auto' }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <ListItemText primary={
                    <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '2rem' }}>
                      {blogPost.title}
                    </Typography>
                  } secondary={
                    <Typography sx={{ fontSize: '1rem' }}>
                      {blogPost.date}
                    </Typography>
                  } />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
              <img src={blogPost.image} alt={blogPost.title} style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }}>
              <Typography sx={{ mt: 1, mb: 1, textAlign: 'justify', textJustify: 'inter-word' }}>
                {blogPost.description}
              </Typography>
            </Grid>
            {blogPost.sections?.map((section, index) => (
              <Grid item xs={12} sx={{ textAlign: 'start', mt: 3 }} key={index}>
                <Typography sx={{ color: '#333333', fontWeight: 'bold', fontSize: '1.25rem' }}>
                  {section.subtitle}
                </Typography>
                <div className="markdown-content" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                  <ReactMarkdown>{section.content}</ReactMarkdown>
                </div>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
      
      <Grid item xs={12} sx={{ textAlign: 'start', mt: 5, mb: 5 }}>
        <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '1.25rem' }}>Read Next</Typography>
      </Grid>

      <Grid container spacing={4} sx={{ width: '95%', margin: 'auto'  }}>
        {randomPosts.map(nextPost => (
          <Grid item xs={12} sm={6} key={nextPost.url} sx={{ paddingTop: '0px !important' }}>
            <Card
              sx={{ cursor: 'pointer', height: '100%', boxShadow: '2px 2px 8px rgba(0,0,0,.1)', border: '1px solid rgba(0,0,0,.1)', backgroundColor: '#fff', borderRadius: '4px', padding: '10px', display: 'flex', flexDirection: 'column'  }}
              onClick={() => navigate(nextPost.url)}
            >
              <CardContent sx={{ flexGrow: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <Grid container sx={{ flexGrow: 1 }}>
                  <Grid item xs={12}>
                    <Typography sx={{ color: '#017eff', fontWeight: 550, fontSize: '1.25rem', textAlign: 'start' }}>
                      {nextPost.title}
                    </Typography>
                    <Typography sx={{textAlign: 'start', mt: 1, mb: 1}}>
                      {nextPost.description.split('.')[0] + '.'}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography sx={{ textAlign: 'start', marginTop: 'auto', marginBottom: '5px', fontWeight: 550  }}>
                {formatDate(nextPost.date)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BlogContentComponent;
