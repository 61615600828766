import { Box } from "@mui/material"
import useWidth from "../../../hooks/useWidth"
import ElectronicMarkBannerComponent from "./electronic-mark-banner.component";
import AboutElectronicMarkComponent from "./about-electronic-mark.component";


const ElectronicMarkComponent = () => {

    const breakpoints = useWidth();


    return (
        <Box>
            
            <ElectronicMarkBannerComponent />

            <Box sx={{backgroundColor: '#E2E2E6', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <AboutElectronicMarkComponent />
            </Box>
           
        </Box>
    )
}

export default ElectronicMarkComponent;
