import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import useWidth from "../../../hooks/useWidth";
import ProductsBannerComponent from "./products-banner.component";
import ProductsSliderComponent from "./products-slider.component";
import ServicesSliderComponent from "./services-slider.component";
import SolutionsSliderComponent from "../solutions/solutions-slider.component";
import DrawerHeader from "../../layout/drawer-header";
import static_variable from "../../layout/static-variable.component";




function ProductsComponent() {

    const breakpoints = useWidth();

    return(


        <Box sx={{width: (breakpoints == "xs" || breakpoints == "sm") ? '90%' : '100%', margin: 'auto', overflowX : 'hidden'}}>

            <DrawerHeader/>

            <ProductsBannerComponent  static_variable={static_variable} />

            <DrawerHeader/> 

            <SolutionsSliderComponent breakpoints={breakpoints} />

            <DrawerHeader/> 

            <Box sx={{mb: 4}}>
                <Typography sx={{fontSize: '1.2rem', margin: 0, color: 'rgb(100, 110, 115)', fontWeight: 700, textTransform: 'uppercase'}}>
                    Services
                </Typography>
            </Box>

            <ServicesSliderComponent static_variable={static_variable} />

                {/* <Grid  container spacing={2}  direction="row" >
                    {products_images.map((value)=>{
                        return(
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                                <Card sx={{ boxShadow: 'none', cursor:'default', ':hover' :{boxShadow: 'rgba(200, 200, 200) 0px 7px 29px 0px'}}} >
                                    <CardMedia
                                        component="img"
                                        image={value.icon}
                                        style={{objectFit: 'contain', margin:'auto'}}
                                    />
                                </Card>
                                <Typography sx={{color: "#007fff", textAlign: 'center' ,fontWeight: 600, mt: 3, pt: 1,pb: 2,fontSize: 20}}>
                                    {value.name}
                                </Typography>
                                <Typography sx={{margin: 0, fontWeight: 400, fontSize: '1rem', textAlign: (breakpoints == "xs" || breakpoints == "sm") ? 'center' : 'justify', textJustify: 'inter-word',  color: 'rgb(100, 110, 115)'}}>
                                    {value.description}
                                </Typography>
                            </Grid>
                        )
                    })}
                </Grid> */}

            <DrawerHeader/>

            <Box sx={{mb: 4}}>
                <Typography sx={{fontSize: '1.2rem', margin: 0, color: 'rgb(100, 110, 115)', fontWeight: 700, textTransform: 'uppercase'}}>
                    Customer Stories
                </Typography>
            </Box>

            <ProductsSliderComponent  static_variable={static_variable}/>

            <DrawerHeader/>

            
        </Box>



    )
    
}

export default ProductsComponent