const unilab_res_types = {
    RL: 'RL',
    BL: 'BL',
    MPL: 'MPL'
}

const UNILAB_RES_RL_ITEMS = ["Name", "Used For", "Description",  "Serial Number","Manufactured Date", "Expiration Date", "Manufactured By"]
const UNILAB_RES_BL_ITEMS = ["Name", "Used For", "Description", "Serial Number", "Manufactured Date", "Expiration Date", "Delivery Date", "Hash Ref", "Distributed By"]
const UNILA_RES_MPL_SPECIAL_ITEMS = ["Name", "Boxes", "Pieces"]
const UNILA_RES_MPL_ITEMS = ["Name", "Boxes", "Pieces","Used For", "Description", "Serial Number", "Manufactured Date", "Hash Ref", "Manufactured By"]

export { unilab_res_types, UNILAB_RES_RL_ITEMS, UNILAB_RES_BL_ITEMS, UNILA_RES_MPL_ITEMS, UNILA_RES_MPL_SPECIAL_ITEMS }
