import { Box, Grid, Typography } from "@mui/material";
import useWidth from "../../hooks/useWidth";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DescriptionIcon from '@mui/icons-material/Description';

const HistoryMplTobaccoComponent = ({verificationName}) => {

    const breakpoints = useWidth();


    const openPdfViewer = (id) => {
        const pdfUrlWithId = `/view/pdf/verification?id=${id}`;
        window.open(pdfUrlWithId, '_blank');
    };

    const historyItems = verificationName === "MPL" ? [
        { id: 'S-5f190117-f2da-4720-9387-b8a8d19d93b7', date: '2024-23-01', location: 'Generic Tobacco Co. Manufacturing plant located in Makati, Philippines' }
    ]: [
        { id: 'S-37b02905-3dcd-49aa-8c67-b675d1f076a5', date: '2024-27-01', location: 'Convenience Store, Makati City, Philippines' },
        { id: 'S-dda20c4e-1358-4e80-8483-3932985aa109', date: '2024-26-01', location: 'Distributor Quezon City, Philippines' },
        { id: 'S-5f190117-f2da-4720-9387-b8a8d19d93b7', date: '2024-23-01', location: 'Generic Tobacco Co. Manufacturing plant located in Makati, Philippines' },
    ];

    return (
        <Box sx={{ padding: 6, pt: breakpoints === 'xs' ? 3 : 1, pb: 0, textAlign: 'start' }}>
            <Typography sx={{ fontWeight: 'bold', mb: 3, fontSize: 17 }}>History</Typography>
            <Grid container spacing={2}>
            {historyItems.map((item, index) => (
                    <Grid key={index} item xs={12} sx={{ paddingTop: '3px' }}>
                        <Grid container>
                            <Grid item xs={9}>
                                <Typography sx={{ fontSize: 17 }}>{item.id}</Typography>
                                <Typography sx={{ fontSize: 17 }}>{item.date}</Typography>
                                <Typography sx={{ fontSize: 17 }}>Location: {item.location}</Typography>
                                <hr style={{ borderTop: '2px dashed #000', width: '100%' }} />
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: 'center' }}>
                                <DescriptionIcon
                                    fontSize="large"
                                    sx={{ color: '#95B0C9', cursor: 'pointer' }}
                                    onClick={() => openPdfViewer(item.id)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
           
            </Grid>
        </Box>
    )
}

export default HistoryMplTobaccoComponent;
