import LandingScreen from "../screen/LandingScreen";
import JoinNowScreen from "../screen/JoinNowScreen";
import AboutUsScreen from "../screen/AboutUsScreen";
import ProductsComponent from "../components/landing/products/products.component";
import SolutionsComponent from "../components/landing/solutions/solutions.component";
import UsecaseComponent from "../components/landing/usecase/usecase.component";
import PrivacyPolicyComponent from "../components/resources/privacy-policy.component";
import VerifyComponent from "../components/landing/verify/verify.component";
import FAQComponent from "../components/support/faq/faq.component";
import PDFViewerComponent from "../components/landing/verify/pdf-viewer.component";
import JSONViewerComponent from "../components/landing/verify/json-viewer.component";
import TextViewerComponent from "../components/landing/verify/text-viewer.component";
import VerifyReferenceLinkScreen from "../screen/VerifyReferenceLinkScreen";
import ElectronicSignatureComponent from "../components/products/electronic-signature/electronic-signature.component";
import ElectronicIdentificationComponent from "../components/products/electronic-identification/electronic-identification.component";
import VerificationComponent from "../components/enterprise/verification/verification.component";
import ComingSoonPage from "../components/landing/coming-soon.component";
import ContactUsComponent from "../components/support/contact-us.component";
import EnterpriseSupportComponent from "../components/support/enterprise-support.component";
import ProductSupportComponent from "../components/support/product-support.component";
import ElectronicMarkComponent from "../components/products/electronic-mark/electronic-mark.component";
import TermsOfServiceComponent from "../components/resources/terms-of-services2.component";
import ElectronicMarkLandingScreen from "../screen/eMarkLandingScreen";

import ClippedDrawer from "../screen/VersionHistoriesScreen";

import SuccessStoriesComponent from "../components/blog/content/eid-insights/success-stories.component";
import DocumentWorkflowComponent from "../components/blog/content/esign-insights/document-workflow.component";
import GuideImplementingComponent from "../components/blog/content/esign-insights/guide-implementing.component";
import VisualContentComponent from "../components/blog/content/emark-asset/visual-content.component";
import BlockchainComponent from "../components/blog/content/emark-asset/blockhain.component";
import UnlockGrowthComponent from "../components/blog/content/partner/unlock-growth.component";
import OvcodeDistributorComponent from "../components/blog/content/partner/ovcode-distributor.component";
import WhatToKnowComponent from "../components/blog/content/industry-insight/what-to-know.component";
import OvcodeRoleComponent from "../components/blog/content/industry-insight/ovcode-role.component";
import OVCDisclaimerScreen from "../screen/OVCDisclaimerScreen";
import EndtoEndEncryptionComponent from "../components/blog/content/ovcode-insights/end-to-end-encryption.component";
import PowerofAATLCertificationComponent from "../components/blog/content/ovcode-insights/power-of-aatl-certification.component";
import EnhancingUserExperienceComponent from "../components/blog/content/industry-insight/enhancing-user-experience.component";
import RealtimeVerificationComponent from "../components/blog/content/industry-insight/real-time-verification.component";
import BlockchainSynergyComponent from "../components/blog/content/industry-insight/blockchain-synergy.component";
import BuildingTrustComponent from "../components/blog/content/industry-insight/building-trust.component";
import DecodingOvcodeSecurityComponent from "../components/blog/content/industry-insight/decoding-ovcode-security.component";
import FutureProofingComponent from "../components/blog/content/industry-insight/future-proofing.component";
import SecureStorageComponent from "../components/blog/content/industry-insight/secure-storage.component";
import HybridApproachComponent from "../components/blog/content/industry-insight/hybrid-approach.component";
import SecurePaymentsComponent from "../components/blog/content/eid-insights/secure-payments.component";
import CybergemsAuthenticatingDigitalTreasuresComponent from "../components/blog/content/cybergems/cybergems-authenticating-digital-treasures.component";
import CybergemsUnveiledComponent from "../components/blog/content/cybergems/cybergems-unveiled.component";
import TheSignificanceOfAATLCertificationComponent from "../components/blog/content/ovcode-insights/the-significance-of-aatl-certification.component";
import SettingForStandardDigitalTransactionsComponent from "../components/blog/content/ovcode-insights/setting-standard-for-digital-transactions.component";
import CybergemsArtOfAuthenticityComponent from "../components/blog/content/cybergems/cybergems-art-of-authenticity.component";
import CybergemsTechnologyThatSecuresMemoriesComponent from "../components/blog/content/cybergems/cybergems-technology-that-secures-memories.component";
import CybergemsFutureOfCollectingComponent from "../components/blog/content/cybergems/cybergems-future-of-collecting.component";
import CybergemsOvcodeRoleNftComponent from "../components/blog/content/cybergems/cybergems-ovcode-role-in-nft.component";
import CybergemsInvestmentInFutureEraComponent from "../components/blog/content/cybergems/cybergems-investment-in-future-era.component";
import CybergemsLegacyInPixelsComponent from "../components/blog/content/cybergems/cybergems-legacy-in-pixels.component";
import CybergemsEvolutionOfDigitalCollectiblesComponent from "../components/blog/content/cybergems/cybergems-evolution-of-digital-collectibles.component";
import CybergemsIntersectingPathsComponent from "../components/blog/content/cybergems/cybergems-intersecting-paths.component";
import CloudTrustComponent from "../components/blog/content/industry-insight/how-ovcode-enhances-cloud-trust.component";
import TrustInDigitalWorldComponent from "../components/blog/content/industry-insight/trust-in-digital-world.component";
import CybergemsEmbracingNewEraComponent from "../components/blog/content/cybergems/cybergems-embracing-new-era.component";
import CybergemsElevatingToGlobalStandardComponent from "../components/blog/content/cybergems/cybergems-elevating-to-global-standards.component";
import ViewUnilabPdfComponent from "../components/unilab/view-unilab-pdf.component";
import TobaccoComponent from "../components/enterprise/verification/tobacco-verification.component";
import UnilabComponent from "../components/enterprise/verification/unilab-verification.component";
import GeolocationScreen from "../screen/GeolocationScreen";
import TransparentEcosystemComponent from "../components/blog/content/industry-insight/transparent-ecosystem.component";
import VerificationScreen from "../screen/VerificationScreen";
import BlogScreen from "../screen/BlogScreen";
import ReleaseNotesScreen from "../screen/ReleaseNoteScreen";
import RevolutionizingSecureStorageComponent from "../components/blog/content/ovcode-insights/revolutionizing-secure-storage.component";
import OvcodePioneeringTheShiftComponent from "../components/blog/content/industry-insight/ovcode-pioneering-the-shift.component";
import InternetOfThingsComponent from "../components/blog/content/industry-insight/internet-of-things.component";
import BlogContentComponent from "../components/blog/content/blog-content.component";
import SmartContractsComponent from "../components/blog/content/industry-insight/smart-contracts.component";
import MedicalDataIntegrityComponent from "../components/blog/content/industry-insight/medical-data-integrity.component";
import OvcodeWayComponent from "../components/blog/content/industry-insight/ovcode-way.component";
import VerificationSystemComponent from "../components/blog/content/industry-insight/verification-system.component";
import ReducingOnlineFraudComponent from "../components/blog/content/industry-insight/reducing-online-fraud.component";
import SupportAndCommunityBlogContentComponent from "../components/blog/content/support-and-community/support-and-community-content.component";

export const routes = [
    {
        path: "/",
        name: 'LandingScreen',
        element: <LandingScreen />,
        isPrivate: false
    },

    {
        path: "/electronic-mark-landing",
        name: 'ElectronicMarkLandingScreen',
        element: <ElectronicMarkLandingScreen />,
        isPrivate: false
    },

    {
        path: "/about-us",
        name: 'AboutUsScreen',
        element: <AboutUsScreen />,
        isPrivate: false
    },

    {
        path: "/coming-soon",
        name: 'ComingSoonPage',
        element: <ComingSoonPage />,
        isPrivate: false
    },

    {
        path: "/version-histories",
        name: 'ClippedDrawer',
        element: <ClippedDrawer/>,
        isPrivate: false
    },

    {
        path: "/version-histories-all",
        name: 'VersionHistoriesAllScreen',
        element: <ReleaseNotesScreen />,
        isPrivate: false
    },

    {
        path: "/version-histories-ovcode-enterprise",
        name: 'OvcodeReleaseNotesScreen',
        element: <ReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-ovcode-mobile",
        name: 'OvcodeMobileReleaseNotesScreen',
        element: <ReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-electronic-sign",
        name: 'ElectronicSignatureReleaseNotesScreen',
        element: <ReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-electronic-identification-admin",
        name: 'ElectronicIdentificationReleaseNotesScreen',
        element: <ReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-electronic-identification-android",
        name: 'ElectronicIdentificationAndroidAppReleaseNotesScreen',
        element: <ReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-electronic-identification-app-binding",
        name: 'ElectronicIdentificationAppBindingReleaseNotesScreen',
        element: <ReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-electronic-identification-web-app",
        name: 'ElectronicIdentificationWebAppReleaseNotesScreen',
        element: <ReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-electronic-identification-regClient",
        name: 'ElectronicIdentificationRegClientReleaseNotesScreen',
        element: <ReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/version-histories-national-identification-admin",
        name: 'NationalIdentificationAdminWebPortalReleaseNotesScreen',
        element: <ReleaseNotesScreen/>,
        isPrivate: false
    },

    {
        path: "/enterprise-support",
        name: 'EnterpriseSupportComponent',
        element: <EnterpriseSupportComponent />,
        isPrivate: false
    },

    {
        path: "/product-support",
        name: 'ProductSupportComponent',
        element: <ProductSupportComponent />,
        isPrivate: false
    },

    {
        path: "/system-policies-and-procedures",
        name: 'ComingSoonPage',
        element: <ComingSoonPage />,
        isPrivate: false
    },


    {
        path: "/contact-us",
        name: 'ContactUsComponent',
        element: <ContactUsComponent />,
        isPrivate: false
    },

    {
        path: "/join",
        name: 'JoinNowScreen',
        element: <JoinNowScreen />,
        isPrivate: false
    }, 

    {
        path: "/products",
        name: 'ProductsComponent',
        element: <ProductsComponent />,
        isPrivate: false
    },

    {
        path: "/electronic-identification",
        name: 'ElectronicIdentificationComponent',
        element: <ElectronicIdentificationComponent />,
        isPrivate: false
    },

    {
        path: "/electronic-signature",
        name: 'ElectronicSignatureComponent',
        element: <ElectronicSignatureComponent />,
        isPrivate: false
    },

    
    {
        path: "/electronic-mark",
        name: 'ElectronicMarkComponent',
        element: <ElectronicMarkComponent />,
        isPrivate: false
    },

    {
        path: "/enterprise",
        name: 'VerificationComponent',
        element: <VerificationComponent />,
        isPrivate: false
    },

    {
        path: "/tobacco",
        name: 'TobaccoComponent',
        element: <TobaccoComponent />,
        isPrivate: false
    },

    {
        path: "/unilab/:id",
        name: 'UnilabComponent',
        element: <UnilabComponent />,
        isPrivate: false
    },
    

    {
        path: "/solutions",
        name: 'SolutionsComponent',
        element: <SolutionsComponent />,
        isPrivate: false
    },

    {
        path: "/use-case",
        name: 'UsecaseComponent',
        element: <UsecaseComponent />,
        isPrivate: false
    },

    {
        path: "/privacy-policy",
        name: 'PrivacyPolicyComponent',
        element: <PrivacyPolicyComponent />,
        isPrivate: false
    },

    {
        path: "/verify",
        name: 'VerifyComponent',
        element: <VerifyComponent />,
        isPrivate: false
    },

    {
        path: "/faq",
        name: 'FAQComponent',
        element: <FAQComponent />,
        isPrivate: false
    },

    {
        path: "/terms-of-service",
        name: 'TermsOfServiceComponent',
        element: <TermsOfServiceComponent />,
        isPrivate: false
    },

    {
        path: "/view/pdf",
        name: 'PDFViewerComponent',
        element: <PDFViewerComponent />,
        isPrivate: false
    },

    {
        path: "/view/json",
        name: 'JSONViewerComponent',
        element: <JSONViewerComponent />,
        isPrivate: false
    },

    {
        path: "/view/text",
        name: 'TextViewerComponent',
        element: <TextViewerComponent />,
        isPrivate: false
    },

    {
        path: "/v/:referenceCode",
        name: 'VerifyReferenceLinkScreen ',
        element: <VerifyReferenceLinkScreen />,
        isPrivate: false
    },

    
    {
        path: "/blog-ovcode-insights",
        name: 'OVCODEInsightsScreen ',
        element: <BlogScreen />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems",
        name: 'CYbergemsBlogScreen',
        element: <BlogScreen />,
        isPrivate: false
    },

    {
        path: "/blog-eID-in-depth",
        name: 'ElectronicIdentificationInDepthScreen ',
        element: <BlogScreen />,
        isPrivate: false
    },

    {
        path: "/blog-eSign-in-depth",
        name: 'ElectronicSignInDepthScreen ',
        element: <BlogScreen />,
        isPrivate: false
    },

    {
        path: "/blog-eMark-as-asset",
        name: 'ElectronicMarkAsAssetScreen ',
        element: <BlogScreen />,
        isPrivate: false
    },

    {
        path: "/blog-support-and-community",
        name: 'SupportAndCommunityScreen ',
        element: <BlogScreen />,
        isPrivate: false
    },

    {
        path: "/blog-partners",
        name: 'PartnersScreen ',
        element: <BlogScreen />,
        isPrivate: false
    },

    {
        path: "/blog-industry-insights",
        name: 'IndustryInsightsScreen ',
        element: <BlogScreen />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-evolution",
        name: 'TheEvolutionOfDigitalTrustComponent ',
        // element: <TheEvolutionOfDigitalTrustComponent />,
        element: <BlogContentComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-digital-trust",
        name: 'WhyDigitalTrustMatterComponent ',
        element: <BlogContentComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-trailblazer-blockchain",
        name: 'TrailblazerBlockchainComponent ',
        element: <BlogContentComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-securing-future",
        name: 'SecuringTheFutureComponent ',
        element: <BlogContentComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-in-news",
        name: 'LatestNewsComponent',
        element: <BlogContentComponent />,
        isPrivate: false
    },

    {
        path: "/blog-eid-identity-verification",
        name: 'IdentityVerificationComponent',
        element: <BlogContentComponent />,
        isPrivate: false
    },

    {
        path: "/blog-eid-success-stories",
        name: 'SuccessStoriesComponent',
        element: <SuccessStoriesComponent />,
        isPrivate: false
    },

    {
        path: "/blog-esign-document-workflow",
        name: 'DocumentWorkflowComponent',
        element: <DocumentWorkflowComponent />,
        isPrivate: false
    },

    {
        path: "/blog-esign-unlocking-efficiency",
        name: 'GuideImplementingComponent',
        element: <GuideImplementingComponent />,
        isPrivate: false
    },

    {
        path: "/blog-emark-visual-content",
        name: 'VisualContentComponent',
        element: <VisualContentComponent />,
        isPrivate: false
    },

    {
        path: "/blog-emark-blockhain",
        name: 'BlockchainComponent',
        element: <BlockchainComponent />,
        isPrivate: false
    },

    {
        path: "/blog-support-navigating-ovcode",
        name: 'SupportAndCommunityBlogContentComponent',
        element: <SupportAndCommunityBlogContentComponent />,
        isPrivate: false
    },

    {
        path: "/blog-support-navigating-secure-storage",
        name: 'SupportAndCommunityBlogContentComponent',
        element: <SupportAndCommunityBlogContentComponent />,
        isPrivate: false
    },

    {
        path: "/blog-support-community-spotlight",
        name: 'SupportAndCommunityBlogContentComponent',
        element: <SupportAndCommunityBlogContentComponent />,
        isPrivate: false
    },

    {
        path: "/blog-support-data-privacy",
        name: 'SupportAndCommunityBlogContentComponent',
        element: <SupportAndCommunityBlogContentComponent />,
        isPrivate: false
    },
    

    {
        path: "/blog-partner-unlock-growth",
        name: 'UnlockGrowthComponent',
        element: <UnlockGrowthComponent />,
        isPrivate: false
    },

    {
        path: "/blog-partner-ovcode-distributor",
        name: 'OvcodeDistributorComponent',
        element: <OvcodeDistributorComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-what-to-know",
        name: 'WhatToKnowComponent',
        element: <WhatToKnowComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-ovcode-role",
        name: 'OvcodeRoleComponent',
        element: <OvcodeRoleComponent />,
        isPrivate: false
    },

    {
        path: "/ovc-disclaimer",
        name: 'OVCDisclaimerScreen',
        element: <OVCDisclaimerScreen />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-beyond-kpi",
        name: 'BeyondKPIComponent',
        element: <BlogContentComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-powered-kpi",
        name: 'PoweredKPIComponent',
        element: <BlogContentComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-end-to-end-encryption",
        name: 'EndtoEndEncryptionComponent',
        element: <EndtoEndEncryptionComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-revolutionizing-secure-storage",
        name: 'RevolutionizingSecureStorageComponent',
        element: <RevolutionizingSecureStorageComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-power-of-AATL-certification",
        name: 'PowerofAATLCertificationComponent',
        element: <PowerofAATLCertificationComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-user-experience",
        name: 'EnhancingUserExperienceComponent',
        element: <EnhancingUserExperienceComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-real-time-verification",
        name: 'RealtimeVerificationComponent',
        element: <RealtimeVerificationComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-blockchain-synergy",
        name: 'BlockchainSynergyComponent',
        element: <BlockchainSynergyComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-building-trust",
        name: 'BuildingTrustComponent',
        element: <BuildingTrustComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-decoding-security",
        name: 'DecodingOvcodeSecurityComponent',
        element: <DecodingOvcodeSecurityComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-future-proofing",
        name: 'FutureProofingComponent',
        element: <FutureProofingComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-pioneering-shift",
        name: 'OvcodePioneeringTheShiftComponent',
        element: <OvcodePioneeringTheShiftComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-flexible-and-secure-storage",
        name: 'SecureStorageComponent',
        element: <SecureStorageComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-cloud-trust",
        name: 'CloudTrustComponent',
        element: <CloudTrustComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-internet-of-things",
        name: 'InternetOfThingsComponent',
        element: <InternetOfThingsComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-trust-digital-world",
        name: 'TrustInDigitalWorldComponent',
        element: <TrustInDigitalWorldComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-hybrid-approach",
        name: 'HybridApproachComponent',
        element: <HybridApproachComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-transparent-ecosystem",
        name: 'TransparentEcosystemComponent',
        element: <TransparentEcosystemComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-cloud-trust",
        name: 'CloudTrustComponent',
        element: <CloudTrustComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-smart-contracts",
        name: 'SmartContractsComponent',
        element: <SmartContractsComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-medical-data-integrity",
        name: 'MedicalDataIntegrityComponent',
        element: <MedicalDataIntegrityComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-ovcode-way",
        name: 'OvcodeWayComponent',
        element: <OvcodeWayComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-verification-system",
        name: 'VerificationSystemComponent',
        element: <VerificationSystemComponent />,
        isPrivate: false
    },

    {
        path: "/blog-industry-reducing-online-fraud",
        name: 'ReducingOnlineFraudComponent',
        element: <ReducingOnlineFraudComponent />,
        isPrivate: false
    },

    {
        path: "/blog-eid-secure-payments",
        name: 'SecurePaymentsComponent',
        element: <SecurePaymentsComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-authenticating-digital-treasure",
        name: 'CybergemsAuthenticatingDigitalTreasuresComponent',
        element: <CybergemsAuthenticatingDigitalTreasuresComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-unveiled",
        name: 'CybergemsUnveiledComponent',
        element: <CybergemsUnveiledComponent />,
        isPrivate: false
    },

    

    {
        path: "/blog-ovcode-insights-significance-of-certification",
        name: 'TheSignificanceOfAATLCertificationComponent',
        element: <TheSignificanceOfAATLCertificationComponent />,
        isPrivate: false
    },

    {
        path: "/blog-ovcode-insights-setting-standard-for-digital-transactions",
        name: 'SettingForStandardDigitalTransactionsComponent',
        element: <SettingForStandardDigitalTransactionsComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-art-of-authenticity",
        name: 'CybergemsArtOfAuthenticityComponent',
        element: <CybergemsArtOfAuthenticityComponent />,
        isPrivate: false
    },


    {
        path: "/blog-cybergems-secures-memories",
        name: 'CybergemsTechnologyThatSecuresMemoriesComponent',
        element: <CybergemsTechnologyThatSecuresMemoriesComponent />,
        isPrivate: false
    },
   
    {
        path: "/blog-cybergems-future-collecting",
        name: 'CybergemsFutureOfCollectingComponent',
        element: <CybergemsFutureOfCollectingComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-ovcode-role-nft",
        name: 'CybergemsOvcodeRoleNftComponent',
        element: <CybergemsOvcodeRoleNftComponent />,
        isPrivate: false
    },
   
    {
        path: "/blog-cybergems-investment-era",
        name: 'CybergemsInvestmentInFutureEraComponent',
        element: <CybergemsInvestmentInFutureEraComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-pixel-legacy",
        name: 'CybergemsLegacyInPixelsComponent',
        element: <CybergemsLegacyInPixelsComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-evolution-of-digital-collectibles",
        name: 'CybergemsEvolutionOfDigitalCollectiblesComponent',
        element: <CybergemsEvolutionOfDigitalCollectiblesComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-intersecting-paths",
        name: 'CybergemsIntersectingPathsComponent',
        element: <CybergemsIntersectingPathsComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-embracing-new-era",
        name: 'CybergemsEmbracingNewEraComponent',
        element: <CybergemsEmbracingNewEraComponent />,
        isPrivate: false
    },

    {
        path: "/blog-cybergems-elevating-to-global-standard",
        name: 'CybergemsElevatingToGlobalStandardComponent',
        element: <CybergemsElevatingToGlobalStandardComponent />,
        isPrivate: false
    },
   
    {
        path: "/view/pdf/verification",
        name: 'ViewUnilabPdfComponent',
        element: <ViewUnilabPdfComponent />,
        isPrivate: false
    },

    {
        path: "/geolocation",
        name: 'GelocationScreen',
        element: <GeolocationScreen />,
        isPrivate: false
    },

    {
        path: "/verification",
        name: 'VerificationScreen',
        element: <VerificationScreen />,
        isPrivate: false
    },
   

]



